import { create } from 'zustand';

const initialState = {
    fields: {
        user_id: '',
        password: '',
    },
    isError: false,
    modal: {
        socialError: false,
    },
};

const useLoginStore = create((set) => ({
    ...initialState,
    setField: (fieldName, text) => {
        set((state) => ({
            fields: {
                ...state.fields,
                [fieldName]: text,
            },
        }));
    },
    setIsError: (boolean) => {
        set({
            isError: boolean,
        });
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useLoginStore;
