import { create } from 'zustand';

const calculatePeriod = (select, range) => {
    let from_date = null;
    let to_date = null;

    if (select === 'week') {
        const currentDate = new Date();
        const today = currentDate.getDate();
        const dayOfWeek = currentDate.getDay();

        const startOfWeek = new Date(currentDate);
        startOfWeek.setDate(today - dayOfWeek + (dayOfWeek === 0 ? -6 : 1)); // 일요일이면 -6일, 아니면 월요일로 설정
        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(startOfWeek.getDate() + 6); // 이번 주 일요일로 설정

        from_date = startOfWeek;
        to_date = endOfWeek;
    } else if (select === 'month') {
        const currentDate = new Date();
        from_date = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        to_date = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    } else if (select === 'custom') {
        from_date = range.from;
        to_date = range.to;
    } else if (select === 'today') {
        const currentDate = new Date();
        from_date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );
        to_date = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
        );
    }

    return { from_date, to_date };
};

const initialState = {
    category: {
        all: true,
        New: false,
        Confirm: false,
        Complete: false,
        NoShow: false,
        Cancel: false,
    },
    period: {
        from_date: new Date(),
        to_date: new Date(),
    },
    periodList: null,
    isToday: true,
    isSummary: true,
    isSelectDate: false,
    modal: {
        detail: {
            isDetail: false,
            detailData: {},
            status: '',
        },
    },
    selectedStatus: null,
    searchName: '',
};

const useCustomerStore = create((set) => ({
    ...initialState,
    toggleCategory: (select) => {
        set({
            category: {
                all: select === '',
                New: select === 'New',
                Confirm: select === 'Confirm',
                Complete: select === 'Complete',
                NoShow: select === 'NoShow',
                Cancel: select === 'Cancel',
                Reject: select === 'Reject',
            },
            selectedStatus: select,
        });
    },
    setName: (text) => {
        set({ searchName: text });
    },

    setPeriod: (select, range) => {
        const { from_date, to_date } = calculatePeriod(select, range);
        set({
            period: {
                from_date: from_date,
                to_date: to_date,
            },
            isToday: false,
        });
    },

    setPeriodList: (text) => {
        set({
            periodList: text,
        });
    },

    toggleToday: () => {
        set((state) => {
            const { from_date, to_date } = calculatePeriod(state.isToday ? 'month' : 'today');
            return {
                ...state,
                period: {
                    from_date: from_date,
                    to_date: to_date,
                },
                isToday: !state.isToday,
            };
        });
    },

    toggleSummary: () => {
        set((state) => ({ isSummary: !state.isSummary }));
    },

    toggleIsSelectDate: () => {
        set((state) => ({ isSelectDate: !state.isSelectDate }));
    },

    setDetailData: (obj) => {
        set((state) => {
            const updatedState = {
                modal: {
                    ...state.modal,
                    detail: {
                        isDetail: true,
                        detailData: {
                            ...state.modal.detail.detailData,
                            ...obj,
                        },
                    },
                },
            };

            return updatedState;
        });
    },
    closeDetail: () => {
        set((state) => {
            const updatedState = {
                modal: {
                    ...state.modal,
                    detail: {
                        ...state.modal.detail,
                        isDetail: false,
                    },
                },
            };

            return updatedState;
        });
    },
    setStatus: (text) => {
        set((state) => ({
            modal: {
                ...state.modal,
                detail: {
                    isDetail: true,
                    detailData: {
                        ...state.modal.detail.detailData,
                    },
                    status: text,
                },
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useCustomerStore;
