import { create } from 'zustand';

const initialState = {
    screen: {
        id: true,
        password: false,
    },
    fields: {
        user_id: '',
        receiveData: 'find-id',
    },
    popup: {
        verifyConfirmErrorID: false,
        verifyConfirmErrorPW: false,
    },
    res: {},
    findId: false,
    findPw: false,
    isCert: false,
};

const useForgotStore = create((set) => ({
    ...initialState,
    setScreen: (screenName) =>
        set({
            screen: {
                id: screenName === 'id',
                password: screenName === 'password',
            },
        }),
    setField: (key, value) =>
        set((state) => ({
            fields: {
                ...state.fields,
                [key]: value,
            },
        })),
    setResponse: (obj) =>
        set({
            res: {
                ...obj,
            },
        }),
    setState: (key, value) =>
        set({
            [key]: value,
        }),
    togglePopup: (popUpName) => {
        set((state) => ({
            popup: {
                ...state.popup,
                [popUpName]: !state.popup[popUpName],
            },
        }));
    },
    setIsCert: (boolean) => {
        set({ isCert: boolean });
    },
    screenTerminate: () => {
        set((state) => ({ ...initialState, screen: state.screen }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useForgotStore;
