import { useRoutes, Navigate, createBrowserRouter } from 'react-router-dom';
import Layout from '@/screens/common/Layout';
import PrivateRoute from './PrivateRoute';
import Home from '@/screens/common/Home';
import Discount from '@/screens/common/Discount';
import BannerDetail from '@/screens/common/BannerDetail';
import DesignerDetail from '@/screens/designer/DesignerDetail';
import StylePhotos from '@/screens/designer/StylePhotos';
import Reservation from '@/screens/user/Reservation';
import History from '@/screens/user/History';
import HistoryDetail from '@/screens/user/HistoryDetail';
import Review from '@/screens/user/Review';
import Mypage from '@/screens/user/MyPage';
import MyReview from '@/screens/user/MyReview';
import ReviewEdit from '@/screens/user/ReviewEdit';
import MyLikes from '@/screens/user/MyLikes';
import MyPageEdit from '@/screens/user/MyPageEdit';
import MyDelete from '@/screens/user/MyDelete';
import NoticeList from '@/screens/board/NoticeList';
import ArchiveList from '@/screens/board/ArchiveList';
import TermList from '@/screens/common/TermList';
import LoginEmail from '@/screens/common/LoginEmail';
import Forgot from '@/screens/common/Forgot';
import Join from '@/screens/common/Join';
import DesignerRoute from './DesignerRoute';
import InfoDesigner from '@/screens/designer/InfoDesigner';
import Around from '@/screens/common/Around';
import Neighbour from '@/screens/common/Neighbour';
import ServiceList from '@/screens/designer/ServiceList';
import Schedule from '@/screens/designer/Schedule';
import DayOffs from '@/screens/designer/DayOffs';
import Customers from '@/screens/designer/Customers';
import Faq from '@/screens/common/Faq';
import QnaList from '@/screens/board/QnaList';
import SearchResult from '@/screens/common/SearchResult';
import Notice from '@/screens/board/Notice';
import Archive from '@/screens/board/Archive';
import LocationMap from '@/screens/common/LocationMap';
import LicenseBuy from '@/screens/designer/LicenseBuy';
import MySns from '@/screens/user/MySns';
import NaverLoginRedirect from '@/components/social/naver/NaverLoginRedirect';
import KakaoLoginRedirect from '@/components/social/kakao/KakaoLoginRedirect';
import PayRedirect from '../screens/designer/PayRedirect';
import NiceSelfAuthRedirect from '@/components/nice/NiceSelfAuthRedirect';
import AppleLoginRedirect from '../components/social/apple/AppleLoginRedirect';
import Privacy from '../screens/common/Privacy';
import Term from '../screens/common/Term';
import Agreement from '../screens/common/Agreement';
import LocationTerm from '../screens/common/LocationTerm';

export const router = createBrowserRouter([
    {
        path: '/',
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Home />,
            },
            {
                path: '/discount',
                element: <Discount />,
            },

            {
                path: '/banner/:id',
                element: <BannerDetail />,
            },
            {
                path: '/designer/:id',
                element: <DesignerDetail />,
            },
            {
                path: '/styles/:id',
                element: <StylePhotos />,
            },
            {
                path: '/reservation/:id',
                element: (
                    <PrivateRoute>
                        <Reservation />
                    </PrivateRoute>
                ),
            },
            {
                path: '/history',
                element: (
                    <PrivateRoute>
                        <History />
                    </PrivateRoute>
                ),
            },
            {
                path: '/history/:id',
                element: (
                    <PrivateRoute>
                        <HistoryDetail />
                    </PrivateRoute>
                ),
            },
            {
                path: '/review',
                element: (
                    <PrivateRoute>
                        <Review />
                    </PrivateRoute>
                ),
            },
            {
                path: '/my',
                children: [
                    {
                        path: '/my/',
                        element: (
                            <PrivateRoute>
                                <Mypage />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: '/my/review',
                        element: (
                            <PrivateRoute>
                                <MyReview />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: '/my/review/:id',
                        element: (
                            <PrivateRoute>
                                <ReviewEdit />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: '/my/likes',
                        element: (
                            <PrivateRoute>
                                <MyLikes />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: '/my/edit',
                        element: (
                            <PrivateRoute>
                                <MyPageEdit />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: '/my/delete',
                        element: (
                            <PrivateRoute>
                                <MyDelete />
                            </PrivateRoute>
                        ),
                    },
                    {
                        path: '/my/sns',
                        element: (
                            <PrivateRoute>
                                <MySns />
                            </PrivateRoute>
                        ),
                    },
                ],
            },
            {
                path: '/notice',
                element: (
                    // <PrivateRoute>
                    <NoticeList />
                    // </PrivateRoute>
                ),
            },
            {
                path: '/notice/:id',
                element: (
                    // <PrivateRoute>
                    <Notice />
                    // </PrivateRoute>
                ),
            },
            {
                path: '/archive',
                element: (
                    // <PrivateRoute>
                    <ArchiveList />
                    // </PrivateRoute>
                ),
            },
            {
                path: '/archive/:id',
                element: (
                    // <PrivateRoute>
                    <Archive />
                    // </PrivateRoute>
                ),
            },
            {
                path: '/term-list',
                children: [
                    {
                        path: '/term-list/',
                        element: <TermList />,
                    },
                    {
                        path: '/term-list/privacy',
                        element: <Privacy />,
                    },
                    {
                        path: '/term-list/term',
                        element: <Term />,
                    },
                    {
                        path: '/term-list/agreement',
                        element: <Agreement />,
                    },
                    {
                        path: '/term-list/location-term',
                        element: <LocationTerm />,
                    },
                ],
            },
            {
                path: '/login',
                element: <LoginEmail />,
            },
            {
                path: '/login/forgot',
                element: <Forgot />,
            },
            {
                path: '/join/:type',
                element: <Join />,
            },
            {
                path: '/info-designer',
                element: (
                    <DesignerRoute>
                        <InfoDesigner />
                    </DesignerRoute>
                ),
            },
            {
                path: '/around',
                element: <Around />,
            },
            {
                path: '/map',
                element: <LocationMap />,
            },
            {
                path: '/service-list',
                element: (
                    <DesignerRoute>
                        <ServiceList />
                    </DesignerRoute>
                ),
            },
            {
                path: '/schedule',
                element: (
                    <DesignerRoute>
                        <Schedule />
                    </DesignerRoute>
                ),
            },
            {
                path: '/dayoffs',
                element: (
                    <DesignerRoute>
                        <DayOffs />
                    </DesignerRoute>
                ),
            },
            {
                path: '/customers',
                element: (
                    <DesignerRoute>
                        <Customers />
                    </DesignerRoute>
                ),
            },
            {
                path: '/faq',
                element: (
                    <PrivateRoute>
                        <Faq />
                    </PrivateRoute>
                ),
            },
            {
                path: '/qna',
                element: (
                    <PrivateRoute>
                        <QnaList />
                    </PrivateRoute>
                ),
            },

            {
                path: '/result',
                element: <SearchResult />,
            },
            {
                path: '/license',
                element: (
                    <DesignerRoute>
                        <LicenseBuy />
                    </DesignerRoute>
                ),
            },
        ],
    },
    {
        path: '/oauth-naver',
        element: <NaverLoginRedirect />,
    },
    {
        path: '/oauth-kakao',
        element: <KakaoLoginRedirect />,
    },
    {
        path: '/oauth-apple',
        element: <AppleLoginRedirect />,
    },
    {
        path: '/payment/callback',
        element: <PayRedirect />,
    },
    {
        path: '/nice/callback',
        element: <NiceSelfAuthRedirect />,
    },
    { path: '*', element: <Navigate to="/" /> },
]);
