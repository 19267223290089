import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Flxbt,
    Spacing30,
    Fz18Medium,
    LiStyled,
    TxtDivider,
    Fz16Medium,
    BorderBox,
    Container,
    ContainerPadding,
    SectionDivider,
    FlxMiddle,
    FlxbtFull,
    Fz15,
    Fz20Bold,
    ColAccent,
    ItemHalf2,
    FlxbtFullMb20,
    LinkFlxMiddle,
    ColGray1,
    MyLink,
    BtnLineGray,
    Fz15Bold,
    Mb20,
    Gap10,
    Gap20,
    Pad20,
    PopShortMsg,
    ListCol2,
    BtnHalfModalBlack,
    BtnHalfModal,
    AsideBigMenu,
    VertDivider,
    AsideMenu,
    TxCent,
    Paragraph,
    Gap15,
    Mb10,
    Spacing36,
    LineGrayBox,
    Mb30,
    ColRed,
    ColPrimary,
    FlxbtFullMb30,
    Spacing10,
} from '@/css/style';
import { useIsMutating, useQueryClient } from '@tanstack/react-query';
import { useReservationSummaryQuery } from '@/services/reservationService';
import useUserStore from '@/stores/user/user';
import ChkOnOff from '@/components/ChkOnOff';
import arrowLightThickGray from '@/assets/img/arrow_right_thick_gray.svg';
import icLoginKakao from '@/assets/img/ic_my_kakao.svg';
import icLoginNaver from '@/assets/img/ic_my_naver.svg';
import icLoginApple from '@/assets/img/ic_my_apple.svg';
import icMyArchive from '@/assets/img/ic_my_archive.svg';
import icMyBell from '@/assets/img/ic_my_bell.svg';
import icMyCheck from '@/assets/img/ic_my_check.svg';
import icMyLike from '@/assets/img/ic_my_like.svg';
import icMyReview from '@/assets/img/ic_my_review.svg';
import icShareBl from '@/assets/img/ic_share_bl.svg';
import { useMyQuery } from '@/services/authService';
import icRsvInfo from '@/assets/img/ic_rsv_info.svg';
import icRsvUser from '@/assets/img/ic_rsv_user.svg';
import icDesigner from '@/assets/img/ic_designer_on.svg';
import icCheck from '@/assets/img/ic_check.svg';
import icChat from '@/assets/img/ic_chat.svg';
import icSmile from '@/assets/img/ic_smile.svg';
import icCalendar from '@/assets/img/ic_calendar.svg';
import icWarnBig from '@/assets/img/ic_warn_big.svg';

import { useDesignerDetailForAsideQuery } from '@/services/designerService';
import {
    useDesignerActiveMutation,
    useDesignerInActiveMutation,
    useDesignerMenuQuery,
} from '@/services/designerService';
import { format, parse } from 'date-fns';
import NewModal from '@/components/NewModal';
import { useShopDiscountQuery } from '@/services/shopService';
import DcShopSlider from '@/components/DcShopSlider';
import DcAllianceSlider from '@/components/DcAllianceSlider';
import useWindowSize from '@/hooks/useWindowSize';

function Mypage() {
    const { width } = useWindowSize();
    const us = useUserStore();
    const queryClient = useQueryClient();
    const location = useLocation();
    const [modal1, setModal1] = React.useState(false);
    const [modal2, setModal2] = React.useState(false);
    const [modal3, setModal3] = React.useState(false);
    const { data } = useReservationSummaryQuery(us.user.id);
    const { data: myData } = useMyQuery();
    const { data: designerData, refetch: refetchDesignerData } = useDesignerDetailForAsideQuery(
        us.user.id,
        us.user.role
    );
    const { data: shopDiscountData } = useShopDiscountQuery({
        limit: 5,
    });
    const { data: designerMenuData, refetch: refetchDesignerMenuData } = useDesignerMenuQuery(
        us.user.id,
        { actived: true },
        { enabled: us.user.role === 'DESIGNER' }
    );
    const DesignerActive = useDesignerActiveMutation(() => refetchDesignerData());
    const DesignerInActive = useDesignerInActiveMutation(() => refetchDesignerData());
    const requestActiveToggle = () => {
        if (!designerData?.data.subscription) {
            setModal1(true);
            return;
        } else if (designerMenuData?.data.length == 0) {
            setModal2(true);
            return;
        } else if (!designerData?.data.profileImage) {
            setModal3(true);
            return;
        } else {
            designerData?.data.actived
                ? DesignerInActive.mutate(us.user.id)
                : DesignerActive.mutate(us.user.id);
        }
    };
    const isMutating = useIsMutating();

    let kakaoData = myData?.data.socials?.find((item) => item.provider === 'kakao') || null;
    let naverData = myData?.data.socials?.find((item) => item.provider === 'naver') || null;
    let appleData = myData?.data.socials?.find((item) => item.provider === 'apple') || null;
    return (
        <Container $pb="6.46rem">
            <ContainerPadding>
                <Spacing10 />
                <Fz20Bold $mb="1.538rem">
                    환영합니다. <ColAccent>{us.user.name}</ColAccent> 님!
                </Fz20Bold>
                <FlxbtFullMb20>
                    <FlxMiddle>
                        <Fz15>{us.user.user_id}</Fz15>
                        {(kakaoData || naverData || appleData) && (
                            <React.Fragment>
                                <Gap10 />
                                {kakaoData && <img src={icLoginKakao} alt="카카오 로그인" />}
                                {naverData && <img src={icLoginNaver} alt="네이버 로그인" />}
                                {appleData && <img src={icLoginApple} alt="애플 로그인" />}
                            </React.Fragment>
                        )}
                    </FlxMiddle>
                    <LinkFlxMiddle to="/my/edit" state={{ previousUrl: location.pathname }}>
                        <ColGray1 as="strong">내 정보 수정</ColGray1>&nbsp;
                        <img src={arrowLightThickGray} alt="카카오 로그인" />
                    </LinkFlxMiddle>
                </FlxbtFullMb20>
            </ContainerPadding>
            {us.user.role === 'DESIGNER' && (
                <>
                    <SectionDivider />
                    {(!designerData?.data.approved ||
                        (designerData?.data.approved && !designerData?.data.subscription) ||
                        (designerData?.data.approved &&
                            designerData?.data.subscription &&
                            designerMenuData?.data.length == 0) ||
                        (designerData?.data.approved &&
                            designerData?.data.subscription &&
                            designerMenuData?.data.length > 0 &&
                            !designerData?.data.actived)) && (
                        <ContainerPadding>
                            <Mb30>
                                <BorderBox $pd="1.154rem">
                                    <Paragraph $col="#000" $fz="1.154rem">
                                        {!designerData?.data.approved && (
                                            <>
                                                근무 헤어샵 승인이 완료되면 서비스 이용 가능합니다.
                                                <br />
                                                혹시 변경이 필요하다면{' '}
                                                <ColPrimary>내 정보 수정</ColPrimary>에서 변경해
                                                주세요.
                                            </>
                                        )}

                                        {designerData?.data.approved &&
                                            !designerData?.data.subscription && (
                                                <>라이센스 결제 후 이용하실 수 있습니다.</>
                                            )}

                                        {designerData?.data.approved &&
                                            designerData?.data.subscription &&
                                            designerMenuData?.data.length == 0 && (
                                                <>
                                                    판매를 원하시면 <ColRed>시술 메뉴 등록</ColRed>
                                                    에서 메뉴을 등록해 주세요.
                                                </>
                                            )}

                                        {
                                            designerData?.data.approved &&
                                            designerData?.data.subscription &&
                                            designerMenuData?.data.length > 0 &&
                                            !designerData?.data.profileImage && (
                                                <>
                                                    <ColRed>디자이너 정보</ColRed>에서 프로필 대표 사진을 등록해 주세요.   
                                                </>
                                            )
                                        }

                                        {designerData?.data.approved &&
                                            designerData?.data.subscription &&
                                            designerMenuData?.data.length > 0 &&
                                            designerData?.data.profileImage &&
                                            !designerData?.data.actived && (
                                                <div style={{
                                                    lineHeight: 1.5,
                                                    fontSize: '14px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'wrap'
                                                  }}>
                                                    아래 <span style={{ margin: '0 4px', verticalAlign: 'middle', display: 'inline-flex', alignItems: 'center' }}>
                                                      <ChkOnOff
                                                        checked={false}
                                                        scale={0.9}
                                                        textOn="노출중"
                                                        textOff="미노출"
                                                      />
                                                    </span> 눌러 상태 변경하면 고객이 예약가능합니다.
                                                    {/* <span style={{ margin: '0 4px', verticalAlign: 'middle', display: 'inline-flex', alignItems: 'center' }}>
                                                      <ChkOnOff
                                                        checked={true}
                                                        scale={0.9}
                                                        textOn="노출중"
                                                        textOff="미노출"
                                                      /></span>  */}
                                                      {/* 변경하면 고객이 예약가능 */}
                                                  </div>
                                            )}
                                    </Paragraph>
                                </BorderBox>
                            </Mb30>
                        </ContainerPadding>
                    )}
                    <ContainerPadding>
                        {designerData?.data.approved && (
                            <FlxbtFullMb30>
                                {/* <div> */}
                                    <ChkOnOff
                                        checked={designerData?.data.actived}
                                        onChange={() => requestActiveToggle()}
                                        // id="designerShow"
                                        disabled={!!isMutating}
                                        scale={width <= 414 ? 1.3 : 1.4}
                                        textOn="노출중"
                                        textOff="미노출"
                                    />
                                    {/* <Mb10 />
                                    <Fz15>
                                        {!designerData?.data.actived ? '고객이 예약할 수 없습니다.' : '고객이 예약할 수 있습니다.'}    
                                    </Fz15>
                                </div> */}
                                <FlxMiddle>
                                    {designerData?.data.subscription && (
                                        <Fz15>
                                            {' '}
                                            {format(
                                                parse(
                                                    designerData?.data.subscription_exp,
                                                    "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                    new Date()
                                                ),
                                                width <= 375
                                                    ? '만료일 : yy.MM.dd'
                                                    : '라이센스 만료 : yy.MM.dd'
                                            )}
                                        </Fz15>
                                    )}
                                    <Gap10 />
                                    <BtnLineGray
                                        $active
                                        as={Link}
                                        to="/license"
                                        state={{ previousUrl: location.pathname }}
                                    >
                                        {designerData?.data.subscription ? '연장하기' : '결제하기'}
                                    </BtnLineGray>
                                </FlxMiddle>
                            </FlxbtFullMb30>
                        )}
                        {!designerData?.data.approved ? (
                            <ContainerPadding>
                                <FlxbtFull>
                                    <AsideBigMenu>
                                        <img src={icRsvInfo} />
                                        <span>받은예약 관리</span>
                                    </AsideBigMenu>
                                    <VertDivider />
                                    <AsideBigMenu
                                        $active
                                        to="/service-list"
                                        state={{ previousUrl: location.pathname }}
                                    >
                                        <img src={icDesigner} />
                                        <span style={designerMenuData?.data.length == 0 ? {color: 'rgb(243, 53, 53)'} : {}}>시술 메뉴 등록</span>
                                    </AsideBigMenu>
                                    <VertDivider />
                                    <AsideBigMenu
                                        $active
                                        to="/info-designer"
                                        state={{ previousUrl: location.pathname }}
                                    >
                                        <img src={icRsvUser} />
                                        <span style={!designerData?.data.profileImage ? {color: 'rgb(243, 53, 53)'} : {}}>디자이너 정보</span>
                                    </AsideBigMenu>
                                </FlxbtFull>
                            </ContainerPadding>
                        ) : (
                            <ContainerPadding>
                                <FlxbtFullMb20>
                                    <AsideBigMenu
                                        to="/customers"
                                        $active
                                        state={{ previousUrl: location.pathname }}
                                        style={{ width: 'calc(33%-1px)' }}
                                    >
                                        <img src={icRsvInfo} alt="" />
                                        <span>받은예약 관리</span>
                                    </AsideBigMenu>
                                    <VertDivider />
                                    <AsideBigMenu
                                        to="/service-list"
                                        $active
                                        state={{ previousUrl: location.pathname }}
                                        style={{ width: 'calc(33%-1px)' }}
                                    >
                                        <img src={icDesigner} alt="" />
                                        <span style={designerMenuData?.data.length == 0 ? {color: 'rgb(243, 53, 53)'} : {}}>시술 메뉴 등록</span>
                                    </AsideBigMenu>
                                    <VertDivider />
                                    <AsideBigMenu
                                        to="/info-designer"
                                        $active
                                        state={{ previousUrl: location.pathname }}
                                        style={{ width: 'calc(33%-1px)' }}
                                    >
                                        <img src={icRsvUser} alt="" />
                                        <span style={!designerData?.data.profileImage ? {color: 'rgb(243, 53, 53)'} : {}}>디자이너 정보</span>
                                    </AsideBigMenu>
                                </FlxbtFullMb20>
                            </ContainerPadding>
                        )}
                    </ContainerPadding>
                    {designerData?.data.approved && (
                        <ContainerPadding>
                            <Spacing10 />
                            <Spacing10 />
                            <AsideMenu
                                as={Link}
                                to="/schedule"
                                state={{ previousUrl: location.pathname }}
                                style={{ width: 'calc(33%-1px)' }}
                            >
                                <img src={icCheck} />
                                브레이크타임 관리
                            </AsideMenu>
                            <AsideMenu
                                as={Link}
                                to="/dayoffs"
                                state={{ previousUrl: location.pathname }}
                                style={{ width: 'calc(33%-1px)' }}
                            >
                                <img src={icChat} />
                                고정/유동 휴무일 관리
                            </AsideMenu>
                            {/* <AsideMenu
                                as={Link}
                                to="/customers"
                                state={{ previousUrl: location.pathname }}
                                style={{ width: 'calc(33%-1px)' }}
                            >
                                <img src={icSmile} />
                                예약 고객 관리
                            </AsideMenu> */}
                        </ContainerPadding>
                    )}
                </>
            )}
            <SectionDivider />

            <ContainerPadding>
                <MyLink to="/history" state={{ previousUrl: location.pathname }}>
                    <img src={icCalendar} />
                    <Fz18Medium>나의 예약</Fz18Medium>
                </MyLink>
                <MyLink to="/my/review" state={{ previousUrl: location.pathname }}>
                    <img src={icMyReview} />
                    <Fz18Medium>나의 리뷰</Fz18Medium>
                </MyLink>
                <MyLink to="/my/likes" state={{ previousUrl: location.pathname }}>
                    <img src={icMyLike} />
                    <Fz18Medium>관심 디자이너</Fz18Medium>
                </MyLink>
                <MyLink to="/my/sns" state={{ previousUrl: location.pathname }}>
                    <img src={icShareBl} />
                    <Fz18Medium>소셜 로그인 연동</Fz18Medium>
                </MyLink>
                <MyLink to="/notice" state={{ previousUrl: location.pathname }}>
                    <img src={icMyBell} />
                    <Fz18Medium>공지사항</Fz18Medium>
                </MyLink>
                <MyLink to="/archive" state={{ previousUrl: location.pathname }}>
                    <img src={icMyArchive} />
                    <Fz18Medium>아카이브</Fz18Medium>
                </MyLink>
                <MyLink to="/term-list" state={{ previousUrl: location.pathname }}>
                    <img src={icMyCheck} />
                    <Fz18Medium>약관 내용 확인</Fz18Medium>
                </MyLink>

                {/* <DcAllianceSlider
                    sliderSetting={{
                        dots: true,
                        infinite: true,
                        arrows: true,
                        rows: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        autoplay: true,
                        autoplaySpeed: 3000,
                        centerMode: false,
                    }}
                    className="slider--banner"
                /> */}

                {/* {shopDiscountData?.pages && shopDiscountData?.pages[0].data.totalDocs > 0 ? (
                    <>
                        <DcShopSlider
                            sliderSetting={{
                                dots: true,
                                infinite: true,
                                arrows: true,
                                rows: 1,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                autoplay: true,
                                autoplaySpeed: 3000,
                                centerMode: false,
                            }}
                            className="slider--banner"
                            data={shopDiscountData}
                        />
                    </>
                ) : null} */}
            </ContainerPadding>
            <Spacing30 />
            <Flxbt style={{ width: '45%', margin: 'auto' }}>
                <ItemHalf2 $jc="center">
                    <Link
                        onClick={() => {
                            queryClient.clear();
                            us.logout();
                        }}
                    >
                        <Fz15 $col="#a1a1a1">로그아웃</Fz15>
                    </Link>
                </ItemHalf2>
                <TxtDivider />
                <ItemHalf2 $jc="center">
                    <Link to="/my/delete" state={{ previousUrl: location.pathname }}>
                        <Fz15 $col="#a1a1a1">회원탈퇴</Fz15>
                    </Link>
                </ItemHalf2>
            </Flxbt>
            <NewModal contentLabel="결제 권장" ariaHideApp={false} isOpen={modal1}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">라이센스 결제 후 이용하실 수 있습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack type="button" onClick={() => setModal1(false)}>
                            닫기
                        </BtnHalfModalBlack>
                        <BtnHalfModal
                            as={Link}
                            to="/license"
                            state={{ previousUrl: location.pathname }}
                        >
                            결제하기
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
            <NewModal contentLabel="결제 권장" ariaHideApp={false} isOpen={modal2}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">시술 메뉴 등록 후 이용하실 수 있습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack type="button" onClick={() => setModal2(false)}>
                            닫기
                        </BtnHalfModalBlack>
                        <BtnHalfModal
                            as={Link}
                            to="/service-list"
                            state={{ previousUrl: location.pathname }}
                        >
                            시술 메뉴 등록
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
            <NewModal contentLabel="프로필 사진을 등록해 주세요." ariaHideApp={false} isOpen={modal3}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">디자이너 정보에서 프로필 대표 사진을 등록해 주세요.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack type="button" onClick={() => setModal3(false)}>
                            닫기
                        </BtnHalfModalBlack>
                        <BtnHalfModal
                            as={Link}
                            to="/info-designer"
                            state={{ previousUrl: location.pathname }}
                        >
                            디자이너 정보
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </Container>
    );
}

export default Mypage;
