import React from 'react';
import { PostImg, MyHeader, Container, ContainerPadding, Fz20Bold, Paragraph } from '@/css/style';
import { useArchiveReadQuery } from '@/services/boardService';
import { useParams } from 'react-router-dom';
import { format, parse } from 'date-fns';
import { editBoardContentImg } from '@/utils/helper';

function Archive() {
    const param = useParams();
    const { data: archiveData } = useArchiveReadQuery(param.id);
    return (
        <Container>
            <MyHeader>
                <div>
                    <Fz20Bold $mb="1.538rem">{archiveData?.data.title}</Fz20Bold>
                    <span>
                        {archiveData?.data.created &&
                            format(
                                parse(
                                    archiveData?.data.created,
                                    "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                    new Date()
                                ),
                                'yyyy. MM. dd'
                            )}
                    </span>
                </div>
            </MyHeader>
            <ContainerPadding>
                {/* <Paragraph $col="#000" $fz="1.154rem"> */}
                <div
                    dangerouslySetInnerHTML={{
                        __html: editBoardContentImg(
                            archiveData?.data.body ? archiveData?.data.body : ''
                        ),
                    }}
                />
                {/* </Paragraph> */}
                {/* <PostImg>
                    <img src="https://talkimg.imbc.com/TVianUpload/tvian/TViews/image/2021/01/20/8fc8a78a-70c1-45d9-98b0-4a70c4629a12.jpg" />
                </PostImg> */}
            </ContainerPadding>
        </Container>
    );
}

export default Archive;
