import React, { useState } from 'react';
import {
    FaqItem,
    MyHeader,
    Paragraph,
    FlxbtFull,
    Container,
    Fz20Bold,
    NavHeight,
    Mb20,
    FaqContent,
} from '@/css/style';
import arrowDownGray from '@/assets/img/arrow_down_thin_gray.svg';
import arrowUpGray from '@/assets/img/arrow_up_thin_gray.svg';

const faqList = [
    {
        question: '머리할래는 어디에서 사용할 수 있습니까?',
        aTitle: '답변입니다.',
        aContent:
            '이 라이선스라는 것은 이미 SIR의 솔루션을 이용하는 방법에 대해 알려 드리고 있는 것이므로 이것에 대한 별도의 답변이나 안내는 적절하지 않습니다.\n라이선스의 내용이 다소 어렵더라도 해결책은 고스란히 라이선스에 나와 있습니다.결론적으로 라이선스는 저희 SIR의 어떤 답변보다도 우선하므로 라이선스를 숙지하여 적용해 주시기 바랍니다.',
    },
    {
        question: 'X에 관한 질문',
        aTitle: '답변 제목 2',
        aContent: 'X에 대한 답변 내용 2',
    },
    {
        question: '페이스북에 관한 질문',
        aTitle: '답변 제목 3',
        aContent: '페이스북에 대한 답변 내용 3',
    },
];

function Faq() {
    const [visible, setVisible] = useState(null);

    const toggleVisibility = (index) => {
        setVisible((prevVisible) => (prevVisible === index ? null : index));
    };

    return (
        <>
            <Container>
                <NavHeight />
                <MyHeader $mb="0">
                    <Fz20Bold>FAQ</Fz20Bold>
                </MyHeader>
                {/* 약관 리스트 */}
                <ul>
                    {faqList.map((option, index) => (
                        <FaqItem key={index}>
                            <FlxbtFull
                                as="button"
                                type="button"
                                onClick={() => toggleVisibility(index)}
                                className={visible === index ? 'on' : ''}
                            >
                                {option.question}
                                <img
                                    src={visible === index ? arrowUpGray : arrowDownGray}
                                    alt={visible === index ? '접기' : '펼치기'}
                                />
                            </FlxbtFull>
                            <FaqContent $visible={visible === index}>
                                <Mb20>
                                    <strong>{option.aTitle}</strong>
                                </Mb20>
                                <Paragraph $col="#000" $fz="1.154rem">
                                    {option.aContent}
                                </Paragraph>
                            </FaqContent>
                        </FaqItem>
                    ))}
                </ul>
                {/* //약관 리스트 */}
            </Container>
        </>
    );
}

export default Faq;
