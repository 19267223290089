import React, { useEffect, useRef } from 'react';
import { Link, useBlocker, useLocation, useOutletContext } from 'react-router-dom';
import {
    ColAccent2,
    ConfirmInfoItem,
    InfoLabel,
    InfoValue,
    Container,
    ContainerPadding,
    Fz20Bold,
    NavHeight,
    Mb30,
    BtnFullLine,
    PopOver,
    StickyBtm,
    BtnFullModal,
    Fz18Bold,
    Spacing70,
    Fz15Bold,
    JoinField,
    PadVert10,
    InputWrapper,
    JoinInput,
    BtnClearInput,
    WarnInput,
    DescInput,
    BtnCloseModal,
    BtnInInputRound,
    BtnLineGreenShorter,
    TimeLeft,
    Mb10,
    Paragraph,
    PadVert30,
    Pad20,
    PopShortMsg,
    Mb20,
    ListColumn,
    FlxbtFullMb20,
    Fz18Medium,
    Fz15,
    BtnFullLineGreen,
    LineCenter,
    AbsolBtm,
    ListCol2,
    BtnHalfModalBlack,
    BtnHalfModal,
    PossibleInput,
    TermOver,
    PadVert30PopOver,
} from '@/css/style';
import { passwordRegex } from '@/utils/helper';
import { useDesignerDetailForAsideQuery } from '@/services/designerService';
import { nicknameRegex, useIntersectionObserver } from '@/utils/helper';
import { useChkNickQuery, useGetCertificateDataQuery, useMyQuery } from '@/services/authService';
import { useGetDealerQuery, useShopQuery } from '@/services/shopService';
import { useUserUpdateMutation } from '@/services/userService';
import useMyPageStore from '@/stores/user/mypage';
import NewModal from '@/components/NewModal';
import NoContent from '@/components/NoContent';
import RadioBox from '@/components/RadioBox';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import icClear from '@/assets/img/ic_clear.svg';
import icWarn from '@/assets/img/ic_warn.svg';
import { useDesignerUpdateMutation } from '@/services/designerService';
import useUserStore from '@/stores/user/user';
import { useIsMutating } from '@tanstack/react-query';
import { toast } from 'react-toastify';

function Mypage() {
    const location = useLocation();
    const ms = useMyPageStore();
    const us = useUserStore();
    const containerRef = useRef(null);
    const dealerRef = useRef(null);
    const isMutating = useIsMutating();
    const { data: myData, refetch } = useMyQuery();
    const { navigate } = useOutletContext();

    useEffect(() => {
        if (myData) {
            ms.setUser(myData.data);
        }
    }, [myData]);

    const { data: designerData, refetch: refetchDesignerData } = useDesignerDetailForAsideQuery(
        us.user.id,
        us.user.role
    );

    const {
        data: shopData,
        refetch: shopRefetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useShopQuery({
        name: ms.fields.salon,
    });

    const {
        data: dealerData,
        isLoading: dealerDataLoading,
        refetch: dealerDataRefetch,
        fetchNextPage: dealerDataFetchNextPage,
        hasNextPage: dealerDataHasNextPage,
        isFetchingNextPage: dealerDataIsFetchingNextPage,
    } = useGetDealerQuery({}, ms.fields.manager_id);

    const { refetch: chkNickRefetch } = useChkNickQuery(
        ms,
        (res) => {
            ms.setVerify('isCheckedNick', true);
        },
        (error) => {
            ms.setVerify('isCheckedNick', false);
        }
    );

    const { refetch: certificateRefetch } = useGetCertificateDataQuery('edit_phone', us.user.id);

    const UserUpdateMutation = useUserUpdateMutation((res) => {
        if (res.status === 200) {
            refetch();
            ms.terminate();
            ms.closeAllModals();
        } else {
            toast.error(res.response?.data.message);
        }
    });

    const DesignerUpdateMutation = useDesignerUpdateMutation(() => {
        refetch();
        refetchDesignerData();
        ms.terminate();
        ms.closeAllModals();
    });

    const searchSalon = () => {
        if (!ms.fields.salon) {
            return;
        }
        shopRefetch();
        ms.togglePopUp('searchSalon');
    };

    const observer = useIntersectionObserver(
        fetchNextPage,
        containerRef,
        shopData,
        isFetchingNextPage
    );
    const observerDealer = useIntersectionObserver(
        dealerDataFetchNextPage,
        dealerRef,
        dealerData,
        dealerDataIsFetchingNextPage
    );

    const requestUserData = (validate, field) => {
        if (!validate) {
            return;
        }
        if (Object.prototype.hasOwnProperty.call(field, 'shop_id')) {
            DesignerUpdateMutation.mutate({ ...field });
        } else {
            UserUpdateMutation.mutate({ ...ms.user, ...field });
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
            ms.terminate();
        };
    }, []);

    function handleMessage(event) {
        const messageData = event.data;
        if (messageData.success && messageData.data?.code === '-1') {
            ms.toggleModal('editPhoneErr');
            ms.setIsCert(true);
            ms.setCertError(messageData.data.data);
        } else if (messageData.success && messageData.data?.code === '0000') {
            ms.toggleModal('editPhoneConfirm');
            ms.setIsCert(true);
        }
    }

    const requestCertificate = () => {
        const parentLeft = window.screenLeft || window.screenX || 0;
        const parentTop = window.screenTop || window.screenY || 0;

        const popupWidth = 500;
        const popupHeight = 600;

        const left = parentLeft + (window.innerWidth - popupWidth) / 2;
        const top = parentTop + (window.innerHeight - popupHeight) / 2;

        const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;
        if (window.ReactNativeWebView) {
        } else {
            window.open('', 'nicePopup', option);
        }

        certificateRefetch();
    };

    let blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            Object.values(ms.modal).some((value) => value === true) ||
            Object.values(ms.popUpStates).some((value) => value === true) ||
            nextLocation.pathname === '/nice/callback'
    );

    useEffect(() => {
        if (blocker.state === 'blocked') {
            Object.values(ms.popUpStates).some((value) => value === true)
                ? ms.closeAllPopUps()
                : Object.values(ms.modal).some((value) => value === true)
                ? ms.closeAllModals()
                : window.ReactNativeWebView && navigate(-5, { replace: true });
            blocker.reset();
        }
    }, [blocker.state]);
    return (
        <>
            <Container>
                <ContainerPadding>
                    <Fz20Bold $mb="3rem">내 정보</Fz20Bold>
                    {/* 정보 목록 */}
                    <Mb30>
                        <ConfirmInfoItem>
                            <InfoLabel>이름</InfoLabel>
                            <InfoValue>{ms.user.name}</InfoValue>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>휴대폰번호</InfoLabel>
                            <InfoValue>{ms.user.phone}</InfoValue>
                            <form
                                name="form"
                                id="form"
                                action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
                            >
                                <input type="hidden" id="m" name="m" value="service" />
                                <input
                                    type="hidden"
                                    id="token_version_id"
                                    name="token_version_id"
                                    value=""
                                />
                                <input type="hidden" id="enc_data" name="enc_data" />
                                <input type="hidden" id="integrity_value" name="integrity_value" />
                            </form>
                            <ColAccent2
                                as="button"
                                type="button"
                                onClick={() => requestCertificate()}
                            >
                                수정
                            </ColAccent2>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>아이디</InfoLabel>
                            <InfoValue>{ms.user.user_id}</InfoValue>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>비밀번호</InfoLabel>
                            <ColAccent2
                                as="button"
                                type="button"
                                onClick={() => ms.toggleModal('password')}
                            >
                                수정
                            </ColAccent2>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>닉네임</InfoLabel>
                            <InfoValue>{ms.user.nickname}</InfoValue>
                            <ColAccent2
                                as="button"
                                type="button"
                                onClick={() => ms.toggleModal('nickname')}
                            >
                                수정
                            </ColAccent2>
                        </ConfirmInfoItem>
                        {ms.user.role === 'DESIGNER' && (
                            <>
                                <ConfirmInfoItem>
                                    <InfoLabel>근무 헤어샵</InfoLabel>
                                    <InfoValue>{designerData?.data.shop.name}</InfoValue>
                                    <ColAccent2
                                        as="button"
                                        type="button"
                                        onClick={() => ms.toggleModal('shop')}
                                    >
                                        수정
                                    </ColAccent2>
                                </ConfirmInfoItem>
                                {designerData?.data.dealer && (
                                    <ConfirmInfoItem>
                                        <InfoLabel>추천인</InfoLabel>
                                        <InfoValue>{designerData?.data.dealer.name}</InfoValue>
                                    </ConfirmInfoItem>
                                )}
                            </>
                        )}
                        {/* //정보 목록 */}
                    </Mb30>
                    {ms.user.role === 'DESIGNER' && (
                        <BtnFullLine
                            as={Link}
                            to="/info-designer"
                            state={{ previousUrl: location.pathname }}
                        >
                            디자이너 추가 정보
                        </BtnFullLine>
                    )}
                </ContainerPadding>
            </Container>
            {/* 비밀번호 수정 팝업 */}
            <PopOver $visible={ms.modal.password}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => ms.toggleModal('password')}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <Spacing70 />
                    <fieldset>
                        <Fz18Bold $mb="3.077rem">비밀번호 수정</Fz18Bold>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>현재 비밀번호</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="currentUserPw"
                                    name="currentUserPw"
                                    placeholder="비밀번호를 입력해 주세요"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        ms.setField('current_password', value);
                                    }}
                                    value={
                                        ms.fields.current_password ? ms.fields.current_password : ''
                                    }
                                    autoComplete="currentUserPw"
                                />
                                <BtnClearInput type="button">
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                        </JoinField>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>새 비밀번호</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="userPwConfirm"
                                    name="userPwConfirm"
                                    placeholder="새 비밀번호를 입력해 주세요"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        ms.setField('password', value);
                                    }}
                                    value={ms.fields.password ? ms.fields.password : ''}
                                    autoComplete="userPwConfirm"
                                />
                                <BtnClearInput type="button">
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                            {!passwordRegex.test(ms.fields.password) && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 최소 8자리 이상 영문+숫자+기호
                                    조합으로 작성해 주세요
                                </WarnInput>
                            )}
                        </JoinField>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>새 비밀번호 확인</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="userPw2"
                                    name="userPw2"
                                    placeholder="비밀번호를 한번 더 입력해 주세요"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        ms.setField('confirm_password', value);
                                    }}
                                    value={
                                        ms.fields.confirm_password ? ms.fields.confirm_password : ''
                                    }
                                />
                                <BtnClearInput type="button">
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                            {(ms.fields.password === undefined ||
                                ms.fields.password !== ms.fields.confirm_password) && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 비밀번호가 일치하지 않습니다.
                                </WarnInput>
                            )}
                        </JoinField>
                    </fieldset>
                    <AbsolBtm>
                        <BtnFullModal
                            type="button"
                            $active={
                                !(
                                    ms.fields.password === undefined ||
                                    ms.fields.password !== ms.fields.confirm_password ||
                                    ms.fields.current_password === undefined
                                ) && passwordRegex.test(ms.fields.password)
                            }
                            onClick={() =>
                                requestUserData(
                                    !(
                                        ms.fields.password === undefined ||
                                        ms.fields.password !== ms.fields.confirm_password ||
                                        ms.fields.current_password === undefined
                                    ) && passwordRegex.test(ms.fields.password),
                                    {
                                        password: ms.fields.password,
                                        confirm_password: ms.fields.confirm_password,
                                        current_password: ms.fields.current_password,
                                    }
                                )
                            }
                            disabled={!!isMutating}
                        >
                            저장
                        </BtnFullModal>
                    </AbsolBtm>
                </ContainerPadding>
            </PopOver>
            {/* //비밀번호 수정 팝업 */}
            {/* 닉네임 수정 팝업 */}
            <PopOver $visible={ms.modal.nickname}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => ms.toggleModal('nickname')}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <Spacing70 />
                    <fieldset>
                        <Fz18Bold $mb="3.077rem">닉네임 수정</Fz18Bold>
                        <InputWrapper>
                            <JoinInput
                                type="text"
                                id="userNick"
                                name="userNick"
                                placeholder="닉네임을 입력해 주세요"
                                maxLength="12"
                                onChange={(e) => {
                                    ms.setVerify('isCheckedNick', '');
                                    const { value } = e.target;
                                    ms.setField('nickname', value);
                                }}
                                value={ms.fields.nickname ? ms.fields.nickname : ''}
                            />
                            <BtnInInputRound onClick={() => chkNickRefetch()}>
                                중복 확인
                            </BtnInInputRound>
                        </InputWrapper>

                        {!nicknameRegex.test(ms.fields.nickname) ? (
                            <WarnInput>
                                <img src={icWarn} alt="경고" />
                                <span>
                                    한글 또는 영문 대소문자 또는 숫자로 2~10자 입력해 주세요.
                                </span>
                            </WarnInput>
                        ) : ms.verify.isCheckedNick === false ? (
                            <WarnInput>
                                <img src={icWarn} alt="경고" />
                                <span>사용 중인 닉네임입니다.</span>
                            </WarnInput>
                        ) : ms.verify.isCheckedNick === true ? (
                            <PossibleInput>
                                <span>사용 가능한 닉네임입니다.</span>
                            </PossibleInput>
                        ) : null}

                        <DescInput>▸ 서비스 이용 시 노출되는 이름입니다</DescInput>
                    </fieldset>
                    <AbsolBtm>
                        <BtnFullModal
                            type="button"
                            $active={
                                nicknameRegex.test(ms.fields.nickname) && ms.verify.isCheckedNick
                            }
                            onClick={() => {
                                if (
                                    nicknameRegex.test(ms.fields.nickname) &&
                                    ms.verify.isCheckedNick
                                ) {
                                    requestUserData(true, {
                                        nickname: ms.fields.nickname,
                                    });
                                }
                            }}
                        >
                            저장
                        </BtnFullModal>
                    </AbsolBtm>
                </ContainerPadding>
            </PopOver>
            {/* //닉네임 수정 팝업 */}
            {/* 근무헤어샵 수정 팝업 */}
            <PopOver $visible={ms.modal.shop}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => ms.toggleModal('shop')}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <Spacing70 />
                    <fieldset>
                        <Fz18Bold $mb="3.077rem">근무 헤어샵 수정</Fz18Bold>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>근무 헤어샵</Fz15Bold>
                            </PadVert10>
                            <Mb10>
                                <InputWrapper>
                                    <JoinInput
                                        type="text"
                                        placeholder="샵 이름을 검색해 주세요"
                                        maxLength="12"
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            ms.setField('salon', value);
                                            ms.setField('isSearched', false);
                                            ms.setField('salonName', '');
                                            ms.setField('shop_id', '');
                                            ms.setField('manager_id', '');
                                            ms.setField('dealer_id', '');
                                            ms.setField('dealer_name', '');
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (!ms.fields.salon) {
                                                    return;
                                                }
                                                searchSalon();
                                            }
                                        }}
                                        value={
                                            ms.fields.isSearched
                                                ? ms.fields.salonName
                                                : ms.fields.salon
                                        }
                                    />
                                    <BtnInInputRound onClick={() => searchSalon()}>
                                        검색
                                    </BtnInInputRound>
                                </InputWrapper>
                            </Mb10>
                            <Paragraph $fz="1.154rem" $col="#A1A1A1">
                                디자이너 님이 활동하시는 매장 이름으로 검색해 주세요!
                                <br />( 근무 매장은 매니저 승인 후 등록 가능합니다. )
                            </Paragraph>
                        </JoinField>
                        {ms.fields.isSearched && (
                            <JoinField>
                                <PadVert10>
                                    <Fz15Bold>추천인 (선택)</Fz15Bold>
                                </PadVert10>
                                <Mb10>
                                    <InputWrapper>
                                        <JoinInput
                                            type="text"
                                            defaultValue={ms.fields.dealer_name}
                                            readOnly={true}
                                        />
                                        <BtnInInputRound
                                            onClick={() => {
                                                if (!ms.fields.salon) {
                                                    return;
                                                }
                                                dealerDataRefetch();
                                                ms.togglePopUp('searchDealer');
                                            }}
                                        >
                                            검색
                                        </BtnInInputRound>
                                    </InputWrapper>
                                </Mb10>
                                <Paragraph $fz="1.154rem" $col="#A1A1A1">
                                    검색한 매장의 추천인을 확인할 수 있습니다.
                                </Paragraph>
                            </JoinField>
                        )}
                    </fieldset>
                    <AbsolBtm>
                        <BtnFullModal
                            type="button"
                            $active={ms.fields.shop_id}
                            onClick={() =>
                                requestUserData(ms.fields.shop_id, {
                                    id: ms.user.id,
                                    shop_id: ms.fields.shop_id,
                                    dealer_id: ms.fields.dealer_id,
                                })
                            }
                        >
                            저장
                        </BtnFullModal>
                    </AbsolBtm>
                </ContainerPadding>
            </PopOver>
            {/* //근무헤어샵 수정 팝업 */}
            <TermOver $visible={ms.popUpStates.searchSalon}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => ms.closeAllPopUps()}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <NavHeight />
                    <PadVert30PopOver>
                        <ListColumn>
                            {shopData?.pages.map((page) =>
                                page.data.docs.map((item) => (
                                    <FlxbtFullMb20 key={item.id}>
                                        <label for={item.id}>
                                            <Fz18Medium $mb="1.538rem">{item.name}</Fz18Medium>
                                            <Fz15 $col="#a1a1a1">{item.address}</Fz15>
                                        </label>
                                        <RadioBox
                                            name="salon"
                                            checked={ms.fields.shop_id === item.id}
                                            onChange={() => {
                                                ms.setField('isSearched', true);
                                                ms.setField('salonName', item.name);
                                                ms.setField('shop_id', item.id);
                                                ms.setField('manager_id', item.manager);
                                            }}
                                            id={item.id}
                                            value={item}
                                        />
                                    </FlxbtFullMb20>
                                ))
                            )}
                            {!(shopData?.pages && shopData?.pages[0].data.docs.length > 0) && (
                                <NoContent message="검색된 헤어샵이 없습니다." />
                            )}
                        </ListColumn>
                        {hasNextPage && (
                            <LineCenter ref={containerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                    </PadVert30PopOver>
                </ContainerPadding>
                <StickyBtm>
                    <BtnFullModal
                        $active={ms.fields.isSearched}
                        onClick={() => ms.closeAllPopUps()}
                    >
                        선택
                    </BtnFullModal>
                </StickyBtm>
            </TermOver>
            <PopOver $visible={ms.popUpStates.searchDealer}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => ms.closeAllPopUps()}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <NavHeight />
                    <PadVert30>
                        <ListColumn>
                            {dealerData?.pages.map((page) =>
                                page.data.docs.map((item) => (
                                    <FlxbtFullMb20 key={item._id}>
                                        <div>
                                            <Fz18Medium $mb="1.538rem">{item.name}</Fz18Medium>
                                            <Fz15 $col="#a1a1a1">{item.referer_code}</Fz15>
                                        </div>
                                        <RadioBox
                                            name="salon"
                                            checked={ms.fields.dealer_id === item._id}
                                            onChange={() => {
                                                ms.setField('dealer_id', item._id);
                                                ms.setField('dealer_name', item.name);
                                            }}
                                            id={item._id}
                                            value={item}
                                        />
                                    </FlxbtFullMb20>
                                ))
                            )}
                            {!(dealerData?.pages && dealerData?.pages[0].data.docs.length > 0) && (
                                <NoContent message="검색된 추천인이 없습니다." />
                            )}
                        </ListColumn>

                        {dealerDataHasNextPage && (
                            <LineCenter ref={dealerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                    </PadVert30>
                </ContainerPadding>
                <StickyBtm>
                    <BtnFullModal
                        $active={ms.fields.isSearched}
                        onClick={() => ms.closeAllPopUps()}
                    >
                        선택
                    </BtnFullModal>
                </StickyBtm>
            </PopOver>
            {/* 가입한 아이디 있을 때 */}
            <NewModal
                isOpen={ms.modal.editPhoneErr}
                contentLabel="가입한 아이디가 있음"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">회원 정보를 찾을수 없습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            ms.toggleModal('editPhoneErr');
                        }}
                    >
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal
                isOpen={ms.modal.editPhoneConfirm}
                contentLabel="핸드폰 번호 수정 완료"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">입력하신 인증정보로 수정되었습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnFullModal
                            $active
                            onClick={() => {
                                ms.toggleModal('editPhoneConfirm');
                                refetch();
                            }}
                        >
                            확인
                        </BtnFullModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </>
    );
}
export default Mypage;
