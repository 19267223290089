import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    ContainerPadding,
    NavHeight,
    Mb10,
    SchInputWrapper,
    Mb1rem,
    Divider,
    TabAccent2,
    ColAccent,
    TabWrapper,
    FlxbtFullMb20,
    Fz15,
    Mb30,
    ListColumn,
    ItemDesigner,
    CircleImg,
    FullImg,
    DesignerInfo,
    Flxbt,
    DesignerName,
    FlxMiddle,
    DesignerSns,
    FlxMiddleMb10,
    DesignerRates,
    AroundScroll,
    RoundProfile,
    BtnLikeInImg,
    FlxbtFull,
    BtnToggleAccent,
    DesignerPrices,
    PriceItem,
    Fz16Medium,
    TxRight,
    ConsumerPrice,
    OrgPrice,
    LineCenter,
    Fz15Bold,
} from '@/css/style';
import useShowStore from '@/stores/common/show';
import TopStepBar from '@/components/TopStepBar';
import icLikeOffFill from '@/assets/img/ic_like_off_fill.svg';
import icReview from '@/assets/img/ic_review.svg';
import icSearch from '@/assets/img/ic_search_green.svg';
import { FaStar } from 'react-icons/fa';
import useSearchStore from '@/stores/common/search';
import useUserStore from '@/stores/user/user';
import NoContent from '@/components/NoContent';
import config from '@/config';
import altimg from '@/assets/img/altimg.png';
import icLikeOnLine from '@/assets/img/ic_like_on.svg';
import icLikeOffLine from '@/assets/img/ic_like_off.svg';
import { snsTable, useIntersectionObserver } from '@/utils/helper';
import arrowUpWhite from '@/assets/img/arrow_up_wh.svg';
import arrowDownGray from '@/assets/img/arrow_down_gray.svg';
import useDisCountStore from '@/stores/common/discount';
import { useBookMarkCreateMutation, useBookMarkDeleteMutation } from '@/services/userService';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import { useIsMutating } from '@tanstack/react-query';
import useMovePath from '@/hooks/useMovePath';

function SearchResult(props) {
    const ss = useShowStore();
    const us = useUserStore();
    const search = useSearchStore();
    const isMutating = useIsMutating();
    const ds = useDisCountStore();
    const navigate = useMovePath();
    const containerRef = useRef(null);
    const { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } = props.data;
    const BookMarkCreate = useBookMarkCreateMutation(() => refetch());
    const BookMarkDelete = useBookMarkDeleteMutation(() => refetch());

    const observer = useIntersectionObserver(fetchNextPage, containerRef, data, isFetchingNextPage);

    return (
        <>
            <TopStepBar
                isCancelBtn={() => {
                    window.scrollTo({ top: 0 });
                    search.toggleIsSearch(false);
                }}
            />
            <ContainerPadding>
                <Mb30>
                    {/* <SchInputWrapper>
                        <input
                            type="text"
                            value={keyword}
                            onChange={(e) => {
                                const { value } = e.target;
                                setKeyword(value);
                            }}
                            placeholder="검색"
                        />
                        <button type="button" onClick={requestSearchDesigner}>
                            <img src={icSearch} alt="찾기" />
                        </button>
                    </SchInputWrapper> */}
                </Mb30>
                <FlxbtFullMb20>
                    <Fz15 $col="#000">
                        총 <ColAccent>$</ColAccent>개
                    </Fz15>
                    {/* 목록 정렬 */}
                    <TabWrapper>
                        <TabAccent2 $active>추천순</TabAccent2>・<TabAccent2>거리순</TabAccent2>・
                        <TabAccent2>인기순</TabAccent2>
                    </TabWrapper>
                    {/* 목록 정렬 */}
                </FlxbtFullMb20>
            </ContainerPadding>
            <Divider $mb="2.3077rem" />
            <AroundScroll>
                <ListColumn>
                    {!isLoading && !(data?.pages && data?.pages[0].data.docs.length > 0) && (
                        <NoContent message="검색된 디자이너가 없습니다." />
                    )}
                    {data?.pages.map((page) =>
                        page.data.docs.map((items) => (
                            <ItemDesigner key={items._id}>
                                <RoundProfile>
                                    <FullImg
                                        src={`
                                                ${config.DESIGNER_IMG_URL}${items.profileImage?.filename}?size=150`}
                                        onError={(e) => {
                                            e.currentTarget.src = altimg;
                                        }}
                                        onClick={() => {
                                            ss.setShowGnbSearch(false);
                                            search.terminate();
                                            navigate(`/designer/${items._id}`);
                                        }}
                                        alt="프로필 이미지"
                                    />
                                    {us.user.user_id &&
                                        (items.isBookmarked ? (
                                            <BtnLikeInImg
                                                type="button"
                                                onClick={() => {
                                                    BookMarkDelete.mutate(items._id);
                                                }}
                                                disabled={!!isMutating}
                                            >
                                                <img src={icLikeOnLine} alt="좋아요" />
                                            </BtnLikeInImg>
                                        ) : (
                                            <BtnLikeInImg
                                                type="button"
                                                onClick={() => {
                                                    BookMarkCreate.mutate(items._id);
                                                }}
                                                disabled={!!isMutating}
                                            >
                                                <img src={icLikeOffLine} alt="좋아요" />
                                            </BtnLikeInImg>
                                        ))}
                                </RoundProfile>
                                <DesignerInfo>
                                    <Flxbt as={Mb10}>
                                        <strong>{items.shop?.name}</strong>
                                        <FlxMiddle>
                                            {items.sns.map((sns) => (
                                                <DesignerSns
                                                    href={sns.link}
                                                    target="_blank"
                                                    key={sns._id}
                                                >
                                                    <img
                                                        src={
                                                            snsTable.find(
                                                                (item) =>
                                                                    item.provider === sns.provider
                                                            ).src
                                                        }
                                                        alt={sns.provider}
                                                    />
                                                </DesignerSns>
                                            ))}
                                        </FlxMiddle>
                                    </Flxbt>
                                    <Flxbt as={Mb10}>
                                        <DesignerName
                                            onClick={() => {
                                                navigate(`/designer/${items._id}`);
                                            }}
                                        >
                                            {items.title}
                                        </DesignerName>
                                    </Flxbt>
                                    <FlxbtFull>
                                        <DesignerRates>
                                            <img src={icReview} alt="후기" />
                                            <strong>{items.ratingCount}</strong>
                                            <FaStar
                                                color="#FFBB00"
                                                style={{ marginLeft: '0.77rem' }}
                                            />
                                            <strong>
                                                {items.rating ? items.averageRating.toFixed(1) : 0}
                                            </strong>
                                        </DesignerRates>
                                        <BtnToggleAccent
                                            $active={ds.toggleStates[items._id]}
                                            onClick={() => ds.togglePrices(items._id)}
                                        >
                                            시술메뉴 {ds.toggleStates[items._id] ? '닫기' : '보기'}
                                            <img
                                                src={
                                                    ds.toggleStates[items._id]
                                                        ? arrowUpWhite
                                                        : arrowDownGray
                                                }
                                                alt="시술메뉴 토글"
                                            />
                                        </BtnToggleAccent>
                                    </FlxbtFull>
                                </DesignerInfo>
                                {items.menus &&
                                    items.menus.length > 0 &&
                                    ds.toggleStates[items._id] && (
                                        <DesignerPrices $mt="1.154rem">
                                            {items.menus.map((menus) => (
                                                <PriceItem key={menus._id}>
                                                    <FlxbtFull $mb={menus.option ? '0.77rem' : 0}>
                                                        <Fz16Medium>{menus.name}</Fz16Medium>

                                                        {menus.normal_price === menus.price ? (
                                                            <TxRight>
                                                                <ConsumerPrice>
                                                                    {menus.normal_price.toLocaleString()}
                                                                    원
                                                                </ConsumerPrice>
                                                            </TxRight>
                                                        ) : (
                                                            <TxRight>
                                                                <OrgPrice>
                                                                    {menus.normal_price.toLocaleString()}
                                                                </OrgPrice>
                                                                <ConsumerPrice>
                                                                    {menus.price.toLocaleString()}원
                                                                </ConsumerPrice>
                                                            </TxRight>
                                                        )}
                                                    </FlxbtFull>
                                                    {menus.option ? <p>{menus.option}</p> : null}
                                                </PriceItem>
                                            ))}
                                        </DesignerPrices>
                                    )}
                            </ItemDesigner>
                        ))
                    )}
                    {hasNextPage && (
                        <LineCenter ref={containerRef}>
                            <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                            <img src={arrowDownThinGray} alt="more" />
                        </LineCenter>
                    )}
                </ListColumn>
                <NavHeight />
            </AroundScroll>
        </>
    );
}

export default SearchResult;
