import { instance } from '@services/api';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';
const reviewService = {
    reviewCreate: async (reservId, data) => {
        const response = await instance.post(`/reservations/${reservId}/review`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    },
    reviewRead: (reservId) => {
        return instance.get(`/reservations/${reservId}/review`);
    },
    reviewUpdate: async (reservId, data) => {
        const response = await instance.put(`/reservations/${reservId}/review`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    },
    reviewDelete: (reviewId) => {
        return instance.delete(`/reservations/${reviewId}/review`);
    },
    review: (page) => {
        return instance.get(`/reviews`, {
            params: {
                page: page,
            },
        });
    },
};

export const useReviewCreateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            let { reservId, rating, content, pic } = obj;
            console.log(obj);
            const formData = new FormData();
            formData.append('rating', rating);
            formData.append('content', content);
            pic.forEach((item) => {
                const { fileData } = item;

                const files = fileData.files;
                console.log(files);
                Object.values(files).forEach((file) => {
                    formData.append('files', file);
                });
            });

            const response = await reviewService.reviewCreate(reservId, formData);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useReviewReadQuery = (reservId) => {
    return useQuery({
        queryKey: ['reviewRead', reservId],
        queryFn: () => reviewService.reviewRead(reservId),
    });
};

export const useReviewUpdateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            let { reservId, rating, content, pic, delete_file_ids } = obj;
            console.log(obj);
            const formData = new FormData();
            formData.append('rating', rating);
            formData.append('content', content);
            delete_file_ids.map((fileId, index) =>
                formData.append(`delete_file_ids[${index}]`, fileId)
            );
            pic.forEach((item) => {
                const { fileData } = item;

                const files = fileData.files;
                Object.values(files).forEach((file) => {
                    formData.append('files', file);
                });
            });

            const response = await reviewService.reviewUpdate(reservId, formData);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useReviewDeleteMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (reviewId) => {
            console.log('reviewId', reviewId);
            const response = await reviewService.reviewDelete(reviewId);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useReviewQuery = () => {
    return useInfiniteQuery({
        queryKey: ['review'],
        queryFn: ({ pageParam }) => reviewService.review(pageParam),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
        gcTime: 0,
    });
};
