import { useState } from 'react';
import {
    InputHidden,
    FlxbtFullMb10,
    Fz15,
    Fz15Block,
    ConsumerPrice,
    Fz16Medium,
    OrgPrice,
    SettingOptions,
    FlxMiddleMb15,
} from '@/css/style';
import icChk from '@/assets/img/ic_chk_green.svg';
import icSetting from '@/assets/img/ic_setting_gray.svg';
import ChkOnOff from '@/components/ChkOnOff';

const ChkLabel = ({
    id,
    name,
    onChange,
    actived,
    serviceName,
    originalPrice,
    consumerPrice,
    serviceDescription,
    menuDelete,
    menuEdit,
    disabled,
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOptions = () => {
        setIsOpen(!isOpen);
    };
    const labelStyle = {
        display: 'block',
        width: '100%',
        padding: '1.538rem',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: actived ? `#00BF9D` : `#fff #fff #E8EAED #fff`,
        backgroundColor: actived ? `#E5F6F3` : null,
        color: actived ? `#757575` : `#a1a1a1`,
    };
    return (
        <label htmlFor={id} style={labelStyle}>
            <InputHidden
                type="checkbox"
                id={id}
                name={name}
                onChange={onChange}
                checked={actived}
                disabled={disabled}
            />
            <FlxbtFullMb10 $pos="relative">
                {/* <strong style={{ color: actived ? `#00BF9D` : `#a1a1a1` }}>
                    {actived ? <img src={icChk} /> : ''}
                    {actived ? ` ` : `미`}노출
                </strong> */}
                <ChkOnOff
                    checked={actived}
                    onChange={onChange}
                    scale={1}
                    textOn="판매"
                    textOff="숨김"
                />
                <button type="button" onClick={toggleOptions}>
                    <img src={icSetting} alt="설정" />
                </button>
                <SettingOptions className={isOpen ? 'open' : ''}>
                    <button
                        type="button"
                        onClick={() => {
                            menuEdit();
                            setIsOpen(!isOpen);
                        }}
                        disabled={disabled}
                    >
                        수정
                    </button>
                    <button type="button" onClick={menuDelete} disabled={disabled}>
                        삭제
                    </button>
                </SettingOptions>
            </FlxbtFullMb10>
            <Fz16Medium $mb="1.154rem" style={{ color: actived ? `#000` : `#a1a1a1` }}>
                {serviceName}
            </Fz16Medium>
            <FlxMiddleMb15>
                {originalPrice && (
                    <OrgPrice>
                        <Fz15>{originalPrice}원</Fz15>
                    </OrgPrice>
                )}

                <ConsumerPrice>
                    <Fz15 style={{ color: actived ? `#00BF9D` : `#a1a1a1` }}>
                        {consumerPrice}원
                    </Fz15>
                </ConsumerPrice>
            </FlxMiddleMb15>
            <Fz15Block>{serviceDescription}</Fz15Block>
        </label>
    );
};

export default ChkLabel;
