import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import {
    Container,
    ContainerPadding,
    Fz15,
    Fz20Bold,
    FlxbtFullMb20,
    LinkFull,
    StickyBtm,
    Divider,
    FlxMiddleMb20,
    Paragraph,
    PadVert30,
    PadVert20,
    TabAccent,
    Gap10,
    Mb20,
    ListColumn,
    Mb15,
    ColBlack,
    RadioLabel,
    BorderBoxGray,
    ChkAndTxt,
    ColBody,
    BorderBoxMb,
    BtnLineGray,
    ItemInfoCol2,
    InfoLabelR,
    InfoValue,
    Fz16Medium,
    ToggleShow,
    ListCol2,
    BtnHalfModalBlack,
    BtnHalfModal,
    BtnFullModal,
    Pad20,
    PopShortMsg,
    Fz15Bold,
    Mb30,
    ConfirmInfoItem,
    InfoLabel,
    BtnFullLine,
    Spacing70,
    PopOver,
    BtnCloseModal,
    LineCenter,
    OrgPrice,
    ConsumerPrice,
} from '@/css/style';
import {
    useDesignerSubscriptionHistoryQuery,
    usePayEncryptParamsMutation,
    useSubscriptionProductsQuery,
} from '@/services/designerService';
import useLicenseStore from '@/stores/designer/lisense';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import RadioBox from '@/components/RadioBox';
import NewModal from '@/components/NewModal';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import { useIntersectionObserver } from '@/utils/helper';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import NoContent from '@/components/NoContent';
import { format, parse } from 'date-fns';
import { useIsMutating } from '@tanstack/react-query';
import config from '@/config';

function LicenseBuy() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = `${config.PG_HOST}/resources/js/v1/SettlePG_v1.2.js`;
        document.head.appendChild(script);

        const userAgent = navigator.userAgent;
        let mobileYn = 'N';

        if (/iPhone|iPad|iPod|Android/i.test(userAgent)) {
            mobileYn = 'Y';
        }

        // OS 구분
        let osType = 'E'; // 기본값은 기타
        if (/Android/i.test(userAgent)) {
            osType = 'A'; // Android
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            osType = 'I'; // iOS
        } else if (/Windows/i.test(userAgent)) {
            osType = 'W'; // Windows
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
            osType = 'M'; // Mac OS
        }
        ls.setPayData({ mobileYn, osType });

        window.addEventListener('message', handleMessage);

        return () => {
            document.head.removeChild(script);
            window.removeEventListener('message', handleMessage);
            ls.terminate();
        };
    }, []);

    function handleMessage(event) {
        const messageData = event.data;
        if (messageData.success) {
            ls.toggleModal('method');
            ls.toggleModal('complete');
        } else if (messageData.success === false) {
            ls.toggleModal('method');
            ls.toggleModal('fail');
        }
    }

    const ls = useLicenseStore();
    const location = useLocation();
    const us = useUserStore();
    const isMutating = useIsMutating();
    const containerRef = useRef(null);
    const {
        data: historyData,
        isLoading,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useDesignerSubscriptionHistoryQuery(us.user.id);
    const observer = useIntersectionObserver(
        fetchNextPage,
        containerRef,
        historyData,
        isFetchingNextPage
    );

    const [isEncryptedReady, setIsEncryptedReady] = useState(false);

    useEffect(() => {
        if (isEncryptedReady) {
            PayEncryptParamsMutation.mutate(ls.payData);
            setIsEncryptedReady(false);
        } else {
            setIsEncryptedReady(false);
        }
    }, [isEncryptedReady]);

    const PayEncryptParamsMutation = usePayEncryptParamsMutation();

    const init = (type) => {
        var curr_date = new Date();
        var year = curr_date.getFullYear().toString();
        var month = ('0' + (curr_date.getMonth() + 1)).slice(-2).toString();
        var day = ('0' + curr_date.getDate()).slice(-2).toString();
        var hours = ('0' + curr_date.getHours()).slice(-2).toString();
        var mins = ('0' + curr_date.getMinutes()).slice(-2).toString();
        var secs = ('0' + curr_date.getSeconds()).slice(-2).toString();
        var random4 = ('000' + Math.random() * 10000).slice(-4).toString();

        // 초기 데이터 객체 생성
        const initData = {
            trdDt: year + month + day,
            trdTm: hours + mins + secs,
            mchtTrdNo: 'PAYMENT' + year + month + day + hours + mins + secs + random4,
            // plainMchtCustNm: us.user.name,
            plainMchtCustNm: 'test',
            plainMchtCustId: us.user.user_id,
            plainEmail: us.user.user_id,
            mchtName: '머리할래',
            mchtEName: 'hairhale',
        };

        // type에 따라 필요한 속성 추가
        switch (type) {
            case 'card':
                initData.method = 'card';
                initData.mchtId = 'nxca_jt_il';
                break;
            default:
                initData.method = type;
        }

        ls.setPayData(initData);
        setIsEncryptedReady(true);
    };

    const { data: subscriptionProductData } = useSubscriptionProductsQuery();

    return (
        <>
            <Container>
                <ContainerPadding>
                    <Paragraph $col="#000" $fz="1.154rem" as={PadVert30}>
                        {us.user.name}님, 머리할래와 함께해 주셔서 감사합니다.
                        <br />
                        서비스 이용을 위하여 정기 라이센스를 구입해 주셔야 합니다.
                    </Paragraph>
                    <FlxMiddleMb20>
                        <TabAccent $active={ls.screen.pay} onClick={() => ls.toggleScreen('pay')}>
                            결제하기
                        </TabAccent>
                        <Gap10 />
                        <TabAccent
                            $active={ls.screen.history}
                            onClick={() => ls.toggleScreen('history')}
                        >
                            결제이력
                        </TabAccent>
                    </FlxMiddleMb20>
                </ContainerPadding>{' '}
                <ToggleShow $visible={ls.screen.pay}>
                    <ContainerPadding>
                        <Fz15 as={Mb20}>라이센스 구입</Fz15>
                        <ListColumn>
                            {subscriptionProductData?.data.map((item) => (
                                <Mb15 key={item.id}>
                                    <RadioLabel htmlFor={`license_${item.id}`}>
                                        <RadioBox
                                            id={`license_${item.id}`}
                                            name="subscribePeriod"
                                            onChange={() => {
                                                ls.selectProduct(item.id);
                                                ls.setPayData({
                                                    productId: item.id,
                                                });
                                            }}
                                            value={item.id}
                                            checked={item.id === ls.selectedProductId || false}
                                        />
                                        <Fz16Medium>
                                            {item.name}
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        </Fz16Medium>
                                        <OrgPrice>
                                            {item.normal_price.toLocaleString()}{' '}
                                        </OrgPrice>{' '}
                                        <ConsumerPrice>
                                            {item.price.toLocaleString()}원
                                        </ConsumerPrice>
                                    </RadioLabel>
                                </Mb15>
                            ))}
                        </ListColumn>
                        <Divider $mt="0.3846rem" $mb="1.538rem" />
                        <FlxbtFullMb20 as={Fz15}>
                            결제 금액
                            {ls.selectedProductId && (
                                <ColBlack>
                                    <strong>
                                        {subscriptionProductData?.data
                                            .find((items) => items.id === ls.selectedProductId)
                                            .price.toLocaleString()}
                                    </strong>{' '}
                                    원
                                </ColBlack>
                            )}
                        </FlxbtFullMb20>
                        <BorderBoxGray $pd="1.154rem" as={Mb20}>
                            <Paragraph $fz="1.154rem">
                                약관
                                <br />
                                요금제 환불 (30일권, 3개월권, 6개월권, 12개월권)
                                <br />
                                서비스 구입 후 14일 이내 요청 시 100% 환불을 받을 수 있으며, 해당
                                기간 이후 환불 요청 시 남은 기간 만큼 환불합니다. (할인 미적용 1일
                                요금 기준)
                                <br />• 구매 후 14일 이내 100% 환불 가능합니다.
                                <br />• 연 단위 or 월 단위 구매 후 중간 환불 시 남은 기간(일)으로
                                비용으로 계산되어 차감 후 환불 처리
                                <br />• 월 단위 이용 고객은 서비스 기간 90%이상 이용기간이 넘을 시
                                환불 하지 않습니다.
                                <br />• 환불 기준일자는 문의 접수를 신청한 일자를 기준으로
                                산정합니다.
                                <br />• 공통 사항 : PG 정책 상 결제일 기준 1년이 지난 건은 환불하지
                                않습니다.
                            </Paragraph>
                        </BorderBoxGray>
                        <ChkAndTxt>
                            <Chkbox
                                id="agreeAll"
                                onChange={() => ls.toggleAgree()}
                                checked={ls.isAgree}
                            />
                            <ColBody>약관에 모두 동의</ColBody>
                        </ChkAndTxt>
                    </ContainerPadding>
                    <StickyBtm>
                        <LinkFull
                            $active={ls.selectedProductId && ls.isAgree}
                            onClick={() => {
                                if (ls.selectedProductId && ls.isAgree) {
                                    init('card');
                                }
                            }}
                            disabled={!!isMutating}
                        >
                            구매하기
                        </LinkFull>
                    </StickyBtm>
                </ToggleShow>
                <ToggleShow $visible={ls.screen.history}>
                    <ContainerPadding>
                        <Divider $mb="1.538rem" />
                        {!isLoading &&
                            !(historyData?.pages && historyData?.pages[0].data.docs.length > 0) && (
                                <NoContent message="결제하신 이력이 없습니다." />
                            )}
                        {historyData?.pages.map((page) =>
                            page.data.docs.map((items) => (
                                <BorderBoxMb key={items.id}>
                                    <FlxbtFullMb20>
                                        <Fz16Medium>{items.product.name} 라이센스 구입</Fz16Medium>
                                        <BtnLineGray
                                            $active
                                            onClick={() => {
                                                ls.setCurrentHistory(items);
                                                ls.toggleModal('receipt');
                                            }}
                                        >
                                            영수증
                                        </BtnLineGray>
                                    </FlxbtFullMb20>
                                    <Divider $mt="0.923rem" $mb="1.154rem" />
                                    <ItemInfoCol2 $mb="1.154rem">
                                        <InfoLabelR as={ColBlack}>결제일</InfoLabelR>
                                        <InfoValue>
                                            {format(
                                                parse(
                                                    items.created,
                                                    "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                    new Date()
                                                ),
                                                'yyyy-MM-dd HH:mm:ss'
                                            )}
                                        </InfoValue>
                                    </ItemInfoCol2>
                                    {items.product.type !== 'free' && (
                                        <ItemInfoCol2 $mb="1.154rem">
                                            <InfoLabelR as={ColBlack}>결제방식</InfoLabelR>
                                            <InfoValue>신용카드</InfoValue>
                                        </ItemInfoCol2>
                                    )}
                                    <ItemInfoCol2>
                                        <InfoLabelR as={ColBlack}>금액</InfoLabelR>
                                        <InfoValue>
                                            {items.product.type === 'free' ? (
                                                <strong>무료</strong>
                                            ) : (
                                                <strong>
                                                    {parseInt(
                                                        items.payment.plainTrdAmt
                                                    ).toLocaleString()}
                                                    원
                                                </strong>
                                            )}
                                        </InfoValue>
                                    </ItemInfoCol2>
                                </BorderBoxMb>
                            ))
                        )}
                    </ContainerPadding>

                    {hasNextPage && (
                        <LineCenter ref={containerRef}>
                            <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                            <img src={arrowDownThinGray} alt="more" />
                        </LineCenter>
                    )}
                </ToggleShow>
            </Container>
            {/* 결제 성공 모달 */}
            {/* <NewModal
                contentLabel="결제 수단"
                ariaHideApp={false}
                isOpen={ls.modal.method}
                $visible
            >
                <Pad20>
                    <PopShortMsg>
                        <PadVert20>
                            <Fz15Bold $col="#000">결제 수단을 선택해 주세요.</Fz15Bold>
                        </PadVert20>
                        <ListCol2 as={PadVert20}>
                            <BtnFullLine
                                onClick={() => init('card')}
                                style={{ width: 'calc(50% - 0.385rem)' }}
                                disabled={!!isMutating}
                            >
                                신용카드
                            </BtnFullLine>
                            <BtnFullLine
                                onClick={() => init('mobile')}
                                style={{ width: 'calc(50% - 0.385rem)' }}
                                disabled={!!isMutating}
                            >
                                휴대폰
                            </BtnFullLine>
                        </ListCol2>
                    </PopShortMsg>
                    <BtnFullModal
                        onClick={() => ls.toggleModal('method')}
                        style={{ backgroundColor: '#000', color: '#fff' }}
                    >
                        취소
                    </BtnFullModal>
                </Pad20>
            </NewModal> */}
            {/* //결제 성공 모달 */}
            {/* 결제 성공 모달 */}
            <NewModal contentLabel="결제 성공" ariaHideApp={false} isOpen={ls.modal.complete}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">결제가 정상적으로 완료되었습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack
                            onClick={() => {
                                refetch();
                                ls.toggleModal('complete');
                                ls.toggleScreen('history');
                            }}
                        >
                            결제 이력 확인
                        </BtnHalfModalBlack>
                        <BtnHalfModal as={Link} to="/" state={{ previousUrl: location.pathname }}>
                            홈으로 이동
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
            {/* //결제 실패 모달 */}
            <NewModal contentLabel="결제 실패" ariaHideApp={false} isOpen={ls.modal.fail}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">결제가 실패하였습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnFullModal
                            $active
                            onClick={() => {
                                ls.toggleModal('fail');
                            }}
                        >
                            확인
                        </BtnFullModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
            {/* 영수증 모달 */}

            <PopOver $visible={ls.modal.receipt}>
                <BtnCloseModal>
                    <img
                        src={arrowLeft}
                        alt="뒤로"
                        onClick={() => {
                            ls.toggleModal('receipt');
                        }}
                    />
                </BtnCloseModal>
                <Spacing70 />
                <ContainerPadding>
                    <Fz20Bold $mb="3rem">영수증</Fz20Bold>
                    <Mb30>
                        {/* <ConfirmInfoItem>
                            <InfoLabel>구분</InfoLabel>
                            <InfoValue>현금영수증 or 카드영수증</InfoValue>
                        </ConfirmInfoItem> */}
                        {ls.currentHistory.payment?.result && (
                            <ConfirmInfoItem>
                                <InfoLabel>거래번호</InfoLabel>
                                <InfoValue>{ls.currentHistory.payment.result.trdNo}</InfoValue>
                            </ConfirmInfoItem>
                        )}
                        {ls.currentHistory.payment && (
                            <ConfirmInfoItem>
                                <InfoLabel>결제방식</InfoLabel>
                                <InfoValue>
                                    신용카드 ({ls.currentHistory.payment.result.fnNm})
                                </InfoValue>
                            </ConfirmInfoItem>
                        )}
                        {/* <ConfirmInfoItem>
                            <InfoLabel>신청구분 (현금영수증일때만)</InfoLabel>
                            <InfoValue></InfoValue>
                        </ConfirmInfoItem> */}
                        {ls.currentHistory.created && (
                            <ConfirmInfoItem>
                                <InfoLabel>결제일자</InfoLabel>
                                <InfoValue>
                                    {format(
                                        parse(
                                            ls.currentHistory.created,
                                            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                            new Date()
                                        ),
                                        'yyyy-MM-dd HH:mm:ss'
                                    )}
                                </InfoValue>
                            </ConfirmInfoItem>
                        )}
                        {ls.currentHistory.product?.name && (
                            <ConfirmInfoItem>
                                <InfoLabel>상품명</InfoLabel>
                                <InfoValue>
                                    {ls.currentHistory.product.name + ' 라이센스'}
                                </InfoValue>
                            </ConfirmInfoItem>
                        )}
                        {ls.currentHistory.payment?.mchtTrdNo && (
                            <ConfirmInfoItem>
                                <InfoLabel>주문번호</InfoLabel>
                                <InfoValue>{ls.currentHistory.payment.mchtTrdNo}</InfoValue>
                            </ConfirmInfoItem>
                        )}
                    </Mb30>
                </ContainerPadding>
            </PopOver>

            {/* //결제 성공 모달 */}
        </>
    );
}

export default LicenseBuy;
