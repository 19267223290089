import { create } from 'zustand';

const initialState = {
    category: {
        designer: true,
        banner: false,
    },
    filter: {
        distance: 5,
        category: [],
    },
    order: {
        price: true,
        distance: false,
        grade: false,
    },
    toggleStates: {},
    showFilter: false,
};

const useDisCountStore = create((set) => ({
    ...initialState,
    toggleCategory: (select) => {
        set((state) => ({
            category: {
                designer: select === 'designer',
                banner: select === 'banner',
                alliance: select === 'alliance',
            },
        }));
    },
    setFilterCategory: (categories) => {
        set((state) => ({
            filter: {
                ...state.filter,
                category: categories.map((category) => ({
                    ...category,
                    isActive: false,
                })),
            },
        }));
    },
    setFilter: (key, value) => {
        set((state) => ({
            filter: {
                ...state.filter,
                [key]: value,
            },
        }));
    },
    setDistance: (text) => set((state) => ({ filter: { ...state.filter, distance: text } })),
    resetFilter: () => {
        set((state) => ({
            filter: {
                distance: [5],
                category: state.filter.category.map((category) => {
                    return {
                        ...category,
                        isActive: false,
                    };
                }),
            },
        }));
    },
    toggleFilterCategory: (id) => {
        set((state) => {
            const updatedCategories = state.filter.category.map((category) => {
                if (category.id === id) {
                    return {
                        ...category,
                        isActive: !category.isActive,
                    };
                }

                return category;
            });

            return {
                filter: {
                    ...state.filter,
                    category: updatedCategories,
                },
            };
        });
    },

    toggleOrder: (select) => {
        set((state) => ({
            order: {
                price: select === 'price',
                distance: select === 'distance',
                grade: select === 'grade',
            },
        }));
    },

    togglePrices: (key) =>
        set((state) => ({
            toggleStates: {
                // ...state.toggleStates,
                [key]: !state.toggleStates[key],
            },
        })),

    setShowFilter: (boolean) => {
        set({
            showFilter: boolean,
        });
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useDisCountStore;
