import { instance } from '@services/api';
import axios from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';

const productService = {
    productCategory: () => {
        return instance.get('/product-categories');
    },
};

export const useProductCategoryQuery = (params) => {
    return useQuery({
        queryKey: ['productCategory'],
        queryFn: () => productService.productCategory(params),
    });
};
