import React from 'react';
import Modal from 'react-modal';
import {
    ConsumerPrice,
    OrgPrice,
    DescInput,
    Select,
    TextLine,
    TextLineAutosize,
    StickyBtm,
    BtnFullModal,
    JoinField,
    PadVert10,
    Fz15Bold,
    InputWrapper,
    JoinInputPlc15,
    FlxbtFull,
    FlxMiddle,
    Fz15,
    PadVert30,
    ContainerFit,
    Fz20Bold,
    Fz16Medium,
    AddContainer,
} from '@/css/style';
import { onlyNum } from '@/utils/helper';
import { useDesignerMenuUpdateMutation } from '@/services/designerService';
import useServiceStore from '@/stores/designer/service';
import useUserStore from '@/stores/user/user';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import { useIsMutating } from '@tanstack/react-query';

export default function ServiceEdit({ isOpen, closeModal, category, refetch }) {
    const us = useUserStore();
    const ss = useServiceStore();
    const isMutating = useIsMutating();
    const DesignerMenuUpdateMutation = useDesignerMenuUpdateMutation(() => {
        refetch();
        ss.terminate();
        closeModal();
    });

    const validate =
        parseInt(ss.edit.normal_price) >= parseInt(ss.edit.price) &&
        ss.edit.category_id &&
        ss.edit.name;

    const serviceEdit = () => {
        if (!validate) {
            return;
        } else {
            DesignerMenuUpdateMutation.mutate({ designerId: us.user.id, params: { ...ss.edit } });
        }
    };
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="시술 메뉴 수정"
            $visible
        >
            <AddContainer>
                <button onClick={closeModal}>
                    <img src={icCloseModal} alt="닫기" />
                </button>
                <PadVert30>
                    <Fz20Bold>시술 메뉴 수정</Fz20Bold>
                </PadVert30>
                <JoinField>
                    <PadVert10>
                        <Fz15Bold>시술 메뉴 선택</Fz15Bold>
                    </PadVert10>
                    <InputWrapper>
                        <Fz15
                            as={Select}
                            onChange={(e) => ss.editService('category_id', e.target.value)}
                        >
                            <option value="">선택</option>
                            {category?.data.map((category) => (
                                <option
                                    key={category.id}
                                    value={category.id}
                                    selected={ss.edit.category === category.id && 'selected'}
                                >
                                    {category.name}
                                </option>
                            ))}
                        </Fz15>
                    </InputWrapper>
                </JoinField>
                <JoinField>
                    <PadVert10>
                        <Fz15Bold>시술명</Fz15Bold>
                    </PadVert10>
                    <InputWrapper>
                        <JoinInputPlc15
                            type="text"
                            id="serviceName"
                            name="serviceName"
                            maxLength="15"
                            value={ss.edit.name}
                            onChange={(e) => ss.editService('name', e.target.value)}
                            placeholder="이름을 입력해 주세요"
                        />
                    </InputWrapper>
                </JoinField>
                <JoinField>
                    <PadVert10>
                        <Fz15Bold>시술 설명</Fz15Bold>
                    </PadVert10>
                    <InputWrapper>
                        <TextLineAutosize
                            cacheMeasurements
                            value={ss.edit.option}
                            placeholder="설명을 입력해 주세요 ex) 기장추가"
                            minRows={1.5}
                            maxRows={8}
                            maxLength="300"
                            onChange={(e) => ss.editService('option', e.target.value)}
                        ></TextLineAutosize>
                    </InputWrapper>
                    <DescInput $pt="1.154rem">300자 이하로 입력 가능합니다.</DescInput>
                </JoinField>
                <JoinField>
                    <PadVert10>
                        <Fz15Bold>정가</Fz15Bold>
                    </PadVert10>
                    <InputWrapper>
                        <JoinInputPlc15
                            type="text"
                            id="serviceOrgPrice"
                            name="serviceOrgPrice"
                            maxLength="10"
                            value={
                                ss.edit.normal_price ? ss.edit.normal_price.toLocaleString() : ''
                            }
                            onChange={(e) =>
                                ss.editService(
                                    'normal_price',
                                    e.target.value.trim() === ''
                                        ? ''
                                        : parseInt(onlyNum(e.target.value))
                                )
                            }
                        />
                        <Fz15 $col="#000">원</Fz15>
                    </InputWrapper>
                    <DescInput $pt="1.154rem">미 입력시 무료로 설정됩니다.</DescInput>
                </JoinField>
                <JoinField>
                    <PadVert10>
                        <Fz15Bold>판매가</Fz15Bold>
                    </PadVert10>
                    <InputWrapper>
                        <JoinInputPlc15
                            type="text"
                            id="servicePrice"
                            name="servicePrice"
                            maxLength="10"
                            value={ss.edit.price ? ss.edit.price.toLocaleString() : ''}
                            onChange={(e) =>
                                ss.editService(
                                    'price',
                                    e.target.value.trim() === ''
                                        ? ''
                                        : parseInt(onlyNum(e.target.value))
                                )
                            }
                        />
                        <Fz15 $col="#000">원</Fz15>
                    </InputWrapper>
                    <DescInput $pt="1.154rem">
                        할인 가격을 등록하시면 할인 가격으로 판매됩니다.
                    </DescInput>
                </JoinField>
                {ss.edit.normal_price !== ss.edit.price ? (
                    <FlxbtFull>
                        <Fz16Medium $col="#FF3062">
                            {ss.edit.normal_price && ss.edit.price && (
                                <>
                                    {Math.ceil(
                                        ((ss.edit.normal_price - ss.edit.price) /
                                            ss.edit.normal_price) *
                                            100
                                    )}
                                    %
                                </>
                            )}
                        </Fz16Medium>
                        {ss.edit.normal_price && ss.edit.price ? (
                            <FlxMiddle>
                                <OrgPrice>{ss.edit.normal_price}원</OrgPrice>
                                <ConsumerPrice>{ss.edit.price}원</ConsumerPrice>
                            </FlxMiddle>
                        ) : (
                            <FlxMiddle>
                                <ConsumerPrice>{ss.edit.normal_price}원</ConsumerPrice>
                            </FlxMiddle>
                        )}
                    </FlxbtFull>
                ) : null}
            </AddContainer>
            <StickyBtm>
                <BtnFullModal
                    $active={validate}
                    disabled={!!isMutating}
                    onClick={() => serviceEdit()}
                >
                    수정
                </BtnFullModal>
            </StickyBtm>
        </Modal>
    );
}
const customStyles = {
    content: {
        top: 0,
        left: 'calc(50% + 3.846rem)',
        width: '87.2%',
        maxWidth: '26.154rem',
        height: '100%',
        transform: 'translateX(-50%)',
        overflow: 'hidden hidden',
    },
};
