import { create } from 'zustand';

const validateField = (fieldName, state) => {
    // 각 필드에 대한 유효성 검사

    switch (fieldName) {
        case 'title':
            return !!state.fields.title;
        case 'profileImage':
            return !!state.fields.profileImage?.filename;
        default:
            return true;
    }
};

const initialState = {
    fields: {
        title: '',
        career: '',
        introduce: '',
        profileImage: {},
        images: [],
        sns: [
            { provider: '', link: '', id: '0', isSnsMenu: false },
            { provider: '', link: '', id: '1', isSnsMenu: false },
        ],
    },
    validations: {
        title: false,
        profileImage: false,
        images: false,
    },
    modal: false,
};

const useInfoDesignerStore = create((set, get) => ({
    ...initialState,
    setField: (fieldName, text) => {
        set((state) => {
            const updatedFields = {
                ...state.fields,
                [fieldName]: text,
            };
            const updatedValidations = {
                ...state.validations,
                [fieldName]: validateField(fieldName, { ...state, fields: updatedFields }),
            };
            return {
                fields: updatedFields,
                validations: updatedValidations,
            };
        });
    },
    setFieldFromServer: (obj) => {
        set((state) => {
            const updatedFields = {
                ...state.fields,
                ...obj,
                sns:
                    obj.sns.length === 1
                        ? [obj.sns[0], ...initialState.fields.sns.slice(1)]
                        : obj.sns.length > 1
                        ? obj.sns.map((item, index) =>
                              index < state.fields.sns.length
                                  ? { ...state.fields.sns[index], ...item }
                                  : item
                          )
                        : initialState.fields.sns,
            };

            // 각 필드에 대한 유효성 검사 및 업데이트
            const updatedValidations = {
                title: validateField('title', { fields: updatedFields }),
                profileImage: validateField('profileImage', { fields: updatedFields }),
                images: validateField('images', { fields: updatedFields }),
            };

            return {
                fields: updatedFields,
                validations: updatedValidations,
            };
        });
    },
    setSns: (id, fieldName, text) => {
        set((state) => {
            const updatedSns = state.fields.sns.map((item) => {
                if (item.id === id) {
                    let updatedItem = { ...item };
                    if (fieldName === 'link') {
                        updatedItem = {
                            ...updatedItem,
                            link: text !== null ? text : updatedItem.url,
                        };
                    } else {
                        updatedItem = {
                            ...updatedItem,
                            provider: text,
                        };
                    }

                    return updatedItem;
                }
                return item;
            });

            return {
                fields: {
                    ...state.fields,
                    sns: updatedSns,
                },
            };
        });
    },

    resetSns: (id) => {
        set((state) => {
            const updatedSns = [...state.fields.sns];
            const currentId = updatedSns.find((item) => item.id === id);
            currentId.provider = '';

            return {
                fields: {
                    ...state.fields,
                    sns: updatedSns,
                },
            };
        });
    },
    toggleSnsMenu: (id) => {
        set((state) => {
            const updatedSns = state.fields.sns.map((item, idx) => {
                if (item.id === id) {
                    return {
                        ...item,
                        isSnsMenu: !item.isSnsMenu,
                    };
                }
                return {
                    ...item,
                    isSnsMenu: false, // 나머지 요소들을 false로 설정
                };
            });

            return {
                fields: {
                    ...state.fields,
                    sns: updatedSns,
                },
            };
        });
    },
    toggleModal: () => {
        set((state) => ({
            modal: !state.modal,
        }));
    },

    terminate: () => {
        set(initialState);
    },
}));

export default useInfoDesignerStore;
