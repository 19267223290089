import React, { useEffect } from 'react';

const InAppDeny = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    const targetUrl = window.location.href;

    if (userAgent.match(/kakaotalk/i)) {
      window.location.href = `kakaotalk://web/openExternal?url=${encodeURIComponent(targetUrl)}`;
    } else if (userAgent.match(/line/i)) {
      if (targetUrl.indexOf('?') !== -1) {
        window.location.href = `${targetUrl}&openExternalBrowser=1`;
      } else {
        window.location.href = `${targetUrl}?openExternalBrowser=1`;
      }
    } else if (userAgent.match(/inapp|naver|snapchat|wirtschaftswoche|thunderbird|instagram|everytimeapp|whatsApp|electron|wadiz|aliapp|zumapp|iphone(.*)whale|android(.*)whale|kakaostory|band|twitter|DaumApps|DaumDevice\/mobile|FB_IAB|FB4A|FBAN|FBIOS|FBSS|trill|SamsungBrowser\/[^1]/i)) {
      if (userAgent.match(/iphone|ipad|ipod/i)) {
        const mobile = document.createElement('meta');
        mobile.name = 'viewport';
        mobile.content = 'width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=no, minimal-ui';
        document.head.appendChild(mobile);

        const fonts = document.createElement('link');
        fonts.href = 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&display=swap';
        document.head.appendChild(fonts);

        document.body.innerHTML = `
          <style>
            body {
              margin: 0;
              padding: 0;
              font-family: 'Noto Sans KR', sans-serif;
              overflow: hidden;
              height: 100%;
            }
          </style>
          <h2 style='padding-top:50px; margin-bottom:10px; text-align:center;font-family: "Noto Sans KR", sans-serif;'>
            인앱브라우저 호환문제로 인해<br />Safari로 접속해야합니다.
          </h2>
          <article style='text-align:center; font-size:17px; word-break:keep-all;color:#999;'>
            아래 버튼을 눌러 Safari를 실행해주세요<br />
            Safari가 열리면, 주소창을 길게 터치한 뒤,<br />
            '붙여놓기 및 이동'을 누르면<br />
            정상적으로 이용할 수 있습니다.<br /><br />
            <button
						onclick="(function() {
							function inAppBrowserOut () {
								function copyToClipboard (val) {
									const t = document.createElement('textarea');
									document.body.appendChild(t);
									t.value = val;
									t.select();
									document.execCommand('copy');
									document.body.removeChild(t);
								};
								copyToClipboard(window.location.href);
								alert('URL주소가 복사되었습니다.\\nSafari가 열리면 주소창을 길게 터치한 뒤,\\n[붙여놓기 및 이동]를 누르면 정상적으로 이용하실 수 있습니다.');
								window.location.href = 'x-web-search://?';
							};
							inAppBrowserOut();
						})()"
              style='min-width:180px;margin-top:10px;height:54px;font-weight: 700;background-color:#00bf9d;color:#fff;border-radius: 4px;font-size:17px;border:0;'
            >
              Safari로 열기
            </button>
          </article>
          <img
            style='width:70%;margin:50px 15% 0 15%'
            src='/img/inappbrowserout.jpeg'
            alt='In App Browser Out'
          />
        `;
      } else {
        window.location.href = `intent://${targetUrl.replace(/https?:\/\//i, '')}#Intent;scheme=http;package=com.android.chrome;end`;
      }
    }
  }, []);

  return null;
};

export default InAppDeny;