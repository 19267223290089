import React, { useEffect } from 'react';
import { createGlobalStyle } from 'styled-components';
import '@fontsource-variable/noto-sans-kr';
import useUserStore from '@/stores/user/user';
import useShowStore from '@/stores/common/show';
import useLocationStore from '@/stores/common/location';
import Setup from './services/interceptor';
import ScrollTop from '@hooks/ScrollTop';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from './config';
import { RouterProvider } from 'react-router-dom';
import { router } from './utils/routes';
import InAppDeny from '@/components/InAppDeny';

function App() {
    const us = useUserStore();
    const ss = useShowStore();
    const ls = useLocationStore();

    Setup(us);

    useEffect(() => {
        if (ss.showAside) {
            document.getElementById('root').style.overflowY = 'hidden';
        } else {
            document.getElementById('root').style.overflowY = 'unset';
        }
    }, [ss.showAside]);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        // OS 구분
        let osType = 'E'; // 기본값은 기타
        if (/Android/i.test(userAgent)) {
            osType = 'A'; // Android
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
            osType = 'I'; // iOS
        } else if (/Windows/i.test(userAgent)) {
            osType = 'W'; // Windows
        } else if (/Macintosh|MacIntel|MacPPC|Mac68K/i.test(userAgent)) {
            osType = 'M'; // Mac OS
        }

        const loadKakaoScript = async () => {
            await new Promise((resolve) => {
                const script = document.createElement('script');
                script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
                script.async = true;
                script.onload = resolve;
                document.body.appendChild(script);
            });

            window.Kakao.init(config.KAKAO_CLIENT_ID);
        };

        loadKakaoScript();
        if (osType === 'A') {
            document.addEventListener('message', (e) => {
                try {
                    const data = JSON.parse(e.data);

                    console.log(data);

                    if (data.event === 'currentPosition' && data?.data?.coords) {
                        ls.getAppLocation(data.data.coords.latitude, data.data.coords.longitude);
                    } else if (data.event === 'currentPositionError') {
                        ls.toggleModal('reject');
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        } else if (osType === 'I') {
            window.addEventListener('message', (e) => {
                try {
                    const data = JSON.parse(e.data);
                    console.log(data);

                    if (data.event === 'currentPosition' && data?.data?.coords) {
                        ls.getAppLocation(data.data.coords.latitude, data.data.coords.longitude);
                    } else if (data.event === 'currentPositionError') {
                        ls.toggleModal('reject');
                    }
                } catch (error) {
                    console.log(error);
                }
            });
        }

        return () => {
            if (osType === 'A') {
                document.removeEventListener('message', () => {});
            } else if (osType === 'I') {
                window.removeEventListener('message', () => {});
            }
        };
    }, []);

    return (
        <>
            <InAppDeny />
            <GlobalStyle />
            <RouterProvider router={router}>
                <ScrollTop />
            </RouterProvider>
            <ToastContainer />
        </>
    );
}

const GlobalStyle = createGlobalStyle`
    *,*::before,*::after {box-sizing: border-box;}
    *:focus-visible{outline:none;}
    html,
    body{
        width: 100%;
        height:100%;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        overscroll-behavior:contain;
        user-select : none;
    }
    html{
        font-size: 13px;
    }
    body{
        min-width: 320px;
        color: #757575;
        line-height: 1;
        letter-spacing:-0.025rem;
        font-family: 'Noto Sans KR Variable',-apple-system,system-ui,helvetica,sans-serif;
        font-weight: 400;
        -webkit-text-size-adjust: none;
        -webkit-font-smoothing:antialiased;
        overflow-y:scroll;
    }
    pre{
        font-family: 'Noto Sans KR Variable',-apple-system,system-ui,helvetica,sans-serif;
        font-weight: 400;
    }
    a,body,button,blockquote,button,caption,dd,dialog,div,details,dl,dt,fieldset,figure,form,h1,h2,h3,h4,h5,h6,html,img,input,legend,li,ol,p,pre,select,summary,textarea,ul,video{
        padding: 0;
        margin: 0;
    }
    a{
        text-decoration: none;
        color: inherit;
    }
    img, video {vertical-align: middle;}
    li,menu,ol,ul{list-style: none}
    h1,h2,h3,h4,h5,h6,button,input,optgroup,select,textarea{font-size: inherit;font-weight: inherit}
    input[type="text"],
    input[type="password"],
    input[type="checkbox"],
    input[type="radio"],
    input[type="file"],
    input[type="date"],
    select,
    textarea{
        -webkit-appearance:none;
        appearance:none;
        border: 0;
        outline:0;
    }
    input::placeholder,
    textarea::placeholder{font-size:inherit;font-family:inherit;color:#A1A1A1;}
    input:-internal-autofill-selected,input[data-autocompleted] {background-color: rgba(0,0,0,0) !important;}
    input:-webkit-autofill,

    input:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
    button,
    select {
        border: 0;
        outline: 0;
        background: none;
        cursor: pointer;
        line-height:1;
    }
    fieldset {
        border: 0;
        outline: 0;
        background: none;
    }
    span, strong {cursor: default;}
    strong {font-weight: 700;}
    p, li {line-height: 1.3;white-space: pre-wrap;}
    address {font-style:normal;cursor:default;}
    label {cursor:pointer;}
    #root {
        width: 100%;
        height:100%;
    }
    .ReactModal__Overlay {
        transition: none;
    }
    // .ReactModal__Content--after-open {margin-left:calc(44rem / 2);}
    .ReactModal__Overlay--after-open::-webkit-scrollbar {visibility:hidden;}
    @media (max-width:992px) {
        div.service-menu {margin-left:-44rem !important;}
        .ReactModal__Content--after-open {margin-left:0 !important;}
        div[title=""][role="button"][aria-label="animation"][tabindex="0"] {margin-left:0 !important;}
    }
    @media (max-width:414px) {
        html{
            font-size: 12.5px;
        }
    }
    .user_id_datalist {
        position: absolute;
        top: 50px;
        background: #fff;
        box-shadow: 0 1px 6px #0003;
        /* transition: opacity .15s ease-in-out, height .25s linear; */
        border-radius: 4px;
        /* width: 360px; */
        width: 100%;
        max-height: 400px;
        overflow: hidden;
        min-width: 48%;
        padding-right: 10px;
        left: -1px;
        z-index: 1000;
      }
      
      .user_id_datalist ul {
        max-height: 400px;
        list-style: none;
        overflow: scroll;
      }
      
      .user_id_datalist ul li {
        line-height: 40px;
        padding-left: 15px;
      }
      
      .user_id_datalist ul li:hover {
        background-color: #E4E4E4;
        cursor: pointer;
      }
`;

export default App;
