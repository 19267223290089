import React, { useEffect } from 'react';
import { useNaverLoginMutation } from '@/services/externalService';
import useLoginStore from '@/stores/common/login';
import useUserStore from '@/stores/user/user';
import { useConnectNaverSocialMutation } from '@/services/userService';

export default function NaverLoginRedirect() {
    const ls = useLoginStore();
    const us = useUserStore();
    const NaverLoginMutation = useNaverLoginMutation(ls, us);
    const ConnectNaverLoginMutation = useConnectNaverSocialMutation();

    useEffect(() => {
        let code = new URL(window.location.href).searchParams.get('code');
        const api = localStorage.getItem('api');
        switch (api) {
            case 'naverLogin':
                NaverLoginMutation.mutate(code);
                break;
            case 'connectNaverSocial':
                ConnectNaverLoginMutation.mutate({ user_id: us.user.id, code: code });
                break;
            default:
                return;
        }
    }, []);

    return;
}
