import { instance } from '@services/api';
import {
    useQuery,
    useMutation,
    QueryClient,
    useQueryClient,
    useInfiniteQuery,
} from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import useLicenseStore from '../stores/designer/lisense';
import config from '../config';
const designerService = {
    designerDetail: (id) => {
        return instance.get(`/designers/${id}`);
    },
    designer: (page, params) => {
        return instance.get('/designers/v2', {
            params: {
                page: page,
                ...params,
            },
        });
    },
    designerProfileAdd: async (id, data) => {
        const response = await instance.post(`/designers/${id}/profile`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    },
    designerImageAdd: async (id, data) => {
        const response = await instance.post(`/designers/${id}/images`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response;
    },
    designerImageDelete: async (id, img) => {
        const response = await instance.delete(`/designers/${id}/images/${img}`);
        return response;
    },
    designerUpdate: (id, data) => {
        return instance.put(`/designers/${id}`, data);
    },
    designerMenu: (id, params) => {
        return instance.get(`/designers/${id}/menus`, {
            params: {
                ...params,
            },
        });
    },
    designerMenuCreate: (designerId, params) => {
        return instance.post(`/designers/${designerId}/menus`, params);
    },
    designerMenuUpdate: (designerId, menuId, params) => {
        return instance.put(`/designers/${designerId}/menus/${menuId}`, params);
    },
    designerMenuActive: (designerId, menuId) => {
        return instance.put(`/designers/${designerId}/menus/${menuId}/active`);
    },
    designerMenuInActive: (designerId, menuId) => {
        return instance.put(`/designers/${designerId}/menus/${menuId}/inactive`);
    },
    designerMenuDelete: (designerId, menuId) => {
        return instance.delete(`/designers/${designerId}/menus/${menuId}`);
    },
    designerReview: (id) => {
        return instance.get(`/designers/${id}/reviews`);
    },
    designerReservation: (page, obj) => {
        const { id, params } = obj;
        return instance.get(`/designers/${id}/reservations`, {
            params: {
                page: page,
                // from_date: param.from_date,
                // to_date: param.to_date,
                ...params,
            },
        });
    },
    designerReservationStatusChange: (designerId, reservId, status) => {
        return instance.put(`/designers/${designerId}/reservations/${reservId}/status`, { status });
    },
    designerUpdateHolidayByDay: (designerId, array) => {
        return instance.put(`/designers/${designerId}/holiday/day`, array);
    },
    designerCreateHolidayByDate: (designerId, date) => {
        return instance.put(`/designers/${designerId}/holiday/date/${date}`);
    },
    designerCreateHolidayByWork: (designerId, date) => {
        return instance.put(`/designers/${designerId}/work/date/${date}`);
    },
    designerDeleteHolidayByDate: (designerId, date) => {
        return instance.delete(`/designers/${designerId}/holiday/date/${date}`);
    },
    designerDeleteHolidayByWork: (designerId, date) => {
        return instance.delete(`/designers/${designerId}/work/date/${date}`);
    },
    designerActive: (designerId) => {
        return instance.put(`/designers/${designerId}/active`);
    },
    designerInActive: (designerId) => {
        return instance.put(`/designers/${designerId}/inactive`);
    },
    designerReservationTimeUpdate: (designerId, date, data) => {
        return instance.put(`/designers/${designerId}/timetable/${date}`, data);
    },
    designerMap: (params) => {
        return instance.get('/designers/map', { params });
    },
    designerReservationSummary: (designerId, params) => {
        return instance.get(`/designers/${designerId}/reservation-summary`, { params });
    },
    designerDiscount: (page, params) => {
        return instance.get(`/advertisements/v2?type=designer`, {
            params: { page: page, ...params },
        });
    },
    payEncryptParams: async (data) => {
        // return instance.post(`http://gbdud.din365.com/settle/pay_encryptParams.php`, data);
        return instance.post(`/payments/encrypt-params`, data);
    },
    subscriptionProducts: () => {
        return instance.get('/subscription-products');
    },
    designerSubscriptionHistory: (designerId) => {
        return instance.get(`/designers/${designerId}/subscriptions`);
    },
};

export const useDesignerDetailQuery = (id) => {
    return useQuery({
        queryKey: ['designerDetail', id],
        queryFn: () => designerService.designerDetail(id),
    });
};

export const useDesignerDetailForAsideQuery = (id, role) => {
    return useQuery({
        queryKey: ['designerDetail', id],
        queryFn: () => designerService.designerDetail(id),
        enabled: id !== '' && role === 'DESIGNER',
    });
};

export const useDesignerQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['designer', params],
        queryFn: ({ pageParam }) => designerService.designer(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
    });
};

export const useDesignerSearchQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['designer'],
        queryFn: ({ pageParam }) => designerService.designer(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
        enabled: false,
    });
};

export const useDesignerProfileAddMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { id, data } = obj;

            const formData = new FormData();
            formData.append('file', data[0]);
            const response = await designerService.designerProfileAdd(id, formData);

            return response;
        },
        onSuccess: async (res) => {
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerImageAddMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { id, data } = obj;
            const formData = new FormData();
            formData.append('file', data[0]);
            const response = await designerService.designerImageAdd(id, formData);

            return response;
        },
        onSuccess: async (res) => {
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerImageDeleteMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { id, imgId } = obj;
            const response = await designerService.designerImageDelete(id, imgId);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerUpdateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { id } = obj;
            if (obj.sns) {
                const filteredSns = obj.sns.filter((snsItem) => snsItem.provider && snsItem.link);
                obj.sns = filteredSns;
            }
            delete obj.holidayByDate;
            delete obj.holidayByDay;
            delete obj.workDay;
            const response = await designerService.designerUpdate(id, obj);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerMenuQuery = (id, params, options = {}) => {
    return useQuery({
        queryKey: ['designerMenu', id, params],
        queryFn: () => designerService.designerMenu(id, params),
        ...options,
    });
};

export const useDesignerMenuCreateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, params } = obj;
            const response = await designerService.designerMenuCreate(designerId, params);
            return response;
        },
        onSuccess: (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useDesignerMenuUpdateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, params } = obj;
            const response = await designerService.designerMenuUpdate(
                designerId,
                params.id,
                params
            );
            return response;
        },
        onSuccess: (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useDesignerMenuActiveMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, menuId } = obj;
            const response = await designerService.designerMenuActive(designerId, menuId);
            return response;
        },
        onSuccess: (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useDesignerMenuInActiveMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, menuId } = obj;
            const response = await designerService.designerMenuInActive(designerId, menuId);
            return response;
        },
        onSuccess: (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useDesignerMenuDeleteMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, menuId } = obj;
            const response = await designerService.designerMenuDelete(designerId, menuId);
            return response;
        },
        onSuccess: (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useDesignerReviewQuery = (id) => {
    return useQuery({
        queryKey: ['designerReview', id],
        queryFn: () => designerService.designerReview(id),
    });
};

export const useDesignerReservationQuery = (obj) => {
    const { from_date, to_date, status } = obj.params;
    return useInfiniteQuery({
        queryKey: ['designerReservation', from_date, to_date, status],
        queryFn: ({ pageParam }) => designerService.designerReservation(pageParam, obj),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
        gcTime: 0,
    });
};

export const useDesignerReservationStatusChangeMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, reservId, status } = obj;
            const response = await designerService.designerReservationStatusChange(
                designerId,
                reservId,
                status
            );

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerUpdateHolidayByDayMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, array } = obj;
            console.log('data', array);
            const response = await designerService.designerUpdateHolidayByDay(designerId, array);

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerCreateHolidayByDateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, date } = obj;
            const response = await designerService.designerCreateHolidayByDate(designerId, date);

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerCreateHolidayByWorkMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, date } = obj;
            const response = await designerService.designerCreateHolidayByWork(designerId, date);

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerDeleteHolidayByDateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, date } = obj;
            console.log('date', date);
            const response = await designerService.designerDeleteHolidayByDate(designerId, date);

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerDeleteHolidayByWorkMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, date } = obj;
            const response = await designerService.designerDeleteHolidayByWork(designerId, date);

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerActiveMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (designerId) => {
            const response = await designerService.designerActive(designerId);

            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerInActiveMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (designerId) => {
            const response = await designerService.designerInActive(designerId);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerReservationTimeUpdateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { designerId, date, data } = obj;
            console.log('obj', obj);
            const response = await designerService.designerReservationTimeUpdate(
                designerId,
                date,
                data
            );
            return response;
        },
        onSuccess: async (res) => {
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useDesignerMapQuery = (params) => {
    console.log('params', params)
    return useQuery({
        queryKey: ['designerMap', params],
        queryFn: () => designerService.designerMap(params),
        enabled: params && params.lat != '' && params.lng != '',
    });
};

export const useDesignerReservationSummaryQuery = (obj) => {
    const { id, params } = obj;
    return useQuery({
        queryKey: ['designerReservationSummary', params],
        queryFn: () => designerService.designerReservationSummary(id, params),
    });
};

export const useDesignerDiscountQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['designerDiscount', params.sort, params.lat, params.lng],
        queryFn: ({ pageParam }) => designerService.designerDiscount(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
    });
};

export const usePayEncryptParamsMutation = (onSuccess) => {
    const ls = useLicenseStore();
    const [payDataReady, setPayDataReady] = useState(false);
    let type;

    if (window.ReactNativeWebView) {
        type = 'self';
    } else {
        type = 'popup';
    }

    useEffect(() => {
        if (payDataReady) {
            console.log('ls.payData', ls.payData);
            if (typeof SETTLE_PG !== 'undefined' && SETTLE_PG.hasOwnProperty('pay')) {
                SETTLE_PG.pay(ls.payData);
                setPayDataReady(false);
            } else {
                console.error('SETTLE_PG.pay() 함수를 찾을 수 없습니다.');
            }
        }
    }, [payDataReady, ls.payData]);

    return useMutation({
        mutationFn: async (data) => {
            console.log('data', data);
            const response = await designerService.payEncryptParams(data);
            return response;
        },
        onSuccess: async (res) => {
            ls.setPayData({
                ...res.data.encParams,
                env: config.PG_HOST,
                pktHash: res.data.hashCipher,
                ui: {
                    type: type,
                    width: '430',
                    height: '660',
                },
            });
            setPayDataReady(true);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useSubscriptionProductsQuery = () => {
    return useQuery({
        queryKey: ['subscriptionProducts'],
        queryFn: () => designerService.subscriptionProducts(),
    });
};

export const useDesignerSubscriptionHistoryQuery = (designerId) => {
    return useInfiniteQuery({
        queryKey: ['designerSubscriptionHistory', designerId],
        queryFn: ({ pageParam }) => designerService.designerSubscriptionHistory(designerId),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
        gcTime: 0,
    });
};
