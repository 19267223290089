import React, { useEffect } from 'react';
import { Link, useNavigate, useOutletContext } from 'react-router-dom';
import {
    ItemDesigner,
    CircleImg,
    FullImg,
    DesignerInfo,
    DesignerName,
    MyHeader,
    ListColumn,
    Fz18Medium,
    Flxbt,
    Fz16Medium,
    Container,
    ContainerPadding,
    FlxMiddle,
    Fz20Bold,
    FlxMiddleMb10,
    Mb10,
    Mb1rem,
    DesignerRates,
    DesignerSns,
} from '@/css/style';
import config from '@config';
import { useBookMarkDeleteMutation, useBookMarkQuery } from '@/services/userService';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import NoContent from '@/components/NoContent';
import altimg from '@/assets/img/altimg.png';
import icLikeOn from '@/assets/img/ic_like_on.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsIg from '@/assets/img/sns_ig.svg';
import snsX from '@/assets/img/sns_x.svg';
import snsYt from '@/assets/img/sns_ytube.svg';
import { FaStar } from 'react-icons/fa';
import { snsTable } from '@/utils/helper';
import { useIsMutating } from '@tanstack/react-query';

function MyLikes() {
    const us = useUserStore();
    const ss = useShowStore();
    const { navigate } = useOutletContext();
    const { isLoading, data, refetch } = useBookMarkQuery(us.user.id);
    const BookMarkDelete = useBookMarkDeleteMutation(() => refetch());
    const isMutating = useIsMutating();

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [isLoading]);

    return (
        <Container>
            <MyHeader>
                <Fz20Bold>관심 디자이너</Fz20Bold>
                <FlxMiddle>
                    <Fz18Medium $col="#00BF9D">{data?.data.totalDocs}</Fz18Medium>
                    &nbsp;<Fz16Medium>명</Fz16Medium>
                </FlxMiddle>
            </MyHeader>
            <ContainerPadding>
                <ListColumn>
                    {!data?.data.docs.length && !isLoading && (
                        <NoContent message="추가한 디자이너가 없습니다." />
                    )}
                    {data?.data.docs.map((designers) => (
                        <ItemDesigner key={designers._id}>
                            <CircleImg
                                onClick={() => {
                                    navigate(`/designer/${designers._id}`);
                                }}
                            >
                                <FullImg
                                    src={`${config.DESIGNER_IMG_URL}${designers.profileImage}?size=150`}
                                    onError={(e) => {
                                        e.currentTarget.src = altimg;
                                    }}
                                    alt="프로필 이미지"
                                />
                            </CircleImg>
                            <DesignerInfo>
                                <Mb10>
                                    <strong>{designers.shop}</strong>
                                </Mb10>
                                <Flxbt as={Mb1rem}>
                                    <DesignerName
                                        onClick={() => {
                                            navigate(`/designer/${designers._id}`);
                                        }}
                                    >
                                        {designers.title}
                                    </DesignerName>
                                    <FlxMiddle>
                                        {designers.sns.map((sns) => (
                                            <DesignerSns href={sns.link} key={sns._id}>
                                                <img
                                                    src={
                                                        snsTable.find(
                                                            (item) => item.provider === sns.provider
                                                        ).src
                                                    }
                                                    alt={sns.provider}
                                                />
                                            </DesignerSns>
                                        ))}
                                    </FlxMiddle>
                                </Flxbt>
                                <Flxbt>
                                    <DesignerRates>
                                        {/* 평가자 {designers.ratingCount}명 */}
                                        <FaStar color="#FFBB00" />
                                        <strong>
                                            {designers.rating
                                                ? designers.averageRating.toFixed(1)
                                                : 0}
                                        </strong>
                                    </DesignerRates>
                                    <button
                                        type="button"
                                        onClick={() => BookMarkDelete.mutate(designers._id)}
                                        disabled={!!isMutating}
                                    >
                                        <img src={icLikeOn} />
                                    </button>
                                </Flxbt>
                            </DesignerInfo>
                        </ItemDesigner>
                    ))}
                </ListColumn>
            </ContainerPadding>
        </Container>
    );
}
export default MyLikes;
