import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    ContainerPadding,
    Fz20Bold,
    FlxbtFullMb20,
    Fz15,
    FlxbtFull,
    Gap15,
    FlxMiddle,
    LineCenter,
    Pad20,
    PopShortMsg,
    Mb20,
    Fz15Bold,
    ListCol2,
    BtnFullModal,
    BtnSnsLogin,
    Mb30,
    BorderBox,
    Paragraph,
    ColPrimary
} from '@/css/style';
import { Apple, Kakao, Naver } from '@/components/OAuth';
import ChkOnOffShort from '@/components/ChkOnOffShort';
import { useMyQuery } from '@/services/authService';
import {
    useDisconnectAppleSocialMutation,
    useDisconnectKakaoSocialMutation,
    useDisconnectNaverSocialMutation,
} from '@/services/userService';
import config from '@/config';
import NewModal from '@/components/NewModal';
import { parse, format } from 'date-fns';
import { useIsMutating } from '@tanstack/react-query';
import icNaver from '@/assets/img/ic_naver.svg';
import icApple from '@/assets/img/ic_apple.svg';
import icKakao from '@/assets/img/ic_kakao.svg';
import useSocialStore from '@/stores/user/social';

function MySns() {
    const { data: socialData, refetch: refetchSocialData } = useMyQuery();
    let kakaoData = socialData?.data.socials?.find((item) => item.provider === 'kakao') || null;
    let naverData = socialData?.data.socials?.find((item) => item.provider === 'naver') || null;
    let appleData = socialData?.data.socials?.find((item) => item.provider === 'apple') || null;
    const [modal, setModal] = useState(false);
    const isMutating = useIsMutating();
    const ss = useSocialStore();

    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
            ss.terminate();
        };
    }, []);

    function handleMessage(event) {
        const messageData = event.data;
        if (messageData.success) {
            refetchSocialData();
        } else if (messageData.success === false) {
            setModal(true);
        }
    }

    const DisconnectKakaoSocialMutation = useDisconnectKakaoSocialMutation(() =>
        refetchSocialData()
    );
    const DisconnectNaverSocialMutation = useDisconnectNaverSocialMutation(() =>
        refetchSocialData()
    );
    const DisconnectAppleSocialMutation = useDisconnectAppleSocialMutation(() =>
        refetchSocialData()
    );

    const openSocialAuthPopup = (authUrl, option) => {
        if (window.ReactNativeWebView) {
            window.location.href = authUrl;
        } else {
            window.open(authUrl, '', option);
        }
    };

    const connectSocialLogin = (provider) => {
        let authUrl, api;
        switch (provider) {
            case 'kakao':
                api = 'connectKakaoSocial';
                authUrl = `https://kauth.kakao.com/oauth/authorize?client_id=${config.KAKAO_CLIENT_ID}&redirect_uri=${config.CLIENT_HOST}/oauth-kakao&response_type=code`;
                break;
            case 'naver':
                api = 'connectNaverSocial';
                authUrl = `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${config.NAVER_CLIENT_ID}&state=false&redirect_uri=${config.CLIENT_HOST}/oauth-naver`;
                break;
            case 'apple':
                api = 'connectAppleSocial';
                authUrl = `https://appleid.apple.com/auth/authorize?client_id=${config.APPLE_CLIENT_ID}&redirect_uri=https://api.hairhale.com/auth/authenticate/apple&response_type=code id_token&state=${api}&scope=name email&response_mode=form_post&m=11&v=1.5.4`;
                break;
            default:
                return;
        }

        localStorage.setItem('api', api);

        const popupWidth = 500;
        const popupHeight = 600;
        const left =
            window.screenLeft || window.screenX || 0 + (window.innerWidth - popupWidth) / 2;
        const top =
            window.screenTop || window.screenY || 0 + (window.innerHeight - popupHeight) / 2;
        const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

        openSocialAuthPopup(authUrl, option);
    };

    return (
        <>
            <Container>
                <ContainerPadding>
                    <Fz20Bold $mb="3.846rem">소셜 로그인 연동</Fz20Bold>
                    <FlxbtFullMb20 style={{ flexWrap: 'wrap' }}>
                    <Mb30>
                                <BorderBox $pd="1.154rem" >
                                    <Paragraph $col="#000" $fz="1.154rem" $wd="100%">
                                        연동 하시면 패스워드 없이 쉽게 로그인 하실수 있습니다.
                                                
                                                {/* 혹시 변경이 필요하다면{' '}
                                                <ColPrimary>내 정보 수정</ColPrimary>에서 변경해
                                                주세요. */}
                                    </Paragraph>
                                </BorderBox>
                            </Mb30>
                        <FlxMiddle>
                            <BtnSnsLogin $bg="#F9E001">
                                <img src={icKakao} alt="kakao" />
                            </BtnSnsLogin>
                            <Gap15 />
                            <Fz15 $col="#000">카카오 로그인 연동</Fz15>
                        </FlxMiddle>
                        <ChkOnOffShort
                            id="kakao"
                            checked={kakaoData || false}
                            onChange={() =>
                                kakaoData
                                    ? DisconnectKakaoSocialMutation.mutate(socialData?.data.id)
                                    : connectSocialLogin('kakao')
                            }
                            disabled={!!isMutating}
                            value="1"
                        />
                        {kakaoData && (
                            <LineCenter>
                                {format(
                                    parse(
                                        kakaoData.created,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                        new Date()
                                    ),
                                    'yyyy. MM. dd'
                                )}
                                , {kakaoData.social_email}
                            </LineCenter>
                        )}
                    </FlxbtFullMb20>
                    <FlxbtFullMb20 style={{ flexWrap: 'wrap' }}>
                        <FlxMiddle>
                            <BtnSnsLogin $bg="#2DB400">
                                <img src={icNaver} alt="naver" />
                            </BtnSnsLogin>
                            <Gap15 />
                            <Fz15 $col="#000">네이버 로그인 연동</Fz15>
                        </FlxMiddle>
                        <ChkOnOffShort
                            id="naver"
                            checked={naverData || false}
                            onChange={() =>
                                naverData
                                    ? DisconnectNaverSocialMutation.mutate(socialData?.data.id)
                                    : connectSocialLogin('naver')
                            }
                            disabled={!!isMutating}
                        />
                        {naverData && (
                            <LineCenter>
                                {format(
                                    parse(
                                        naverData.created,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                        new Date()
                                    ),
                                    'yyyy. MM. dd'
                                )}
                                , {naverData.social_email}
                            </LineCenter>
                        )}
                    </FlxbtFullMb20>
                    <FlxbtFull style={{ flexWrap: 'wrap' }}>
                        <FlxMiddle>
                            <BtnSnsLogin $bg="#000">
                                <img src={icApple} alt="apple" />
                            </BtnSnsLogin>
                            <Gap15 />
                            <Fz15 $col="#000">애플 로그인 연동</Fz15>
                        </FlxMiddle>
                        <ChkOnOffShort
                            id="apple"
                            checked={appleData || false}
                            onChange={() =>
                                appleData
                                    ? DisconnectAppleSocialMutation.mutate(socialData?.data.id)
                                    : connectSocialLogin('apple')
                            }
                            disabled={!!isMutating}
                        />
                        {appleData && (
                            <LineCenter>
                                {format(
                                    parse(
                                        appleData.created,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                        new Date()
                                    ),
                                    'yyyy. MM. dd'
                                )}
                                , {appleData.social_email}
                            </LineCenter>
                        )}
                    </FlxbtFull>
                </ContainerPadding>
            </Container>
            {/* 연동 실패 모달 */}
            <NewModal contentLabel="연동 실패" ariaHideApp={false} isOpen={ss.modal.socialError}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">
                                연동에 실패했습니다.
                                <br />
                                연동할 아이디를 다시 확인해 주세요.
                            </Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnFullModal
                            type="button"
                            onClick={() => ss.toggleModal('socialError')}
                            $active={true}
                        >
                            닫기
                        </BtnFullModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
            {/* //결제 권장 모달 */}
        </>
    );
}
export default MySns;
