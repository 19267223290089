import { instance } from '@services/api';
import axios from 'axios';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';

const shopService = {
    shop: (page, params) => {
        return instance.get('/shops', {
            params: {
                page: page,
                ...params,
            },
        });
    },
    getDealer: (page, params, managerId) => {
        return instance.get(`/managers/${managerId}/dealers`, {
            params: {
                page: page,
                ...params,
            },
        });
    },
    shopDetail: (id) => {
        return instance.get(`/shops/${id}`);
    },
    shopCreateRequest: (data) => {
        return instance.post('/shops/req', data);
    },
    shopDiscount: (page, params) => {
        return instance.get(`/advertisements/v2?type=shop`, { params: { page: page, ...params } });
    },
    shopDiscountRead: (id) => {
        return instance.get(`/advertisements/${id}`);
    },
};

export const useShopQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['shop', params],
        queryFn: ({ pageParam }) => shopService.shop(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
        enabled: false,
        gcTime: 0,
    });
};

export const useShopDetailQuery = (id) => {
    return useQuery({
        queryKey: ['shopDetail', id],
        queryFn: () => shopService.shopDetail(id),
        enabled: false,
    });
};

export const useShopCreateRequestMutation = (js) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await shopService.shopCreateRequest(data);
            return response;
        },
        onSuccess: (res) => {
            console.log('js', js);
            console.log('성공:', res);
            js.setField('shop_id', res.data.id);
            js.setField('salon', res.data.name);
            js.setField('salonName', res.data.name);
            js.togglePopUp('requestShop');
        },
        onError: (error) => {
            console.log(error);
            js.togglePopUp('addressError');
        },
    });
};

export const useShopDiscountQuery = (params) => {
    return useInfiniteQuery({
        queryKey: ['shopDiscount', params],
        queryFn: ({ pageParam }) => shopService.shopDiscount(pageParam, params),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
    });
};

export const useShopDiscountReadQuery = (id) => {
    return useQuery({
        queryKey: ['shopDiscountRead', id],
        queryFn: () => shopService.shopDiscountRead(id),
    });
};

export const useGetDealerQuery = (params, managerId) => {
    return useInfiniteQuery({
        queryKey: ['dealer', managerId],
        queryFn: ({ pageParam }) => shopService.getDealer(pageParam, params, managerId),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            if (lastPage.data.totalPages > lastPage.data.page) {
                return lastPage.data.page + 1;
            } else {
                return null;
            }
        },
        enabled: false,
    });
};
