import { create } from 'zustand';

const initialState = {
    category: {
        day: true,
        date: false,
    },
    closedDays: {
        date: [],
    },
    daypicker: {
        holidayByDay: [],
        currentMonth: null,
    },
    dayTable: [
        { weekNum: 1, disabled: false },
        { weekNum: 2, disabled: false },
        { weekNum: 3, disabled: false },
        { weekNum: 4, disabled: false },
        { weekNum: 5, disabled: false },
        { weekNum: 6, disabled: false },
    ],
    weekTable: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    },
    isSelected: {
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
    },
    modal: {
        closedDays: false,
        confirmModal: false,
        constantHolidayConfirm: false,
    },
};

const useDayoffsStore = create((set, get) => ({
    ...initialState,
    toggleCategory: (select) => {
        set({
            category: {
                day: select === 'day',
                date: select === 'date',
            },
        });
    },
    setClosedDaysDate: (day) => {
        set((state) => ({
            closedDays: {
                ...state.closedDays,
                date: day, // 기존의 date 배열에 새로운 day 값을 추가
            },
            modal: {
                ...state.modal,
                confirmModal: day ? true : false, // day가 있으면 true, 없으면 false
            },
        }));
    },
    setCurrentMonth: (obj) => {
        set((state) => ({
            daypicker: {
                ...state.daypicker,
                currentMonth: obj,
            },
        }));
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    setHolidayByDay: (array) => {
        set((state) => ({
            daypicker: {
                ...state.daypicker,
                holidayByDay: array,
            },
        }));
    },
    toggleWeekTable: (select) => {
        set((state) => ({
            weekTable: {
                ...state.weekTable,
                [select]: !state.weekTable[select],
            },
        }));
    },
    setWeekTable: (array) => {
        set((state) => {
            const updatedWeekTable = { ...state.weekTable };

            array.forEach((item) => {
                const { week } = item;

                if (Array.isArray(week)) {
                    week.forEach((weekDay) => {
                        // 주어진 요일이 weekTable에 존재하면 값을 true로 설정
                        if (!updatedWeekTable[weekDay]) {
                            updatedWeekTable[weekDay] = true;
                        }
                    });
                }
            });

            return { weekTable: updatedWeekTable };
        });
    },
    toggleSelected: (select) =>
        set((state) => ({
            isSelected: {
                ...state.isSelected,
                [select]: !state.isSelected[select],
            },
        })),

    setSelected: (holidayByDay) => {
        set((state) => {
            return {
                isSelected: [0, 1, 2, 3, 4, 5, 6].map(
                    (n) => holidayByDay.filter((i) => i.day === n).length > 0
                ),
            };
        });
    },

    terminate: () => {
        set(initialState);
    },
}));

export default useDayoffsStore;
