import radioOff from '@/assets/img/radio_off.svg';
import radioOn from '@/assets/img/radio_on.svg';

const RadioBox = (props) => {
    const radioStyle = {
        position: 'relative',
        top: 2,
        width: '1.73rem',
        minWidth: '1.73rem',
        height: '1.73rem',
        border: 0,
        backgroundImage: props.checked ? `url(${radioOn})` : `url(${radioOff})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
    };

    return (
        <>
            <input
                type="radio"
                id={props.id}
                name={props.name}
                style={radioStyle}
                checked={props.checked}
                onChange={props.onChange}
            />
        </>
    );
};

export default RadioBox;
