import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Gap10, NavBar, NavLogo, NavTitle } from '@/css/style';
import useShowStore from '@/stores/common/show';
import Aside from '@/components/Aside';
import GnbSearch from '@/components/GnbSearch';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import icSearch from '@/assets/img/ic_search_bl.svg';
import icMenu from '@/assets/img/ic_menu_bl.svg';
import logoTxt from '@/assets/img/logo_txt.svg';
import config from '../config';

const TopNavBar = () => {
    const location = useLocation();
    const ss = useShowStore();
    const navigate = useNavigate();
    return (
        <NavBar>
            {ss.isLogo ? (
                <NavLogo>
                    <Link to="/" state={{ previousUrl: location.pathname }}>
                        <img src={logoTxt} alt="머리할래" />
                        {config.MODE == 'test' && (
                            <>
                                &nbsp;
                                <span style={{ color: 'red', fontSize: '15px', fontWeight: '500' }}>
                                    개발모드
                                </span>
                            </>
                        )}
                    </Link>
                </NavLogo>
            ) : (
                <NavLogo>
                    <img
                        src={arrowLeft}
                        alt="뒤로가기"
                        onClick={() => {
                            if (!location.state?.previousUrl) {
                                if (location.pathname === '/my/edit' && window.ReactNativeWebView) {
                                    navigate(-5, { state: { previousUrl: location.pathname } });
                                } else {
                                    navigate('/', { state: { previousUrl: location.pathname } });
                                }
                            } else if (location.pathname === '/login') {
                                navigate('/', { state: { previousUrl: location.pathname } });
                            } else {
                                navigate(-1, { state: { previousUrl: location.pathname } });
                            }
                        }}
                    />
                </NavLogo>
            )}
            <NavTitle>{ss.barTitle}</NavTitle>
            {ss.isRightMenu && (
                <>
                    <button onClick={() => ss.setShowGnbSearch(true)} type="button">
                        <img src={icSearch} alt="검색" />
                    </button>
                    <Gap10 />
                    {/* <button onClick={() => ss.setShowAside(true)} type="button"> */}
                    <Link to="/my" state={{ previousUrl: location.pathname }}>
                        <img src={icMenu} alt="메뉴 열기" />
                    </Link>
                    {/* </button> */}
                    {/* <Aside /> */}
                    <GnbSearch />
                </>
            )}
        </NavBar>
    );
};

export default TopNavBar;
