import React, { useEffect, useState } from 'react';
import {
    FlxbtFullMb10,
    JoinInputPlc15,
    BtnHalfModalBlack,
    BtnHalfModal,
    GridImg,
    ListCol3,
    StyleItem,
    PhotoItem,
    TextBoxWrapper,
    Textbox,
    TextBoxCount,
    SelectSnsOptions,
    SelectSnsCurrent,
    SelectSns,
    Mb20,
    BtnInInputRound,
    JoinFileLabel,
    DescInput,
    JoinField,
    InputWrapper,
    fz14,
    fz14Bold,
    Fz15Bold,
    SectionDivider,
    Mb10,
    Container,
    Fz18Medium,
    StickyBtm,
    ContainerPadding,
    ListCol2,
    InfoLabel,
    InfoValue,
    FlxbtFull,
    SelectSnsImg,
    BtnDelete,
    InputHidden,
    FileSquareLabel,
    Pad20,
    PopShortMsg,
} from '@/css/style';
import config from '@config';
import {
    useDesignerDetailQuery,
    useDesignerImageAddMutation,
    useDesignerImageDeleteMutation,
    useDesignerProfileAddMutation,
    useDesignerUpdateMutation,
} from '@/services/designerService';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useShopDetailQuery } from '@/services/shopService';
import useInfoDesignerStore from '@/stores/designer/infodesigner';
import useUserStore from '@/stores/user/user';
import NewModal from '@/components/NewModal';
import icClear from '@/assets/img/ic_clear.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsIg from '@/assets/img/sns_ig.svg';
import snsX from '@/assets/img/sns_x.svg';
import snsYt from '@/assets/img/sns_ytube.svg';
import { toast } from 'react-toastify';
import { useIsMutating } from '@tanstack/react-query';

function InfoDesigner() {
    const { navigate } = useOutletContext();
    const is = useInfoDesignerStore();
    const us = useUserStore();
    const isMutating = useIsMutating();

    const { data: designerData, refetch: refetchDesignerData } = useDesignerDetailQuery(us.user.id);
    const DesignerProfileAdd = useDesignerProfileAddMutation(() =>
        DesignerUpdate.mutate(is.fields)
    );
    const DesignerImageAdd = useDesignerImageAddMutation(() => DesignerUpdate.mutate(is.fields));
    const DesignerImageDelete = useDesignerImageDeleteMutation(() =>
        DesignerUpdate.mutate(is.fields)
    );
    const DesignerUpdate = useDesignerUpdateMutation(() => {
        refetchDesignerData();
    });

    useEffect(() => {
        if (designerData) {
            is.setFieldFromServer(designerData.data);
        }
    }, [designerData]);

    useEffect(() => {
        return () => {
            is.terminate();
        };
    }, []);

    const normalizeInstagramUrl = (input) => {
        input = input.trim();
    
        // URL에서 username 추출 또는 입력을 그대로 username으로 사용
        let username;
        if (input.includes('instagram.com/')) {
            // URL에서 username 부분 추출
            const urlParts = input.split('instagram.com/');
            username = urlParts[urlParts.length - 1];
            
            // 쿼리 스트링 제거
            username = username.split('?')[0];
            
            // 끝에 붙은 슬래시 제거
            username = username.replace(/\/$/, '');
            
            // 추가 경로가 있다면 제거 (예: /posts, /reels 등)
            username = username.split('/')[0];
        } else {
            username = input;
        }
    
        // username에서 특수문자 제거 (언더스코어 제외)
        username = username.replace(/[^\w\d_]/gi, '');
    
        // 빈 문자열이면 null 반환
        if (username === '') {
            return null;
        }
    
        // 정상적인 URL 형식으로 변환
        return `https://www.instagram.com/${username}`;
    }

    const normalizeYouTubeUrl = (input) => {
        input = input.trim();
        
        // URL에서 쿼리 파라미터 제거
        input = input.split('?')[0];
        
        // 끝의 슬래시 제거
        input = input.replace(/\/$/, '');
        
        let identifier;
        
        if (input.includes('youtube.com/') || input.includes('youtu.be/')) {
            const urlParts = input.split(/(?:youtube\.com\/|youtu\.be\/)/);
            identifier = urlParts[urlParts.length - 1];
        
            // 채널 URL 처리
            if (identifier.startsWith('channel/')) {
            return `https://www.youtube.com/${identifier}`;
            }
            // 사용자 URL 처리
            else if (identifier.startsWith('user/')) {
            return `https://www.youtube.com/${identifier}`;
            }
            // 커스텀 URL 처리
            else if (identifier.startsWith('c/') || identifier.startsWith('@')) {
            return `https://www.youtube.com/${identifier}`;
            }
            // 동영상 ID 처리
            else if (/^[a-zA-Z0-9_-]{11}$/.test(identifier)) {
            return `https://www.youtube.com/watch?v=${identifier}`;
            }
            // 재생목록 처리
            else if (identifier.startsWith('playlist?list=')) {
            return `https://www.youtube.com/${identifier}`;
            }
        } else {
            identifier = input;
        }
        
        // '@' 기호로 시작하는 경우 처리
        if (identifier.startsWith('@')) {
            return `https://www.youtube.com/${identifier}`;
        }
        
        // 채널 ID 형식 (24자 길이의 문자열)
        if (/^[a-zA-Z0-9_-]{24}$/.test(identifier)) {
            return `https://www.youtube.com/channel/${identifier}`;
        }
        
        // 그 외의 경우, 사용자명으로 간주
        identifier = identifier.replace(/[^\w\d-]/gi, '');
        return identifier ? `https://www.youtube.com/@${identifier}` : null;
        };
        
    const normalizeXUrl = (input) => {
        input = input.trim();
        let username;
        if (input.includes('twitter.com/') || input.includes('x.com/')) {
            const urlParts = input.split(/(?:twitter\.com\/|x\.com\/)/);
            username = urlParts[urlParts.length - 1].split('?')[0].replace(/\/$/, '').split('/')[0];
        } else {
            username = input;
        }
        username = username.replace(/[^\w\d_]/gi, '');
        return username ? `https://x.com/${username}` : null;
    };
    
    const normalizeFacebookUrl = (input) => {
        input = input.trim();
        let username;
        if (input.includes('facebook.com/')) {
            const urlParts = input.split('facebook.com/');
            username = urlParts[urlParts.length - 1].split('?')[0].replace(/\/$/, '').split('/')[0];
        } else {
            username = input;
        }
        username = username.replace(/[^\w\d.]/gi, '');
        return username ? `https://www.facebook.com/${username}` : null;
    };
    
    const postInfoDesigner = () => {
        if (Object.values(is.validations).every((val) => val === true)) {
            DesignerUpdate.mutate(is.fields);
            is.toggleModal();
        } else {
            for (const key in is.validations) {
                if (is.validations.hasOwnProperty(key) && is.validations[key] === false) {
                    switch (key) {
                        case 'title':
                            toast.error('디자이너 이름을 추가해 주세요.');
                            break;
                        case 'profileImage':
                            toast.error('프로필 대표 사진을 추가해 주세요.');
                            break;
                        default:
                            break;
                    }
                }
            }
        }
    };
    const noKorean = (value) => {
        if(isKorean(value)){
          toast.error('한글은 입력할 수 없습니다.');
        }
        return value.replace(/[^a-zA-Z0-9!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+/g, '');
    };

    const isKorean = (text) => {
        const koreanRegex = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/;
        return koreanRegex.test(text);
    };

    return (
        <>
            <Container $pb="7.307rem">
                <ContainerPadding>
                    <Fz18Medium $col="#00BF9D" $mb="2.3077rem">
                        {designerData?.data.approved ? '승인' : '심사중'}
                    </Fz18Medium>
                    <Mb10>
                        <ListCol2>
                            <InfoLabel>담당 매니저</InfoLabel>
                            <InfoValue>
                                {designerData?.data.shop.manager?.name
                                    ? designerData.data.shop.manager.name
                                    : '관리자'}
                            </InfoValue>
                        </ListCol2>
                    </Mb10>
                    <ListCol2>
                        <InfoLabel>연락처</InfoLabel>
                        <InfoValue>
                            {designerData?.data.shop.manager?.region.tel
                                ? designerData?.data.shop.manager?.region.tel
                                : designerData?.data.shop.manager?.phone
                                ? designerData.data.shop.manager.phone
                                : '1566-1986'}
                        </InfoValue>
                    </ListCol2>
                </ContainerPadding>
                <SectionDivider />
                <ContainerPadding>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>디자이너 이름</Fz15Bold>&nbsp;<fz14Bold><span style={{color:'#f33535'}}>(필수)</span></fz14Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinInputPlc15
                                type="text"
                                value={is.fields.title}
                                maxLength="15"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    is.setField('title', value);
                                }}
                                placeholder="직급을 입력해 주세요"
                            />
                        </InputWrapper>
                        <DescInput>
                            * 직급은 앱에 노출되며 회원정보 수정에서 상시 수정 가능합니다.
                        </DescInput>
                    </JoinField>
                    {/* <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>디자이너 면허증 등록 (필수)</Fz15Bold>
                        </Mb10>
                        <InputWrapper>
                            <JoinFileLabel>
                                <input
                                    type="file"
                                    accept="image/*, application/pdf"
                                    onChange={(e) => {
                                        is.addFile('license', e);
                                    }}
                                /> */}
                    {/* 메모: 아래 span 태그에 파일명이 들어와야합니다. 파일명 텍스트는 검은색, 기본 문구는  #A1A1A1 */}
                    {/* <JoinInputPlc15
                                    type="text"
                                    placeholder="면허증 사본을 등록해 주세요"
                                    value={is.fields.license.fileData?.name}
                                    readOnly
                                /> */}
                    {/* <span>{is.fields.license[0]?.fileData.file.name}</span> */}
                    {/* <BtnInInputRound>첨부파일</BtnInInputRound>
                            </JoinFileLabel>
                        </InputWrapper>
                    </JoinField> */}
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>SNS 계정</Fz15Bold>&nbsp;<fz14>(선택)</fz14>
                        </Mb20>
                        <Mb20>
                            앱에 노출하실 SNS계정을 입력해 주세요. 최대 2개까지 등록 가능합니다.
                        </Mb20>
                        {is.fields.sns.map((items) => {
                            const currentSocial = snsOptions
                                .filter((option) => items.provider === option.provider)
                                .map((option) => option.content);
                            return (
                                <FlxbtFullMb10 key={items.id}>
                                    <FlxbtFull style={{ alignItems: 'flex-end' }}>
                                        <SelectSns>
                                            <SelectSnsCurrent
                                                onClick={() => is.toggleSnsMenu(items.id)}
                                            >
                                                {currentSocial.length ? (
                                                    <SelectSnsImg src={currentSocial} />
                                                ) : (
                                                    '선택'
                                                )}
                                            </SelectSnsCurrent>
                                            <SelectSnsOptions
                                                className={items.isSnsMenu ? 'open' : ''}
                                            >
                                                <label
                                                    onClick={() => {
                                                        is.resetSns(items.id);
                                                        is.toggleSnsMenu(items.id);
                                                    }}
                                                >
                                                    선택
                                                </label>
                                                {snsOptions.map(({ id, provider, content }) => (
                                                    <SelectSnsOption
                                                        key={id}
                                                        radioName={`designerSns${items.id}`}
                                                        onChange={() => {
                                                            is.setSns(
                                                                items.id,
                                                                'provider',
                                                                provider
                                                            );
                                                        }}
                                                        onClick={() => {
                                                            is.toggleSnsMenu(items.id);
                                                        }}
                                                        value={items.id}
                                                    >
                                                        {content}
                                                    </SelectSnsOption>
                                                ))}
                                            </SelectSnsOptions>
                                        </SelectSns>
                                        <InputWrapper style={snsInputStyle}>
                                            <JoinInputPlc15
                                                type="text"
                                                value={items.link}
                                                onChange={(e) => {
                                                    if(items.provider == ''){
                                                        toast.error('SNS를 선택해 주세요.');
                                                        is.setSns(items.id, 'link', '');
                                                        return;
                                                    } else {
                                                        const { value } = e.target;
                                                        is.setSns(items.id, 'link', noKorean(value));
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    const { value } = e.target;
                                                    if(items.provider === 'Instagram'){
                                                        is.setSns(items.id, 'link', normalizeInstagramUrl(value));
                                                    } else if(items.provider === 'Youtube'){
                                                        is.setSns(items.id, 'link', normalizeYouTubeUrl(value));
                                                    } else if(items.provider === 'Twitter'){
                                                        is.setSns(items.id, 'link', normalizeXUrl(value));
                                                    } else if(items.provider === 'Facebook'){
                                                        is.setSns(items.id, 'link', normalizeFacebookUrl(value));
                                                    }
                                                }}
                                                placeholder="URL을 입력해 주세요"
                                                style={{width:'100%'}}
                                            />
                                        </InputWrapper>
                                    </FlxbtFull>
                                </FlxbtFullMb10>
                            );
                        })}
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>수상 이력</Fz15Bold>&nbsp;<fz14>(선택)</fz14>
                        </Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                rows="8"
                                maxLength="1000"
                                placeholder="앱에 노출되는 정보입니다. 디자이너님의 수상 이력을 입력하여 고객님의 마음을 잡아보세요!"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    is.setField('career', value);
                                }}
                                value={is.fields.career}
                            ></Textbox>
                            <TextBoxCount>
                                {is.fields.career?.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /1,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>프로필 소개</Fz15Bold>&nbsp;<fz14>(선택)</fz14>
                        </Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                rows="8"
                                maxLength="1000"
                                placeholder="앱에 노출되는 정보입니다. 자신을 PR 하실 수 있는 정보를 등록해 주세요."
                                onChange={(e) => {
                                    const { value } = e.target;
                                    is.setField('introduce', value);
                                }}
                                value={is.fields.introduce}
                            ></Textbox>
                            <TextBoxCount>
                                {is.fields.introduce?.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /1,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb10>
                            <Fz15Bold>프로필 대표 사진</Fz15Bold>&nbsp;<fz14Bold><span style={{color:'#f33535'}}>(필수)</span></fz14Bold>
                        </Mb10>
                        <ListCol3>
                            {is.fields.profileImage?.filename && (
                                <StyleItem>
                                    <PhotoItem>
                                        <GridImg
                                            src={`${config.DESIGNER_IMG_URL}${is.fields.profileImage.filename}`}
                                        />
                                    </PhotoItem>
                                </StyleItem>
                            )}
                            <StyleItem>
                                <FileSquareLabel>
                                    <InputHidden
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            DesignerProfileAdd.mutate({
                                                id: is.fields.id,
                                                data: e.target.files,
                                            });
                                        }}
                                        disabled={!!isMutating}
                                    />
                                </FileSquareLabel>
                            </StyleItem>
                        </ListCol3>
                    </JoinField>
                    <JoinField $mb="3.846rem">
                        <Mb20>
                            <Fz15Bold>스타일 사진</Fz15Bold>&nbsp;<fz14>(선택)</fz14>
                        </Mb20>
                        <Mb20>한 번에 최대 10장 이하 등록 가능합니다.</Mb20>
                        <ListCol3>
                            {is.fields.images?.map((image, index) => (
                                <StyleItem key={index}>
                                    <PhotoItem>
                                        <GridImg
                                            src={`${config.DESIGNER_IMG_URL}${image.filename}`}
                                        />
                                        <BtnDelete
                                            onClick={() =>
                                                DesignerImageDelete.mutate({
                                                    id: is.fields.id,
                                                    imgId: image.id,
                                                })
                                            }
                                            disabled={!!isMutating}
                                        >
                                            <img src={icClear} alt="삭제" />
                                        </BtnDelete>
                                    </PhotoItem>
                                    {/* <StyleInput
                                        placeholder="시술 이름"
                                        value={is.fields.pic_style[index].fileData.desc || ''}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            is.addFileDesc(index, newValue);
                                        }}
                                    /> */}
                                </StyleItem>
                            ))}
                            <StyleItem>
                                <FileSquareLabel>
                                    <InputHidden
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) => {
                                            DesignerImageAdd.mutate({
                                                id: is.fields.id,
                                                data: e.target.files,
                                            });
                                        }}
                                        disabled={!!isMutating}
                                    />
                                </FileSquareLabel>
                            </StyleItem>
                        </ListCol3>
                    </JoinField>
                </ContainerPadding>
                <StickyBtm>
                    <ListCol2>
                        <BtnHalfModalBlack
                            type="button"
                            onClick={() => navigate('/', { replace: true })}
                        >
                            취소
                        </BtnHalfModalBlack>
                        <BtnHalfModal
                            $active={Object.values(is.validations).every((val) => val === true)}
                            onClick={postInfoDesigner}
                            disabled={!!isMutating}
                        >
                            저장
                        </BtnHalfModal>
                    </ListCol2>
                </StickyBtm>
            </Container>
            <NewModal isOpen={is.modal} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">디자이너 추가 정보가 저장되었습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack onClick={() => is.toggleModal()}>닫기</BtnHalfModalBlack>
                        <BtnHalfModal onClick={() => navigate('/my', { replace: true })}>
                            My
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </>
    );
}
const snsInputStyle = {
    width: 'calc(83% - 0.77rem)',
    // width: '100%',
};

const SelectSnsOption = ({ id, value, children, onChange, radioName, onClick }) => (
    <label htmlFor={id} key={id}>
        <input type="radio" name={radioName} id={id} onChange={onChange} onClick={onClick} />
        <SelectSnsImg src={children} alt={value} />
    </label>
);

const snsOptions = [
    { id: 0, provider: 'Instagram', content: snsIg },
    { id: 1, provider: 'Twitter', content: snsX },
    { id: 2, provider: 'Facebook', content: snsFb },
    { id: 3, provider: 'Youtube', content: snsYt },
];
export default InfoDesigner;
