import axios from 'axios';
import config from '@config';

export const instance = axios.create({
    baseURL: config.API_SERVER_HOST,
    headers: {
        'Content-Type': 'application/json',
    },
    withCredentials: true,
});
