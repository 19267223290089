import { create } from 'zustand';

const initialState = {
    toggleReviews: {},
};

const useMyReviewStore = create((set) => ({
    ...initialState,
    toggleReview: (key) =>
        set((state) => ({
            toggleReviews: {
                ...state.toggleReviews,
                [key]: !state.toggleReviews[key],
            },
        })),
    terminate: () => {
        set(initialState);
    },
}));

export default useMyReviewStore;
