import useUserStore from '@/stores/user/user';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function PrivateRoute(props) {
    const us = useUserStore();
    const location = useLocation();

    useEffect(() => {
        if (!us.user.user_id) {
            localStorage.setItem('redirect', location.pathname);
        }
    }, []);

    if (us.user.user_id) {
        return props.children;
    } else {
        return <Navigate replace to="/login" state={{ previousUrl: location.pathname }} />;
    }
}

export default PrivateRoute;
