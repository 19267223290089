import { create } from 'zustand';

const initialState = {
    isLoading: false,
    forMap: false,
    lat: '',
    lng: '',
    lat_map: '',
    lng_map: '',
    searchAddress: '',
    addr: null,
    modal: { reject: false, option: false, address: false },
    prev: '',
};

const getAppLocationHandler = (set, lat, lng, forMap) => {
    console.log('getAppLocationHandler', lat, lng);
    const { naver } = window;

    // lat 및 lng 값을 설정합니다.
    const newLat = set.forMap
        ? set.lat_map !== undefined
            ? set.lat_map
            : lat
        : set.lat !== undefined
        ? set.lat
        : lat;
    const newLng = set.forMap
        ? set.lng_map !== undefined
            ? set.lng_map
            : lng
        : set.lng !== undefined
        ? set.lng
        : lng;

    console.log(newLat, newLng);
    // 상태 업데이트
    set((prevState) => ({
        ...prevState,
        [prevState.forMap ? 'lat_map' : 'lat']: newLat,
        [prevState.forMap ? 'lng_map' : 'lng']: newLng,
    }));

    // reverseGeocode 호출
    if (naver.maps.Service && !forMap) {
        naver.maps.Service.reverseGeocode(
            {
                coords: new naver.maps.LatLng(newLat, newLng),
            },
            function (status, response) {
                if (status !== naver.maps.Service.Status.OK) {
                    return alert('Something wrong!');
                }

                var result = response.v2;
                set((prevState) => ({
                    ...prevState,
                    addr: result.address.jibunAddress,
                    isLoading: false,
                }));
                // do Something
            }
        );
    }

    set({ isLoading: false });
};

const getLocationHandler = (set, latitude, longitude, forMap = false) => {
    if (navigator.geolocation) {
        set({
            isLoading: true,
            forMap: forMap,
        });
        navigator.geolocation.getCurrentPosition(
            (position) =>
                getAppLocationHandler(
                    set,
                    latitude ? latitude : position.coords.latitude,
                    longitude ? longitude : position.coords.longitude,
                    forMap
                ),
            function (error) {
                set({ isLoading: false, modal: { reject: true } });
                console.error(error);
            },
            {
                enableHighAccuracy: false,
                maximumAge: 0,
            }
        );
    } else {
        alert('GPS를 지원하지 않습니다');
        return;
    }
};

const relocateHandler = (set, latitude, longitude) => {
    const { naver } = window;
    if (naver.maps.Service) {
        naver.maps.Service.reverseGeocode(
            {
                coords: new naver.maps.LatLng(latitude, longitude),
            },
            function (status, response) {
                if (status !== naver.maps.Service.Status.OK) {
                    return alert('Something wrong!');
                }

                var result = response.v2;
                set((prevState) => ({
                    ...prevState,
                    addr: result.address.jibunAddress,
                    isLoading: false,
                    lat: latitude,
                    lng: longitude,
                }));
                // do Something
            }
        );
    }
};

const useLocationStore = create((set) => ({
    ...initialState,
    setField: (fieldName, text) => set((state) => ({ [fieldName]: text })),
    toggleModal: (modal) =>
        set((state) => ({ modal: { ...state.modal, [modal]: !state.modal[modal] } })),
    getLocation: (latitude, longitude) => {
        getLocationHandler(set, latitude, longitude);
    },
    getLocationMap: (latitude, longitude) => {
        getLocationHandler(set, latitude, longitude, true);
    },
    getAppLocation: (latitude, longitude) => {
        getAppLocationHandler(set, latitude, longitude);
    },
    relocate: (latitude, longitude) => {
        relocateHandler(set, latitude, longitude);
    },
    resetModal: () => {
        set(() => ({
            modal: {
                ...initialState.modal,
            },
            searchAddress: '',
        }));
    },
}));

// document.addEventListener('message',(e) => {
//     const data = JSON.parse(e.data);
//     if(data.event == 'currentPosition' && data?.data?.coords){
//         useLocationStore.getLocation(data.data.coords.latitude, data.data.coords.longitude)
//     }
//     console.log('e.data.event', data.event)
//     console.log('e.data?.data?.coords', data?.data?.coords)

// });

export default useLocationStore;
