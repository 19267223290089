import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

function useMovePath() {
    const navigate = useNavigate();
    const location = useLocation();
    const [previousUrl, setPreviousUrl] = useState(null);

    useEffect(() => {
        setPreviousUrl(location.pathname);
    }, [location.pathname]);

    const navigateWithPrevious = (to, { state, replace } = {}) => {
        const newState = { ...state, previousUrl };
        if (replace) {
            navigate(to, { state: newState, replace: true });
        } else {
            navigate(to, { state: newState });
        }
    };

    return navigateWithPrevious;
}

export default useMovePath;
