import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import {
    Spacing30,
    Spacing40,
    Spacing50,
    Spacing70,
    FlxRight,
    Container,
    ContainerPadding,
    BtnLogin2,
    FlxMiddle,
    JoinField,
    PadVert10,
    InputWrapper,
    JoinInput,
    fz14,
    Fz15Bold,
    Fz15,
    Fz30Bold,
    WarnInput,
    Mb50,
    AbsolBtm,
    Pad20,
    BtmJoinDesigner,
    LineCenter,
    SnsLoginTit,
    TxCent,
    Mb40,
    Mb15,
    PopShortMsg,
    BtnFullModal,
    BtnHalfModal,
    Mb20,
    Mb30,
    ListCol2,
    BtnCloseModal3,
} from '@/css/style';
import { useLoginMutation } from '@/services/authService';
import { Apple, Kakao, Naver } from '@/components/OAuth';
import useJoinStore from '@/stores/common/join';
import useLoginStore from '@/stores/common/login';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import NewModal from '@/components/NewModal';
import icScissor from '@/assets/img/ic_scissor.svg';
import icWarn from '@/assets/img/ic_warn.svg';
import { useRefreshTokenMutation } from '@/services/authService';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';

function LoginEmail() {
    const ls = useLoginStore();
    const us = useUserStore();
    const location = useLocation();
    const { navigate } = useOutletContext();
    const login = useLoginMutation(us, (e) => setLoginErrorMessage(e.response.data?.message));
    const refreshTokenMutation = useRefreshTokenMutation(us);
    const [isRoleModal, setIsRoleModal] = useState(false);
    const [emailDatalist, setEmailDatalist] = useState([]);
    const [loginErrorMessage, setLoginErrorMessage] = useState('');

    useEffect(() => {
        us.user.id && navigate('/');
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
            ls.terminate();
        };
    }, []);

    useEffect(() => {
        const emails = [
            'naver.com',
            'gmail.com',
            'daum.net',
            'kakao.com',
            'hanmail.net',
            'nate.com',
            'outlook.com',
            'hotmail.com',
            'icloud.com',
        ];

        if (ls.fields?.user_id?.length > 0 && ls.fields?.user_id?.indexOf('@') < 0) {
            setEmailDatalist(emails.map((emailDomain) => `${ls.fields.user_id}@${emailDomain}`));
        } else {
            setEmailDatalist([]);
        }
    }, [ls.fields]);

    const validate = ls.fields.user_id && ls.fields.password;
    const requestLogin = () => {
        if (!validate) {
            return;
        } else {
            login.mutate(ls.fields);
        }
    };

    async function handleMessage(event) {
        const messageData = event.data;
        if (messageData.success) {
            const redirect = messageData.redirect;
            if (messageData.res) {
                us.registUser(messageData.res);

                redirect ? navigate(redirect, { replace: true }) : navigate('/', { replace: true });
            } else {
                const response = await refreshTokenMutation.mutateAsync();
                if (response.status === 200) {
                    redirect
                        ? navigate(redirect, { replace: true })
                        : navigate('/', { replace: true });
                } else {
                    ls.toggleModal('socialError');
                }
            }
        } else if (messageData.success === false) {
            ls.toggleModal('socialError');
        }
    }

    return (
        <>
            <Container>
                <Spacing40 />
                <ContainerPadding>
                    <form>
                        <Fz30Bold $mb="3.077rem">LOGIN</Fz30Bold>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>아이디</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="text"
                                    id="userId"
                                    name="userId"
                                    maxLength="30"
                                    value={ls.fields.user_id}
                                    placeholder="아이디를 입력해 주세요"
                                    autoComplete="off"
                                    onChange={(e) => ls.setField('user_id', e.target.value)}
                                />
                                {emailDatalist.length > 0 && (
                                    <div className="user_id_datalist">
                                        <ul>
                                            {emailDatalist.map((email) => (
                                                <li
                                                    key={email}
                                                    onClick={() => ls.setField('user_id', email)}
                                                >
                                                    {email}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </InputWrapper>
                            {/* {ls.isError && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 아이디를 확인해 주세요.
                                </WarnInput>
                            )} */}
                        </JoinField>
                        <JoinField $mb="1.884rem">
                            <PadVert10>
                                <Fz15Bold>비밀번호</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="userPw"
                                    name="userPw"
                                    value={ls.fields.password}
                                    placeholder="비밀번호를 입력해 주세요"
                                    onChange={(e) => ls.setField('password', e.target.value)}
                                    autoComplete="off"
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            requestLogin();
                                        }
                                    }}
                                />
                            </InputWrapper>

                            {/* {ls.isError && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 비밀번호를 확인해 주세요.
                                </WarnInput>
                            )} */}
                            {
                                loginErrorMessage && (
                                    <WarnInput>
                                        <img src={icWarn} alt="경고" /> {loginErrorMessage}
                                    </WarnInput>
                                )
                            }
                        </JoinField>
                        <FlxRight as={Mb50}>
                            <FlxMiddle>
                                <Link to="/login/forgot" state={{ previousUrl: location.pathname }}>
                                    <Fz15>ID/PW 찾기</Fz15>
                                </Link>
                                &nbsp;・&nbsp;
                                <Link onClick={() => setIsRoleModal(true)}>
                                    <Fz15>회원가입</Fz15>
                                </Link>
                            </FlxMiddle>
                        </FlxRight>
                        <BtnLogin2
                            $active={validate}
                            type="button"
                            onClick={() => {
                                requestLogin();
                            }}
                        >
                            로그인
                        </BtnLogin2>
                    </form>
                    <ContainerPadding>
                        <Mb30 />
                        <SnsLoginTit as={TxCent}>소셜 로그인</SnsLoginTit>
                        <LineCenter as={Mb15} style={{ columnGap: '1.538rem' }}>
                            <Kakao />
                            <Naver />
                            <Apple />
                        </LineCenter>
                        <TxCent>
                            <fz14>회원가입 후 소셜 로그인 연동이 필요합니다.</fz14>
                        </TxCent>
                    </ContainerPadding>
                    <AbsolBtm $wd="100%" $bt="0" style={{ left: 0 }}>
                        <BtmJoinDesigner
                            onClick={() => {
                                navigate('/join/designer');
                            }}
                        >
                            <img src={icScissor} />
                            <Fz15Bold $col="#fff">혹시 디자이너인가요?</Fz15Bold>
                        </BtmJoinDesigner>
                    </AbsolBtm>
                </ContainerPadding>
            </Container>
            <NewModal isOpen={ls.modal.socialError} contentLabel="연동 오류" ariaHideApp={false}>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">
                                [소셜 로그인] 연동된 계정이 없습니다.
                            </Fz15Bold>
                        </Mb20>
                        <Fz15Bold>소셜 로그인 연동 후 이용해 해주세요.</Fz15Bold>
                        <Fz15>MY페이지 &gt; 소셜 로그인 연동</Fz15>
                    </PopShortMsg>
                    <BtnFullModal $active onClick={() => ls.toggleModal('socialError')}>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal isOpen={isRoleModal} contentLabel="회원가입선택" ariaHideApp={false}>
                <BtnCloseModal3>
                    <img src={icCloseModal} alt="닫기" onClick={() => setIsRoleModal(false)} />
                </BtnCloseModal3>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">회원가입</Fz15Bold>
                        </Mb20>
                        <Fz15>회원 유형을 선택해 주세요.</Fz15>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModal $active onClick={() => navigate('/join/user')}>
                            일반
                        </BtnHalfModal>
                        <BtnHalfModal $active onClick={() => navigate('/join/designer')}>
                            디자이너
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </>
    );
}

export default LoginEmail;
