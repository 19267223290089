import React from 'react';
import {
    FaqItem,
    MyHeader,
    LinkFlxbtFull,
    Container,
    Fz20Bold,
    NavHeight,
    Fz15,
    ColRed,
} from '@/css/style';

function QnaList() {
    return (
        <>
            <Container>
                <NavHeight />
                <MyHeader $mb="0">
                    <Fz20Bold>Q&A</Fz20Bold>
                </MyHeader>
                {/* 문의 리스트 */}
                <ul>
                    <FaqItem>
                        <LinkFlxbtFull to="">
                            <Fz15 $col="#000" as="h4">
                                How do I delete my account?
                            </Fz15>
                            <span>2024.01.04</span>
                            {/* 메모: 답변대기 / 답변완료로 분기 */}
                            <span>답변대기</span>
                        </LinkFlxbtFull>
                    </FaqItem>
                    <FaqItem>
                        <LinkFlxbtFull to="">
                            <Fz15 $col="#000" as="h4">
                                예약 후 결제는 어떻게 진행되나요?
                            </Fz15>
                            <span>2023.11.15</span>
                            <ColRed>답변완료</ColRed>
                        </LinkFlxbtFull>
                    </FaqItem>
                </ul>
                {/* //문의 리스트 */}
            </Container>
        </>
    );
}

export default QnaList;
