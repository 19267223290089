import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
    ArchiveThumb,
    PadVert20,
    MyHeader,
    GridImg,
    ListColumn,
    Fz15Bold,
    Container,
    ContainerPadding,
    Fz20Bold,
    Mb20,
} from '@/css/style';
import { useArchiveListReadQuery } from '@/services/boardService';
import { format, parse } from 'date-fns';

function ArchiveList() {
    const { data: archiveListData } = useArchiveListReadQuery();
    const location = useLocation();
    return (
        <Container>
            {/* <MyHeader>
                <Fz20Bold>아카이브</Fz20Bold>
            </MyHeader> */}
            <ContainerPadding>
                <ListColumn>
                    {archiveListData?.data.docs.map((items) => (
                        <Mb20
                            as={Link}
                            to={`/archive/${items.id}`}
                            state={{ previousUrl: location.pathname }}
                        >
                            {/* <ArchiveThumb>
                                    <GridImg src="https://img.wkorea.com/w/2023/11/style_655eb5dbe6e5b-744x930.jpg" />
                                </ArchiveThumb> */}
                            <PadVert20>
                                <Fz15Bold $col="#000">{items.title}</Fz15Bold>
                            </PadVert20>
                            <span>
                                {format(
                                    parse(items.created, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date()),
                                    'yyyy. MM. dd'
                                )}
                            </span>
                        </Mb20>
                    ))}
                </ListColumn>
            </ContainerPadding>
        </Container>
    );
}

export default ArchiveList;
