import React, { useEffect, useRef, useState } from 'react';
import {
    ListCol3,
    PopShortMsg,
    HistoryList,
    ListColumn,
    StyleItem,
    PadVert30,
    Paragraph,
    TxtDivider,
    ChkAndTxt,
    Fz16Medium,
    RsvStatus,
    RsvStatusGreen,
    RsvStatusPink,
    BorderBox,
    Container,
    ContainerPadding,
    SectionDivider,
    FlxMiddle,
    FlxbtFull,
    FlxbtFullMb15,
    FlxbtFullMb20,
    Fz15,
    Gap10,
    Mb20,
    ListCol4,
    ColAccent,
    Pad20,
    Spacing20,
    Fz18Medium,
    Divider,
    ConsumerPrice,
    OrgPrice,
    ItemInfoCol2,
    Gap20,
    InfoValue,
    FieldSet,
    InfoLabel,
    TimeItem,
    BtnFullModal,
    JoinField,
    PadVert10,
    InputWrapper,
    JoinInputPlc15,
    ItemHalf2,
    BorderBoxMb,
    Spacing30,
    Spacing10,
    TabAccent2,
    FlxMiddleMb20,
    BtnResetFilter,
    RsvServiceList,
    ToggleShow,
    BtnCloseModal3,
    ColBlack,
    CusModalTit,
    InfoLabelM,
    TabWrapper,
    BtnLineGray2,
    LineCenter,
    Fz15Bold,
} from '@/css/style';
import { format, parse } from 'date-fns';
import {
    useDesignerReservationQuery,
    useDesignerReservationStatusChangeMutation,
    useDesignerReservationSummaryQuery,
} from '@/services/designerService';
import { useIntersectionObserver } from '@/utils/helper';
import useCustomerStore from '@/stores/designer/customer';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import ChkOnOff from '@/components/ChkOnOff';
import NewModal from '@/components/NewModal';
import NoContent from '@/components/NoContent';
import RadioBox from '@/components/RadioBox';
import RsvDayPicker from '@/components/DayPicker';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import icReset from '@/assets/img/ic_reset.svg';
import icSearch from '@/assets/img/ic_search_gray.svg';

function Customers() {
    const [modalIsOpen, setIsOpen] = React.useState(false);
    function closeModal() {
        setIsOpen(false);
    }
    const cs = useCustomerStore();
    const us = useUserStore();
    const ss = useShowStore();
    const containerRef = useRef(null);

    const {
        data: reservationData,
        refetch: refetchReservationData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
    } = useDesignerReservationQuery({
        id: us.user.id,
        params: {
            from_date: format(cs.period.from_date, 'yyyy-MM-dd'),
            to_date: format(cs.period.to_date, 'yyyy-MM-dd'),
            status: cs.selectedStatus,
            textBy: 'name',
            text: cs.searchName,
        },
    });

    const { data: summaryData, refetch: refetchSummaryData } = useDesignerReservationSummaryQuery({
        id: us.user.id,
        params: {
            from_date: format(cs.period.from_date, 'yyyy-MM-dd'),
            to_date: format(cs.period.to_date, 'yyyy-MM-dd'),
        },
    });

    const ReservationStatusChangeMutation = useDesignerReservationStatusChangeMutation(() => {
        refetchReservationData();
        refetchSummaryData();
    });
    const observer = useIntersectionObserver(
        fetchNextPage,
        containerRef,
        reservationData,
        isFetchingNextPage
    );

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [isLoading]);

    const requestReservationStatus = () => {
        if (!cs.modal.detail.status) return;

        ReservationStatusChangeMutation.mutate({
            designerId: us.user.id,
            reservId: cs.modal.detail.detailData.id,
            status: cs.modal.detail.status,
        });

        cs.closeDetail();
    };

    const [range, setRange] = useState(null);
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = `0${date.getMonth() + 1}`.slice(-2);
        const day = `0${date.getDate()}`.slice(-2);
        return `${year}.${month}.${day}`;
    };
    const applyPeriod = () => {
        if (
            (cs.periodList && cs.periodList !== 'custom') ||
            (cs.periodList === 'custom' && range && range.from && range.to)
        ) {
            cs.setPeriod(cs.periodList, range);
            setRange(null);
            cs.toggleIsSelectDate();
        }
    };

    return (
        <>
            <Container $pb="0">
                {/* 목록 보기일때 */}
                <ToggleShow $visible>
                    <ContainerPadding>
                        <FlxbtFullMb20>
                            <Fz18Medium>예약 고객 관리</Fz18Medium>
                            <ChkOnOff
                                id="isSummary"
                                checked={cs.isSummary}
                                onChange={() => {
                                    cs.toggleSummary();
                                }}
                                textOn="요약"
                                textOff="숨김"
                            />
                        </FlxbtFullMb20>
                        <FlxbtFull>
                            <BtnLineGray2
                                onClick={() => {
                                    cs.toggleIsSelectDate();
                                }}
                            >
                                {formatDate(cs.period.from_date)} ~ {formatDate(cs.period.to_date)}
                            </BtnLineGray2>
                            <ChkAndTxt htmlFor="isToday">
                                <Chkbox
                                    id="isToday"
                                    checked={cs.isToday}
                                    onChange={cs.toggleToday}
                                />
                                <Fz16Medium>오늘만</Fz16Medium>
                            </ChkAndTxt>
                        </FlxbtFull>
                        {/* 예약내역 요약 박스 */}
                        {cs.isSummary && (
                            <>
                                <Spacing20 />
                                <BorderBox>
                                    <FlxbtFull>
                                        <ItemHalf2>
                                            <Fz16Medium $col="#757575">예약 확정</Fz16Medium>
                                            <Fz16Medium>
                                                {' '}
                                                {summaryData?.data.status.find(
                                                    (item) => item.value === 'Confirm'
                                                )?.total_count || 0}{' '}
                                                건
                                            </Fz16Medium>
                                        </ItemHalf2>
                                        <TxtDivider />
                                        <ItemHalf2>
                                            <Fz16Medium $col="#757575">예약 완료</Fz16Medium>
                                            <Fz16Medium>
                                                {' '}
                                                {summaryData?.data.status.find(
                                                    (item) => item.value === 'Complete'
                                                )?.total_count || 0}{' '}
                                                건
                                            </Fz16Medium>
                                        </ItemHalf2>
                                    </FlxbtFull>
                                    <Spacing20 />
                                    <FlxbtFull>
                                        <ItemHalf2>
                                            <Fz16Medium $col="#757575">노쇼</Fz16Medium>
                                            <Fz16Medium>
                                                {' '}
                                                {summaryData?.data.status.find(
                                                    (item) => item.value === 'NoShow'
                                                )?.total_count || 0}{' '}
                                                건
                                            </Fz16Medium>
                                        </ItemHalf2>
                                        <TxtDivider />
                                        <ItemHalf2>
                                            <Fz16Medium $col="#757575">예약 취소</Fz16Medium>
                                            <Fz16Medium>
                                                {' '}
                                                {summaryData?.data.status.find(
                                                    (item) => item.value === 'Cancel'
                                                )?.total_count || 0}{' '}
                                                건
                                            </Fz16Medium>
                                        </ItemHalf2>
                                    </FlxbtFull>
                                </BorderBox>
                                <Spacing10 />
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        placeholder="고객명을 입력해 주세요"
                                        maxLength="10"
                                        value={cs.searchName}
                                        onChange={(e) => cs.setName(e.target.value)}
                                    />
                                    <button type="button" onClick={() => refetchReservationData()}>
                                        <img src={icSearch} alt="찾기" />
                                    </button>
                                </InputWrapper>
                            </>
                        )}
                        {/* //예약내역 요약 박스 */}
                        {/* //예약 관리 상단블록 */}
                    </ContainerPadding>
                    <SectionDivider />
                    <ContainerPadding>
                        {/* 목록 필터 */}
                        <TabWrapper $mb="1.538rem">
                            <TabAccent2
                                $active={cs.category.all}
                                onClick={() => cs.toggleCategory('')}
                            >
                                전체
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={cs.category.New}
                                onClick={() => cs.toggleCategory('New')}
                            >
                                예약
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={cs.category.Confirm}
                                onClick={() => cs.toggleCategory('Confirm')}
                            >
                                확정
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={cs.category.Complete}
                                onClick={() => cs.toggleCategory('Complete')}
                            >
                                완료
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={cs.category.NoShow}
                                onClick={() => cs.toggleCategory('NoShow')}
                            >
                                노쇼
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={cs.category.Cancel}
                                onClick={() => cs.toggleCategory('Cancel')}
                            >
                                취소
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={cs.category.Reject}
                                onClick={() => cs.toggleCategory('Reject')}
                            >
                                거절
                            </TabAccent2>
                        </TabWrapper>
                        {/* 목록 필터 */}
                    </ContainerPadding>
                    <HistoryList>
                        <ContainerPadding>
                            <FlxbtFullMb20>
                                <Fz15 $col="#000">
                                    총{' '}
                                    <ColAccent>
                                        {reservationData?.pages[0].data.totalDocs}
                                    </ColAccent>
                                    개
                                </Fz15>
                                <BtnResetFilter onClick={() => cs.terminate()}>
                                    <img src={icReset} /> 초기화
                                </BtnResetFilter>
                            </FlxbtFullMb20>
                            <ListColumn>
                                {/* 240110 메모: 박스를 누르면 예약 상세 정보 모달이 떠야합니다 */}
                                {!(
                                    reservationData?.pages &&
                                    reservationData?.pages[0].data.docs.length > 0
                                ) && <NoContent message="검색된 예약이 없습니다." />}
                                {reservationData?.pages.map((page) =>
                                    page.data.docs.map((items) => (
                                        <Fz15
                                            $col="#000"
                                            as={BorderBoxMb}
                                            key={items.id}
                                            onClick={() => cs.setDetailData(items)}
                                        >
                                            <FlxbtFullMb15>
                                                <Fz16Medium>
                                                    <ColBlack>{items.name} 고객</ColBlack>
                                                </Fz16Medium>
                                                {(() => {
                                                    switch (items.status) {
                                                        case 'New':
                                                            return null; // 렌더링할 내용이 없는 경우
                                                        case 'Confirm':
                                                            return (
                                                                <RsvStatusPink>확정</RsvStatusPink>
                                                            );
                                                        case 'Complete':
                                                            return (
                                                                <RsvStatusGreen>
                                                                    완료
                                                                </RsvStatusGreen>
                                                            );
                                                        case 'Reject':
                                                            return (
                                                                <RsvStatusGreen>
                                                                    거절
                                                                </RsvStatusGreen>
                                                            );
                                                        case 'NoShow':
                                                            return <RsvStatus>노쇼</RsvStatus>;
                                                        case 'Cancel':
                                                            return <RsvStatus>취소</RsvStatus>;
                                                        default:
                                                            return null; // 기본적으로 처리할 내용이 없는 경우
                                                    }
                                                })()}
                                            </FlxbtFullMb15>
                                            {items.phone}
                                            <Divider $mt="1.154rem" $mb="1.154rem" />
                                            <RsvServiceList>
                                                {items.menus.map((menus) => (
                                                    <li key={menus._id}>
                                                        <h5>{menus.name}</h5>
                                                        <FlxMiddle>
                                                            <ConsumerPrice>
                                                                {menus.price}
                                                            </ConsumerPrice>
                                                            원
                                                        </FlxMiddle>
                                                    </li>
                                                ))}
                                            </RsvServiceList>
                                            <ItemInfoCol2 $mb="1.154rem">
                                                <InfoLabelM>접수일</InfoLabelM>
                                                <InfoValue>
                                                    {' '}
                                                    {format(
                                                        parse(
                                                            items.created,
                                                            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                            new Date()
                                                        ),
                                                        'yyyy-MM-dd HH:mm'
                                                    )}
                                                </InfoValue>
                                            </ItemInfoCol2>
                                            <ItemInfoCol2>
                                                <InfoLabelM>예약일</InfoLabelM>
                                                <InfoValue>
                                                    {/* <strong>23.12.17 일요일 오후 04:30</strong> */}
                                                    <strong>{items.date + ' ' + items.time}</strong>
                                                </InfoValue>
                                            </ItemInfoCol2>
                                        </Fz15>
                                    ))
                                )}
                            </ListColumn>
                            {hasNextPage && (
                                <LineCenter ref={containerRef}>
                                    <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                    <img src={arrowDownThinGray} alt="more" />
                                </LineCenter>
                            )}
                        </ContainerPadding>
                    </HistoryList>
                </ToggleShow>
                {/* //목록 보기일때 */}
            </Container>
            {/* 조회 조건 설정 모달 */}
            <NewModal
                isOpen={cs.isSelectDate}
                onRequestClose={closeModal}
                contentLabel="조회 조건 설정"
                ariaHideApp={false}
                $visible
            >
                <BtnCloseModal3 onClick={() => cs.toggleIsSelectDate()}>
                    <img src={icCloseModal} alt="닫기" />
                </BtnCloseModal3>
                <Pad20>
                    <Fz15 as="form">
                        <PopShortMsg>
                            <CusModalTit>조회 조건 설정</CusModalTit>
                            <ListCol3>
                                <StyleItem>
                                    <TimeItem
                                        $active={cs.periodList === 'week'}
                                        htmlFor="periodWeek"
                                    >
                                        <input
                                            type="radio"
                                            name="rsvPeriod"
                                            id="periodWeek"
                                            value="periodWeek"
                                            onChange={() => {
                                                cs.setPeriodList('week');
                                            }}
                                        />
                                        이번 주
                                    </TimeItem>
                                </StyleItem>
                                <StyleItem>
                                    <TimeItem
                                        $active={cs.periodList === 'month'}
                                        htmlFor="periodMonth"
                                    >
                                        <input
                                            type="radio"
                                            name="rsvPeriod"
                                            id="periodMonth"
                                            value="periodMonth"
                                            onChange={() => {
                                                cs.setPeriodList('month');
                                            }}
                                        />
                                        이번 달
                                    </TimeItem>
                                </StyleItem>
                                <StyleItem>
                                    <TimeItem
                                        $active={cs.periodList === 'custom'}
                                        htmlFor="periodEtc"
                                    >
                                        <input
                                            type="radio"
                                            name="rsvPeriod"
                                            id="periodEtc"
                                            value="periodEtc"
                                            onChange={() => {
                                                cs.setPeriodList('custom');
                                            }}
                                        />
                                        직접 설정
                                    </TimeItem>
                                </StyleItem>
                            </ListCol3>
                            {cs.periodList === 'custom' && (
                                <>
                                    <Spacing30 />
                                    {/* 메모: "기간"을 설정해야함 */}
                                    <RsvDayPicker
                                        mode="range"
                                        disabled={false}
                                        selected={range}
                                        onSelect={setRange}
                                        classNames="syhi"
                                    />
                                </>
                            )}
                        </PopShortMsg>
                        <BtnFullModal
                            type="button"
                            $active={
                                (cs.periodList && cs.periodList !== 'custom') ||
                                (cs.periodList === 'custom' && range && range.from && range.to)
                            }
                            onClick={applyPeriod}
                        >
                            저장
                        </BtnFullModal>
                    </Fz15>
                </Pad20>
            </NewModal>
            {/* //조회 조건 설정 모달 */}
            {/* 예약 상세정보 모달 */}
            <NewModal
                isOpen={cs.modal.detail.isDetail}
                onRequestClose={closeModal}
                contentLabel="예약 상세정보"
                ariaHideApp={false}
                $visible
            >
                <BtnCloseModal3 onClick={() => cs.closeDetail()}>
                    <img src={icCloseModal} alt="닫기" />
                </BtnCloseModal3>
                <Pad20>
                    <CusModalTit>예약 상세정보</CusModalTit>
                    <FlxbtFullMb15>
                        <Fz16Medium>
                            <ColBlack>{cs.modal.detail.detailData.name}</ColBlack>
                        </Fz16Medium>
                        {(() => {
                            switch (cs.modal.detail.detailData.status) {
                                case 'New':
                                    return null; // 렌더링할 내용이 없는 경우
                                case 'Confirm':
                                    return <RsvStatusPink>확정</RsvStatusPink>;
                                case 'Complete':
                                    return <RsvStatusGreen>완료</RsvStatusGreen>;
                                case 'Reject':
                                    return <RsvStatusGreen>거절</RsvStatusGreen>;
                                case 'NoShow':
                                    return <RsvStatus>노쇼</RsvStatus>;
                                case 'Cancel':
                                    return <RsvStatus>취소</RsvStatus>;
                                default:
                                    return null; // 기본적으로 처리할 내용이 없는 경우
                            }
                        })()}
                    </FlxbtFullMb15>
                    <Divider $mt="1.538rem" $mb="1.538rem" />
                    <ItemInfoCol2 $mb="1.154rem">
                        <InfoLabel>방문자</InfoLabel>
                        <InfoValue>{cs.modal.detail.detailData.name}</InfoValue>
                    </ItemInfoCol2>
                    <ItemInfoCol2 $mb="1.154rem">
                        <InfoLabel>연락처</InfoLabel>
                        <InfoValue>{cs.modal.detail.detailData.phone}</InfoValue>
                    </ItemInfoCol2>
                    <ItemInfoCol2 $mb="1.154rem">
                        <InfoLabel>접수일</InfoLabel>
                        <InfoValue>{cs.modal.detail.detailData.created}</InfoValue>
                    </ItemInfoCol2>
                    <ItemInfoCol2 $mb="1.154rem">
                        <InfoLabel>예약일</InfoLabel>
                        <InfoValue>
                            {cs.modal.detail.detailData.date +
                                ' ' +
                                cs.modal.detail.detailData.time}
                        </InfoValue>
                    </ItemInfoCol2>
                    <ItemInfoCol2 $mb="1.154rem">
                        <InfoLabel>시술 메뉴</InfoLabel>
                        <InfoValue>
                            {cs.modal.detail.detailData.menus?.map((item, index) => (
                                <React.Fragment key={item._id}>
                                    {index > 0 && ', '}
                                    {item.name}
                                </React.Fragment>
                            ))}
                        </InfoValue>
                    </ItemInfoCol2>
                    <ItemInfoCol2 $mb="1.154rem">
                        <InfoLabel>금액</InfoLabel>
                        <InfoValue>
                            {cs.modal.detail.detailData.menus?.reduce(
                                (total, item) => total + item.price,
                                0
                            )}
                            원
                        </InfoValue>
                    </ItemInfoCol2>
                    <ItemInfoCol2>
                        <InfoLabel>요청사항</InfoLabel>
                        <InfoValue>{cs.modal.detail.detailData.memo}</InfoValue>
                    </ItemInfoCol2>
                    {cs.modal.detail.detailData.status !== 'Complete' && (
                        <>
                            <PadVert30>
                                <ListCol4>
                                    <TimeItem
                                        htmlFor="rsvConfirmed"
                                        $active={cs.modal.detail.status === 'Confirm'}
                                    >
                                        <input
                                            type="radio"
                                            name="rsvStatus"
                                            id="rsvConfirmed"
                                            value="rsvConfirmed"
                                            checked={cs.modal.detail.status === 'Confirm'}
                                            onChange={() => cs.setStatus('Confirm')}
                                        />
                                        확정
                                    </TimeItem>
                                    <TimeItem
                                        htmlFor="rsvComplete"
                                        $active={cs.modal.detail.status === 'Complete'}
                                    >
                                        <input
                                            type="radio"
                                            name="rsvStatus"
                                            id="rsvComplete"
                                            value="rsvComplete"
                                            checked={cs.modal.detail.status === 'Complete'}
                                            onChange={() => cs.setStatus('Complete')}
                                        />
                                        완료
                                    </TimeItem>
                                    <TimeItem
                                        htmlFor="rsvNoshow"
                                        $active={cs.modal.detail.status === 'NoShow'}
                                    >
                                        <input
                                            type="radio"
                                            name="rsvStatus"
                                            id="rsvNoshow"
                                            value="rsvNoshow"
                                            checked={cs.modal.detail.status === 'NoShow'}
                                            onChange={() => cs.setStatus('NoShow')}
                                        />
                                        노쇼
                                    </TimeItem>
                                    <TimeItem
                                        htmlFor="rsvCancel"
                                        $active={cs.modal.detail.status === 'Cancel'}
                                    >
                                        <input
                                            type="radio"
                                            name="rsvStatus"
                                            id="rsvCancel"
                                            value="rsvCancel"
                                            checked={cs.modal.detail.status === 'Cancel'}
                                            onChange={() => cs.setStatus('Cancel')}
                                        />
                                        취소
                                    </TimeItem>
                                    <TimeItem
                                        htmlFor="rsvReject"
                                        $active={cs.modal.detail.status === 'Reject'}
                                    >
                                        <input
                                            type="radio"
                                            name="rsvStatus"
                                            id="rsvReject"
                                            value="rsvReject"
                                            checked={cs.modal.detail.status === 'Reject'}
                                            onChange={() => cs.setStatus('Reject')}
                                        />
                                        거절
                                    </TimeItem>
                                </ListCol4>
                            </PadVert30>
                            <BtnFullModal
                                $active={cs.modal.detail.status}
                                onClick={() => requestReservationStatus()}
                            >
                                저장
                            </BtnFullModal>
                        </>
                    )}
                </Pad20>
            </NewModal>
            {/* //예약 상세정보 모달 */}
            <NewModal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="조회 조건 설정"
                $visible
            >
                <BtnCloseModal3>
                    <img src={icCloseModal} alt="닫기" />
                </BtnCloseModal3>
                <Pad20>
                    <Fz15 as="form">
                        <CusModalTit>예약 등록/변경</CusModalTit>
                        <FlxMiddleMb20>
                            <ChkAndTxt htmlFor="">
                                <FlxMiddle>
                                    <RadioBox />
                                    <Gap10 />
                                    예약 가능
                                </FlxMiddle>
                            </ChkAndTxt>
                            <Gap20 />
                            <ChkAndTxt htmlFor="">
                                <FlxMiddle>
                                    <RadioBox />
                                    <Gap10 />
                                    예약 막기
                                </FlxMiddle>
                            </ChkAndTxt>
                        </FlxMiddleMb20>
                        {/* 240110 메모: 예약막기 선택하면 아래 필드셋 감춤 */}
                        <FieldSet $visible>
                            <Mb20>
                                <Paragraph $fz="1.154rem" $col="#000">
                                    23.12.17 일요일 오후 04:30
                                    <br />
                                    해당 시간에 예약을 등록합니다.
                                </Paragraph>
                            </Mb20>
                            <Mb20>
                                <PadVert10>
                                    <strong>방문자 이름</strong>
                                </PadVert10>
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        id="customerName"
                                        name="customerName"
                                        placeholder="실 방문자 이름을 작성해 주세요"
                                    />
                                </InputWrapper>
                            </Mb20>
                            <Mb20>
                                <PadVert10>
                                    <strong>방문자 연락처</strong>
                                </PadVert10>
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        id="customerPhone"
                                        name="customerPhone"
                                        placeholder="실 방문자 연락처를 입력해 주세요"
                                    />
                                </InputWrapper>
                            </Mb20>
                            <Mb20>
                                <PadVert10>
                                    <strong>시술명(선택)</strong>
                                </PadVert10>
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        id="serviceName"
                                        name="serviceName"
                                        placeholder="시술명을 입력해 주세요"
                                    />
                                </InputWrapper>
                            </Mb20>
                            <JoinField>
                                <PadVert10>
                                    <strong>시술 요금(선택)</strong>
                                </PadVert10>
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        id="servicePrice"
                                        name="servicePrice"
                                        placeholder="요금을 입력해 주세요"
                                    />
                                </InputWrapper>
                            </JoinField>
                        </FieldSet>
                        <BtnFullModal>저장</BtnFullModal>
                    </Fz15>
                </Pad20>
            </NewModal>
        </>
    );
}

export default Customers;
