import React, { useEffect, useRef, useState } from 'react';
import NewModal from '@/components/NewModal';
import {
    BtnClearInput,
    WarnInput,
    PopShortMsg,
    Fz15,
    BtnFullModal,
    Spacing10,
    StickyBtm,
    AbsolBtm,
    Fz18Bold,
    fz14Bold,
    Fz15Bold,
    JoinInput,
    DescInput,
    ColAccent2,
    BtnInInputRound,
    JoinField,
    InputWrapper,
    ContainerFit,
    ContainerPadding,
    Mb40,
    FlxMiddle,
    Mb10,
    Mb20,
    Flxbt,
    LinkFull,
    FieldSet,
    ColAccent,
    Paragraph,
    InfoLabel,
    InfoValue,
    Pad20,
    BtnCloseModal,
    PopOver,
    NavHeight,
    FlxbtFullMb20,
    Fz18Medium,
    ListColumn,
    PadVert30,
    PadVert20,
    ListCol2,
    BtnHalfModalBlack,
    BtnHalfModal,
    PadVert10,
    ConfirmInfoItem,
    AgreeAllBox,
    Mb30,
    TermModal,
    TermItem,
    FlxRight,
    BtnLineGreenShorter,
    BtnFullLineGreen,
    ColBody,
    LineCenter,
    BtnCloseModal3,
    ChkAndTxt,
    TermOver,
    TermHeader,
    PossibleInput,
    DaumWrapper,
    PadVert30PopOver,
} from '@/css/style';
import {
    useChkIdQuery,
    useRegistDesignerMutation,
    useRegistMutation,
} from '@/services/authService';
import { emailRegex, useIntersectionObserver, passwordRegex } from '@/utils/helper';

import { popOvers } from '../../utils/termData';

import {
    useBlocker,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import { useShopCreateRequestMutation } from '@/services/shopService';
import { useShopQuery } from '@/services/shopService';
import useJoinStore from '@/stores/common/join';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import ChkOnOffShort from '@/components/ChkOnOffShort';
import RadioBox from '@/components/RadioBox';
import NoContent from '@/components/NoContent';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import arrowRightThinGray from '@/assets/img/arrow_right_thin_gray.svg';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import icChk from '@/assets/img/ic_chk_red.svg';
import icClear from '@/assets/img/ic_clear.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import icWarn from '@/assets/img/ic_warn.svg';
import { nicknameRegex, onlyEmail } from '../../utils/helper';
import { useChkNickQuery, useGetCertificateDataQuery } from '../../services/authService';
import { useGetDealerQuery } from '../../services/shopService';
import { useIsFetching, useIsMutating } from '@tanstack/react-query';
import DaumPostcodeEmbed from 'react-daum-postcode';

function Join() {
    const { navigate } = useOutletContext();
    useEffect(() => {
        switch (params.type) {
            case 'user':
                js.setRegistRole('user');
                break;
            case 'designer':
                js.setRegistRole('designer');
                break;
            default:
                navigate('/login', { replace: true });
                break;
        }
        window.addEventListener('message', handleMessage);

        return () => {
            js.terminate();
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const params = useParams();

    const js = useJoinStore();
    const us = useUserStore();
    const containerRef = useRef(null);
    const dealerRef = useRef(null);
    const isMutating = useIsMutating();
    const { refetch: certificateRefetch } = useGetCertificateDataQuery(
        js.registRole === 'user' ? 'joinUser' : 'joinDesigner'
    );
    const { refetch: chkIdRefetch } = useChkIdQuery(
        js,
        (res) => {
            js.setVerify('isCheckedId', true);
            js.chkIdValidate();
        },
        (error) => {
            js.setVerify('isCheckedId', false);
            js.chkIdValidate();
        }
    );
    const { refetch: chkNickRefetch } = useChkNickQuery(
        js,
        (res) => {
            js.setVerify('isCheckedNick', true);
            js.chkNickValidate();
        },
        (error) => {
            js.setVerify('isCheckedNick', false);
            js.chkNickValidate();
        }
    );

    const shopCreateRequest = useShopCreateRequestMutation(js);
    const regist = useRegistMutation(js, us);
    const registDesigner = useRegistDesignerMutation(js, us);
    const {
        data: shopData,
        refetch: shopRefetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useShopQuery({
        name: js.fields.salon,
    });

    const {
        data: dealerData,
        isLoading: dealerDataLoading,
        refetch: dealerDataRefetch,
        fetchNextPage: dealerDataFetchNextPage,
        hasNextPage: dealerDataHasNextPage,
        isFetchingNextPage: dealerDataIsFetchingNextPage,
    } = useGetDealerQuery({}, js.fields.manager_id);
    const observer = useIntersectionObserver(
        fetchNextPage,
        containerRef,
        shopData,
        isFetchingNextPage
    );
    const observerDealer = useIntersectionObserver(
        dealerDataFetchNextPage,
        dealerRef,
        dealerData,
        dealerDataIsFetchingNextPage
    );

    const toggleField = (fieldName, direction) => {
        const currentIndex = js.screenOrder.indexOf(fieldName);
        const canGoForward =
            direction === 'forward' &&
            currentIndex !== -1 &&
            currentIndex !== js.screenOrder.length - 1;
        const canGoBackward = direction === 'backward' && currentIndex !== -1 && currentIndex !== 0;

        if (
            canGoForward &&
            js.validations[js.validatesOrder[js.validatesOrder.indexOf(js.currentValidate)]]
        ) {
            if (fieldName === 'term') {
                const parentLeft = window.screenLeft || window.screenX || 0;
                const parentTop = window.screenTop || window.screenY || 0;

                const popupWidth = 500;
                const popupHeight = 600;

                const left = parentLeft + (window.innerWidth - popupWidth) / 2;
                const top = parentTop + (window.innerHeight - popupHeight) / 2;

                const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;
                if (window.ReactNativeWebView) {
                } else {
                    window.open('', 'nicePopup', option);
                }

                requestCertificate();
                return;
            }
            js.setScreenStates(fieldName, false);
            const newIndex = currentIndex + 1;
            js.setScreenStates(js.screenOrder[newIndex], true);
            js.setCurrentField(js.screenOrder[newIndex]);
            js.setCurrentValidate(js.validatesOrder[newIndex]);
        } else if (canGoBackward) {
            js.setScreenStates(fieldName, false);
            const newIndex = currentIndex - 1;
            js.setScreenStates(js.screenOrder[newIndex], true);
            js.setCurrentField(js.screenOrder[newIndex]);
            js.setCurrentValidate(js.validatesOrder[newIndex]);
        } else if (currentIndex === js.screenOrder.length - 1) {
            if (!isMutating) {
                if (js.registRole === 'user') {
                    regist.mutate(js.fields);
                } else {
                    registDesigner.mutate(js.fields);
                }
            }
        } else if (!canGoBackward && direction === 'backward') {
            js.togglePopUp('cancelSignUp');
        }
    };

    const [popOverData, setPopOverData] = useState(popOvers);
    const togglePopOver = (index) => {
        const updatedPopOvers = [...popOverData];
        updatedPopOvers[index].isVisible = !updatedPopOvers[index].isVisible;
        setPopOverData(updatedPopOvers);
    };

    const requestCertificate = () => {
        certificateRefetch();
    };

    function handleMessage(event) {
        const messageData = event.data;
        if (messageData.success && messageData.data?.code === '-1') {
            js.togglePopUp('certErrorById');
            js.setCertError(messageData.data.data);
            js.setIsCert(true);
        } else if (messageData.success && messageData.data?.code === '0000') {
            js.setField('name', messageData.data.data.name);
            js.setField('phone', messageData.data.data.mobileno);
            js.setField('self_auth_no', messageData.data.data.self_auth_no);
            js.setScreenStates('term', false);
            js.setScreenStates('user_id', true);
            js.setCurrentField('user_id');
            js.setCurrentValidate('user_id');
            js.setIsCert(true);
        }
    }

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        js.setMakeShop('address', data.address);
        js.setMakeShop('address_detail', extraAddress);
        js.togglePopUp('searchAddress');
    };

    const [accept, setAccept] = useState(false);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        return (
            (nextLocation.pathname === '/login' || nextLocation.pathname === '/nice/callback') &&
            !accept
        );
    });
    useEffect(() => {
        if (blocker.state === 'blocked') {
            if (js.popUpStates.searchSalon) {
                js.togglePopUp('searchSalon');
            } else if (Object.values(js.popUpStates).some((value) => value === true)) {
                js.closeAllPopUps();
            } else {
                toggleField(js.screenOrder[js.screenOrder.indexOf(js.currentField)], 'backward');
            }
            blocker.reset();
        }
        if (accept) {
            if (window.ReactNativeWebView) {
                if (js.isCert) {
                    navigate(-3, { replace: true });
                } else {
                    navigate(-1, { replace: true });
                }
            } else {
                navigate(-1, { replace: true });
            }
        }
    }, [blocker.state, accept]);

    return (
        <>
            <ContainerFit>
                <ContainerPadding>
                    <Spacing10 />
                    <BtnCloseModal
                        onClick={() =>
                            toggleField(
                                js.screenOrder[js.screenOrder.indexOf(js.currentField)],
                                'backward'
                            )
                        }
                    >
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <BtnCloseModal3
                        onClick={() => js.togglePopUp('cancelSignUp')}
                        style={{ right: '1.038rem' }}
                    >
                        <Fz15Bold $col="#A1A1A1">취소</Fz15Bold>
                    </BtnCloseModal3>
                    <form
                        name="form"
                        id="form"
                        action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
                    >
                        <input type="hidden" id="m" name="m" value="service" />
                        <input
                            type="hidden"
                            id="token_version_id"
                            name="token_version_id"
                            value=""
                        />
                        <input type="hidden" id="enc_data" name="enc_data" />
                        <input type="hidden" id="integrity_value" name="integrity_value" />
                    </form>
                    <FieldSet $visible={js.screenStates.term}>
                        <Fz18Bold $mb="3.846rem">서비스 신청 및 약관 동의</Fz18Bold>
                        <Mb20>
                            <Fz15 $col="#FF3062">
                                <img src={icChk} /> 약관에 동의해 주세요
                            </Fz15>
                        </Mb20>
                        {/* 약관 동의 체크박스 리스트 */}
                        <Mb30 as="ul">
                            {popOvers.map((popOver, index) => (
                                <TermItem key={index}>
                                    <FlxMiddle as="label" htmlFor={`terms_${index}`}>
                                        <Chkbox
                                            checked={js.terms[index]}
                                            onChange={() => js.toggleTerm(index)}
                                            id={`terms_${index}`}
                                            value={js.terms[index]}
                                        />
                                        {popOver.title}
                                    </FlxMiddle>
                                    <button type="button" onClick={() => togglePopOver(index)}>
                                        <img src={arrowRightThinGray} alt="약관 보기" />
                                    </button>
                                </TermItem>
                            ))}
                        </Mb30>
                        {/* //약관 동의 체크박스 리스트 */}
                        <AgreeAllBox as="label" htmlFor="agreeAll">
                            <ChkAndTxt>
                                <Chkbox
                                    checked={js.terms[4]}
                                    id="agreeAll"
                                    onChange={() => js.toggleTerm(4)}
                                    value={js.terms[4]}
                                />
                                <ColBody>약관에 모두 동의</ColBody>
                            </ChkAndTxt>
                        </AgreeAllBox>
                        <Paragraph $fz="1.154rem" $col="#A1A1A1">
                            전체 동의는 필수 및 선택정보에 대한 동의도 포함되어 있으며, 개별적으로
                            동의를 선택하실 수 있습니다.
                            <br />
                            선택항목에 대한 동의를 거부하시는 경우에도 서비스는 이용이 가능합니다.
                        </Paragraph>
                    </FieldSet>
                    {/* 아이디 입력 */}
                    <FieldSet $visible={js.screenStates.user_id}>
                        <Mb40>
                            <Flxbt>
                                <Fz18Bold>아이디를 입력해 주세요</Fz18Bold>
                                <FlxMiddle>
                                    <Fz15Bold>
                                        <ColAccent2>1</ColAccent2> / {js.screenOrder.length - 2}
                                    </Fz15Bold>
                                </FlxMiddle>
                            </Flxbt>
                        </Mb40>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>아이디</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="text"
                                    id="user_id"
                                    name="user_id"
                                    placeholder="이메일을 입력해 주세요."
                                    maxLength="30"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        js.setVerify('isCheckedId', '');
                                        js.setField('user_id', value);
                                    }}
                                    // 메모: 아이디 규칙은 api랑 관련이 있는것같아서 패스하겠습니다. 한글 입력이 안되어야합니다
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            chkIdRefetch();
                                        }
                                    }}
                                    defaultValue={js.fields.user_id}
                                    autoComplete="user_id"
                                />
                                <BtnInInputRound onClick={() => chkIdRefetch()}>
                                    중복 확인
                                </BtnInInputRound>
                            </InputWrapper>
                            {!js.validations.user_id ? (
                                <WarnInput>
                                    {!emailRegex.test(js.fields.user_id) ? (
                                        <>
                                            <img src={icWarn} alt="경고" />
                                            <span>이메일 형식이 맞지 않습니다.</span>
                                        </>
                                    ) : js.verify.isCheckedId === false ? (
                                        <>
                                            <img src={icWarn} alt="경고" />
                                            <span>사용 중인 이메일입니다.</span>
                                        </>
                                    ) : null}
                                </WarnInput>
                            ) : (
                                <PossibleInput>
                                    <span>사용 가능한 이메일입니다.</span>
                                </PossibleInput>
                            )}
                        </JoinField>
                    </FieldSet>
                    {/* //아이디 입력 */}
                    {/* 비밀번호 입력 */}
                    <FieldSet $visible={js.screenStates.password}>
                        <Mb40>
                            <Flxbt>
                                <Fz18Bold>비밀번호를 입력해 주세요</Fz18Bold>
                                <FlxMiddle>
                                    <Fz15Bold>
                                        <ColAccent2>2</ColAccent2> / {js.screenOrder.length - 2}
                                    </Fz15Bold>
                                </FlxMiddle>
                            </Flxbt>
                        </Mb40>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>비밀번호</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="userPw"
                                    name="userPw"
                                    placeholder="비밀번호를 입력해 주세요"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        js.setField('password', value);
                                    }}
                                    defaultValue={js.fields.password}
                                    autoComplete="userPw"
                                />
                                <BtnClearInput
                                    tabIndex="-1"
                                    onClick={() => {
                                        js.setField('password', '');
                                    }}
                                >
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                            {!js.validations.password && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" /> 최소 8자리 이상 영문+숫자+기호
                                    조합으로 작성해 주세요
                                </WarnInput>
                            )}
                        </JoinField>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>비밀번호 확인</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="password"
                                    id="userPw2"
                                    name="userPw2"
                                    placeholder="비밀번호를 한번 더 입력해 주세요"
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        js.setField('confirm_password', value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            toggleField(
                                                js.screenOrder[
                                                    js.screenOrder.indexOf(js.currentField)
                                                ],
                                                'forward'
                                            );
                                        }
                                    }}
                                    defaultValue={js.fields.confirm_password}
                                    autoComplete="userPw2"
                                />
                                <BtnClearInput
                                    tabIndex="-1"
                                    onClick={() => {
                                        js.setField('confirm_password', '');
                                    }}
                                >
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                            {!js.validations.confirm_password && (
                                <WarnInput>
                                    <img src={icWarn} alt="경고" />{' '}
                                    {!passwordRegex.test(js.fields.confirm_password)
                                        ? '최소 8자리 이상 영문+숫자+기호 조합으로 작성해 주세요'
                                        : js.fields.comfirm_password !== js.fields.password
                                        ? '비밀번호가 일치하지 않습니다'
                                        : null}
                                </WarnInput>
                            )}
                        </JoinField>
                    </FieldSet>
                    {/* //비밀번호 입력 */}
                    {/* 닉네임 입력 */}
                    <FieldSet $visible={js.screenStates.nickname}>
                        <Mb40>
                            <Flxbt>
                                <Fz18Bold>닉네임을 입력해 주세요</Fz18Bold>
                                <FlxMiddle>
                                    <Fz15Bold>
                                        <ColAccent2>3</ColAccent2> / {js.screenOrder.length - 2}
                                    </Fz15Bold>
                                </FlxMiddle>
                            </Flxbt>
                        </Mb40>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>닉네임</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <InputWrapper>
                                <JoinInput
                                    type="text"
                                    id="userNick"
                                    name="userNick"
                                    placeholder="닉네임을 입력해 주세요"
                                    maxLength="12"
                                    onChange={(e) => {
                                        js.setVerify('isCheckedNick', '');
                                        const { value } = e.target;
                                        js.setField('nickname', value);
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            chkNickRefetch();
                                        }
                                    }}
                                    defaultValue={js.fields.nickname}
                                />
                                <BtnInInputRound onClick={() => chkNickRefetch()}>
                                    중복 확인
                                </BtnInInputRound>
                            </InputWrapper>
                            {!js.validations.nickname ? (
                                <WarnInput>
                                    {!nicknameRegex.test(js.fields.nickname) ? (
                                        <>
                                            <img src={icWarn} alt="경고" />
                                            <span>
                                                한글 또는 영문 대소문자 또는 숫자로 2~10자 입력해
                                                주세요.
                                            </span>
                                        </>
                                    ) : js.verify.isCheckedNick === false ? (
                                        <>
                                            <img src={icWarn} alt="경고" />
                                            <span>사용 중인 닉네임입니다.</span>
                                        </>
                                    ) : null}
                                </WarnInput>
                            ) : (
                                <PossibleInput>
                                    <span>사용 가능한 닉네임입니다.</span>
                                </PossibleInput>
                            )}
                            <DescInput>▸ 서비스 이용 시 노출되는 이름입니다</DescInput>
                        </JoinField>
                    </FieldSet>
                    {/* 헤어샵 이름 입력 */}
                    <FieldSet $visible={js.screenStates.salon}>
                        <Mb40>
                            <Flxbt>
                                <Fz18Bold>근무하시는 헤어샵을 입력해 주세요</Fz18Bold>
                                <FlxMiddle>
                                    <Fz15Bold>
                                        <ColAccent2>4</ColAccent2> / {js.screenOrder.length - 2}
                                    </Fz15Bold>
                                </FlxMiddle>
                            </Flxbt>
                        </Mb40>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>근무 헤어샵</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <Mb10>
                                <InputWrapper>
                                    <JoinInput
                                        type="text"
                                        placeholder="헤어샵 이름을 입력 후 검색해 주세요"
                                        maxLength="20"
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            js.setField('salon', value);
                                            js.setField('isSearched', false);
                                            js.setField('salonName', '');
                                            js.setField('shop_id', '');
                                            js.setField('manager_id', '');
                                            js.setField('dealer_id', '');
                                            js.setField('dealer_name', '');
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === 'Enter') {
                                                if (!js.fields.salon) {
                                                    return;
                                                }

                                                shopRefetch();
                                                js.togglePopUp('searchSalon');
                                            }
                                        }}
                                        value={
                                            js.fields.isSearched
                                                ? js.fields.salonName
                                                : js.fields.salon
                                        }
                                    />
                                    <BtnInInputRound
                                        onClick={() => {
                                            if (!js.fields.salon) {
                                                return;
                                            }

                                            shopRefetch();
                                            js.togglePopUp('searchSalon');
                                        }}
                                    >
                                        검색
                                    </BtnInInputRound>
                                </InputWrapper>
                            </Mb10>
                            <Paragraph $fz="1.154rem" $col="#A1A1A1">
                                디자이너 님이 활동하시는 헤어샵 이름으로 검색해 주세요!
                                <br />( 근무 헤어샵은 매니저 승인 후 등록 가능합니다. )
                            </Paragraph>
                        </JoinField>
                        {js.fields.isSearched && (
                            <JoinField>
                                <PadVert10>
                                    <Fz15Bold>추천인 (선택)</Fz15Bold>
                                </PadVert10>
                                <Mb10>
                                    <InputWrapper>
                                        <JoinInput
                                            type="text"
                                            defaultValue={js.fields.dealer_name}
                                            readOnly={true}
                                        />
                                        <BtnInInputRound
                                            onClick={() => {
                                                if (!js.fields.salon) {
                                                    return;
                                                }
                                                dealerDataRefetch();
                                                js.togglePopUp('searchDealer');
                                            }}
                                        >
                                            검색
                                        </BtnInInputRound>
                                    </InputWrapper>
                                </Mb10>
                                <Paragraph $fz="1.154rem" $col="#A1A1A1">
                                    검색한 매장의 추천인을 확인할 수 있습니다.
                                </Paragraph>
                            </JoinField>
                        )}
                    </FieldSet>
                    {/* //헤어샵 이름 입력 */}

                    {/* //헤어샵 추가 요청 팝업 */}
                    {/* //아이디 입력 */}
                    <FieldSet $visible={js.screenStates.confirm}>
                        <Fz18Bold $mb="2.769rem">입력 정보 확인</Fz18Bold>
                        {/* 정보 목록 */}
                        <ConfirmInfoItem>
                            <InfoLabel>이름</InfoLabel>
                            <InfoValue>{js.fields.name}</InfoValue>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>휴대폰번호</InfoLabel>
                            <InfoValue>{js.fields.phone}</InfoValue>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>아이디</InfoLabel>
                            <InfoValue>{js.fields.user_id}</InfoValue>
                        </ConfirmInfoItem>
                        <ConfirmInfoItem>
                            <InfoLabel>닉네임</InfoLabel>
                            <InfoValue>{js.fields.nickname}</InfoValue>
                        </ConfirmInfoItem>
                        {js.registRole === 'designer' && (
                            <>
                                <ConfirmInfoItem>
                                    <InfoLabel>매장</InfoLabel>
                                    <InfoValue>{js.fields.salonName}</InfoValue>
                                </ConfirmInfoItem>
                                {js.fields.dealer_name && (
                                    <ConfirmInfoItem>
                                        <InfoLabel>추천인</InfoLabel>
                                        <InfoValue>{js.fields.dealer_name}</InfoValue>
                                    </ConfirmInfoItem>
                                )}
                            </>
                        )}
                        {/* //정보 목록 */}
                    </FieldSet>

                    <AbsolBtm>
                        {js.screenStates.salon && (
                            <>
                                <Mb30>
                                    <Fz15>
                                        검색하신 헤어샵이 없다면 아래 추가 요청 버튼을 클릭하셔서 샵
                                        이름 및 주소를 등록해 주세요.
                                    </Fz15>
                                </Mb30>
                                <Mb30>
                                    <BtnFullLineGreen
                                        onClick={() => {
                                            js.togglePopUp('requestShop');
                                            js.setField('isSearched', false);
                                            js.setField('salonName', '');
                                            js.setField('shop_id', '');
                                            js.setField('manager_id', '');
                                            js.setField('dealer_id', '');
                                            js.setField('dealer_name', '');
                                        }}
                                    >
                                        헤어샵 추가 요청
                                    </BtnFullLineGreen>
                                </Mb30>
                            </>
                        )}
                        <LinkFull
                            $active={
                                js.validations[
                                    js.validatesOrder[js.validatesOrder.indexOf(js.currentValidate)]
                                ]
                            }
                            onClick={() => {
                                toggleField(
                                    js.screenOrder[js.screenOrder.indexOf(js.currentField)],
                                    'forward'
                                );
                            }}
                        >
                            {js.screenOrder[js.screenOrder.indexOf(js.currentField)] === 'confirm'
                                ? '가입완료'
                                : js.screenOrder[js.screenOrder.indexOf(js.currentField)] === 'term'
                                ? '본인인증하기'
                                : '계속하기'}
                        </LinkFull>
                    </AbsolBtm>
                </ContainerPadding>
            </ContainerFit>
            <TermOver $visible={js.popUpStates.searchSalon}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => js.closeAllPopUps()}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <NavHeight />
                    <PadVert30PopOver>
                        <ListColumn>
                            {shopData?.pages.map((page) =>
                                page.data.docs.map((item) => (
                                    <FlxbtFullMb20 key={item.id}>
                                        <label for={item.id}>
                                            <Fz18Medium $mb="1.538rem">{item.name}</Fz18Medium>
                                            <Fz15 $col="#a1a1a1">{item.address}</Fz15>
                                        </label>
                                        <RadioBox
                                            name="salon"
                                            checked={js.fields.shop_id === item.id}
                                            onChange={() => {
                                                js.setField('isSearched', true);
                                                js.setField('salonName', item.name);
                                                js.setField('shop_id', item.id);
                                                js.setField('manager_id', item.manager);
                                            }}
                                            id={item.id}
                                            value={item}
                                        />
                                    </FlxbtFullMb20>
                                ))
                            )}
                            {!(shopData?.pages && shopData?.pages[0].data.docs.length > 0) && (
                                <NoContent message="검색된 헤어샵이 없습니다." />
                            )}
                        </ListColumn>

                        {hasNextPage && (
                            <LineCenter ref={containerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                    </PadVert30PopOver>
                </ContainerPadding>
                <StickyBtm>
                    <BtnFullModal
                        $active={js.fields.isSearched}
                        onClick={() => js.closeAllPopUps()}
                    >
                        선택
                    </BtnFullModal>
                </StickyBtm>
            </TermOver>
            {/* 헤어샵 추가 요청 팝업 */}
            <PopOver $visible={js.popUpStates.requestShop}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => js.togglePopUp('requestShop')}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <NavHeight />
                    <PadVert30>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>헤어샵 이름</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <Mb10>
                                <InputWrapper>
                                    <JoinInput
                                        type="text"
                                        placeholder="예)머리할래 강남점"
                                        defaultValue={js.makeShop.name}
                                        onChange={(e) => js.setMakeShop('name', e.target.value)}
                                    />
                                </InputWrapper>
                            </Mb10>
                        </JoinField>
                        <JoinField>
                            <PadVert10>
                                <Fz15Bold>주소</Fz15Bold>&nbsp;
                                <fz14Bold>
                                    <span style={{ color: '#f33535' }}>(필수)</span>
                                </fz14Bold>
                            </PadVert10>
                            <Mb10>
                                <InputWrapper>
                                    <JoinInput
                                        type="text"
                                        placeholder="예) 판교역로 235, 한남동714"
                                        maxLength="20"
                                        defaultValue={js.makeShop.address}
                                        readOnly
                                    />
                                    <BtnInInputRound
                                        onClick={() => js.togglePopUp('searchAddress')}
                                    >
                                        주소 검색
                                    </BtnInInputRound>
                                    {js.popUpStates.searchAddress && (
                                        <DaumWrapper>
                                            <DaumPostcodeEmbed onComplete={handleComplete} />
                                        </DaumWrapper>
                                    )}
                                </InputWrapper>
                            </Mb10>
                        </JoinField>
                        <JoinField $mb="0">
                            <PadVert10>
                                <Fz15Bold>상세 주소 (선택)</Fz15Bold>
                            </PadVert10>
                            <Mb10>
                                <InputWrapper>
                                    <JoinInput
                                        type="text"
                                        placeholder="상세 주소를 입력해 주세요"
                                        maxLength="20"
                                        defaultValue={js.makeShop.address_detail}
                                        onChange={(e) =>
                                            js.setMakeShop('address_detail', e.target.value)
                                        }
                                    />
                                </InputWrapper>
                            </Mb10>
                        </JoinField>
                    </PadVert30>
                    <AbsolBtm
                        onClick={() => {
                            if (!js.makeShop.name || !js.makeShop.address) {
                                return;
                            }

                            shopCreateRequest.mutate(js.makeShop);
                        }}
                        disabled={!!isMutating}
                    >
                        <BtnFullModal
                            $active={js.makeShop.name != null && js.makeShop.address != null}
                        >
                            저장
                        </BtnFullModal>
                    </AbsolBtm>
                </ContainerPadding>
            </PopOver>
            <TermOver $visible={js.popUpStates.searchDealer}>
                <ContainerPadding>
                    <BtnCloseModal onClick={() => js.closeAllPopUps()}>
                        <img src={arrowLeft} alt="뒤로" />
                    </BtnCloseModal>
                    <NavHeight />
                    <PadVert30PopOver>
                        <ListColumn>
                            {dealerData?.pages.map((page) =>
                                page.data.docs.map((item) => (
                                    <FlxbtFullMb20 key={item._id}>
                                        <label for={item._id}>
                                            <Fz18Medium $mb="1.538rem">{item.name}</Fz18Medium>
                                            <Fz15 $col="#a1a1a1">{item.referer_code}</Fz15>
                                        </label>
                                        <RadioBox
                                            name="salon"
                                            checked={js.fields.dealer_id === item._id}
                                            onChange={() => {
                                                js.setField('dealer_id', item._id);
                                                js.setField('dealer_name', item.name);
                                            }}
                                            id={item._id}
                                            value={item}
                                        />
                                    </FlxbtFullMb20>
                                ))
                            )}
                            {!(dealerData?.pages && dealerData?.pages[0].data.docs.length > 0) && (
                                <NoContent message="검색된 추천인이 없습니다." />
                            )}
                        </ListColumn>

                        {dealerDataHasNextPage && (
                            <LineCenter ref={dealerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                    </PadVert30PopOver>
                </ContainerPadding>
                <StickyBtm>
                    <BtnFullModal
                        $active={js.fields.isSearched}
                        onClick={() => {
                            js.closeAllPopUps();
                        }}
                    >
                        선택
                    </BtnFullModal>
                </StickyBtm>
            </TermOver>
            {/* 회원가입 절차 취소 */}
            <NewModal
                isOpen={js.popUpStates.cancelSignUp}
                contentLabel="회원가입 취소"
                ariaHideApp={false}
            >
                <BtnCloseModal3>
                    <img
                        src={icCloseModal}
                        alt="닫기"
                        onClick={() => {
                            js.closeAllPopUps();
                            if (blocker.state === 'blocked') {
                                blocker.reset();
                            }
                        }}
                    />
                </BtnCloseModal3>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">취소하시겠습니까?</Fz15Bold>
                        </Mb20>
                        <Fz15>취소 시 입력하신 정보가 초기화 됩니다.</Fz15>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            setAccept(true);
                            navigate('/login', { replace: true });
                        }}
                    >
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            {/* //회원가입 절차 취소 */}
            {/* 샵추가 오류 */}
            <NewModal
                isOpen={js.popUpStates.addressError}
                contentLabel="샵추가 오류"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">주소를 다시 확인해 주세요.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            js.togglePopUp('addressError');
                        }}
                    >
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            {/* //샵검색 오류 */}
            {/* 회원가입 완료 */}
            <NewModal
                isOpen={js.popUpStates.joinComplete}
                contentLabel="회원가입 완료"
                ariaHideApp={false}
            >
                {js.registRole === 'user' ? (
                    <Pad20>
                        <PopShortMsg>
                            <Mb20>
                                <Fz15Bold $col="#000">
                                    머리할래 회원가입이 완료 되었습니다.
                                </Fz15Bold>
                            </Mb20>
                            <Paragraph $fz="1.154rem">
                                앞으로 <ColAccent>{js.fields.name}</ColAccent>님께 최선을 다하는
                                <br />
                                서비스가 될 수 있도록 노력하며
                                <br />
                                최고의 헤어 디자이너를 소개해 드리겠습니다.
                                <br />
                                감사드립니다.
                            </Paragraph>
                        </PopShortMsg>
                        <BtnFullModal
                            $active
                            onClick={() => {
                                navigate('/', { replace: true });
                            }}
                        >
                            홈으로
                        </BtnFullModal>
                    </Pad20>
                ) : (
                    <Pad20>
                        <PopShortMsg>
                            <Mb20>
                                <Fz15Bold $col="#000">
                                    머리할래 회원가입이 완료 되었습니다.
                                </Fz15Bold>
                            </Mb20>
                            <PadVert20>
                                <Paragraph $fz="1.154rem">
                                    <ColAccent>{js.fields.name}</ColAccent>님<br />
                                    가입해 주셔서 감사합니다.
                                    <br />
                                    담당 매니저님이 가입 정보 확인 후<br />
                                    서비스 이용이 가능합니다.
                                    <br />
                                    승인 절차를 기다리시기 전<br />
                                    디자이너의추가 정보를 미리 등록하시면
                                    <br />
                                    조금 더 빠르게 이용이 가능합니다.
                                </Paragraph>
                            </PadVert20>
                        </PopShortMsg>
                        <ListCol2>
                            <BtnHalfModalBlack
                                onClick={() => {
                                    navigate('/', { replace: true });
                                }}
                            >
                                홈으로
                            </BtnHalfModalBlack>
                            <BtnHalfModal
                                onClick={() => {
                                    navigate('/info-designer', { replace: true });
                                }}
                            >
                                추가 정보 등록
                            </BtnHalfModal>
                        </ListCol2>
                    </Pad20>
                )}
            </NewModal>
            {/* //회원가입 완료 */}
            {/* 회원가입 실패 */}
            <NewModal
                isOpen={js.popUpStates.joinFail}
                contentLabel="회원가입 실패"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">머리할래 회원가입이 실패 하였습니다.</Fz15Bold>
                        </Mb20>
                        <Paragraph $fz="1.154rem">{js.registError}</Paragraph>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            navigate('/', { replace: true });
                        }}
                    >
                        홈으로
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            {/* //회원가입 실패 */}
            {/* 약관 팝업 */}
            {popOvers.map((popOver, index) => (
                <TermOver key={index} style={{ display: popOver.isVisible ? 'block' : 'none' }}>
                    <TermModal>
                        <TermHeader>
                            <button type="button" onClick={() => togglePopOver(index)}>
                                <img src={arrowLeft} alt="뒤로" />
                            </button>
                        </TermHeader>
                        {/* <NavHeight/> */}
                        <Fz18Bold $mb="2.3077rem">{popOver.title}</Fz18Bold>
                        {/* <Paragraph> */}
                        <div dangerouslySetInnerHTML={{ __html: popOver.content }} />
                        {/* </Paragraph> */}
                    </TermModal>
                </TermOver>
            ))}
            {/* //약관 팝업 */}
            {/* 가입한 아이디 있을 때 */}
            <NewModal
                isOpen={js.popUpStates.certErrorById}
                contentLabel="가입한 아이디가 있음"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">이미 가입한 아이디가 있습니다.</Fz15Bold>
                            <br />
                            <Fz15Bold $col="#000">{js.certError.name}</Fz15Bold>
                            <br />
                            <Fz15Bold $col="#000">{js.certError.user_id}</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            js.togglePopUp('certErrorById');
                        }}
                    >
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
        </>
    );
}
export default Join;
