import React, { useEffect, useState } from 'react';
import {
    Block,
    ListCol2,
    PadVert30,
    TabService,
    TabItem,
    RsvTab,
    Mb15,
    Paragraph,
    Container,
    ContainerPadding,
    TxCent,
} from '@/css/style';
import {
    useDesignerMenuActiveMutation,
    useDesignerMenuDeleteMutation,
    useDesignerMenuInActiveMutation,
    useDesignerMenuQuery,
} from '@/services/designerService';
import { useProductCategoryQuery } from '@/services/productService';
import useServiceStore from '@/stores/designer/service';
import useUserStore from '@/stores/user/user';
import ChkLabel from '@/components/ChkLabel';
import ServiceAdd from '@/components/ServiceAdd';
import ServiceEdit from '@/components/ServiceEdit';
import ServicePreview from '@/components/ServicePreview';
import icWarnBig from '@/assets/img/ic_warn_big.svg';
import { Mb30 } from '../../css/style';
import { useIsMutating, useMutationState } from '@tanstack/react-query';

function ServiceList() {
    const ss = useServiceStore();
    const us = useUserStore();
    const isMutating = useIsMutating();
    const { data: categoryData } = useProductCategoryQuery();
    const { data: designerMenuData, refetch: refetchDesignerMenuData } = useDesignerMenuQuery(
        us.user.id,
        { category: ss.selectedCategory }
    );
    const DesignerMenuDelete = useDesignerMenuDeleteMutation(() => refetchDesignerMenuData());
    const DesignerMenuActive = useDesignerMenuActiveMutation(() => refetchDesignerMenuData());
    const DesignerMenuInActive = useDesignerMenuInActiveMutation(() => refetchDesignerMenuData());

    useEffect(() => {
        if (categoryData) {
            ss.setCategoryFromServer(categoryData.data);
        }
    }, [categoryData]);

    useEffect(() => {
        return () => {
            ss.terminateAll();
        };
    }, []);

    return (
        <>
            <Container>
                <ContainerPadding>
                    <ListCol2 as={Mb30}>
                        <TabService $active onClick={() => ss.showModal('add')}>
                            시술 메뉴 등록 +
                        </TabService>
                    </ListCol2>
                    {/* 시술 메뉴 선택 */}
                    <RsvTab>
                        <TabItem
                            $active={ss.category.find((item) => item.id === '')?.selected}
                            onClick={() => ss.setCategory('')}
                        >
                            전체
                        </TabItem>
                        {categoryData?.data.map((category) => {
                            const selectedCategory = ss.category.find(
                                (item) => item.id === category.id
                            );
                            if (selectedCategory) {
                                return (
                                    <TabItem
                                        key={category.id}
                                        $active={selectedCategory.selected}
                                        onClick={() => ss.setCategory(category.id)}
                                    >
                                        {category.name}
                                    </TabItem>
                                );
                            }
                            return null; // selectedCategory가 없으면 렌더링하지 않음
                        })}
                    </RsvTab>
                    {/* //시술 메뉴 선택 */}
                    {/* 시술 메뉴 목록 */}
                    {designerMenuData?.data.map((menu) => (
                        <ChkLabel
                            key={menu.id}
                            serviceName={menu.name}
                            originalPrice={
                                menu.normal_price === menu.price
                                    ? null
                                    : menu.normal_price.toLocaleString()
                            }
                            consumerPrice={menu.price.toLocaleString()}
                            serviceDescription={menu.option}
                            actived={menu.actived}
                            disabled={!!isMutating}
                            onChange={() => {
                                menu.actived
                                    ? DesignerMenuInActive.mutate({
                                          designerId: us.user.id,
                                          menuId: menu.id,
                                      })
                                    : DesignerMenuActive.mutate({
                                          designerId: us.user.id,
                                          menuId: menu.id,
                                      });
                            }}
                            menuDelete={() =>
                                DesignerMenuDelete.mutate({
                                    designerId: us.user.id,
                                    menuId: menu.id,
                                })
                            }
                            menuEdit={() => {
                                ss.showModal('edit');
                                ss.setDetailData(menu);
                            }}
                        />
                    ))}
                    {/* 등록 상품이 없는 경우 노출 내용 */}
                    {!designerMenuData?.data.length && (
                        <Block>
                            <TxCent>
                                <Mb15>
                                    <img src={icWarnBig} alt="경고 이미지" />
                                </Mb15>
                                <Paragraph $fz="1.23rem" $col="#A1A1A1">
                                    등록하신 상품이 없습니다.
                                    <br />
                                    상단 시술 메뉴 등록 버튼을 클릭하셔
                                    <br />
                                    상품을 등록해 주세요!{' '}
                                </Paragraph>
                            </TxCent>
                        </Block>
                    )}
                </ContainerPadding>
            </Container>
            {/* 시술 메뉴 등록 외부 모달 */}
            <ServiceAdd
                isOpen={ss.modal.add}
                category={categoryData}
                refetch={refetchDesignerMenuData}
                closeModal={() => {
                    ss.showModal(false);
                }}
            />
            <ServiceEdit
                isOpen={ss.modal.edit}
                category={categoryData}
                refetch={refetchDesignerMenuData}
                closeModal={() => {
                    ss.showModal(false);
                }}
            />{' '}
            {/* // 시술 메뉴 등록 외부 모달 */}
            {/* 상품 미리보기 외부 모달 */}
            <ServicePreview
                isOpen={ss.modal.preview}
                closeModal={() => {
                    ss.showModal(false);
                }}
            />
            {/* // 상품 미리보기 외부 모달 */}
        </>
    );
}
export default ServiceList;
