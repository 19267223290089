import React from 'react';
import { NavStepWrapper, NavLogo, NavTitle, Fz15Bold } from '@/css/style';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';

const TopStepBar = (props) => {
    return (
        <NavStepWrapper>
            {/* <NavLogo as="button" type="button">
                <img src={arrowLeft} alt="뒤로" onClick={props.toggleField} />
            </NavLogo> */}
            {props.title ? <NavTitle>{props.title}</NavTitle> : null}
            {props.isCancelBtn && (
                <button type="button" data-btntype="cancel" onClick={props.isCancelBtn}>
                    <Fz15Bold $col="#A1A1A1">취소</Fz15Bold>
                </button>
            )}
        </NavStepWrapper>
    );
};

export default TopStepBar;
