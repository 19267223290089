import { create } from 'zustand';

const initialState = {
    distance: [5],
    cut: false,
    dye: false,
    perm: false,
};

const useFilterStore = create((set) => ({
    ...initialState,
    setDistance: (text) => set((state) => ({ distance: text })),
    setField: (fieldName) => set((state) => ({ [fieldName]: !state[fieldName] })),
    terminate: () => {
        set(initialState);
    },
}));

export default useFilterStore;
