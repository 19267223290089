import React, { useEffect, useState } from 'react';
import { useParams, Navigate, useNavigate, useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import useForgotStore from '@/stores/common/forgot';
import useJoinStore from '@/stores/common/join';
import useMyPageStore from '@/stores/user/mypage';

const decodeByAES256 = (data) => {
    const k = process.env.REACT_APP_AES_KEY;
    const rk = k.padEnd(32, ' ');
    const cipher = CryptoJS.AES.decrypt(data, CryptoJS.enc.Utf8.parse(rk), {
        iv: CryptoJS.enc.Utf8.parse(''),
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC,
    });
    return cipher.toString(CryptoJS.enc.Utf8);
};

function PayRedirect() {
    const location = useLocation();
    const fs = useForgotStore();
    const js = useJoinStore();
    const ms = useMyPageStore();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();
    useEffect(() => {
        const enc_data = decodeURIComponent(searchParams.get('enc_data'));

        const data = JSON.parse(decodeByAES256(enc_data));
        switch (data.data.request_type) {
            case 'joinUser':
                if (data.code === '0000') {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close(); // Close the popup window
                    } else {
                        js.setField('name', data.data.name);
                        js.setField('phone', data.data.mobileno);
                        js.setField('self_auth_no', data.data.self_auth_no);
                        js.setRegistRole('user');
                        js.setScreenStates('term', false);
                        js.setScreenStates('user_id', true);
                        js.setCurrentField('user_id');
                        js.setCurrentValidate('user_id');
                        js.setIsCert(true);
                        navigate('/join/user');
                    }
                } else {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close(); // Close the popup window
                    } else {
                        js.setCertError(data.data);
                        js.togglePopUp('certErrorById');
                        js.setRegistRole('user');
                        js.setIsCert(true);
                        navigate('/join/user');
                    }
                }
                break;
            case 'joinDesigner':
                if (data.code === '0000') {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close(); // Close the popup window
                    } else {
                        js.setField('name', data.data.name);
                        js.setField('phone', data.data.mobileno);
                        js.setField('self_auth_no', data.data.self_auth_no);
                        js.setRegistRole('designer');
                        js.setScreenStates('term', false);
                        js.setScreenStates('user_id', true);
                        js.setCurrentField('user_id');
                        js.setCurrentValidate('user_id');
                        js.setIsCert(true);
                        navigate('/join/designer');
                    }
                } else {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close(); // Close the popup window
                    } else {
                        js.setCertError(data.data);
                        js.togglePopUp('certErrorById');
                        js.setRegistRole('designer');
                        js.setIsCert(true);
                        navigate('/join/designer');
                    }
                }
                break;
            case 'edit_phone':
                if (data.code === '0000') {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close(); // Close the popup window
                    } else {
                        ms.toggleModal('editPhoneConfirm');
                        ms.setIsCert(true);
                        navigate('/my/edit');
                    }
                } else {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close(); // Close the popup window
                    } else {
                        ms.setCertError(data.data);
                        ms.toggleModal('editPhoneErr');
                        ms.setIsCert(true);
                        navigate('/my/edit');
                    }
                }
                break;
            case 'find-id':
                if (data.code === '0000') {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close();
                    } else {
                        fs.setResponse(data);
                        fs.setState('findId', true);
                        fs.setScreen('id');
                        fs.setIsCert(true);
                        navigate('/login/forgot');
                    }
                } else {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close();
                    } else {
                        fs.setResponse(data);
                        fs.setState('findId', false);
                        fs.setScreen('id');
                        fs.setIsCert(true);
                        fs.togglePopup('verifyConfirmError');
                        navigate('/login/forgot');
                    }
                }
                break;
            case 'find-pw':
                if (data.code === '0000') {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close();
                    } else {
                        fs.setResponse(data);
                        fs.setState('findPw', true);
                        fs.setIsCert(true);
                        fs.setScreen('password');
                        navigate('/login/forgot');
                    }
                } else {
                    if (window.opener) {
                        window.opener.postMessage({
                            success: true,
                            data: data,
                        });
                        window.close();
                    } else {
                        fs.setResponse(data);
                        fs.setState('findPw', false);
                        fs.setIsCert(true);
                        fs.setScreen('password');
                        fs.togglePopup('verifyConfirmErrorPW');
                        navigate('/login/forgot');
                    }
                }
                break;
            default:
                break;
        }
    }, []);

    return null;
}

export default PayRedirect;
