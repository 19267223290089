import { create } from 'zustand';

const initialState = {
    category: {
        showShop: true,
        showDesigner: false,
    },
    order: {
        distance: false,
        rating: false,
        price: true,
    },
    selectedOrder: 'price',
};

const useHomeStore = create((set) => ({
    ...initialState,
    toggleCategory: (select) => {
        set((state) => ({
            category: {
                showShop: select === 'showShop',
                showDesigner: select === 'showDesigner',
            },
        }));
    },
    toggleOrder: (select) => {
        set((state) => ({
            order: {
                distance: select === 'distance',
                rating: select === 'rating',
                price: select === 'price',
            },
            selectedOrder: select,
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useHomeStore;
