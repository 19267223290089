import { format } from 'date-fns';

const { useReservTimeV2Query } = require('@/services/reservationService');

const DesignerAvaliableTime = ({ designerId }) => {
  const { data: reservTimeData } = useReservTimeV2Query({
    id: designerId,
    date: format(new Date(), 'yyyy-MM-dd'),
  });

  if (!reservTimeData) return null;

  return (
    <div>
      <img
        src={
          require(`@/assets/img/ic_time.svg`).default
        }
        width={15}
        height={15}
        style={{
          display: 'inline-block',
          marginRight: '0.3rem',
        }}
        alt="시간"
      />
      {reservTimeData.data.availableTime.length
        ? `오늘 ${reservTimeData.data.availableTime[0]} 이후 예약 가능해요.`
        : reservTimeData.data.isDesignerDayoff
        ? '오늘은 휴무일이에요.'
        : '오늘 예약 마감되었어요.'}
    </div>
  );
}
export default DesignerAvaliableTime;