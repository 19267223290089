import React, { useEffect, useState } from 'react';
import useLicenseStore from '../../stores/designer/lisense';
import { useParams, Navigate, useNavigate, useLocation } from 'react-router-dom';
import useUserStore from '../../stores/user/user';

function PayRedirect() {
    const ls = useLicenseStore();
    const us = useUserStore();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const navigate = useNavigate();

    useEffect(() => {
        const outStatCd = searchParams.get('outStatCd');
        switch (outStatCd) {
            case '0021':
                if (!window.ReactNativeWebView) {
                    window.opener.postMessage({
                        success: true,
                        data: outStatCd,
                    });
                    window.close(); // Close the popup window
                } else {
                    ls.toggleModal('complete');
                    navigate('/license', { replace: true });
                }
                break;
            case '0031':
                if (!window.ReactNativeWebView) {
                    window.opener.postMessage({
                        success: false,
                        // data: res,
                    });
                    window.close(); // Close the popup window
                } else {
                    ls.toggleModal('fail');
                    navigate('/license', { replace: true });
                }

                break;
            default:
                us.logout();
        }
    }, []);

    return null;
}

export default PayRedirect;
