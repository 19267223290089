import { format, getDay, getWeekOfMonth } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useNavermaps } from 'react-naver-maps';
import snsIg from '@/assets/img/sns_ig.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsX from '@/assets/img/sns_x.svg';
import snsYt from '@/assets/img/sns_ytube.svg';

export const getDisabledDates = (currentMonth, designerDetailData, ds) => {
    const disabledDates = [];

    for (
        let day = 1;
        day <= new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
        day++
    ) {
        const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
        const dayNumber = getDay(date);
        const weekNumber = getWeekOfMonth(date);

        designerDetailData?.data.holidayByDay.forEach(({ day, week }) => {
            if (day === dayNumber && week.includes(weekNumber)) {
                disabledDates.push(format(date, 'yyyy-MM-dd'));
            }
        });
    }

    ds.setHolidayByDay(disabledDates);
};

export const useIntersectionObserver = (fetchNextPage, containerRef, data, isFetchingNextPage) => {
    const observerRef = useRef(null);

    useEffect(() => {
        observerRef.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && !isFetchingNextPage) {
                    fetchNextPage();
                }
            },
            { threshold: 1.0 }
        );

        if (containerRef.current && observerRef.current && data) {
            observerRef.current.disconnect();
            observerRef.current.observe(containerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observerRef.current.disconnect();
            }
        };
    }, [fetchNextPage, containerRef, data, isFetchingNextPage]);

    return observerRef;
};

export const getAddressLatLng = (address, navermaps) => {
    return new Promise((resolve, reject) => {
        navermaps.Service.geocode(
            {
                address: address,
            },
            function (status, response) {
                if (status !== navermaps.Service.Status.OK) {
                    console.log('Error');
                    reject('Something went wrong!');
                } else {
                    const result = response.result;
                    resolve(result);
                }
            }
        );
    });
};

export const phoneRegex = /^(01[016789]{1})[0-9]{3,4}[0-9]{4}$/;
export const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",<.>/?\\`~])[A-Za-z\d!@#$%^&*()\-_=+{}[\]|;:'",<.>/?\\`~]{8,}$/;
export const nicknameRegex = /^[a-zA-Z0-9가-힣]{2,10}$/;
export const onlyLetter = (value) => {
    return value.replace(/[^\ㄱ-ㅎ가-힣a-zA-Z]+/g, '');
};
export const onlyLetterNum = (value) => {
    return value.replace(/[^\d\ㄱ-ㅎ가-힣a-zA-Z]+/g, '');
};
export const onlyNum = (value) => {
    return value.replace(/[^0-9]/g, '');
};
export const onlyEmail = (value) => {
    return value.replace(/[^a-zA-Z0-9._%+-@.]/g, '');
};

export const maskEmail = (email) => {
    return email.replace(/^(.)(.*)(?=@)/, function (_, a, b) {
        return a + b.replace(/./g, '*');
    });
};

export const snsTable = [
    { src: snsIg, provider: 'Instagram' },
    { src: snsFb, provider: 'Facebook' },
    { src: snsX, provider: 'Twitter' },
    { src: snsYt, provider: 'Youtube' },
];

export const dayMappings = {
    // holiday: { name: '공휴일' },
    monday: { name: '월요일', dayNumber: 1 },
    tuesday: { name: '화요일', dayNumber: 2 },
    wednesday: { name: '수요일', dayNumber: 3 },
    thursday: { name: '목요일', dayNumber: 4 },
    friday: { name: '금요일', dayNumber: 5 },
    saturday: { name: '토요일', dayNumber: 6 },
    sunday: { name: '일요일', dayNumber: 0 },
};

export const editBoardContentImg = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const imgTags = doc.querySelectorAll('img');

    imgTags.forEach((img) => {
        const src = img.getAttribute('src');
        if (src) {
            img.setAttribute('src', `${src}${src.includes('?') ? '&' : '?'}size=350`);
        }
        img.style.maxWidth = '100%';
        img.style.display = 'block';
        img.style.margin = '0 auto';
    });

    return doc.body.innerHTML;
};

export const getShopDistance = (distance) => {
    let roundedDistance = (distance / 1000).toFixed(1);
    if (roundedDistance < 0.1) {
        return '<100m';
    } else if (roundedDistance < 1) {
        return roundedDistance * 1000 + 'm';
    } else {
        return roundedDistance + 'km';
    }
};
