import { create } from 'zustand';

const initialState = {
    category: {
        all: true,
        New: false,
        Confirm: false,
        Complete: false,
        NoShow: false,
        Cancel: false,
        Reject: false,
    },
    modal: {
        review: false,
        cancel: false,
    },
    selectedReservId: null,
    selectedStatus: null,
};

const useHistoryStore = create((set) => ({
    ...initialState,
    toggleCategory: (select) => {
        set((state) => ({
            category: {
                all: select === '',
                New: select === 'New',
                Confirm: select === 'Confirm',
                Complete: select === 'Complete',
                NoShow: select === 'NoShow',
                Cancel: select === 'Cancel',
                Reject: select === 'Reject',
            },
            selectedStatus: select,
        }));
    },
    showModal: (select, reservId) => {
        set((state) => ({
            modal: {
                ...state.modal,
                cancel: select === 'cancel',
            },
            selectedReservId: reservId ? reservId : null,
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useHistoryStore;
