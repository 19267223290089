import React from 'react';
import Modal from 'react-modal';

Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0,0.85)';
// Modal.defaultStyles.overlay.overflowY = 'scroll';
Modal.defaultStyles.overlay.zIndex = 120;
Modal.defaultStyles.content.overflowY = 'auto';
Modal.defaultStyles.content.top = '50%';
Modal.defaultStyles.content.right = 'unset';
Modal.defaultStyles.content.bottom = 'unset';
Modal.defaultStyles.content.left = '50%';
Modal.defaultStyles.content.width = '90%';
Modal.defaultStyles.content.maxWidth = '26.923rem';
Modal.defaultStyles.content.transform = 'translate(-50%,-50%)';
Modal.defaultStyles.content.padding = 0;
Modal.defaultStyles.content.marginLeft = 'calc(44rem / 2)';
Modal.defaultStyles.content.border = 0;
Modal.defaultStyles.content.zIndex = 20;
Modal.defaultStyles.content.backgroundColor = '#fff';
Modal.setAppElement('#root');

export default function NewModal(props) {
    return (
        <Modal
            isOpen={props.isOpen}
            onRequestClose={props.onRequestClose}
            style={props.customStyles}
            contentLabel={props.contentLabel}
            ariaHideApp={props.ariaHideApp}
        >
            {props.children}
        </Modal>
    );
}
