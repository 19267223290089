const MODE = process.env.REACT_APP_MODE;

const API_SERVER_HOST = process.env.REACT_APP_API_HOST;

const CLIENT_HOST = process.env.REACT_APP_HOST;

const NAVER_CLIENT_ID = process.env.REACT_APP_NAVER_CLIENT_ID;

const KAKAO_CLIENT_ID = process.env.REACT_APP_KAKAO_CLIENT_ID;

const APPLE_CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;

const PG_HOST = process.env.REACT_APP_PG_HOST;

const config = {
    MODE,
    API_SERVER_HOST,
    CLIENT_HOST,
    NAVER_CLIENT_ID,
    KAKAO_CLIENT_ID,
    APPLE_CLIENT_ID,
    PG_HOST,
    REVIEW_IMG_URL: `${API_SERVER_HOST}/files/review/`,
    DESIGNER_IMG_URL: `${API_SERVER_HOST}/files/designer/`,
    ADVERTISEMENT_IMG_URL: `${API_SERVER_HOST}/files/advertisement/`,
    SHOP_IMG_URL: `${API_SERVER_HOST}/files/shop/`,
};

export default config;
