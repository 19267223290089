import { create } from 'zustand';

const initialState = {
    filter: {
        distance: 5,
        category: [],
        minPrice: '',
        maxPrice: '',
    },
    modal: {
        filter: false,
    },
    order: {
        distance: true,
        rating: false,
        price: false,
    },
    selectedOrder: 'distance',
    selectedDesignerId: [],
    toggleStates: {},
};

const useAroundStore = create((set) => ({
    ...initialState,
    showModal: (select) => {
        set((state) => ({
            modal: {
                ...state.modal,
                filter: select === 'filter',
            },
        }));
    },
    toggleOrder: (select) => {
        set((state) => ({
            order: {
                distance: select === 'distance',
                rating: select === 'rating',
                price: select === 'price',
            },
            selectedOrder: select,
        }));
    },
    togglePrices: (key) =>
        set((state) => ({
            toggleStates: {
                // ...state.toggleStates,
                [key]: !state.toggleStates[key],
            },
        })),
    setCategory: (categories) => {
        set((state) => ({
            filter: {
                ...state.filter,
                category: categories.map((category) => ({
                    ...category,
                    isActive: false,
                })),
            },
        }));
    },
    setSelectedDesignerId: (id) => {
        set((state) => {
            const isSelected = state.selectedDesignerId.includes(id);

            if (!isSelected) {
                return { selectedDesignerId: [...state.selectedDesignerId, id] };
            } else {
                return { selectedDesignerId: state.selectedDesignerId };
            }
        });
    },
    setAllFilter: (filter) => {
        set((state) => ({
            filter: {
                ...state.filter,
                ...filter,
            },
        }));
    },
    setFilter: (key, value) => {
        set((state) => ({
            filter: {
                ...state.filter,
                [key]: value,
            },
        }));
    },
    toggleCategory: (id) => {
        set((state) => {
            const updatedCategories = state.filter.category.map((category) => {
                if (category.id === id) {
                    return {
                        ...category,
                        isActive: !category.isActive,
                    };
                }
                return category;
            });

            return {
                filter: {
                    ...state.filter,
                    category: updatedCategories,
                },
            };
        });
    },
    resetFilter: () => {
        set((state) => ({
            filter: {
                distance: 5,
                minPrice: '',
                maxPrice: '',
                category: state.filter.category.map((category) => ({
                    ...category,
                    isActive: false,
                })),
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useAroundStore;
