import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    Paragraph,
    Flxbt,
    LineGrayBox,
    Mb30,
    FooterBg,
    ColGray2,
    ColAccent,
    ContainerPadding,
    FlxGap10,
    TermOver,
    TermHeader,
    Fz18Bold,
    TermModal,
    FooterFlxGap10,
    NavHeight,
    List,
    Spacing30,
} from '@/css/style';
import { popOvers } from '../utils/termData';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import snsFb from '@/assets/img/sns_fb.svg';
import snsIg from '@/assets/img/sns_ig.svg';
import snsX from '@/assets/img/sns_x.svg';
import useMovePath from '../hooks/useMovePath';

export default function Footer() {
    const [popOverData, setPopOverData] = useState(popOvers);

    const togglePopOver = (index) => {
        const updatedPopOvers = [...popOverData];
        updatedPopOvers[index].isVisible = !updatedPopOvers[index].isVisible;
        setPopOverData(updatedPopOvers);
    };

    const navigate = useMovePath();
    return (
        <>
            <FooterBg>
                <ContainerPadding>
                    <FooterFlxGap10 as={Mb30}>
                        <button type="button" onClick={() => navigate('/term-list/agreement')}>
                            <ColGray2>이용약관</ColGray2>
                        </button>
                        <span></span>
                        <button type="button" onClick={() => navigate('/term-list/privacy')}>
                            <ColAccent>개인정보처리방침</ColAccent>
                        </button>
                        {/* <span></span>
                        <button type="button">
                            <ColGray2>회사소개</ColGray2>
                        </button> */}
                    </FooterFlxGap10>
                    <Mb30>
                        <Paragraph $col="#a1a1a1">
                            머리할래
                            <br />
                            대표이사: 최희영 / 광주광역시 서구 상무중앙로78번길 5-6, 9층 926호
                            <br />
                            사업자등록번호: 550-06-03028 개인정보보호책임자: 최희영 /
                            구매안전서비스(에스크로)서비스
                        </Paragraph>
                    </Mb30>
                    <FooterFlxGap10 as={List}>
                        <strong>고객센터</strong>
                        <a href="tel:1566-1986">
                            <strong>1566-1986</strong>
                        </a>
                        <span></span>
                        <strong>문의메일</strong>
                        <a href="mailto:hairhale@hairhale.com">
                            <strong>hairhale@hairhale.com</strong>
                        </a>
                    </FooterFlxGap10>
                    <Spacing30 />
                    <Flxbt>
                        <LineGrayBox as="a" href="mailto:hairhale@naver.com">
                            제휴 입점 및 기타문의
                        </LineGrayBox>
                        <FlxGap10>
                            {/* <Link
                                href
                                target="_blank"
                                rel="noopener noreferrer"
                                to={
                                    'https://www.facebook.com/people/%EB%A8%B8%EB%A6%AC%ED%95%A0%EB%9E%98/61556651211417/'
                                }
                            >
                                <img src={snsFb} alt="페이스북" />
                            </Link> */}
                            <Link
                                href
                                target="_blank"
                                rel="noopener noreferrer"
                                to={'https://www.instagram.com/hairhale.official'}
                            >
                                <img src={snsIg} alt="인스타그램" />
                            </Link>
                            {/* <Link
                                href
                                target="_blank"
                                rel="noopener noreferrer"
                                to={'https://twitter.com/meolihalla8529'}
                            >
                                <img src={snsX} alt="트위터" />
                            </Link> */}
                        </FlxGap10>
                    </Flxbt>
                </ContainerPadding>
            </FooterBg>
            {popOvers.map((popOver, index) => (
                <TermOver key={index} style={{ display: popOver.isVisible ? 'block' : 'none' }}>
                    <TermModal>
                        <TermHeader>
                            <button type="button" onClick={() => togglePopOver(index)}>
                                <img src={arrowLeft} alt="뒤로" />
                            </button>
                        </TermHeader>
                        {/* <NavHeight /> */}
                        <Fz18Bold $mb="2.3077rem">{popOver.title}</Fz18Bold>
                        {/* <Paragraph> */}
                        <div dangerouslySetInnerHTML={{ __html: popOver.content }} />
                        {/* </Paragraph> */}
                    </TermModal>
                </TermOver>
            ))}
        </>
    );
}
