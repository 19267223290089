import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/css/slick_custom.css';
import { DcSlideWrapper, FullImg, ImgGradient, SlideTxt } from '@/css/style';
import { useNavigate } from 'react-router-dom';
import config from '@config';
import useMovePath from '../hooks/useMovePath';

export default function DcBanner({ sliderSetting, className, data }) {
    const navigate = useMovePath();
    const [isDragging, setIsDragging] = useState(false);

    const handleClick = (id) => {
        if (!isDragging) {
            navigate(`/banner/${id}`);
        }
    };

    const settings = {
        ...sliderSetting,
        pauseOnHover: true,
        beforeChange: () => {
            setIsDragging(true);
        },
        afterChange: () => {
            setIsDragging(false);
        },
    };

    return (
        <Slider {...settings} className={className}>
            {data?.pages[0].data.docs.map((items) => (
                <DcSlideWrapper key={items._id} onClick={() => handleClick(items._id)}>
                    <ImgGradient />
                    <FullImg
                        src={`${config.ADVERTISEMENT_IMG_URL}${items.main_image?.filename}?size=350`}
                        onError={(e) => (e.currentTarget.src = require('@assets/img/altimg.png'))}
                    />
                    <SlideTxt>{items.shop.name}</SlideTxt>
                </DcSlideWrapper>
            ))}
        </Slider>
    );
}
