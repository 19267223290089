import React, { useEffect } from 'react';
import Modal from 'react-modal';
import {
    BtnModalPhotoLeft,
    Pad20,
    BtnModalPhotoRight,
    ModalHeader,
    ModalPhoto,
    GridImg,
    PhotoGrid,
    PhotoItem,
    NavHeight,
    Fz18Medium,
    BtnCloseModal2,
    Container,
    Mb20,
    FullImg,
    ContainerPadding,
} from '@/css/style';
import config from '@/config';
import { useDesignerDetailQuery } from '@/services/designerService';
import { useParams } from 'react-router-dom';
import altimg from '@/assets/img/altimg.png';
import arrowLeftWhite from '@/assets/img/arrow_left_wh.svg';
import arrowRightWhite from '@/assets/img/arrow_right_wh.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';

function StylePhotos() {
    const params = useParams();
    const { data: designerData } = useDesignerDetailQuery(params.id);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [currentImageIndex, setCurrentImageIndex] = React.useState(0); // 상태 추가
    function openModal(index) {
        setCurrentImageIndex(index); // 해당 이미지의 index를 상태에 업데이트합니다.
        setIsOpen(true); // 모달을 열어 해당 이미지를 보여줍니다.
    }
    function closeModal() {
        setIsOpen(false);
    }
    const nextImage = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex = prevIndex + 1 >= designerData?.data.images.length ? 0 : prevIndex + 1;
            return newIndex;
        });
    };
    const prevImage = () => {
        setCurrentImageIndex((prevIndex) => {
            const newIndex =
                prevIndex - 1 < 0 ? designerData?.data.images.length - 1 : prevIndex - 1;
            return newIndex;
        });
    };
    return (
        <>
            <Container>
                <NavHeight />
                <ContainerPadding>
                    <Mb20>
                        <PhotoGrid>
                            {designerData?.data.images.map((images, index) => (
                                <PhotoItem key={index} onClick={() => openModal(index)}>
                                    <GridImg
                                        src={`${config.DESIGNER_IMG_URL}${images.filename}`}
                                        onError={(e) => {
                                            e.currentTarget.src = altimg;
                                        }}
                                    />
                                </PhotoItem>
                            ))}
                        </PhotoGrid>
                    </Mb20>
                </ContainerPadding>
            </Container>
            <Modal isOpen={modalIsOpen} ariaHideApp={false} style={slideShowStyles}>
                <ModalHeader>
                    <BtnCloseModal2 onClick={closeModal}>
                        <img src={icCloseModal} alt="닫기" />
                    </BtnCloseModal2>
                    <Fz18Medium>
                        상세보기({currentImageIndex + 1}/{designerData?.data.images.length})
                    </Fz18Medium>
                </ModalHeader>
                <ModalPhoto>
                    <BtnModalPhotoLeft onClick={() => prevImage()}>
                        <img src={arrowLeftWhite} />
                    </BtnModalPhotoLeft>
                    <FullImg
                        src={`${config.DESIGNER_IMG_URL}${
                            designerData?.data.images[[currentImageIndex]].filename
                        }`}
                    />
                    <BtnModalPhotoRight onClick={() => nextImage()}>
                        <img src={arrowRightWhite} />
                    </BtnModalPhotoRight>
                    {/* <Pad20>
                        <Fz18Medium $col="#fff">시술명</Fz18Medium>
                    </Pad20> */}
                </ModalPhoto>
            </Modal>
        </>
    );
}
const slideShowStyles = {
    overlay: {
        backgroundColor: 'unset',
        zIndex: 102,
    },
    content: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        overflowY: 'auto',
        top: 0,
        right: 'unset',
        bottom: 0,
        left: '50%',
        width: '100%',
        maxWidth: 440,
        padding: 0,
        transform: 'translateX(-50%)',
        border: 0,
        zIndex: 103,
        backgroundColor: 'rgba(0,0,0,.85)',
    },
};
export default StylePhotos;
