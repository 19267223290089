import React, { useEffect, useState } from 'react';
import DcShopSlider from '@/components/DcShopSlider';
import {
    Pad20,
    SchInputWrapper,
    Fz20Bold,
    SearchTop,
    PopOver,
    LinkFlxbtFull,
    Fz15Bold,
    FlxMiddle,
    Spacing50,
    SearchBottom,
    PopOverAbsol,
} from '@/css/style';
import useDisCountStore from '@/stores/common/discount';
import useShowStore from '@/stores/common/show';
import arrowRightGray from '@/assets/img/arrow_right_thin_gray.svg';
import icCloseModal from '@/assets/img/ic_close_modal.svg';
import icSearch from '@/assets/img/ic_search_green.svg';
import SearchResult from '@/screens/common/SearchResult';
import { useShopDiscountQuery } from '../services/shopService';
import Footer from './Footer';
import NoContent from './NoContent';
import useSearchStore from '../stores/common/search';
import { useDesignerQuery, useDesignerSearchQuery } from '../services/designerService';
import { useLocation } from 'react-router-dom';

export default function GnbSearch() {
    const ss = useShowStore();
    const ds = useDisCountStore();
    const search = useSearchStore();
    const location = useLocation();

    const { data: shopDiscountData } = useShopDiscountQuery();
    const {
        data: searchResult,
        isLoading,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useDesignerSearchQuery({
        search_text: search.keyword,
    });

    const requestSearchDesigner = () => {
        if (!search.keyword) {
            return;
        } else {
            refetch();
            search.toggleIsSearch();
        }
    };
    return (
        <>
            <PopOverAbsol $visible={ss.showGnbSearch}>
                <SearchTop>
                    {/* 240110 메모:bottombar가 보이는 구조인데 어떻게 해야할까요?
그리고 시안상으로는 상태바까지 색이 차있는데 웹 앱이라 한계가 있는 듯 해요.
  <meta name="theme-color" content="#ff0000">
메타태그 이용방법을 찾아봤는데 이 컴포넌트가 노출될때만 바꿀수는 없을것같은데 어떻게 생각하시나요? */}
                    <button
                        type="button"
                        onClick={() => {
                            ss.setShowGnbSearch(false);
                            search.terminate();
                        }}
                    >
                        <img src={icCloseModal} alt="닫기" />
                    </button>
                    <Spacing50 />
                    <Fz20Bold $mb="2.3077rem" $col="#fff">
                        디자이너 또는 헤어샵을
                        <br />
                        검색해 주세요!
                    </Fz20Bold>
                    <SchInputWrapper>
                        <input
                            type="text"
                            value={search.keyword}
                            maxLength="10"
                            onChange={(e) => {
                                const { value } = e.target;
                                search.setKeyword(value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    requestSearchDesigner();
                                }
                            }}
                            placeholder="검색"
                        />
                        <button type="button" onClick={requestSearchDesigner}>
                            <img src={icSearch} alt="찾기" />
                        </button>
                    </SchInputWrapper>
                </SearchTop>
                <SearchBottom>
                    <Pad20>
                        <LinkFlxbtFull
                            to="/discount"
                            onClick={() => ds.toggleCategory('banner')}
                            state={{ previousUrl: location.pathname }}
                        >
                            <Fz15Bold $col="#00bf9d">지금 할인중</Fz15Bold>
                            <FlxMiddle>
                                <strong>더보기</strong>
                                <img src={arrowRightGray} alt="할인 배너 더보기" />
                            </FlxMiddle>
                        </LinkFlxbtFull>
                    </Pad20>
                    {!(
                        shopDiscountData?.pages && shopDiscountData?.pages[0].data.docs.length > 0
                    ) && <NoContent message="할인 중인 헤어샵이 없습니다." />}
                    <DcShopSlider
                        sliderSetting={SearchBanner}
                        className="slider--banner slider--search"
                        data={shopDiscountData}
                    />
                </SearchBottom>
            </PopOverAbsol>
            <PopOverAbsol $visible={search.isSearch}>
                <SearchResult
                    data={{
                        data: searchResult,
                        isLoading,
                        refetch,
                        fetchNextPage,
                        hasNextPage,
                        isFetchingNextPage,
                    }}
                />
            </PopOverAbsol>
        </>
    );
}
const SearchBanner = {
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
};
