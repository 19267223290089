import { create } from 'zustand';
import config from '../../config/index';

const initialState = {
    validate: {},
    modal: {
        method: false,
        complete: false,
        fail: false,
        receipt: false,
    },
    screen: {
        pay: true,
        history: false,
    },
    selectedProductId: null,
    selectedStatus: null,
    isAgree: false,
    payData: {
        method: '', // 결제수단
        trdDt: '', // 요청일자(yyyyMMdd)
        trdTm: '', // 요청시간(HHmmss)
        mchtTrdNo: '', // 상점주문번호
        mchtName: '세틀뱅크', // 상점한글명
        mchtEName: 'Settlebank', // 상점영문명
        pmtPrdtNm: '테스트상품', // 상품명
        notiUrl: `${config.API_SERVER_HOST}/payments/noti`, // 노티
        nextUrl: `${config.API_SERVER_HOST}/payments/callback`, // 결과화면 URL
        cancUrl: `${config.API_SERVER_HOST}/payments/callback`, // 결제취소 URL
        plainMchtCustNm: '홍길동', // 고객명(평문)
        custAcntSumry: '세틀뱅크', // 통장인자내용
        expireDt: '', // 입금만료일시(yyyyMMddHHmmss)
        mchtParam: '상점 예약 필드', // 상점예약필드
        plainCphoneNo: '', // 핸드폰번호(평문)
        plainEmail: 'HongGilDong@example.com', // 이메일주소(평문)
        telecomCd: '', // 통신사코드
        // prdtTerm: '20221231235959', // 상품제공기간
        plainMchtCustId: 'HongGilDong', // 상점고객아이디(평문)
        taxTypeCd: '', // 면세여부(Y:면세, N:과세, G:복합과세)
        plainTaxAmt: '', // 과세금액(평문)(복합과세인 경우 필수)
        plainVatAmt: '', // 부가세금액(평문)(복합과세인 경우 필수)
        plainTaxFreeAmt: '', // 비과세금액(평문)(복합과세인 경우 필수)
        plainSvcAmt: '', // 봉사료(평문)
        cardType: '', // 카드결제타입
        chainUserId: '', // 현대카드Payshot ID
        cardGb: '', // 특정카드사코드
        plainClipCustNm: '', // 클립포인트고객명(평문)
        plainClipCustCi: '', // 클립포인트CI(평문)
        plainClipCustPhoneNo: '', // 클립포인트고객핸드폰번호(평문)
        certNotiUrl: '', // 인증결과URL
        skipCd: '', // 스킵여부
        multiPay: '', // 포인트복합결제
        autoPayType: '', // 자동결제 타입(공백:일반결제, M:월자동 1회차)
        linkMethod: '', // 연동방식
        custIp: '', // 고객IP주소
        corpPayCode: '', // 간편결제 코드
        corpPayType: '', // 간편결제 타입(CARD:카드, POINT:포인트)
        cashRcptUIYn: '', // 현금영수증 발급 여부
        plainTrdAmt: '300',
        mchtId: 'nx_mid_il',
        mobileYn: 'N',
        osType: 'W',
        appScheme: 'com.hairhale',
    },
    currentHistory: [],
};

const useLicenseStore = create((set) => ({
    ...initialState,
    toggleScreen: (screenName) => {
        set((state) => ({
            screen: {
                pay: screenName === 'pay',
                history: screenName === 'history',
            },
        }));
    },
    toggleModal: (modalName) => {
        set((state) => ({
            modal: {
                [modalName]: !state.modal[modalName],
            },
        }));
    },
    selectProduct: (productId) => {
        set((state) => ({
            selectedProductId: productId,
        }));
    },
    setPayData: (obj) => {
        set((state) => {
            const updatedState = {
                payData: {
                    ...state.payData,
                    ...obj,
                },
            };

            return updatedState;
        });
    },
    setCurrentHistory: (arr) => {
        set((state) => {
            const updatedState = {
                currentHistory: arr,
            };

            return updatedState;
        });
    },
    toggleAgree: () => {
        set((state) => ({
            isAgree: !state.isAgree,
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useLicenseStore;
