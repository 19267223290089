import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import {
    FlxbtFullMb20,
    SettingOptions,
    MyHeader,
    Paragraph,
    Spacing20,
    ReviewThumb,
    GridImg,
    Fz15Bold,
    ReviewDate,
    ItemReview,
    Fz18Medium,
    Fz16Medium,
    Container,
    ContainerPadding,
    FlxbtFull,
    Fz20Bold,
    PadVert20,
    LineCenter,
    FlxMiddle,
    BtnModalPhoto,
} from '@/css/style';
import config from '@/config';
import { format, parse } from 'date-fns';
import { ko } from 'date-fns/locale';
import { useIntersectionObserver } from '@/utils/helper';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useReviewDeleteMutation, useReviewQuery } from '@/services/reviewService';
import useMyReviewStore from '@/stores/user/myreview';
import useShowStore from '@/stores/common/show';
import RatingStars from '@/components/RatingStars';
import altimg from '@/assets/img/altimg.png';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import arrowLeftWhite from '@/assets/img/arrow_left_wh.svg';
import arrowRightWhite from '@/assets/img/arrow_right_wh.svg';
import icSetting from '@/assets/img/ic_setting_gray.svg';
import NoContent from '@/components/NoContent';
import { useIsMutating } from '@tanstack/react-query';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnModalPhoto className={className} style={{ ...style }} onClick={onClick}>
            <img src={arrowRightWhite} alt="" />
        </BtnModalPhoto>
    );
}
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <BtnModalPhoto className={className} style={{ ...style }} onClick={onClick}>
            <img src={arrowLeftWhite} alt="" />
        </BtnModalPhoto>
    );
}
function MyReview() {
    const ms = useMyReviewStore();
    const ss = useShowStore();
    const { navigate } = useOutletContext();
    const containerRef = useRef(null);
    const isMutating = useIsMutating();
    const {
        data: reviewData,
        refetch: reviewDataRefetch,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
        isLoading,
    } = useReviewQuery();
    const ReviewDeleteMutation = useReviewDeleteMutation(() => reviewDataRefetch());
    const observer = useIntersectionObserver(
        fetchNextPage,
        containerRef,
        reviewData,
        isFetchingNextPage
    );

    useEffect(() => {
        return () => {
            ms.terminate();
        };
    }, []);

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [isLoading]);

    return (
        <Container>
            <MyHeader $mb="0">
                <Fz20Bold>내가 작성한 리뷰</Fz20Bold>
                <FlxMiddle>
                    <Fz18Medium $col="#00BF9D">{reviewData?.pages[0].data.totalDocs}</Fz18Medium>
                    &nbsp;<Fz16Medium>건</Fz16Medium>
                </FlxMiddle>
            </MyHeader>
            <ContainerPadding>
                {/* 내 리뷰 목록 */}
                {!(reviewData?.pages && reviewData?.pages[0].data.docs.length > 0) && (
                    <NoContent message="작성하신 리뷰가 없습니다." />
                )}
                {reviewData?.pages.map((page) =>
                    page.data.docs.map((review) => (
                        <section key={review.id}>
                            <ItemReview>
                                <FlxbtFullMb20>
                                    <Fz15Bold $col="#000">
                                        {review.reservation.designer.title}
                                    </Fz15Bold>
                                    <ReviewDate>
                                        {' '}
                                        {format(new Date(review.created), 'yyyy. MM. dd', {
                                            locale: ko,
                                        })}{' '}
                                    </ReviewDate>
                                </FlxbtFullMb20>
                                <FlxbtFull $pos="relative">
                                    <p>
                                        {' '}
                                        {format(
                                            parse(
                                                review.reservation.date +
                                                    ' ' +
                                                    review.reservation.time,
                                                'yyyy-MM-dd HH:mm',
                                                new Date()
                                            ),
                                            'yyyy. MM. dd (E)',
                                            { locale: ko }
                                        )}{' '}
                                        {review.reservation.time}{' '}
                                        {review.reservation.menus.map((menus) => menus.name + ' ')}
                                    </p>
                                    <button
                                        type="button"
                                        onClick={() => ms.toggleReview(review.reservation.id)}
                                    >
                                        <img src={icSetting} alt="옵션" />
                                    </button>
                                    <SettingOptions
                                        className={
                                            ms.toggleReviews[review.reservation.id] ? 'open' : ''
                                        }
                                    >
                                        <button
                                            type="button"
                                            onClick={() =>
                                                navigate(`/my/review/${review.reservation.id}`, {
                                                    replace: true,
                                                    state: { from: 'my' },
                                                })
                                            }
                                        >
                                            수정
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                ReviewDeleteMutation.mutate(review.reservation.id)
                                            }
                                            disabled={!!isMutating}
                                        >
                                            삭제
                                        </button>
                                    </SettingOptions>
                                </FlxbtFull>
                                <FlxMiddle as={PadVert20}>
                                    <RatingStars rating={review.rating} />
                                    &nbsp;&nbsp;{review.rating}
                                </FlxMiddle>
                                <Paragraph $fz="1.154rem">{review.content}</Paragraph>
                                {review.images.length > 0 && (
                                    <>
                                        <Spacing20 />
                                        <Slider {...sliderStyle} className="slider--myreview">
                                            {review.images.map((image) => (
                                                <ReviewThumb key={image.filename}>
                                                    <GridImg
                                                        src={`${config.REVIEW_IMG_URL}${image.filename}?size=350`}
                                                        alt="이미지"
                                                        onError={(e) => {
                                                            e.currentTarget.src = altimg;
                                                        }}
                                                    />
                                                </ReviewThumb>
                                            ))}
                                        </Slider>
                                    </>
                                )}
                            </ItemReview>
                        </section>
                    ))
                )}
                {hasNextPage && (
                    <LineCenter ref={containerRef}>
                        <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                        <img src={arrowDownThinGray} alt="more" />
                    </LineCenter>
                )}
            </ContainerPadding>
        </Container>
    );
}
const sliderStyle = {
    autoplay: false,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
};

export default MyReview;
