import React, { useEffect } from 'react';
import {
    Container,
    ContainerPadding,
    JoinField,
    PadVert10,
    InputWrapper,
    JoinInput,
    Fz15Bold,
    TabAccent,
    Gap10,
    FlxMiddleMb30,
    BtnFullLineGreen,
    Fz18Bold,
    Mb30,
    Paragraph,
    LinkLogin,
    ColAccent2,
    Pad20,
    PopShortMsg,
    BtnFullModal,
    ToggleShow,
    Mb20,
    WarnInput,
    ConfirmInfoItem,
    InfoLabel,
    InfoValue,
} from '@/css/style';
import { emailRegex } from '@/utils/helper';
import useForgotStore from '@/stores/common/forgot';
import NewModal from '@/components/NewModal';
import icWarn from '@/assets/img/ic_warn.svg';

import { useGetCertificateDataQuery } from '@/services/authService';
import { useBlocker, useLocation, useOutletContext } from 'react-router-dom';

function Forgot() {
    const fs = useForgotStore();
    const location = useLocation();
    const { navigate } = useOutletContext();
    const { refetch: certificateRefetch } = useGetCertificateDataQuery(
        fs.fields.receiveData,
        fs.fields.user_id
    );

    const requestCertificate = (receiveData) => {
        const parentLeft = window.screenLeft || window.screenX || 0;
        const parentTop = window.screenTop || window.screenY || 0;

        const popupWidth = 500;
        const popupHeight = 600;

        const left = parentLeft + (window.innerWidth - popupWidth) / 2;
        const top = parentTop + (window.innerHeight - popupHeight) / 2;

        const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

        if (fs.fields.receiveData === 'find-pw') {
            if (!fs.fields.user_id) {
                return;
            }
            if (window.ReactNativeWebView) {
            } else {
                window.open('', 'nicePopup', option);
            }
            certificateRefetch();
        } else {
            if (window.ReactNativeWebView) {
            } else {
                window.open('', 'nicePopup', option);
            }
            certificateRefetch();
        }
    };
    useEffect(() => {
        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
            fs.terminate();
        };
    }, []);

    function handleMessage(event) {
        const messageData = event.data;
        if (messageData.success) {
            switch (messageData.data.data.request_type) {
                case 'find-id':
                    if (messageData.data?.code === '-1') {
                        fs.setState('findId', false);
                        fs.togglePopup('verifyConfirmErrorID');
                        fs.setIsCert(true);
                    } else if (messageData.data?.code === '0000') {
                        fs.setResponse(messageData.data);
                        fs.setState('findId', true);
                        fs.setIsCert(true);
                    }
                    break;
                case 'find-pw':
                    if (messageData.data?.code === '-1') {
                        fs.setState('findPW', false);
                        fs.togglePopup('verifyConfirmErrorPW');
                        fs.setIsCert(true);
                    } else if (messageData.data?.code === '0000') {
                        fs.setResponse(messageData.data);
                        fs.setState('findPW', true);
                        fs.setIsCert(true);
                    }
                    break;
                default:
                    break;
            }
        }
    }
    console.log('fs.isCe2rt', fs.isCert);
    let blocker = useBlocker(({ currentLocation, nextLocation }) => {
        console.log('currentLocation, nextLocation', currentLocation, nextLocation);
        return (
            (nextLocation.pathname === '/login' || nextLocation.pathname === '/nice/callback') &&
            fs.isCert
        );
    });

    useEffect(() => {
        if (blocker.state === 'blocked') {
            window.ReactNativeWebView && navigate(-5, { replace: true });
        }
    }, [blocker.state]);
    return (
        <>
            <Container>
                <form
                    name="form"
                    id="form"
                    action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb"
                >
                    <input type="hidden" id="m" name="m" value="service" />
                    <input type="hidden" id="token_version_id" name="token_version_id" value="" />
                    <input type="hidden" id="enc_data" name="enc_data" />
                    <input type="hidden" id="integrity_value" name="integrity_value" />
                </form>
                <ContainerPadding>
                    <FlxMiddleMb30>
                        <TabAccent
                            $active={fs.screen.id}
                            onClick={() => {
                                fs.setScreen('id');
                                fs.screenTerminate();
                                fs.setField('receiveData', 'find-id');
                            }}
                        >
                            아이디 찾기
                        </TabAccent>
                        <Gap10 />
                        <TabAccent
                            $active={fs.screen.password}
                            onClick={() => {
                                fs.setScreen('password');
                                fs.screenTerminate();
                                fs.setField('receiveData', 'find-pw');
                            }}
                        >
                            비밀번호 찾기
                        </TabAccent>
                    </FlxMiddleMb30>
                    <ToggleShow $visible={fs.screen.id}>
                        {Object.keys(fs.res).length === 0 && (
                            <>
                                <Fz18Bold $mb="3.846rem">
                                    가입하셨던 휴대폰 번호로 인증해 주세요
                                </Fz18Bold>
                                <BtnFullLineGreen onClick={() => requestCertificate('find-id')}>
                                    본인 인증
                                </BtnFullLineGreen>
                            </>
                        )}

                        {fs.findId && (
                            <>
                                <Fz18Bold $mb="3.846rem">정보 확인</Fz18Bold>
                                <Mb30>
                                    <ConfirmInfoItem>
                                        <InfoLabel>이름</InfoLabel>
                                        <InfoValue>{fs.res.data.name}</InfoValue>
                                    </ConfirmInfoItem>
                                    <ConfirmInfoItem>
                                        <InfoLabel>휴대폰번호</InfoLabel>
                                        <InfoValue>{fs.res.data.mobileno}</InfoValue>
                                    </ConfirmInfoItem>
                                </Mb30>
                                <Paragraph $fz="1.154rem" $ta="center" as={Mb30}>
                                    회원님의 아이디는
                                    <br />
                                    <ColAccent2>{fs.res.data.user_id}</ColAccent2> 입니다.
                                </Paragraph>
                                <LinkLogin to="/login" state={{ previousUrl: location.pathname }}>
                                    로그인하기
                                </LinkLogin>
                            </>
                        )}
                        {/* //아이디 안내 */}
                    </ToggleShow>
                    <ToggleShow $visible={fs.screen.password}>
                        {Object.keys(fs.res).length === 0 && (
                            <>
                                <Fz18Bold $mb="3.846rem">
                                    가입하셨던 아이디 입력/휴대폰 인증을 해주세요
                                </Fz18Bold>
                                <JoinField>
                                    <PadVert10>
                                        <Fz15Bold>아이디</Fz15Bold>
                                    </PadVert10>
                                    <InputWrapper>
                                        <JoinInput
                                            type="text"
                                            id="userId"
                                            name="userId"
                                            placeholder="아이디를 입력해 주세요"
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                fs.setField('user_id', value);
                                            }}
                                            value={fs.fields.user_id ? fs.fields.user_id : ''}
                                        />
                                    </InputWrapper>
                                    {!emailRegex.test(fs.fields.user_id) && (
                                        <WarnInput>
                                            <img src={icWarn} alt="경고" /> 이메일 형식이 맞지
                                            않습니다.
                                        </WarnInput>
                                    )}
                                </JoinField>
                                <BtnFullLineGreen
                                    onClick={() =>
                                        requestCertificate(`find-pw,${fs.fields.user_id}`)
                                    }
                                >
                                    본인 인증
                                </BtnFullLineGreen>
                            </>
                        )}

                        {fs.findPw && (
                            <>
                                <Fz18Bold $mb="3.846rem">정보 확인</Fz18Bold>
                                <Mb30>
                                    <ConfirmInfoItem>
                                        <InfoLabel>이름</InfoLabel>
                                        <InfoValue>{fs.res.data.name}</InfoValue>
                                    </ConfirmInfoItem>
                                    <ConfirmInfoItem>
                                        <InfoLabel>휴대폰번호</InfoLabel>
                                        <InfoValue>{fs.res.data.mobileno}</InfoValue>
                                    </ConfirmInfoItem>
                                    <ConfirmInfoItem>
                                        <InfoLabel>아이디</InfoLabel>
                                        <InfoValue>{fs.res.data.user_id}</InfoValue>
                                    </ConfirmInfoItem>
                                </Mb30>
                                <Paragraph $fz="1.154rem" $ta="center" as={Mb30}>
                                    문자로 <ColAccent2>임시 비밀번호</ColAccent2>를 보냈습니다.
                                    <br />
                                    비밀번호는 마이페이지에서 변경 가능합니다.
                                </Paragraph>
                                <LinkLogin to="/login" state={{ previousUrl: location.pathname }}>
                                    로그인하기
                                </LinkLogin>
                            </>
                        )}
                        {/* //아이디 안내 */}
                    </ToggleShow>
                </ContainerPadding>
            </Container>
            <NewModal
                isOpen={fs.popup.verifyConfirmErrorID}
                contentLabel="사용자 정보 오류"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">가입된 사용자가 없습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <BtnFullModal $active onClick={() => fs.togglePopup('verifyConfirmErrorID')}>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal
                isOpen={fs.popup.verifyConfirmErrorPW}
                contentLabel="사용자 정보 오류"
                ariaHideApp={false}
            >
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">비밀번호를 찾을 수 없습니다.</Fz15Bold>
                        </Mb20>
                    </PopShortMsg>
                    <BtnFullModal $active onClick={() => fs.togglePopup('verifyConfirmErrorPW')}>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
        </>
    );
}

export default Forgot;
