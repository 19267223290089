import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import {
    Container,
    ItemDesigner,
    DesignerInfo,
    DesignerName,
    RoundProfile,
    ListColumn,
    Fz15Bold,
    LineCenter,
    FullImg,
    Fz15,
    FlxMiddle,
    TabAccent2,
    SectionDivider,
    ContainerPadding,
    Spacing10,
    Spacing36,
    SpotBannerWrapper,
    Flxbt,
    Fz20Bold,
    LineGrayBox,
    TabWrapper,
    DesignerSns,
    Mb5,
    Mb10,
    Mb15,
    Mb1rem,
    BtnLikeInImg,
    FlxbtFull,
    DesignerRates,
    Pad20,
    PopShortMsg,
    Mb20,
    ListCol2,
    BtnHalfModalBlack,
    JoinInput,
    BtnClearInput,
    Paragraph,
    BtnFullModal,
    Gap10,
    OptionBox,
    Block,
    Mb40,
    BtnToggleAccent,
    BtnHalfModal,
    DesignerPrices,
    PriceItem,
    LinkFlxMiddle,
    Fz16Medium,
    TxRight,
    OrgPrice,
    ConsumerPrice,
    Fz18Medium,
    InputWrapper,
    ColAccent,
} from '@/css/style';
import config from '@config';
import { FaStar } from 'react-icons/fa';
import {
    getAddressLatLng,
    getShopDistance,
    snsTable,
    useIntersectionObserver,
} from '../../utils/helper';
import { useBookMarkCreateMutation, useBookMarkDeleteMutation } from '@/services/userService';
import { useDesignerQuery } from '@/services/designerService';

import useDisCountStore from '@/stores/common/discount';
import useHomeStore from '@/stores/common/home';
import useLocationStore from '@/stores/common/location';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import DcShopSlider from '@/components/DcShopSlider';
import Footer from '@/components/Footer';
import NewModal from '@/components/NewModal';
import NoContent from '@/components/NoContent';
import SpotBanner from '@/components/SpotBanner';
import altimg from '@/assets/img/altimg.png';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import icClear from '@/assets/img/ic_clear.svg';
import icFind from '@/assets/img/ic_find.svg';
import icReview from '@/assets/img/ic_review.svg';
import arrowDownGray from '@/assets/img/arrow_down_gray.svg';
import arrowUpWhite from '@/assets/img/arrow_up_wh.svg';
import icLikeOffLine from '@/assets/img/ic_like_off.svg';
import icLikeOnLine from '@/assets/img/ic_like_on.svg';
import { useShopDiscountQuery } from '@services/shopService';
import { useIsMutating } from '@tanstack/react-query';
import DesignerAvaliableTime from '@/components/DesignerAvaliableTime';
import SkeletonLoader from '@/components/SkeletonLoader';
import Lottie from 'react-lottie';
import animationData from '@/assets/lottieLoader.json';

function List() {
    const hs = useHomeStore();
    const us = useUserStore();
    const ds = useDisCountStore();
    const ls = useLocationStore();
    const ss = useShowStore();
    const [addresses, setAddresses] = useState([]);
    const containerRef = useRef(null);
    const location = useLocation();
    const isMutating = useIsMutating();
    const { navigate, navermaps } = useOutletContext();

    const { data: shopDiscountData } = useShopDiscountQuery({
        limit: 5,
    });

    const { data, isLoading, refetch, fetchNextPage, hasNextPage, isFetchingNextPage } =
        useDesignerQuery({
            sort: hs.selectedOrder,
            lat: ls.lat,
            lng: ls.lng,
        });

    const observer = useIntersectionObserver(fetchNextPage, containerRef, data, isFetchingNextPage);

    const handleSearchAddress = () => {
        getAddressLatLng(ls.searchAddress, navermaps)
            .then((result) => {
                setAddresses(result.items);
            })
            .catch((error) => {
                // 오류가 발생한 경우에 대한 처리
                console.error('에러 발생:', error);
                // 에러 처리 로직을 구현합니다.
            });
    };

    const BookMarkCreate = useBookMarkCreateMutation(() => refetch());
    const BookMarkDelete = useBookMarkDeleteMutation(() => refetch());

    useEffect(() => {
        return () => {
            ds.terminate();
            ls.resetModal();
        };
    }, []);

    // useEffect(() => {
    //     ls.isLoading ? ss.setLottie(true) : ss.setLottie(false);
    // }, [ls.isLoading]);
    return (
        <Container>
            <ContainerPadding>
                <SpotBannerWrapper>
                    <SpotBanner />
                </SpotBannerWrapper>
                {/* <LinkFlxMiddle>
                    <img src={icAnnounce} alt="공지" />
                    <Gap10 />
                    <Fz15>머리할래 이용사항을 안내드립니다.</Fz15>
                </LinkFlxMiddle> */}
                {shopDiscountData?.pages && shopDiscountData?.pages[0].data.totalDocs > 0 ? (
                    <>
                        {/* <Spacing36 /> */}
                        <Flxbt>
                            <Fz20Bold>지금 할인중</Fz20Bold>
                            <FlxMiddle>
                                <ul className="homebannerdot"></ul>
                                <LineGrayBox>
                                    <Link
                                        to="/discount"
                                        onClick={() => ds.toggleCategory('banner')}
                                        state={{ previousUrl: location.pathname }}
                                    >
                                        더보기
                                    </Link>
                                </LineGrayBox>
                            </FlxMiddle>
                        </Flxbt>
                        <Spacing10 />
                        <DcShopSlider
                            sliderSetting={{
                                dots: true,
                                infinite: true,
                                arrows: true,
                                rows: 1,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                autoplay: true,
                                autoplaySpeed: 3000,
                                centerMode: false,
                                // customPaging: (i) => (
                                //     <>
                                //         <strong>{i + 1}</strong> /{' '}
                                //         {shopDiscountData?.pages[0].data.totalDocs}
                                //     </>
                                // ),
                            }}
                            className="slider--banner"
                            data={shopDiscountData}
                        />
                    </>
                ) : null}
            </ContainerPadding>
            <SectionDivider style={{ margin: '2rem 0' }} />
            <ContainerPadding>
                <Flxbt as={Mb20}>
                    {ls.lat === '' && ls.lng === '' && ls.lat_map === '' && ls.lng_map === '' ? (
                        <LineGrayBox as="button" type="button" onClick={() => ls.getLocation()}>
                            <img src={icFind} />
                            &nbsp;현위치
                        </LineGrayBox>
                    ) : (
                        <FlxMiddle>
                            <LineGrayBox
                                as="button"
                                type="button"
                                onClick={() => ls.toggleModal('option')}
                            >
                                <img src={icFind} />
                                &nbsp;재설정
                            </LineGrayBox>
                            {/* {data?.pages && data?.pages[0].data.docs.length == 0 && (
                                <>
                                    <Gap10 />
                                    <address>{ls.addr}</address>
                                </>
                            )} */}
                        </FlxMiddle>
                    )}

                    {/* {!isLoading && !(data?.pages && data?.pages[0].data.docs.length == 0) && ( */}
                    <TabWrapper>
                        {/* <TabAccent2 $active={hs.order.good} onClick={() => hs.toggleOrder('good')}>
                            추천순
                        </TabAccent2>
                        ・ */}
                        <ul>
                            <li>
                                <address>{ls.addr}</address>
                            </li>
                            <li>
                                <TabAccent2
                                    $active={hs.order.price}
                                    onClick={() => hs.toggleOrder('price')}
                                >
                                    가격낮은순
                                </TabAccent2>
                                ・
                                {/* {ls.addr && (
                                        <> */}
                                <TabAccent2
                                    $active={hs.order.distance}
                                    onClick={() => {
                                        if (!ls.addr) ls.getLocation();
                                        hs.toggleOrder('distance');
                                    }}
                                >
                                    거리
                                </TabAccent2>
                                ・
                                {/* </>
                                    )} */}
                                <TabAccent2
                                    $active={hs.order.rating}
                                    onClick={() => hs.toggleOrder('rating')}
                                >
                                    인기
                                </TabAccent2>
                            </li>
                        </ul>
                    </TabWrapper>
                    {/* )} */}
                </Flxbt>
                {/* 추천 디자이너 리스트 */}

                <section>
                    <ListColumn>
                        {isLoading ? (
                            <SkeletonLoader count={5} />
                        ) : !(data?.pages && data?.pages[0].data.docs.length > 0) ? (
                            <NoContent message="검색된 디자이너가 없습니다." />
                        ) : data?.pages.map((page) =>
                            page.data.docs.map((items) => (
                                <ItemDesigner key={items._id}>
                                    <RoundProfile>
                                        <FullImg
                                            src={`
                                                ${config.DESIGNER_IMG_URL}${items.profileImage?.filename}?size=150`}
                                            onError={(e) => {
                                                e.currentTarget.src = altimg;
                                            }}
                                            onClick={() => navigate(`/designer/${items._id}`)}
                                            alt="프로필 이미지"
                                        />
                                        {us.user.user_id &&
                                            (items.isBookmarked ? (
                                                <BtnLikeInImg
                                                    type="button"
                                                    onClick={() => {
                                                        BookMarkDelete.mutate(items._id);
                                                    }}
                                                    disabled={!!isMutating}
                                                >
                                                    <img src={icLikeOnLine} alt="좋아요" />
                                                </BtnLikeInImg>
                                            ) : (
                                                <BtnLikeInImg
                                                    type="button"
                                                    onClick={() => {
                                                        BookMarkCreate.mutate(items._id);
                                                    }}
                                                    disabled={!!isMutating}
                                                >
                                                    <img src={icLikeOffLine} alt="좋아요" />
                                                </BtnLikeInImg>
                                            ))}
                                    </RoundProfile>
                                    <DesignerInfo>
                                        <Flxbt as={Mb10}>
                                            <span>
                                                <strong>{items.shop?.name}</strong>&nbsp;
                                                {ls.lat !== '' && ls.lng !== '' && (
                                                    <ColAccent>
                                                        {getShopDistance(items.distance)}
                                                    </ColAccent>
                                                )}
                                            </span>
                                            <FlxMiddle>
                                                {items.sns.map((sns) => (
                                                    <DesignerSns
                                                        href={sns.link}
                                                        target="_blank"
                                                        key={sns._id}
                                                    >
                                                        <img
                                                            src={
                                                                snsTable.find(
                                                                    (item) =>
                                                                        item.provider ===
                                                                        sns.provider
                                                                ).src
                                                            }
                                                            alt={sns.provider}
                                                        />
                                                    </DesignerSns>
                                                ))}
                                            </FlxMiddle>
                                        </Flxbt>
                                        <Flxbt as={Mb15}>
                                            <DesignerName
                                                onClick={() => {
                                                    navigate(`/designer/${items._id}`);
                                                }}
                                            >
                                                {items.title}
                                            </DesignerName>
                                        </Flxbt>
                                        <Flxbt as={Mb5}>
                                            <DesignerAvaliableTime designerId={items._id} />
                                            {/* <div>
                                                <img
                                                    src={
                                                        require(`@/assets/img/ic_time.svg`).default
                                                    }
                                                    width={15}
                                                    height={15}
                                                    style={{ marginRight: '0.3rem' }}
                                                    alt="시간"
                                                />
                                                {items.avaliableTime.length
                                                    ? `오늘 ${items.avaliableTime[0]} 이후 예약 가능해요.`
                                                    : items.isDayoff
                                                    ? '오늘은 휴무일이에요.'
                                                    : '오늘 예약 마감되었어요.'}
                                            </div> */}
                                        </Flxbt>
                                        <FlxbtFull>
                                            <DesignerRates>
                                                <img src={icReview} alt="후기" />
                                                <strong>{items.ratingCount}</strong>
                                                <FaStar
                                                    color="#FFBB00"
                                                    style={{ marginLeft: '0.77rem' }}
                                                />
                                                <strong>
                                                    {items.rating
                                                        ? items.averageRating.toFixed(1)
                                                        : 0}
                                                </strong>
                                            </DesignerRates>
                                            <BtnToggleAccent
                                                $active={ds.toggleStates[items._id]}
                                                onClick={() => ds.togglePrices(items._id)}
                                            >
                                                시술메뉴{' '}
                                                {ds.toggleStates[items._id] ? '닫기' : '보기'}
                                                <img
                                                    src={
                                                        ds.toggleStates[items._id]
                                                            ? arrowUpWhite
                                                            : arrowDownGray
                                                    }
                                                    alt="시술메뉴 토글"
                                                />
                                            </BtnToggleAccent>
                                        </FlxbtFull>
                                    </DesignerInfo>
                                    {items.menus &&
                                        items.menus.length > 0 &&
                                        ds.toggleStates[items._id] && (
                                            <DesignerPrices $mt="1.154rem">
                                                {items.menus.map((menus, index) => {
                                                    return (
                                                        <PriceItem key={index}>
                                                            <FlxbtFull
                                                                $mb={menus.option ? '0.77rem' : 0}
                                                            >
                                                                <Fz16Medium>
                                                                    {menus.name}
                                                                </Fz16Medium>

                                                                {menus.normal_price ===
                                                                menus.price ? (
                                                                    <TxRight>
                                                                        <ConsumerPrice>
                                                                            {menus.normal_price.toLocaleString()}
                                                                            원
                                                                        </ConsumerPrice>
                                                                    </TxRight>
                                                                ) : (
                                                                    <TxRight>
                                                                        {menus.price != 0 && (
                                                                            <OrgPrice>
                                                                                {menus.normal_price.toLocaleString()}
                                                                            </OrgPrice>
                                                                        )}
                                                                        <ConsumerPrice>
                                                                            {menus.price == 0
                                                                                ? '무료'
                                                                                : `${menus.price.toLocaleString()}원`}
                                                                        </ConsumerPrice>
                                                                    </TxRight>
                                                                )}
                                                            </FlxbtFull>
                                                            {menus.option ? (
                                                                <p>{menus.option}</p>
                                                            ) : null}
                                                        </PriceItem>
                                                    );
                                                })}
                                            </DesignerPrices>
                                        )}
                                </ItemDesigner>
                            ))
                        )}
                    </ListColumn>
                </section>
                {hasNextPage && (
                    <LineCenter ref={containerRef} style={{textAlign:'center'}}>
                        {/* <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                        <img src={arrowDownThinGray} alt="more" /> */}
                        <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                        }}
                        style={{
                            width: '2.5rem',
                            height: '2.5rem',
                        }}
                    />
                    </LineCenter>
                )}
                <Spacing36 />
            </ContainerPadding>
            <Footer />
            <NewModal isOpen={ls.modal.reject} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">내 위치 설정</Fz15Bold>
                        </Mb20>
                        <Paragraph $fz="1.154rem">
                            사용기기의 설정에서 '위치정보' 사용을 허용해 주시기 바랍니다.
                        </Paragraph>
                    </PopShortMsg>
                    <BtnFullModal $active onClick={() => ls.toggleModal('reject')}>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal isOpen={ls.modal.option} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">내 위치 설정</Fz15Bold>
                        </Mb20>
                        <Paragraph $fz="1.154rem">세가지 옵션 중 선택해 주세요.</Paragraph>
                    </PopShortMsg>
                    <Block>
                        <OptionBox
                            onClick={() => {
                                ls.toggleModal('option');
                                ls.getLocation();
                            }}
                        >
                            현재 위치로 재검색
                        </OptionBox>
                        <OptionBox
                            onClick={() => {
                                ls.toggleModal('option');
                                ls.toggleModal('address');
                            }}
                        >
                            주소로 위치 찾기
                        </OptionBox>
                        <OptionBox
                            onClick={() => {
                                ls.setField('prev', '/');
                                navigate('/map');
                            }}
                        >
                            지도로 위치 찾기
                        </OptionBox>
                    </Block>
                    <BtnFullModal onClick={() => ls.toggleModal('option')} $active>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal isOpen={ls.modal.address} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb40>
                            <Fz15Bold $col="#000">위치 변경</Fz15Bold>
                        </Mb40>
                        <Mb20>
                            <InputWrapper>
                                <JoinInput
                                    type="text"
                                    placeholder="예) 판교역로 235, 한남동714"
                                    maxLength="20"
                                    onChange={(e) => ls.setField('searchAddress', e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearchAddress();
                                        }
                                    }}
                                    value={ls.searchAddress}
                                />
                                <BtnClearInput
                                    tabIndex="-1"
                                    onClick={() => {
                                        ls.setField('searchAddress', '');
                                    }}
                                >
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                        </Mb20>
                    </PopShortMsg>
                    <Fz18Medium
                        $mb="3.077rem"
                        $col="#00BF9D"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            ls.setField('lat', addresses[0]?.point.y);
                            ls.setField('lng', addresses[0]?.point.x);
                            ls.setField(
                                'addr',
                                addresses[0]?.address.length > 15
                                    ? `${addresses[0]?.address.substring(0, 15)}...`
                                    : addresses[0]?.address
                            );
                            ls.toggleModal('address');
                        }}
                    >
                        {addresses[0]?.address}
                    </Fz18Medium>
                    <ListCol2>
                        <BtnHalfModalBlack
                            onClick={() => {
                                ls.toggleModal('address');
                            }}
                        >
                            취소
                        </BtnHalfModalBlack>
                        <BtnHalfModal onClick={handleSearchAddress}>검색</BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </Container>
    );
}

export default List;
