import React, { useEffect, useState } from 'react';
import { ShortMap } from '@/css/style';
// import { Container as NaverMap, Marker, useNavermaps } from 'react-naver-maps';
import { Container as MapDiv, Marker, NaverMap, useNavermaps } from 'react-naver-maps';

const Map = (props) => {
    const { lat, lng, navermaps } = props;
    const [markerPosition, setMarkerPosition] = useState(null);

    useEffect(() => {
        if (lat && lng) {
            setMarkerPosition(new navermaps.LatLng(lat, lng));
        }
    }, [lat, lng, navermaps]);

    return (
        <MapDiv>
            <ShortMap>
                <NaverMap defaultCenter={markerPosition}>
                    {markerPosition && (
                        <Marker
                            position={markerPosition}
                            icon={{
                                content:
                                    '<div style="background-color: #FF3062; width: 18px;min-width:18px; height: 18px; border:3px solid #fff; border-radius: 50%;"></div>',
                                anchor: new navermaps.Point(10, 10),
                            }}
                        />
                    )}
                </NaverMap>
            </ShortMap>
        </MapDiv>
    );
};

export default Map;
