import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { MenuBadge, FooterImg, FooterBar, FooterInner, FooterItem, FooterList } from '@/css/style';
import gnbAroundOff from '@/assets/img/gnb_around_off.svg';
import gnbAroundOn from '@/assets/img/gnb_around_on.svg';
import gnbDiscountOff from '@/assets/img/gnb_discount_off.svg';
import gnbDiscountOn from '@/assets/img/gnb_discount_on.svg';
import gnbHistoryOff from '@/assets/img/gnb_history_off.svg';
import gnbHistoryOn from '@/assets/img/gnb_history_on.svg';
import gnbHomeOff from '@/assets/img/gnb_home_off.svg';
import gnbHomeOn from '@/assets/img/gnb_home_on.svg';
import gnbMyOff from '@/assets/img/gnb_my_off.svg';
import gnbMyOn from '@/assets/img/gnb_my_on.svg';

const BottomNavBar = () => {
    const location = useLocation();

    return (
        <FooterBar>
            <FooterList>
                <FooterItem>
                    <NavLink to={'/discount'} state={{ previousUrl: location.pathname }}>
                        <FooterInner>
                            <FooterImg>
                                <img
                                    src={
                                        location.pathname === '/discount'
                                            ? gnbDiscountOn
                                            : gnbDiscountOff
                                    }
                                    alt="지금 할인중"
                                />
                            </FooterImg>
                            <span
                                style={{
                                    color: location.pathname === '/discount' ? '#00bfa5' : '',
                                }}
                            >
                                지금 할인중
                            </span>
                        </FooterInner>
                    </NavLink>
                </FooterItem>
                <FooterItem>
                    <NavLink to="/around" state={{ previousUrl: location.pathname }}>
                        <FooterInner>
                            <FooterImg>
                                <img
                                    src={
                                        location.pathname === '/around' ? gnbAroundOn : gnbAroundOff
                                    }
                                    alt="내 주변"
                                />
                            </FooterImg>
                            <span
                                style={{
                                    color: location.pathname === '/around' ? '#00bfa5' : '',
                                }}
                            >
                                내 주변
                            </span>
                        </FooterInner>
                    </NavLink>
                </FooterItem>
                <FooterItem>
                    <NavLink to="/" state={{ previousUrl: location.pathname }}>
                        <FooterInner>
                            <FooterImg>
                                <img
                                    src={location.pathname === '/' ? gnbHomeOn : gnbHomeOff}
                                    alt="내 주변"
                                />
                            </FooterImg>
                            <span
                                style={{
                                    color: location.pathname === '/' ? '#00bfa5' : '',
                                }}
                            >
                                홈
                            </span>
                        </FooterInner>
                    </NavLink>
                </FooterItem>
                <FooterItem>
                    <NavLink to="/history" state={{ previousUrl: location.pathname }}>
                        <FooterInner>
                            <FooterImg>
                                {/* <MenuBadge
                                    style={{
                                        visibility:
                                            location.pathname === '/history' ? 'hidden' : 'visible',
                                    }}
                                >
                                    $
                                </MenuBadge> */}
                                <img
                                    src={
                                        location.pathname === '/history'
                                            ? gnbHistoryOn
                                            : gnbHistoryOff
                                    }
                                    alt="내 예약"
                                />
                            </FooterImg>
                            <span
                                style={{
                                    color: location.pathname === '/history' ? '#00bfa5' : '',
                                }}
                            >
                                내 예약
                            </span>
                        </FooterInner>
                    </NavLink>
                </FooterItem>
                <FooterItem>
                    <NavLink to="/my" state={{ previousUrl: location.pathname }}>
                        <FooterInner>
                            <FooterImg>
                                <img
                                    src={location.pathname === '/my' ? gnbMyOn : gnbMyOff}
                                    alt="MY"
                                />
                            </FooterImg>
                            <span
                                style={{
                                    color: location.pathname === '/my' ? '#00bfa5' : '',
                                }}
                            >
                                MY
                            </span>
                        </FooterInner>
                    </NavLink>
                </FooterItem>
            </FooterList>
        </FooterBar>
    );
};

export default BottomNavBar;
