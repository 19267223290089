import React, { useState } from 'react';
import {
    TermOver,
    TermHeader,
    Fz18Bold,
    TermModal,
    MyHeader,
    Paragraph,
    Container,
    ContainerPadding,
    Fz20Bold,
    NavHeight,
    TermItem2,
} from '@/css/style';
import { popOvers } from '../../utils/termData';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import arrowRightThinGray from '@/assets/img/arrow_right_thin_gray.svg';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import useMovePath from '../../hooks/useMovePath';

function LocationTerm() {
    const { navigate } = useOutletContext();
    const location = useLocation();

    return (
        <>
            {/* 약관 팝업 */}
            <TermOver style={{ display: 'block' }}>
                <TermModal>
                    <TermHeader>
                        <button
                            type="button"
                            onClick={() => {
                                if (!location.state?.previousUrl) {
                                    navigate('/', { state: { previousUrl: location.pathname } });
                                } else {
                                    navigate(-1, { state: { previousUrl: location.pathname } });
                                }
                            }}
                        >
                            <img src={arrowLeft} alt="뒤로" />
                        </button>
                    </TermHeader>
                    {/* <NavHeight /> */}
                    <Fz18Bold $mb="2.3077rem">{popOvers[3].title}</Fz18Bold>
                    {/* <Paragraph> */}
                    <div dangerouslySetInnerHTML={{ __html: popOvers[3].content }} />
                    {/* </Paragraph> */}
                </TermModal>
            </TermOver>
            {/* //약관 팝업 */}
        </>
    );
}
export default LocationTerm;
