import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Slide, FullImg } from '@/css/style';
import imgBanner1 from '@/assets/img/img_dummy.jpeg';

export default function SpotBanner() {
    const sliderStyle = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        customPaging: (i) => <span>{i + 1}</span>,
    };

    return (
        <Slider {...sliderStyle} className="slider--spotlight">
            <FullImg src={imgBanner1} alt="" />
            {/* <FullImg src={imgBanner1} alt="" />
            <FullImg src={imgBanner1} alt="" /> */}
        </Slider>
    );
}
