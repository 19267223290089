import axios from 'axios';
import { useMutation, useQuery } from '@tanstack/react-query';
import config from '@config';
import { useNavigate } from 'react-router-dom';

const instance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
});

const externalService = {
    getHolidays: async (year, month) => {
        const serviceKey = process.env.REACT_APP_HOLIDAY_API_KEY;

        return instance
            .get('http://apis.data.go.kr/B090041/openapi/service/SpcdeInfoService/getHoliDeInfo', {
                params: {
                    solYear: year,
                    solMonth: month,
                    ServiceKey: serviceKey,
                    _type: 'json',
                },
            })
            .then((res) => res.data);
    },
    kakaoLogin: async (code) => {
        return instance
            .post(`${config.API_SERVER_HOST}/auth/authenticate/kakao`, {
                code: code,
            })
            .then((res) => res.data);
    },
    naverLogin: async (code) => {
        return instance
            .post(`${config.API_SERVER_HOST}/auth/authenticate/naver`, {
                code: code,
            })
            .then((res) => res.data);
    },
    appleLogin: async (code) => {
        return instance
            .post(`${config.API_SERVER_HOST}/auth/authenticate/apple`, {
                code: code,
            })
            .then((res) => res.data);
    },
    callNiceCertScreen: async (obj) => {
        const userAgent = navigator.userAgent;
        const { form } = document;
        // const parentLeft = window.screenLeft || window.screenX || 0;
        // const parentTop = window.screenTop || window.screenY || 0;

        // const popupWidth = 500;
        // const popupHeight = 600;

        // const left = parentLeft + (window.innerWidth - popupWidth) / 2;
        // const top = parentTop + (window.innerHeight - popupHeight) / 2;

        // const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

        if (form) {
            const { enc_data, integrity_value, token_version_id } = obj;

            if (window.ReactNativeWebView) {
            } else {
                // window.open(config.CLIENT_HOST, 'nicePopup', option);
                form.target = 'nicePopup';
            }
            form.enc_data.value = enc_data;
            form.token_version_id.value = token_version_id;
            form.integrity_value.value = integrity_value;
            form.submit();
        }
    },
};

export const useGetHolidaysQuery = (year, month) => {
    return useQuery({
        queryKey: ['getHolidays', year, month],
        queryFn: () => externalService.getHolidays(year, month),
    });
};

export const useKakaoLoginMutation = (ls, us) => {
    const navigate = useNavigate();
    const redirect = localStorage.getItem('redirect');
    return useMutation({
        mutationFn: async (code) => {
            const response = await externalService.kakaoLogin(code);
            return response;
        },
        onSuccess: async (res) => {
            us.registUser(res);
            if (window.opener) {
                window.opener.postMessage({
                    success: true,
                    redirect: localStorage.getItem('redirect'),
                    res: res,
                });
                window.close(); // Close the popup window
            } else {
                redirect ? navigate(redirect, { replace: true }) : navigate('/', { replace: true });
            }
            return res;
        },
        onError: async (error) => {
            console.log(error);
            if (window.opener) {
                window.opener.postMessage({
                    success: false,
                });
                window.close();
            } else {
                ls.toggleModal('socialError');
                navigate('/login', { replace: true });
            }
        },
    });
};

export const useNaverLoginMutation = (ls, us) => {
    const navigate = useNavigate();
    const redirect = localStorage.getItem('redirect');
    return useMutation({
        mutationFn: async (code) => {
            const response = await externalService.naverLogin(code);
            return response;
        },
        onSuccess: async (res) => {
            us.registUser(res);
            if (window.opener) {
                window.opener.postMessage({
                    success: true,
                    redirect: localStorage.getItem('redirect'),
                    res: res,
                });
                window.close();
            } else {
                redirect ? navigate(redirect, { replace: true }) : navigate('/', { replace: true });
            }
            return res;
        },
        onError: async (error) => {
            console.log(error);
            if (window.opener) {
                window.opener.postMessage({
                    success: false,
                });
                window.close();
            } else {
                ls.toggleModal('socialError');
                navigate('/login', { replace: true });
            }
        },
    });
};

export const useAppleLoginMutation = (ls, us) => {
    const navigate = useNavigate();
    const redirect = localStorage.getItem('redirect');
    return useMutation({
        mutationFn: async (code) => {
            const response = await externalService.appleLogin(code);
            return response;
        },
        onSuccess: async (res) => {
            us.registUser(res);
            if (window.opener) {
                window.opener.postMessage({
                    success: true,
                    redirect: localStorage.getItem('redirect'),
                    res: res,
                });
                window.close();
            } else {
                redirect ? navigate(redirect, { replace: true }) : navigate('/', { replace: true });
            }
            return res;
        },
        onError: async (error) => {
            console.log(error);
            if (window.opener) {
                window.opener.postMessage({
                    success: false,
                });
                window.close();
            } else {
                ls.toggleModal('socialError');
                navigate('/login', { replace: true });
            }
        },
    });
};

export const useCallNiceCertScreenMutation = () => {
    return useMutation({
        mutationFn: async (obj) => {
            const response = await externalService.callNiceCertScreen(obj);
            return response;
        },
        onSuccess: async (res) => {
            console.log(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};
