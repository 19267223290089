import { instance } from '@services/api';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import useSocialStore from '../stores/user/social';

const userService = {
    bookMarkCreate: async (desginerId) => {
        const response = await instance.post(`/designers/${desginerId}/bookmark`);
        return response;
    },
    bookMarkDelete: (desginerId) => {
        return instance.delete(`/designers/${desginerId}/bookmark`);
    },

    bookMark: (userId) => {
        return instance.get(`/users/${userId}/bookmark/designer`);
    },
    userUpdate: (userId, params) => {
        return instance.put(`/users/${userId}`, params);
    },
    connectKakaoSocial: (user_id, code) => {
        console.log({ code: code });
        return instance.post(
            `/users/${user_id}/socials/kakao`,
            { code: code },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            }
        );
    },
    disconnectKakaoSocial: (user_id) => {
        return instance.delete(`/users/${user_id}/socials/kakao`);
    },
    connectNaverSocial: (user_id, code) => {
        return instance.post(
            `/users/${user_id}/socials/naver`,
            { code: code },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            }
        );
    },
    disconnectNaverSocial: (user_id) => {
        return instance.delete(`/users/${user_id}/socials/naver`);
    },
    connectAppleSocial: (user_id, id_token) => {
        return instance.post(
            `/users/${user_id}/socials/apple`,
            { id_token: id_token },
            {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
                },
            }
        );
    },
    disconnectAppleSocial: (user_id) => {
        return instance.delete(`/users/${user_id}/socials/apple`);
    },
};

export const useBookMarkCreateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (desingerId) => {
            const response = await userService.bookMarkCreate(desingerId);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useBookMarkDeleteMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (desingerId) => {
            const response = await userService.bookMarkDelete(desingerId);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useBookMarkQuery = (userId) => {
    return useQuery({
        queryKey: ['bookMark', userId],
        queryFn: () => userService.bookMark(userId),
    });
};

export const useUserUpdateMutation = (func) => {
    return useMutation({
        mutationFn: async (obj) => {
            console.log(obj);
            const response = await userService.userUpdate(obj.id, obj);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            func(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
            func(error);
        },
    });
};

export const useConnectKakaoSocialMutation = () => {
    const navigate = useNavigate();
    const ss = useSocialStore();
    return useMutation({
        mutationFn: async ({ user_id, code }) => {
            const response = await userService.connectKakaoSocial(user_id, code);
            return response;
        },
        onSuccess: async (res) => {
            if (window.ReactNativeWebView) {
                navigate('/my/sns', { replace: true });
            } else {
                window.opener.postMessage({
                    success: true,
                    social: 'kakao',
                });
                window.close();
            }

            return res;
        },
        onError: async (error) => {
            if (window.ReactNativeWebView) {
                ss.toggleModal('socialError');
                navigate('/my/sns', { replace: true });
            } else {
                window.opener.postMessage({
                    success: false,
                    social: 'kakao',
                });
                window.close();
            }
            console.log(error);

            console.log(error);
        },
    });
};

export const useDisconnectKakaoSocialMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (user_id) => {
            const response = await userService.disconnectKakaoSocial(user_id);
            return response;
        },
        onSuccess: async (res) => {
            onSuccess();
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useConnectNaverSocialMutation = () => {
    const navigate = useNavigate();
    const ss = useSocialStore();
    return useMutation({
        mutationFn: async ({ user_id, code }) => {
            const response = await userService.connectNaverSocial(user_id, code);
            return response;
        },
        onSuccess: async (res) => {
            if (window.ReactNativeWebView) {
                navigate('/my/sns', { replace: true });
            } else {
                window.opener.postMessage({
                    success: true,
                    social: 'naver',
                });
                window.close();
            }

            return res;
        },
        onError: async (error) => {
            if (window.ReactNativeWebView) {
                ss.toggleModal('socialError');
                navigate('/my/sns', { replace: true });
            } else {
                window.opener.postMessage({
                    success: false,
                    social: 'naver',
                });
                window.close();
            }
            console.log(error);
        },
    });
};

export const useDisconnectNaverSocialMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (user_id) => {
            const response = await userService.disconnectNaverSocial(user_id);
            return response;
        },
        onSuccess: async (res) => {
            onSuccess();
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useConnectAppleSocialMutation = () => {
    const navigate = useNavigate();
    const ss = useSocialStore();
    return useMutation({
        mutationFn: async ({ user_id, id_token }) => {
            const response = await userService.connectAppleSocial(user_id, id_token);
            return response;
        },
        onSuccess: async (res) => {
            if (window.ReactNativeWebView) {
                navigate('/my/sns', { replace: true });
            } else {
                window.opener.postMessage({
                    success: true,
                    social: 'apple',
                });
                window.close();
            }

            return res;
        },
        onError: async (error) => {
            if (window.ReactNativeWebView) {
                ss.toggleModal('socialError');
                navigate('/my/sns', { replace: true });
            } else {
                window.opener.postMessage({
                    success: false,
                    social: 'apple',
                });
                window.close();
            }

            console.log(error);
        },
    });
};

export const useDisconnectAppleSocialMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (user_id) => {
            const response = await userService.disconnectAppleSocial(user_id);
            return response;
        },
        onSuccess: async (res) => {
            onSuccess();
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};
