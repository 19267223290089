import React from 'react';
import { Fz16Medium, Mb15, Spacing100, TxCent } from '@/css/style';
import icWarnBig from '@/assets/img/ic_warn_big.svg';

export default function NoContent(props) {
    return (
        <>
            <Spacing100 />
            <TxCent>
                <Mb15>
                    <img src={icWarnBig} />
                </Mb15>
                <Fz16Medium $col="#A1A1A1">{props.message}</Fz16Medium>
            </TxCent>
            <Spacing100 />
        </>
    );
}
