import React from 'react';
import { BrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import reportWebVitals from '@/reportWebVitals';
import App from '@/App';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { NavermapsProvider } from 'react-naver-maps';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Router, { routerTest } from './utils/routes';

if (process.env.NODE_ENV === 'production') {
    console.info(`
    =========================
    FRONT_VERSION : ${process.env.REACT_APP_VERSION}
    =========================\n
  `);

    console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
queryClient.setDefaultOptions({
    queries: {
        retry: 0,
        refetchOnWindowFocus: false,
    },
});

root.render(
    // <React.StrictMode>
    <NavermapsProvider ncpClientId="t2kntuhk0e">
        <QueryClientProvider client={queryClient}>
            <App />
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    </NavermapsProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (process.env.NODE_ENV !== 'production') {
    reportWebVitals(console.log);
}
