import React, { useState, useEffect } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps, InfoWindow, Marker } from 'react-naver-maps';
import { MapLayout } from '@/css/style';
import useLocationStore from '@/stores/common/location';
import useShowStore from '@/stores/common/show';
import useAroundStore from '../stores/common/around';
import { useLocation, useOutletContext } from 'react-router-dom';
import config from '@config';
import useMovePath from '@/hooks/useMovePath';
import altimg from '@/assets/img/altimg.png';

function Map(data) {
    const navermaps = useNavermaps();
    const ls = useLocationStore();
    const ss = useShowStore();
    const as = useAroundStore();

    const [map, setMap] = useState(null);
    const [loading, setLoading] = useState(true);
    const [markers, setMarkers] = useState([]);
    const [openInfoWindow, setOpenInfoWindow] = useState(null);

    const navigate = useMovePath();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    useEffect(() => {
        window.goToDesigner = (id) => {
            navigate(`/designer/${id}`);
        };

        return () => {
            delete window.goToDesigner;
        };
    }, [navigate]);

    useEffect(() => {
        if (map) {
            markers.forEach((marker) => {
                marker.setMap(null);
            });

            setMarkers([]);

            if (ls.lat_map !== '' && ls.lng_map !== '') {
                setLoading(false);
                map.setCenter(new navermaps.LatLng(ls.lat_map, ls.lng_map));
            }

            const uniquePositions = {};

            data?.data?.forEach((item) => {
                // const positionKey = `${item.shop._id}`;
                const positionKey = `${item.shop.location.coordinates[1]}-${item.shop.location.coordinates[0]}`;
                if (!uniquePositions[positionKey]) {
                    uniquePositions[positionKey] = {
                        position: item.shop.location,
                        items: [],
                    };
                }
                uniquePositions[positionKey].items.push({
                    price: item.menus[0].price,
                    name: item.menus[0].name,
                    id: item._id,
                    designerImageUrl : !item.profileImage?.filename ? '/img/altimg.png' : `${config.DESIGNER_IMG_URL}${item.profileImage?.filename}?size=50`,
                    designerName: item.title,
                });
            });

            const newMarkers = Object.entries(uniquePositions).map(
                ([positionKey, positionInfo]) => {
                    const position = new navermaps.LatLng(
                        positionInfo.position.coordinates[1],
                        positionInfo.position.coordinates[0]
                    );

                    let markerContent;
                    if (positionInfo.items.length === 1) {
                        const item = positionInfo.items[0];
                        markerContent = `
                            <div style="position: relative;">
                                <div style="
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    overflow: hidden;
                                    background-color: white;
                                    border: 2px solid #ffffff;
                                    box-shadow: 0 2px 6px rgba(0,0,0,0.3);
                                ">
                                    <img src="${item.designerImageUrl}" alt="${item.designerName}" 
                                        onerror="this.onerror=null;this.src='${altimg}';"
                                        style="
                                        width: 100%;
                                        height: 100%;
                                        object-fit: cover;
                                    ">
                                </div>
                                <div style="
                                    position: absolute;
                                    top: 100%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    background-color: white;
                                    padding: 4px 8px;
                                    border-radius: 4px;
                                    box-shadow: 0 2px 6px rgba(0,0,0,0.3);
                                    white-space: nowrap;
                                    margin-top: 4px;
                                    font-size: 12px;
                                ">
                                    ${item.name}<br>
                                    <strong>${item.price.toLocaleString()}원</strong>
                                </div>
                            </div>
                        `;
                    } else {
                        markerContent = `
                            <div class="marker-cluster" style="
                                background-color: #ff5722;
                                color: white;
                                border-radius: 50%;
                                width: 35px;
                                height: 35px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-weight: bold;
                                cursor: pointer;
                                box-shadow: 0 2px 6px rgba(0,0,0,0.3);
                                font-size: 14px;
                            ">
                                ${positionInfo.items.length}
                            </div>
                        `;
                    }

                    const marker = new navermaps.Marker({
                        position: position,
                        map: map,
                        icon: {
                            content: markerContent,
                            size: new navermaps.Size(50, 50),
                            anchor: new navermaps.Point(25, 25),
                        },
                    });

                    if (positionInfo.items.length > 1) {
                        const listContent = positionInfo.items.map(item => `
                            <li style="
                                border-bottom: 1px solid #eee;
                                display: flex;
                                padding: 3px 6px;
                                align-items: center;
                                cursor: pointer;
                            " onclick="window.goToDesigner('${item.id}')">
                                <img src="${item.designerImageUrl}" alt="${item.designerName}" 
                                    onerror="this.onerror=null;this.src='${altimg}';"
                                    style="
                                    width: 35px;
                                    height: 35px;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                ">
                                <span style="font-size: 12px; color: #666;margin-right:5px">${item.name}</span>
                                <span style="font-weight: bold; font-size: 12px;">${item.price.toLocaleString()}원</span>
                            </li>
                        `).join('');

                        const infoWindow = new navermaps.InfoWindow({
                            content: `
                                <div id="infoWindow-${positionKey}" class="info-window-content" style="min-width: 200px; max-height: 250px; overflow-y: auto;">
                                    <ul style="list-style-type: none; padding-left: 0; margin: 0;">
                                        ${listContent}
                                    </ul>
                                </div>
                            `,
                            backgroundColor: "#fff",
                            borderColor: "#888",
                            borderWidth: 1,
                            anchorSize: new navermaps.Size(0, 0),
                            pixelOffset: new navermaps.Point(10, -10),
                        });
                    
                        let isInfoWindowOpen = false;
                    
                        const openInfoWindowHandler = () => {
                            if (openInfoWindow) {
                                openInfoWindow.close();
                            }
                            infoWindow.open(map, marker);
                            setOpenInfoWindow(infoWindow);
                            isInfoWindowOpen = true;
                        };

                        const closeInfoWindowHandler = () => {
                            infoWindow.close();
                            setOpenInfoWindow(null);
                            isInfoWindowOpen = false;
                        };
                    
                       navermaps.Event.addListener(marker, 'click', () => {
                            map.setCenter(marker.getPosition());
                            if (isInfoWindowOpen) {
                                closeInfoWindowHandler();
                            } else {
                                openInfoWindowHandler();
                            }
                       });
                    } else {
                        navermaps.Event.addListener(marker, 'click', function () {
                            map.setCenter(marker.getPosition());
                            if (openInfoWindow) {
                                openInfoWindow.close();
                                setOpenInfoWindow(null);
                            }
                            navigate(`/designer/${positionInfo.items[0].id}`);
                            // as.setSelectedDesignerId(positionInfo.items[0].id);
                        });
                    }

                    return marker;
                }
            );

            setMarkers(newMarkers);

            navermaps.Event.addListener(map, 'click', () => {
                if (openInfoWindow) {
                    openInfoWindow.close();
                    setOpenInfoWindow(null);
                }
            });

            const dragStartListener = navermaps.Event.addListener(map, 'dragstart', () => {
                if (openInfoWindow) {
                    openInfoWindow.close();
                    setOpenInfoWindow(null);
                }
            });
    
            const centerChangedListener = navermaps.Event.addListener(map, 'dragend', () => {
                const center = map.getCenter();
                ls.setField('lat_map', center.lat());
                ls.setField('lng_map', center.lng());
            });
    
            return () => {
                navermaps.Event.removeListener(dragStartListener);
                navermaps.Event.removeListener(centerChangedListener);
                newMarkers.forEach((marker) => {
                    marker.setMap(null);
                });
            };
        }
    }, [map, data, openInfoWindow]);

    useEffect(() => {
        if(map){
            map.setZoom(searchParams.get('zl') || 16);

            const zoomChangedListener = navermaps.Event.addListener(map, 'zoom_changed', function() {
                var newZoom = map.getZoom();
                const url = new URL(window.location);
                url.searchParams.set('zl', newZoom);
                window.history.pushState({}, '', url);
            });

            return () => {
                navermaps.Event.removeListener(zoomChangedListener);
            };
        }
    }, [map]);

    useEffect(() => {
        loading ? ss.setLottie(true) : ss.setLottie(false);
    }, [loading]);

    return (
        <MapLayout>
            <MapDiv
                style={{
                    width: '100%',
                    height: '100%',
                }}
            >
                <NaverMap
                    defaultZoom={16}
                    defaultMapTypeId={navermaps.MapTypeId.NORMAL}
                    ref={(ref) => setMap(ref)}
                >
                    <Marker
                        key={`${ls.lat_map}-${ls.lng_map}`}
                        position={new navermaps.LatLng(ls.lat_map, ls.lng_map)}
                    />
                </NaverMap>
            </MapDiv>
        </MapLayout>
    );
}

export default Map;