import { create } from 'zustand';

const validateField = (fieldName, state) => {
    switch (fieldName) {
        case 'time':
            return state.reserv.time !== '';
        case 'menu':
            return state.reserv.menu.length > 0;
        case 'date':
            return state.reserv.date !== '';
        case 'name':
            return state.reserv.name !== '';
        case 'phone':
            return state.reserv.phone !== '';
        case 'isAgree':
            return state.toggle.isAgree;
        default:
            return true;
    }
};

function updateReservation(state, key, value) {
    const updatedReservs = {
        ...state.reserv,
        [key]: value,
    };

    const updatedValidations = {
        ...state.validations,
        [state.currentScreen]: {
            ...state.validations[state.currentScreen],
            [key]: validateField(key, {
                ...state,
                reserv: updatedReservs,
            }),
        },
    };

    return {
        reserv: updatedReservs,
        validations: updatedValidations,
    };
}

const initialState = {
    reserv: {
        date: undefined,
        time: '',
        menu: [],
        memo: '',
    },
    category: [],
    selectedCategory: '',
    currentScreen: 'step1',
    currentValidate: 'step1',
    screenOrder: ['step1', 'step2'],
    validatesOrder: ['step1', 'step2'],
    screen: {
        step1: true,
        step2: false,
    },
    validations: {
        step1: {
            time: false,
            menu: false,
            date: false,
        },
        step2: {
            term: false,
            name: false,
            phone: false,
        },
    },
    toggle: {
        term1: false,
        term2: false,
        isOtherVisit: false,
        isAgree: false,
    },
    modal: {
        complete: false,
        fail: false,
        msg: '',
    },
};

const useReservationStore = create((set) => ({
    ...initialState,
    showModal: (select) => {
        set((state) => ({
            modal: {
                ...state.modal,
                complete: select === 'complete',
                fail: select === 'fail',
            },
        }));
    },
    setErr: (text) => {
        set((state) => ({
            modal: {
                ...state.modal,
                msg: text,
            },
        }));
    },

    setReserv: (key, value) => {
        return set((state) => updateReservation(state, key, value));
    },
    setReservFromServer: (obj) => {
        set((state) => {
            const updatedReservs = {
                ...state.reserv,
                ...obj,
            };

            const updatedValidations = {
                ...state.validations,
                step1: {
                    ...state.validations.step1,
                    time: validateField('time', {
                        ...state,
                        reserv: updatedReservs,
                    }),
                    menu: validateField('menu', {
                        ...state,
                        reserv: updatedReservs,
                    }),
                    date: validateField('date', {
                        ...state,
                        reserv: updatedReservs,
                    }),
                },
                step2: {
                    ...state.validations.step2,
                    name: validateField('name', {
                        ...state,
                        reserv: updatedReservs,
                    }),
                    phone: validateField('phone', {
                        ...state,
                        reserv: updatedReservs,
                    }),
                },
            };

            return {
                reserv: updatedReservs,
                validations: updatedValidations,
            };
        });
    },
    setMenu: (obj) => {
        return set((state) => {
            let updatedMenu = [...state.reserv.menu];

            const index = updatedMenu.findIndex((item) => item.id === obj.id);

            if (index === -1) {
                updatedMenu.push(obj);
            } else {
                updatedMenu = updatedMenu.filter((item) => item.id !== obj.id);
            }

            // 여기서 필요한 정보를 업데이트하거나 처리
            return updateReservation(state, 'menu', updatedMenu);
        });
    },

    setCategory: (id) => {
        set((state) => {
            // 선택된 카테고리를 찾기 위해 find 함수를 사용합니다.
            const updatedCategory = state.category.map((category) => {
                // 주어진 id와 카테고리의 id가 일치하면 해당 카테고리의 selected를 true로 설정합니다.
                if (category.id === id) {
                    return { ...category, selected: true };
                } else {
                    return { ...category, selected: false };
                }
            });

            return {
                ...state,
                category: updatedCategory,
                selectedCategory: id, // 선택된 카테고리의 id를 저장합니다.
            };
        });
    },
    setCategoryFromServer: (arr) => {
        const updatedCategory = arr.map((item) => ({ ...item, selected: false }));

        const categoriesWithAll = [{ name: '전체', id: '', selected: true }, ...updatedCategory];

        set((state) => ({
            category: categoriesWithAll,
        }));
    },
    setCurrentScreen: (fieldName) => {
        set({ currentScreen: fieldName });
    },

    setCurrentValidate: (fieldName) => {
        set({ currentValidate: fieldName });
    },

    setScreen: (fieldName, value) => {
        set((state) => ({
            screen: {
                ...state.screen,
                [fieldName]: value,
            },
        }));
    },
    toggleState: (select) => {
        set((state) => {
            const updatedValidations = { ...state.validations };

            if (select === 'isAgree') {
                // isAgree가 true일 때
                updatedValidations.step2.term = !state.toggle[select];
            }

            return {
                toggle: {
                    ...state.toggle,
                    [select]: !state.toggle[select],
                },
                validations: updatedValidations,
            };
        });
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useReservationStore;
