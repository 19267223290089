import React, { useEffect, useRef, useState, useCallback } from 'react';
import Draggable from 'react-draggable';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
    ContainerMap,
    ContainerPadding,
    BtnAroundFilter,
    PopOver,
    Spacing20,
    FlxbtFullMb40,
    Fz18Bold,
    HalfField,
    AbsolBtm,
    BtnFullModal,
    BtnResetFilter,
    FlxMiddle,
    Fz15Bold,
    JoinField,
    PadVert10,
    InputWrapper,
    Select,
    AroundList,
    RsvTab,
    TabItem,
    JoinInputPlc15,
    Fz15,
    TabAccent2,
    Pad20,
    ItemDesigner,
    CircleImg,
    RoundProfile,
    DesignerInfo,
    DesignerName,
    FullImg,
    FlxMiddleMb15,
    Gap10,
    AroundScroll,
    FlxGap10,
    FlxbtFull,
    ColBlack,
    FullLine,
    ColAccent,
    SwipeUpContainer,
    SwipeUpHandle,
    Divider,
    TabWrapper,
    DesignerSns,
    FlxMiddleMb10,
    FlxbtFullMb15,
    PopShortMsg,
    Mb20,
    Mb5,
    ListCol2,
    BtnHalfModalBlack,
    Paragraph,
    DesignerPrices,
    PriceItem,
    Fz16Medium,
    TxRight,
    ConsumerPrice,
    OrgPrice,
    BtnToggleAccent,
    DesignerRates,
    Flxbt,
} from '@/css/style';
import { onlyNum } from '@/utils/helper';
import { useBookMarkCreateMutation, useBookMarkDeleteMutation } from '@/services/userService';
import { useDesignerMapQuery } from '@/services/designerService';
import { useProductCategoryQuery } from '@/services/productService';
import useAroundStore from '@/stores/common/around';
import useLocationStore from '@/stores/common/location';
import Map from '@/components/Map';
import NewModal from '@/components/NewModal';
import config from '@config';
import altimg from '@/assets/img/altimg.png';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import icUpHandle from '@/assets/img/arrow_up_thin_gray.svg';
import icDownHandle from '@/assets/img/arrow_down_thin_gray.svg';
import icReset from '@/assets/img/ic_reset.svg';
import icReview from '@/assets/img/ic_review.svg';
import icSetting from '@/assets/img/ic_setting.svg';
import { FaStar } from 'react-icons/fa';
import { getShopDistance, snsTable } from '@/utils/helper';
import arrowUpWhite from '@/assets/img/arrow_up_wh.svg';
import arrowDownGray from '@/assets/img/arrow_down_gray.svg';
import NoContent from '@/components/NoContent';
import DesignerAvaliableTime from '@/components/DesignerAvaliableTime';
import useMovePath from '@/hooks/useMovePath';
import { TxCent } from '../../css/style';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: ${props => props.$visible ? 'block' : 'none'};
`;

function Around() {
    const as = useAroundStore();
    const ls = useLocationStore();
    const [params, setParams] = useState({});
    const [tempFilter, setTempFilter] = useState({
        distance: 5,
        category: as.filter.category,
        minPrice: as.filter.minPrice,
        maxPrice: as.filter.maxPrice,
    });
    const { data: productCategoryData } = useProductCategoryQuery();
    const BookMarkCreate = useBookMarkCreateMutation(() => refetch());
    const BookMarkDelete = useBookMarkDeleteMutation(() => refetch());
    const searchParams = new URLSearchParams(window.location.search);

    const [visibleDesigners, setVisibleDesigners] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const [isDraggedToBounds, setIsDraggedToBounds] = useState(false);
    const designersPerPage = 10;

    const {
        data: designerMapData,
        refetch,
        isFetching,
    } = useDesignerMapQuery(params);

    useEffect(() => {
        if (designerMapData && designerMapData.data.docs && isDraggedToBounds) {
            const startIndex = (page - 1) * designersPerPage;
            const endIndex = page * designersPerPage;
            const newDesigners = designerMapData.data.docs.slice(startIndex, endIndex);
            
            if (page === 1) {
                setVisibleDesigners(newDesigners);
            } else {
                setVisibleDesigners(prev => [...prev, ...newDesigners]);
            }

            setHasMore(endIndex < designerMapData.data.docs.length);
        }
    }, [designerMapData, page, isDraggedToBounds]);

    const loadMoreDesigners = useCallback(() => {
        if (hasMore && !isFetching && isDraggedToBounds) {
            setPage(prevPage => prevPage + 1);
        }
    }, [hasMore, isFetching, isDraggedToBounds]);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasMore && isDraggedToBounds) {
            loadMoreDesigners();
        }
    }, [hasMore, loadMoreDesigners, isDraggedToBounds]);


    useEffect(() => {
        const options = {
            root: null,
            rootMargin: "30px",
            threshold: 0.1
        };

        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }

        return () => {
            if (loader.current) {
                observer.unobserve(loader.current);
            }
        };
    }, [handleObserver]);

    useEffect(() => {
        if (productCategoryData) {
            const category = searchParams.get('category');
            const lat = searchParams.get('lat');
            const lng = searchParams.get('lng');
            const sort = searchParams.get('sort');
            const maxdistance = searchParams.get('maxdistance');
            const minprice = searchParams.get('minprice');
            const maxprice = searchParams.get('maxprice');

            const filter = {
              ...as.filter,
            }

            if (lat && lng) {
                filter.lat_map = parseFloat(lat);
                filter.lng_map = parseFloat(lng);
            } else {
                ls.getLocationMap();
            }

            if (sort) as.toggleOrder(sort);

            if (category) {
                const categories = category.split(',');
                as.setCategory(
                    productCategoryData.data.map((item) => ({
                        ...item,
                        isActive: categories.includes(item.name),
                    }))
                );
                filter.category = productCategoryData.data.map((item) => ({
                    ...item,
                    isActive: categories.includes(item.name),
                }))
            } else {
                as.setCategory(productCategoryData.data);    
                filter.category = productCategoryData.data.map((item) => ({
                    ...item,
                    isActive: false,
                }))
            }     

            filter.distance =  maxdistance ? parseFloat(maxdistance) : 5;
            filter.minPrice = minprice ? parseFloat(minprice) : '';
            filter.maxPrice = maxprice ? parseFloat(maxprice) : '';

            as.setAllFilter(filter);                  
        }
    }, [productCategoryData]);

    useEffect(() => {
        setParams({
            lat: ls.lat_map,
            lng: ls.lng_map,
            maxdistance: as.filter.distance,
            minprice: as.filter.minPrice,
            maxprice: as.filter.maxPrice,
            category: as.filter.category
                .filter((item) => item.isActive)
                .map((item) => item.name)
                .join(', '),
            sort: as.selectedOrder,
        })
    }, [ls.lat_map, ls.lng_map, as.selectedOrder, as.filter.category, as.filter.distance, as.filter.minPrice, as.filter.maxPrice]);

    useEffect(() => {
        const url = new URL(window.location);
        url.searchParams.set('lat', ls.lat_map);
        url.searchParams.set('lng', ls.lng_map);
        url.searchParams.set('sort', as.selectedOrder);
        window.history.replaceState({}, '', url);
    }, [ls.lat_map, ls.lng_map, as.selectedOrder]);

    const requestFilter = () => {
        const url = new URL(window.location);
        url.searchParams.set('category', tempFilter.category
            .filter((item) => item.isActive)
            .map((item) => item.name)
            .join(','));
        url.searchParams.set('maxdistance', tempFilter.distance);
        url.searchParams.set('minprice', tempFilter.minPrice);
        url.searchParams.set('maxprice', tempFilter.maxPrice);
        window.history.replaceState({}, '', url);

        as.setAllFilter(tempFilter);

        as.showModal(false);
    };

    const navigate = useMovePath();

    const handleToggleDraggablePosition = () => {
        if (!isDraggedToBounds) {
           resetList();
        }
        setIsDraggedToBounds(!isDraggedToBounds);
    };

    const resetList = useCallback(() => {
        setPage(1);
        setVisibleDesigners([]);
        setHasMore(true);
    }, []);

    const handleSortChange = useCallback((sortOption) => {
        as.toggleOrder(sortOption);
        resetList();
        refetch();
    }, [as, resetList, refetch]);

    const openFilterModal = () => {
        setTempFilter({
            ...as.filter,   
            category: JSON.parse(JSON.stringify(as.filter.category))
        });
        as.showModal('filter');
    };

    const closeFilterModal = () => {
        setTempFilter({
            ...as.filter,   
            category: JSON.parse(JSON.stringify(as.filter.category))
        });
        as.showModal(false);
    };

    return (
        <>
            <ContainerMap>
                <BtnAroundFilter
                    onClick={openFilterModal}
                    style={{ zIndex: 99 }}
                >
                    <img src={icSetting} /> 맞춤 검색
                </BtnAroundFilter>
                <Map data={designerMapData?.data.docs}/>
                <Draggable
                    axis="y"
                    bounds={{ top: (-window.innerHeight * 80) / 100, right: 0, bottom: 0, left: 0 }}
                    position={{ x: 0, y: 0 }}
                    disabled={true}
                >
                    <SwipeUpContainer $isDraggedToBounds={isDraggedToBounds}>
                        <SwipeUpHandle onClick={handleToggleDraggablePosition}>
                            <img
                                src={!isDraggedToBounds ? icUpHandle : icDownHandle}
                                alt="드래그"
                                width={28}
                            />
                            <TxCent style={{color: 'rgb(255, 48, 98)', height: 13}}>
                                {designerMapData?.data.docs.length > 0 && <>
                                    {!isDraggedToBounds
                                    ? '검색된 디자이너 보기'
                                    : '검색된 디자이너 닫기'}
                                </>}
                            </TxCent>
                        </SwipeUpHandle>
                        <Pad20>
                            <TabWrapper>
                                <TabAccent2
                                    $active={as.order.price}
                                    onClick={() => handleSortChange('price')}
                                >
                                    가격낮은순
                                </TabAccent2>
                                ・
                                <TabAccent2
                                    $active={as.order.distance}
                                    onClick={() => handleSortChange('distance')}
                                >
                                    거리
                                </TabAccent2>
                                ・
                                <TabAccent2
                                    $active={as.order.rating}
                                    onClick={() => handleSortChange('rating')}
                                >
                                    인기
                                </TabAccent2>
                            </TabWrapper>
                        </Pad20>
                        <Divider />
                        {isDraggedToBounds && (
                            <AroundScroll>
                                <AroundList>
                                    {!(visibleDesigners.length > 0) && !isFetching && (
                                        <NoContent message="현재 위치에 있는 디자이너가 없습니다." />
                                    )}
                                    {visibleDesigners.map((designer) => (
                                        <ItemDesigner key={designer._id}>
                                                    <FullLine $mb="1rem">
                                                        {designer.distance && (
                                                            <ColAccent>
                                                                {getShopDistance(
                                                                    designer.distance
                                                                )}
                                                            </ColAccent>
                                                        )}
                                                        <strong>
                                                            &nbsp;{designer.shop.name}
                                                        </strong>
                                                    </FullLine>
                                                    <RoundProfile
                                                        style={{
                                                            border:
                                                                as.selectedDesignerId.includes(
                                                                    designer._id
                                                                ) && '3px solid #00BF9D',
                                                        }}
                                                    >
                                                        <FullImg
                                                            src={!designer.profileImage?.filename ? '/img/altimg.png' : `${config.DESIGNER_IMG_URL}${designer.profileImage?.filename}?size=150`}
                                                            onError={(e) => {
                                                                e.currentTarget.src = altimg;
                                                            }}
                                                            onClick={() => {
                                                                navigate(
                                                                    `/designer/${designer._id}`
                                                                );
                                                            }}
                                                            alt="프로필 이미지"
                                                        />
                                                    </RoundProfile>
                                                    <DesignerInfo>
                                                        <FlxbtFullMb15>
                                                            <DesignerName
                                                                onClick={() => {
                                                                    navigate(
                                                                        `/designer/${designer._id}`
                                                                    );
                                                                }}
                                                            >
                                                                {designer.title}
                                                            </DesignerName>
                                                            <FlxMiddle>
                                                                {designer.sns.map(
                                                                    (sns) => (
                                                                        <DesignerSns
                                                                            href={sns.link}
                                                                            key={sns._id}
                                                                        >
                                                                            <img
                                                                                src={
                                                                                    snsTable.find(
                                                                                        (item) =>
                                                                                            item.provider ===
                                                                                            sns.provider
                                                                                    ).src
                                                                                }
                                                                                alt={sns.provider}
                                                                            />
                                                                        </DesignerSns>
                                                                    )
                                                                )}
                                                            </FlxMiddle>
                                                        </FlxbtFullMb15>
                                                        <FlxMiddleMb15>
                                                            {designer.menus[0].name}
                                                            <Gap10 />
                                                            <ColBlack as="strong">
                                                            {designer.menus[0].price.toLocaleString()}원
                                                            </ColBlack>
                                                        </FlxMiddleMb15>
                                                        <Flxbt as={Mb5}>
                                                            <DesignerAvaliableTime designerId={designer._id} />
                                                        </Flxbt>
                                                        <FlxbtFull>
                                                            <DesignerRates>
                                                                <img src={icReview} alt="후기" />
                                                                <strong>
                                                                    {
                                                                        designer
                                                                            .reviewCount
                                                                    }
                                                                </strong>
                                                                <FaStar color="#FFBB00" />
                                                                <strong>
                                                                    {designer.rating
                                                                        ? designer.averageRating.toFixed(
                                                                              1
                                                                          )
                                                                        : 0}
                                                                </strong>
                                                            </DesignerRates>
                                                            {/* {designer
                                                                .isBookmarked ? (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        BookMarkDelete.mutate(
                                                                            designer
                                                                                ._id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={icLikeOn}
                                                                        alt="좋아요"
                                                                    />
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    type="button"
                                                                    onClick={() => {
                                                                        BookMarkCreate.mutate(
                                                                            designer
                                                                                ._id
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={icLikeOffFill}
                                                                        alt="좋아요"
                                                                    />
                                                                </button>
                                                            )} */}
                                                            <BtnToggleAccent
                                                                $active={
                                                                    as.toggleStates[
                                                                        designer._id
                                                                    ]
                                                                }
                                                                onClick={() =>
                                                                    as.togglePrices(
                                                                        designer._id
                                                                    )
                                                                }
                                                            >
                                                                시술메뉴{' '}
                                                                {as.toggleStates[designer._id]
                                                                    ? '닫기'
                                                                    : '보기'}
                                                                <img
                                                                    src={
                                                                        as.toggleStates[
                                                                            designer._id
                                                                        ]
                                                                            ? arrowUpWhite
                                                                            : arrowDownGray
                                                                    }
                                                                    alt="시술메뉴 토글"
                                                                />
                                                            </BtnToggleAccent>
                                                        </FlxbtFull>
                                                    </DesignerInfo>
                                                    {designer.menus &&
                                                        designer.menus.length > 0 &&
                                                        as.toggleStates[designer._id] && (
                                                            <DesignerPrices $mt="1.154rem">
                                                                {designer.menus.map((menus) => (
                                                                    <PriceItem key={menus._id}>
                                                                        <FlxbtFull
                                                                            $mb={
                                                                                menus.option
                                                                                    ? '0.77rem'
                                                                                    : 0
                                                                            }
                                                                        >
                                                                            <Fz16Medium>
                                                                                {menus.name}
                                                                            </Fz16Medium>

                                                                            {menus.normal_price ===
                                                                            menus.price ? (
                                                                                <TxRight>
                                                                                    <ConsumerPrice>
                                                                                        {
                                                                                            menus.normal_price.toLocaleString()
                                                                                        }
                                                                                        원
                                                                                    </ConsumerPrice>
                                                                                </TxRight>
                                                                            ) : (
                                                                                <TxRight>
                                                                                    {menus.price !=
                                                                                        0 && (
                                                                                        <OrgPrice>
                                                                                            {
                                                                                                menus.normal_price.toLocaleString()
                                                                                            }
                                                                                        </OrgPrice>
                                                                                    )}
                                                                                    <ConsumerPrice>
                                                                                        {menus.price ==
                                                                                        0
                                                                                            ? '무료'
                                                                                            : `${menus.price.toLocaleString()}원`}
                                                                                    </ConsumerPrice>
                                                                                </TxRight>
                                                                            )}
                                                                        </FlxbtFull>
                                                                        {menus.option ? (
                                                                            <p>{menus.option}</p>
                                                                        ) : null}
                                                                    </PriceItem>
                                                                ))}
                                                 </DesignerPrices>
                                              )}
                                        </ItemDesigner>
                                    ))}
                                    {hasMore && <div ref={loader}></div>}
                                </AroundList>
                            </AroundScroll>
                        )}
                    </SwipeUpContainer>
                </Draggable>
            </ContainerMap>
            <Overlay $visible={isDraggedToBounds} onClick={handleToggleDraggablePosition} />
            {/* 필터 팝업 */}
            <PopOver $visible={as.modal.filter}>
                <ContainerPadding>
                    <Spacing20 />
                    <form>
                        <FlxbtFullMb40>
                            <BtnResetFilter
                                onClick={() => {
                                    setTempFilter({
                                        distance: 5,
                                        category: as.filter.category.map((item) => ({
                                            ...item,
                                            isActive: false,
                                        })),
                                        minPrice: '',
                                        maxPrice: '',
                                    });
                                }}
                            >
                                <img src={icReset} /> 초기화
                            </BtnResetFilter>
                            <button
                                type="button"
                                onClick={closeFilterModal}
                            >
                                <img src={icCloseModal} alt="닫기" />
                            </button>
                        </FlxbtFullMb40>
                        {/* 검색 노출 거리 */}
                        <Fz18Bold $mb="2.3077rem">맞춤 검색</Fz18Bold>
                        <JoinField $mb="3.077rem">
                            <PadVert10>
                                <Fz15Bold>노출거리</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <Select
                                    onChange={(e) => setTempFilter({...tempFilter, distance: e.target.value})}
                                    value={tempFilter.distance || 5}
                                    defaultValue={tempFilter.distance || 5}
                                >
                                    <option value="0.5">0.5km</option>
                                    <option value="1">1km</option>
                                    <option value="1.5">1.5km</option>
                                    <option value="2">2km</option>
                                    <option value="2.5">2.5km</option>
                                    <option value="3">3km</option>
                                    <option value="3.5">3.5km</option>
                                    <option value="4">4km</option>
                                    <option value="4.5">4.5km</option>
                                    <option value="5">5km</option>
                                </Select>
                            </InputWrapper>
                        </JoinField>
                        {/* //검색 노출 거리 */}
                        {/* 카테고리 */}
                        <PadVert10>
                            <Fz15Bold>카테고리</Fz15Bold>
                        </PadVert10>
                        <RsvTab>
                            {productCategoryData?.data.map((item, index) => (
                                <TabItem
                                    $active={tempFilter.category[index]?.isActive}
                                    value={item.id}
                                    key={item.id}
                                    onClick={() => {
                                        const newCategory = [...tempFilter.category];
                                        newCategory[index].isActive = !newCategory[index].isActive;
                                        setTempFilter({...tempFilter, category: newCategory});
                                    }}
                                >
                                    {item.name}
                                </TabItem>
                            ))}
                        </RsvTab>
                        {/* //카테고리 */}
                        {/* 최대 가격 */}
                        <HalfField style={{
                            marginBottom: '2.3077rem'
                        }}>
                            <PadVert10>
                                <Fz15Bold>가격(원)</Fz15Bold>
                            </PadVert10>
                            <FlxMiddle style={{display:'flex', gap: 5}}>
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        value={tempFilter.minPrice}
                                        defaultValue={tempFilter.minPrice}
                                        maxLength="12"
                                        onChange={(e) =>
                                            setTempFilter({...tempFilter, minPrice: onlyNum(e.target.value)})
                                        }                                    
                                        style={{width: '100%'}}                                   
                                    />
                                </InputWrapper>
                                ~<Gap10  />
                                <InputWrapper>
                                    <JoinInputPlc15
                                        type="text"
                                        value={tempFilter.maxPrice}
                                        defaultValue={tempFilter.maxPrice}
                                        maxLength="12"
                                        onChange={(e) =>
                                            setTempFilter({...tempFilter, maxPrice: onlyNum(e.target.value)})
                                        }
                                        style={{width: '100%'}}                                   
                                    />
                                </InputWrapper>
                            </FlxMiddle>
                        </HalfField>
                        {/* 메모: around에서만 footer zIndex:121 */}
                        {/* <AbsolBtm> */}
                            <BtnFullModal
                                type="button"
                                $active
                                onClick={() => {
                                    requestFilter();
                                }}
                            >
                                적용하기
                            </BtnFullModal>
                        {/* </AbsolBtm> */}
                        {/* //최대 가격 */}
                    </form>
                </ContainerPadding>
            </PopOver>
            <NewModal isOpen={ls.modal.reject} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">내 위치 설정</Fz15Bold>
                        </Mb20>
                        <Paragraph $fz="1.154rem">
                            사용 기기의 설정에서 '위치정보' 사용을
                            <br />
                            허용해 주시기 바랍니다.
                        </Paragraph>
                    </PopShortMsg>
                    <BtnFullModal
                        $active
                        onClick={() => {
                            ls.toggleModal('reject');
                            navigate('/');
                        }}
                    >
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>

            {/* //필터 팝업 */}
        </>
    );
}
const sliderSetting = {
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
};
export default Around;
