import React from 'react';
import { InputHidden } from '@/css/style';
import chkHandle from '@/assets/img/chk_handle2.svg';
const ChkOnOffShort = (props) => {
    const labelStyle = {
        position: 'relative',
        display: 'flex',
        width: 52,
        height: 29,
        borderRadius: '3rem',
        backgroundColor: props.checked ? `#00BF9D` : `#E8EAED`,
        color: props.checked ? `#fff` : `#757575`,
        transition: 'all .4s',
    };
    const handleStyle = {
        position: 'absolute',
        top: 0,
        left: props.checked ? 22 : 0,
        width: 29,
        minWidth: 29,
        height: 29,
        transition: 'all .4s',
        zIndex: 2,
    };
    const chkId = props.id;

    return (
        <label htmlFor={chkId} style={labelStyle}>
            <InputHidden
                type="checkbox"
                id={chkId}
                name={chkId}
                checked={props.checked}
                onChange={props.onChange}
                onClick={props.onClick}
                disabled={props.disabled}
            />
            <img src={chkHandle} style={handleStyle} />
        </label>
    );
};
export default ChkOnOffShort;
