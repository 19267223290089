import React, { useEffect, useState } from 'react';
import '@/css/slick_custom.css';
import {
    BtnFullModal,
    BtnLineGreenShorter,
    FieldSet,
    BtnToggleAccent2,
    PopOver,
    Mb30,
    FlxMiddle,
    TabAccent,
    Gap10,
    NavHeight,
    ListCol2,
    StickyBtm,
    LinkFull,
    Paragraph,
    Fz15,
    Fz18Medium,
    Container,
    FlxbtFull,
    Mb20,
    Fz15Bold,
    ContainerPadding,
    FlxMiddleMb20,
    FlxbtFullMb10,
    BtnInInputRound,
    Pad20,
    PopShortMsg,
    Spacing30,
    SectionDivider,
    ColAccent2,
    ColAccent,
    BtnHalfModalBlack,
    BtnHalfModal,
    Col2Grid,
    OffList,
    ChkAndTxt,
    Spacing70,
    BtnCloseModal,
    FlxbtFullMb50,
} from '@/css/style';
import { getDay, getWeekOfMonth, format, parse } from 'date-fns';
import { getDisabledDates } from '@/utils/helper';
import {
    useDesignerDetailQuery,
    useDesignerCreateHolidayByDateMutation,
    useDesignerUpdateHolidayByDayMutation,
    useDesignerCreateHolidayByWorkMutation,
    useDesignerDeleteHolidayByDateMutation,
    useDesignerDeleteHolidayByWorkMutation,
} from '@/services/designerService';
import useDayoffsStore from '@/stores/designer/dayoffs';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import NewModal from '@/components/NewModal';
import RsvDayPicker from '@/components/DayPicker';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import ChkOnOff from '@/components/ChkOnOff';

function DayOffs() {
    const ds = useDayoffsStore();
    const us = useUserStore();
    const { data: designerData, refetch: designerDataRefetch } = useDesignerDetailQuery(us.user.id);
    const designerUpdateHolidayByDayMutation = useDesignerUpdateHolidayByDayMutation((res) => {
        designerDataRefetch();
        ds.toggleModal('constantHolidayConfirm');
    });
    const designerCreateHolidayByDateMutation = useDesignerCreateHolidayByDateMutation(() => {
        designerDataRefetch();
        ds.setClosedDaysDate();
    });
    const designerCreateHolidayByWorkMutation = useDesignerCreateHolidayByWorkMutation(() => {
        designerDataRefetch();
        ds.setClosedDaysDate();
    });
    const designerDeleteHolidayByDateMutation = useDesignerDeleteHolidayByDateMutation(() => {
        designerDataRefetch();
        ds.setClosedDaysDate();
    });
    const designerDeleteHolidayByWorkMutation = useDesignerDeleteHolidayByWorkMutation(() => {
        designerDataRefetch();
        ds.setClosedDaysDate();
    });

    useEffect(() => {
        let firstDayOfMonth;
        if (!ds.daypicker.currentMonth) {
            firstDayOfMonth = new Date();
            firstDayOfMonth.setDate(1);
        } else {
            firstDayOfMonth = ds.daypicker.currentMonth;

            firstDayOfMonth.setDate(1);
        }

        getDisabledDates(firstDayOfMonth, designerData, ds);
        if (designerData) {
            ds.setWeekTable(designerData?.data.holidayByDay);
            ds.setSelected(designerData.data.holidayByDay);
        }
    }, [designerData]);

    const requestClosedDays = () => {
        if (
            !(
                (Object.values(ds.isSelected).some(Boolean) &&
                    Object.values(ds.weekTable).some(Boolean)) ||
                (Object.values(ds.isSelected).every((val) => !val) &&
                    Object.values(ds.weekTable).every((val) => !val))
            )
        ) {
            return;
        }
        const selectedWeeks = Object.keys(ds.weekTable)
            .filter((week) => ds.weekTable[week])
            .map((week) => parseInt(week));

        const selectedDays = [];

        for (let i = 0; i < 7; i++) {
            if (ds.isSelected[i]) {
                selectedDays.push({
                    day: i,
                    week: [...selectedWeeks],
                });
            }
        }

        designerUpdateHolidayByDayMutation.mutate({ designerId: us.user.id, array: selectedDays });
    };

    const requestClosedDates = () => {
        if (!ds.closedDays.date) {
            return;
        }

        if (ds.closedDays.date instanceof Date) {
            const formattedDate = format(ds.closedDays.date, 'yyyy-MM-dd');

            // 근무일인지 확인
            const isWorkDate = designerData?.data.workDate.some((workDate) => {
                return (
                    format(parse(workDate.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') ===
                    formattedDate
                );
            });

            const isFixedHoliday = ds.daypicker.holidayByDay.some(
                (holiday) => holiday === formattedDate
            );

            const isHoliday = designerData?.data.holidayByDate.some(
                (fixedHoliday) =>
                    format(parse(fixedHoliday.date, 'yyyy-MM-dd', new Date()), 'yyyy-MM-dd') ===
                    formattedDate
            );

            if (isWorkDate) {
                designerDeleteHolidayByWorkMutation.mutate({
                    designerId: us.user.id,
                    date: formattedDate,
                });
            } else if (isHoliday) {
                designerDeleteHolidayByDateMutation.mutate({
                    designerId: us.user.id,
                    date: formattedDate,
                });
            } else if (isFixedHoliday) {
                // 고정휴무일이면 근무일 추가
                designerCreateHolidayByWorkMutation.mutate({
                    designerId: us.user.id,
                    date: formattedDate,
                });
            } else {
                designerCreateHolidayByDateMutation.mutate({
                    designerId: us.user.id,
                    date: formattedDate,
                });
            }
        }
    };

    if (designerData) {
        return (
            <>
                <Container $pos="relative">
                    <ContainerPadding>
                        <FlxMiddleMb20>
                            <TabAccent
                                $active={ds.category.day}
                                onClick={() => ds.toggleCategory('day')}
                            >
                                고정 휴무일 설정
                            </TabAccent>
                            <Gap10 />
                            <TabAccent
                                $active={ds.category.date}
                                onClick={() => ds.toggleCategory('date')}
                            >
                                유동 휴무일 설정
                            </TabAccent>
                        </FlxMiddleMb20>
                    </ContainerPadding>
                    <form>
                        {/* 요일별 설정 탭 컨텐츠 */}
                        <FieldSet $visible={ds.category.day}>
                            <ContainerPadding>
                                {/* <Fz18Medium $mb="1.538rem">고정 휴무일 설정 </Fz18Medium> */}
                                {/* <Spacing30 /> */}
                                <Col2Grid>
                                    <ChkAndTxt htmlFor="everyWeek1">
                                        <Chkbox
                                            id="everyWeek1"
                                            name="everyWeek1"
                                            checked={ds.weekTable[1]}
                                            onChange={() => {
                                                ds.toggleWeekTable(1);
                                            }}
                                        />
                                        첫째 주
                                    </ChkAndTxt>
                                    <ChkAndTxt htmlFor="everyWeek2">
                                        <Chkbox
                                            id="everyWeek2"
                                            name="everyWeek2"
                                            checked={ds.weekTable[2]}
                                            onChange={() => {
                                                ds.toggleWeekTable(2);
                                            }}
                                        />
                                        둘째 주
                                    </ChkAndTxt>
                                    <ChkAndTxt htmlFor="everyWeek3">
                                        <Chkbox
                                            id="everyWeek3"
                                            name="everyWeek3"
                                            checked={ds.weekTable[3]}
                                            onChange={() => {
                                                ds.toggleWeekTable(3);
                                            }}
                                        />
                                        셋째 주
                                    </ChkAndTxt>
                                    <ChkAndTxt htmlFor="everyWeek4">
                                        <Chkbox
                                            id="everyWeek4"
                                            name="everyWeek4"
                                            checked={ds.weekTable[4]}
                                            onChange={() => {
                                                ds.toggleWeekTable(4);
                                            }}
                                        />
                                        넷째 주
                                    </ChkAndTxt>
                                    <ChkAndTxt htmlFor="everyWeek5">
                                        <Chkbox
                                            id="everyWeek5"
                                            name="everyWeek5"
                                            checked={ds.weekTable[5]}
                                            onChange={() => {
                                                ds.toggleWeekTable(5);
                                            }}
                                        />
                                        다섯째 주
                                    </ChkAndTxt>
                                    <ChkAndTxt htmlFor="everyWeek6">
                                        <Chkbox
                                            id="everyWeek6"
                                            name="everyWeek6"
                                            checked={ds.weekTable[6]}
                                            onChange={() => {
                                                ds.toggleWeekTable(6);
                                            }}
                                        />
                                        여섯째 주
                                    </ChkAndTxt>
                                </Col2Grid>
                            </ContainerPadding>
                            <SectionDivider />
                            <ContainerPadding>
                                <FlxbtFullMb10>
                                    <Fz15Bold>월요일</Fz15Bold>
                                    <FlxMiddle>
                                        {/* {ds.isSelected[1] ? (
                                            <Fz15 $col="#FF3062">
                                                {Object.values(ds.weekTable).every((val) => val)
                                                    ? '매주'
                                                    : Object.keys(ds.weekTable).map(
                                                          (item, index) => (
                                                              <>
                                                                  {ds.weekTable[item] && (
                                                                      <React.Fragment key={index}>
                                                                          {weekTable[item]}
                                                                          {index <
                                                                              Object.values(
                                                                                  ds.weekTable
                                                                              ).filter((val) => val)
                                                                                  .length && ','}
                                                                      </React.Fragment>
                                                                  )}
                                                              </>
                                                          )
                                                      )}
                                            </Fz15>
                                        ) : null}
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[1] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(1)}
                                        >
                                            {ds.isSelected[1] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[1]}
                                            onChange={() => ds.toggleSelected(1)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb10>
                                <FlxbtFullMb10>
                                    <Fz15Bold>화요일</Fz15Bold>
                                    <FlxMiddle>
                                        {/* <Fz15 $col="#FF3062">
                                            {designerData?.data.holidayByDay.map((dayData) => {
                                                return (
                                                    <React.Fragment key={dayData.id}>
                                                        {dayData.day === 2 &&
                                                            dayData.week.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {weekTable[item]}
                                                                    {index <
                                                                        dayData.week.length - 1 &&
                                                                        ','}
                                                                </React.Fragment>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Fz15>
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[2] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(2)}
                                        >
                                            {ds.isSelected[2] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[2]}
                                            onChange={() => ds.toggleSelected(2)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb10>
                                <FlxbtFullMb10>
                                    <Fz15Bold>수요일</Fz15Bold>
                                    <FlxMiddle>
                                        {/* <Fz15 $col="#FF3062">
                                            {designerData?.data.holidayByDay.map((dayData) => {
                                                return (
                                                    <React.Fragment key={dayData.id}>
                                                        {dayData.day === 3 &&
                                                            dayData.week.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {weekTable[item]}
                                                                    {index <
                                                                        dayData.week.length - 1 &&
                                                                        ','}
                                                                </React.Fragment>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Fz15>
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[3] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(3)}
                                        >
                                            {ds.isSelected[3] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[3]}
                                            onChange={() => ds.toggleSelected(3)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb10>
                                <FlxbtFullMb10>
                                    <Fz15Bold>목요일</Fz15Bold>
                                    <FlxMiddle>
                                        {/* <Fz15 $col="#FF3062">
                                            {designerData?.data.holidayByDay.map((dayData) => {
                                                return (
                                                    <React.Fragment key={dayData.id}>
                                                        {dayData.day === 4 &&
                                                            dayData.week.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {weekTable[item]}
                                                                    {index <
                                                                        dayData.week.length - 1 &&
                                                                        ','}
                                                                </React.Fragment>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Fz15>
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[4] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(4)}
                                        >
                                            {ds.isSelected[4] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[4]}
                                            onChange={() => ds.toggleSelected(4)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb10>
                                <FlxbtFullMb10>
                                    <Fz15Bold>금요일</Fz15Bold>

                                    <FlxMiddle>
                                        {/* <Fz15 $col="#FF3062">
                                            {designerData?.data.holidayByDay.map((dayData) => {
                                                return (
                                                    <React.Fragment key={dayData.id}>
                                                        {dayData.day === 5 &&
                                                            dayData.week.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {weekTable[item]}
                                                                    {index <
                                                                        dayData.week.length - 1 &&
                                                                        ','}
                                                                </React.Fragment>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Fz15>
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[5] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(5)}
                                        >
                                            {ds.isSelected[5] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[5]}
                                            onChange={() => ds.toggleSelected(5)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb10>
                                <FlxbtFullMb10>
                                    <Fz15Bold>토요일</Fz15Bold>
                                    <FlxMiddle>
                                        {/* <Fz15 $col="#FF3062">
                                            {designerData?.data.holidayByDay.map((dayData) => {
                                                return (
                                                    <React.Fragment key={dayData.id}>
                                                        {dayData.day === 6 &&
                                                            dayData.week.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {weekTable[item]}
                                                                    {index <
                                                                        dayData.week.length - 1 &&
                                                                        ','}
                                                                </React.Fragment>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Fz15>
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[6] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(6)}
                                        >
                                            {ds.isSelected[6] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[6]}
                                            onChange={() => ds.toggleSelected(6)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb10>
                                <FlxbtFullMb50>
                                    <Fz15Bold>일요일</Fz15Bold>
                                    <FlxMiddle>
                                        {/* <Fz15 $col="#FF3062">
                                            {designerData?.data.holidayByDay.map((dayData) => {
                                                return (
                                                    <React.Fragment key={dayData.id}>
                                                        {dayData.day === 0 &&
                                                            dayData.week.map((item, index) => (
                                                                <React.Fragment key={index}>
                                                                    {weekTable[item]}
                                                                    {index <
                                                                        dayData.week.length - 1 &&
                                                                        ','}
                                                                </React.Fragment>
                                                            ))}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </Fz15>
                                        <Gap15 /> */}
                                        {/* <BtnToggleAccent2
                                            className={ds.isSelected[0] ? 'off' : 'on'}
                                            onClick={() => ds.toggleSelected(0)}
                                        >
                                            {ds.isSelected[0] ? '휴무' : '근무'}
                                        </BtnToggleAccent2> */}
                                        <ChkOnOff
                                            checked={!ds.isSelected[0]}
                                            onChange={() => ds.toggleSelected(0)}
                                            scale={1.2}
                                            textOn="근무일"
                                            textOff="휴무일"
                                        />
                                    </FlxMiddle>
                                </FlxbtFullMb50>
                            </ContainerPadding>
                            <StickyBtm>
                                <LinkFull
                                    $active={
                                        (Object.values(ds.isSelected).some(Boolean) &&
                                            Object.values(ds.weekTable).some(Boolean)) ||
                                        (Object.values(ds.isSelected).every((val) => !val) &&
                                            Object.values(ds.weekTable).every((val) => !val))
                                    }
                                    onClick={() => requestClosedDays()}
                                >
                                    저장
                                </LinkFull>
                            </StickyBtm>
                        </FieldSet>
                        {/* //요일별 설정 탭 컨텐츠 */}
                        {/* 날짜별 설정 탭 컨텐츠 */}
                        <FieldSet $visible={ds.category.date}>
                            <ContainerPadding>
                                {/* <Fz18Medium $mb="1.538rem">유동 휴무일 설정 </Fz18Medium> */}
                                <Mb30>
                                    <Paragraph $col="#000" $fz="1.154rem">
                                        매주/격주/매달 휴무일을 설정하실 수 있습니다.
                                    </Paragraph>
                                </Mb30>
                                <RsvDayPicker
                                    mode="single"
                                    selected={ds.closedDays.date}
                                    onSelect={ds.setClosedDaysDate}
                                    onMonthChange={(date) => {
                                        const firstDayOfMonth = new Date(
                                            date.getFullYear(),
                                            date.getMonth(),
                                            1
                                        );
                                        ds.setCurrentMonth(firstDayOfMonth);
                                        getDisabledDates(firstDayOfMonth, designerData, ds);
                                    }}
                                    disabled={[{ before: new Date() }]}
                                    modifiers={{
                                        holiday: [
                                            ...ds.daypicker.holidayByDay,
                                            ...(designerData?.data.holidayByDate || []).map(
                                                (items) => {
                                                    return items.date;
                                                }
                                            ),
                                        ].map((date) => new Date(date + 'T00:00:00')),
                                        work: [
                                            ...(designerData?.data.workDate || []).map((items) => {
                                                return items.date;
                                            }),
                                        ].map((date) => new Date(date + 'T00:00:00')),
                                    }}
                                    modifiersClassNames={{ holiday: 'offday', work: 'workday' }}
                                />
                            </ContainerPadding>
                            <StickyBtm>
                                <BtnLineGreenShorter onClick={() => ds.toggleModal('closedDays')}>
                                    휴무일 보기
                                </BtnLineGreenShorter>
                            </StickyBtm>
                        </FieldSet>
                        {/* //날짜별 설정 탭 컨텐츠 */}
                    </form>
                </Container>
                {/* 휴무일 보기 팝업 */}
                <PopOver $visible={ds.modal.closedDays}>
                    <Spacing70 />
                    <ContainerPadding>
                        <BtnCloseModal onClick={() => ds.toggleModal('closedDays')}>
                            <img src={icCloseModal} alt="닫기" />
                        </BtnCloseModal>
                        <Fz18Medium $mb="1.538rem">휴무일 보기</Fz18Medium>
                        <Paragraph $fz="1.154rem" $col="#000">
                            설정하신 휴무일 목록입니다. 휴무일은 우측 버튼을 클릭하여 취소하실 수
                            있습니다.
                        </Paragraph>
                    </ContainerPadding>
                    <SectionDivider />
                    <ContainerPadding>
                        <OffList>
                            {designerData?.data.holidayByDate.map((items) => (
                                <li>
                                    <FlxMiddle>
                                        {/* 메모: 반복/일회 분기 */}
                                        <ColAccent2 as="strong">휴무</ColAccent2>
                                        {/* <ColAccent as="strong">일회</ColAccent> */}
                                        <Fz15 as="p">
                                            {' '}
                                            {new Intl.DateTimeFormat('ko-KR', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                weekday: 'long',
                                            }).format(new Date(items.date))}{' '}
                                            휴무
                                        </Fz15>
                                    </FlxMiddle>
                                    <BtnInInputRound
                                        onClick={() =>
                                            designerDeleteHolidayByDateMutation.mutate({
                                                designerId: us.user.id,
                                                date: items.date,
                                            })
                                        }
                                    >
                                        취소
                                    </BtnInInputRound>
                                </li>
                            ))}
                            {designerData?.data.workDate.map((items) => (
                                <li>
                                    <FlxMiddle>
                                        {/* 메모: 반복/일회 분기 */}
                                        <ColAccent as="strong">근무</ColAccent>
                                        <Fz15 as="p">
                                            {new Intl.DateTimeFormat('ko-KR', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                                weekday: 'long',
                                            }).format(new Date(items.date))}{' '}
                                            근무
                                        </Fz15>
                                    </FlxMiddle>
                                    <BtnInInputRound
                                        onClick={() =>
                                            designerDeleteHolidayByWorkMutation.mutate({
                                                designerId: us.user.id,
                                                date: items.date,
                                            })
                                        }
                                    >
                                        취소
                                    </BtnInInputRound>
                                </li>
                            ))}
                        </OffList>
                        <BtnLineGreenShorter onClick={() => ds.toggleModal('closedDays')}>
                            휴무일 설정 페이지 이동
                        </BtnLineGreenShorter>
                    </ContainerPadding>
                </PopOver>
                {/* //휴무일 보기  */}
                {/* 휴무일 해제 모달 */}
                <NewModal
                    contentLabel="휴무일 해제"
                    isOpen={ds.modal.confirmModal}
                    ariaHideApp={false}
                >
                    <Pad20>
                        <PopShortMsg>
                            <Mb20>
                                {ds.closedDays.date && (
                                    <Fz15Bold $col="#000">
                                        <ColAccent2>
                                            {new Intl.DateTimeFormat('ko-KR', {
                                                year: 'numeric',
                                                month: 'long',
                                                day: 'numeric',
                                            }).format(ds.closedDays.date)}
                                        </ColAccent2>
                                        을{' '}
                                        {(() => {
                                            if (ds.closedDays.date instanceof Date) {
                                                const closedDate = format(
                                                    ds.closedDays.date,
                                                    'yyyy-MM-dd'
                                                );
                                                const isWorkDate = designerData?.data.workDate.some(
                                                    (workDate) =>
                                                        format(
                                                            parse(
                                                                workDate.date,
                                                                'yyyy-MM-dd',
                                                                new Date()
                                                            ),
                                                            'yyyy-MM-dd'
                                                        ) === closedDate
                                                );
                                                const isFixedHoliday =
                                                    ds.daypicker.holidayByDay.some(
                                                        (holiday) => holiday === closedDate
                                                    );
                                                const isHoliday =
                                                    designerData?.data.holidayByDate.some(
                                                        (fixedHoliday) =>
                                                            format(
                                                                parse(
                                                                    fixedHoliday.date,
                                                                    'yyyy-MM-dd',
                                                                    new Date()
                                                                ),
                                                                'yyyy-MM-dd'
                                                            ) === closedDate
                                                    );

                                                if (isWorkDate) {
                                                    return '휴무';
                                                } else if (isHoliday || isFixedHoliday) {
                                                    return '근무';
                                                } else {
                                                    return '휴무';
                                                }
                                            } else {
                                                return '휴무';
                                            }
                                        })()}
                                        일로 지정하시겠습니까?
                                    </Fz15Bold>
                                )}
                            </Mb20>
                        </PopShortMsg>

                        <ListCol2>
                            <BtnHalfModalBlack onClick={() => ds.toggleModal('confirmModal')}>
                                아니요
                            </BtnHalfModalBlack>
                            <BtnHalfModal onClick={() => requestClosedDates()}>네</BtnHalfModal>
                        </ListCol2>
                    </Pad20>
                </NewModal>
                {/* //휴무일 해제 모달 */}
                {/* 휴무일 등록 완료 모달 */}
                <NewModal contentLabel="휴무일 등록 완료" isOpen={ds.modal.constantHolidayConfirm}>
                    <Pad20>
                        <PopShortMsg>
                            <Mb20>
                                <Fz15Bold $col="#000">고정 휴무일 설정이 완료되었습니다.</Fz15Bold>
                            </Mb20>
                        </PopShortMsg>
                        <BtnFullModal
                            $active
                            onClick={() => ds.toggleModal('constantHolidayConfirm')}
                        >
                            확인
                        </BtnFullModal>
                    </Pad20>
                </NewModal>
                {/* //휴무일 등록 완료 모달 */}
            </>
        );
    }
}
export default DayOffs;
