import { create } from 'zustand';

const initialState = {
    isActive: false,
    modal: {
        active: false,
    },
};

const useAsideStore = create((set) => ({
    ...initialState,
    toggleActive: () => {
        set((state) => ({
            isActive: !state.isActive,
        }));
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    setActive: (text) => {
        set((state) => ({
            isActive: text,
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useAsideStore;
