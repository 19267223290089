import React from 'react';
import { InputHidden } from '@/css/style';
import chkHandle from '@/assets/img/chk_handle2.svg';
import useWindowSize from '@/hooks/useWindowSize';
const ChkOnOff = (props) => {
    const { width } = useWindowSize();
    const labelStyle = {
        position: 'relative',
        // display: 'flex',
        display: 'inline-block',
        width: props.scale ? 78 * props.scale : 78,
        height: props.scale ? 29 * props.scale : 29,
        borderRadius: '3rem',
        // backgroundColor: props.checked ? `#00BF9D` : `#E8EAED`,
        // color: props.checked ? `#fff` : `#757575`,
        backgroundColor: props.checked ? `#00BF9D` : `#eb4034`,
        color: `#fff`,
        transition: 'all .4s',
    };
    const handleStyle = {
        position: 'absolute',
        top: 0,
        left: props.checked ? props.scale ? 48 * props.scale : 48 : 0,
        width: props.scale ? 29 * props.scale : 29,
        minWidth: props.scale ? 29 * props.scale : 29,
        height: props.scale ? 29 * props.scale : 29,
        transition: 'all .4s',
        zIndex: 2,
    };
    const txtStyle = {
        position: 'absolute',
        top: props.scale ? `${0.56 * props.scale}rem` : '0.56rem',
        left: props.checked ? props.scale ? `${1.2 * props.scale}rem` : `1.2rem` : props.scale ? `${2.3 * props.scale}rem` : `3rem`,
        fontSize: 414 <= width ? `${props.scale ? props.scale * 12.5 : 12.5}px` : `${props.scale ? props.scale * 13 : 13}px`,
        lineHeight: 1,
    };
    const chkId = props.id;

    return (
        <label htmlFor={chkId} style={labelStyle}>
            <InputHidden
                type="checkbox"
                id={chkId}
                name={chkId}
                checked={props.checked}
                onChange={props.onChange}
                onClick={props.onClick}
                disabled={props.disabled}
            />
            <img src={chkHandle} style={handleStyle} />
            <strong style={txtStyle}>{props.checked ? props.textOn : props.textOff}</strong>
        </label>
    );
};
export default ChkOnOff;
