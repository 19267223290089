import React, { useEffect } from 'react';
import {
    Link,
    useLoaderData,
    useLocation,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom';
import {
    StickyBtm,
    LinkFull,
    InfoLabel,
    InfoValue,
    BtnLineGray,
    Fz15Bold,
    Spacing20,
    Mb10,
    ColAccent2,
    Fz18Medium,
    Fz16Medium,
    Container,
    ContainerPadding,
    SectionDivider,
    Mb20,
    ColAccent,
    Spacing70,
    Flx,
    Divider,
    FlxMiddleMb10,
    Pad20,
    PopShortMsg,
    ListCol2,
    BtnHalfModalBlack,
    BtnHalfModal,
} from '@/css/style';
import { ko } from 'date-fns/locale';
import { format, parse } from 'date-fns';
import { useReservationReadQuery } from '@/services/reservationService';
import { useReservationUpdateMutation } from '@/services/reservationService';
import useHistoryStore from '@/stores/user/history';
import NewModal from '@/components/NewModal';
import useReviewStore from '@/stores/user/review';

function HistoryDetail() {
    const params = useParams();
    const location = useLocation();
    const { navigate } = useOutletContext();
    const hs = useHistoryStore();
    const rs = useReviewStore();

    const { data: reservationReadData, refetch: reservationRefetch } = useReservationReadQuery(
        params.id
    );
    const ReservationUpdateMutation = useReservationUpdateMutation(() => {
        reservationRefetch();
    });

    const requestCancel = () => {
        ReservationUpdateMutation.mutate({
            reservId: params.id,
            data: { status: 'Cancel' },
        });
        hs.showModal(false);
    };

    if (reservationReadData) {
        return (
            <>
                <Container>
                    {/* <Spacing70 /> */}
                    {/* 예약내역 상단 블록 */}
                    <ContainerPadding>
                        <Mb20>
                            {reservationReadData?.data.status === 'Confirm' ? (
                                <ColAccent>예약완료</ColAccent>
                            ) : reservationReadData?.data.status === 'Complete' ? (
                                <span>방문완료</span>
                            ) : reservationReadData?.data.status === 'Reject' ? (
                                <span>예약거절</span>
                            ) : reservationReadData?.data.status === 'NoShow' ? (
                                <span>노쇼</span>
                            ) : reservationReadData?.data.status === 'Cancel' ? (
                                <ColAccent2>예약취소</ColAccent2>
                            ) : null}
                        </Mb20>
                        <Divider />
                        <div>
                            <Spacing20 />
                            <Link
                                to={`/designer/${reservationReadData?.data.designer.id}`}
                                state={{ previousUrl: location.pathname }}
                            >
                                <Mb10>
                                    <Fz15Bold $col="#00bf9d">
                                        {reservationReadData?.data.designer.shop.name}
                                    </Fz15Bold>{' '}
                                </Mb10>
                                <Fz16Medium $mb="1.538rem" $col="#000">
                                    {reservationReadData?.data.designer.title}
                                </Fz16Medium>
                            </Link>
                            {/* 메모: 예약취소나 리뷰작성 버튼이 없을때는 둘다 미노출 */}
                            {reservationReadData?.data.status === 'New' ? (
                                <BtnLineGray onClick={() => hs.showModal('cancel')}>
                                    예약취소
                                </BtnLineGray>
                            ) : reservationReadData?.data.status === 'Complete' &&
                              !reservationReadData?.data.review ? (
                                <BtnLineGray
                                    $active
                                    onClick={() => {
                                        rs.setField('reservId', params.id);
                                        navigate('/review', { replace: true });
                                    }}
                                >
                                    리뷰작성
                                </BtnLineGray>
                            ) : null}
                        </div>
                    </ContainerPadding>
                    {/* //예약 상단 블록 */}
                    <SectionDivider />
                    {/* 예약내역 상세 박스 */}
                    <ContainerPadding>
                        <Fz18Medium $mb="1.538rem">예약정보</Fz18Medium>
                        <Divider $mb="1.538rem" />
                        <div>
                            {/* 메모: 방문자가 주문자와 다른 경우 방문자 이름, 연락처 노출 */}
                            <Mb20 as={Flx}>
                                <InfoLabel>방문자</InfoLabel>
                                <InfoValue>{reservationReadData?.data.name}</InfoValue>
                            </Mb20>
                            <Mb20 as={Flx}>
                                <InfoLabel>연락처</InfoLabel>
                                <InfoValue>{reservationReadData?.data.phone}</InfoValue>
                            </Mb20>
                            <Mb20 as={Flx}>
                                <InfoLabel>접수일</InfoLabel>
                                <InfoValue>
                                    {format(
                                        parse(
                                            reservationReadData?.data.created,
                                            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                            new Date()
                                        ),
                                        'yyyy. MM. dd (E) HH:mm',
                                        { locale: ko }
                                    )}
                                </InfoValue>
                            </Mb20>
                            <Mb20 as={Flx}>
                                <InfoLabel>예약일</InfoLabel>
                                <InfoValue>
                                    {format(
                                        parse(
                                            reservationReadData?.data.date +
                                                ' ' +
                                                reservationReadData?.data.time,
                                            'yyyy-MM-dd HH:mm',
                                            new Date()
                                        ),
                                        'yyyy. MM. dd (E)',
                                        { locale: ko }
                                    )}{' '}
                                    {reservationReadData?.data.time}
                                </InfoValue>
                            </Mb20>
                            <Mb10 as={Flx}>
                                <InfoLabel>시술 메뉴</InfoLabel>
                                <InfoValue>
                                    {reservationReadData?.data.menus.map((menus) => (
                                        <FlxMiddleMb10 key={menus._id}>{menus.name}</FlxMiddleMb10>
                                    ))}
                                </InfoValue>
                                {/* <InfoValue>펌 / 베이직염색</InfoValue> */}
                            </Mb10>
                            <Mb20 as={Flx}>
                                <InfoLabel>금액</InfoLabel>
                                <InfoValue>
                                    {' '}
                                    {reservationReadData?.data.menus
                                        .reduce((total, item) => total + item.price, 0)
                                        .toLocaleString()}
                                    원
                                </InfoValue>
                            </Mb20>
                            <Flx>
                                <InfoLabel>요청사항</InfoLabel>
                                <InfoValue>{reservationReadData?.data.memo}</InfoValue>
                            </Flx>
                        </div>
                    </ContainerPadding>
                    {/* //예약내역 상세 박스 */}
                    {reservationReadData?.data.status === 'Complete' && (
                        <StickyBtm
                            onClick={() =>
                                navigate(`/designer/${reservationReadData?.data.designer.id}`)
                            }
                        >
                            <LinkFull $active>재예약</LinkFull>
                        </StickyBtm>
                    )}
                </Container>
                {/* 예약취소 모달 */}
                <NewModal isOpen={hs.modal.cancel} contentLabel="예약취소" $visible>
                    <Pad20>
                        <PopShortMsg>
                            <Fz15Bold $col="#000">예약을 취소하시겠습니까?</Fz15Bold>
                        </PopShortMsg>
                        <ListCol2>
                            <BtnHalfModalBlack onClick={() => hs.showModal(false)}>
                                취소
                            </BtnHalfModalBlack>
                            <BtnHalfModal onClick={() => requestCancel()}>확인</BtnHalfModal>
                        </ListCol2>
                    </Pad20>
                </NewModal>
                {/* //예약취소 모달 */}
            </>
        );
    }
}
const borTopStyle = {
    width: '100%',
    paddingTop: '1.154rem',
    borderTop: '1px solid #E8EAED',
};
export default HistoryDetail;
