import { create } from 'zustand';

const validateField = (fieldName, text, state) => {
    // 각 필드에 대한 유효성 검사
    switch (fieldName) {
        case 'rating':
            // name 필드에 대한 유효성 검사
            return text > 0;

        case 'content':
            // name 필드에 대한 유효성 검사
            return text.length >= 10;

        case 'reservId':
            // name 필드에 대한 유효성 검사
            return text != null;

        default:
            return true;
    }
};

const initialState = {
    fields: {
        rating: '',
        content: '',
        pic: [],
        reservId: '',
        delete_file_ids: [],
    },
    validations: {
        rating: false,
        content: false,
        reservId: false,
    },
    modal: {
        confirm: false,
    },
};

const useReviewStore = create((set, get) => ({
    ...initialState,
    setField: (fieldName, text) => {
        set((state) => {
            let updatedFields = { ...state.fields };
            let updatedValidations = { ...state.validations };

            updatedFields[fieldName] = text;
            updatedValidations[fieldName] = validateField(fieldName, text, state);

            return {
                fields: updatedFields,
                validations: updatedValidations,
            };
        });
    },
    setFieldFromServer: (obj) => {
        set((state) => {
            let updatedFields = { ...state.fields };
            let updatedValidations = { ...state.validations };

            // Iterate over properties of obj
            for (const fieldName in obj) {
                if (Object.prototype.hasOwnProperty.call(obj, fieldName)) {
                    const text = obj[fieldName];

                    updatedFields[fieldName] = text;
                    updatedValidations[fieldName] = validateField(fieldName, text, state);
                }
            }

            return {
                fields: updatedFields,
                validations: updatedValidations,
            };
        });
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    addImage: (event) => {
        const files = event.target.files;
        const updatedFields = { ...get().fields };

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const imageURL = URL.createObjectURL(file);

            updatedFields.pic.push({
                fileURL: imageURL,
                fileData: {
                    files,
                },
            });
        }

        // Zustand를 사용하여 상태 업데이트
        set({
            fields: {
                ...updatedFields,
            },
        });
    },
    deleteImage: (index) => {
        set((state) => {
            const updatedFields = { ...state.fields };
            const updatedImages = [...updatedFields.pic];

            updatedImages.splice(index, 1); // 이미지 삭제

            return {
                fields: {
                    ...updatedFields,
                    pic: updatedImages,
                },
            };
        });
    },
    deleteImageServer: (id) => {
        set((state) => {
            const updatedFields = { ...state.fields };
            const updatedImages = [...updatedFields.pic_server];
            const index = updatedImages.findIndex((image) => image.id === id);

            if (index !== -1) {
                const deletedImageId = updatedImages[index].id;
                const updatedDeleteFileIds = [...state.fields.delete_file_ids, deletedImageId];
                updatedImages.splice(index, 1);

                return {
                    fields: {
                        ...updatedFields,
                        pic_server: updatedImages,
                        delete_file_ids: updatedDeleteFileIds,
                    },
                };
            }

            return state;
        });
    },
    terminate: () => {
        set(() => ({
            ...initialState,
            fields: {
                ...initialState.fields,
                pic: [],
            },
        }));
    },
}));

export default useReviewStore;
