import { create } from 'zustand';

const initialState = {
    service: {
        category_id: '',
        name: '',
        option: '',
        price: '',
        normal_price: '',
    },
    edit: {},
    preview: {
        cut: true,
        perm: false,
        clinic: false,
        dry: false,
        set: false,
        makeup: false,
        event: false,
    },
    category: [],
    selectedCategory: '',
    modal: {
        add: false,
        edit: false,
        preview: false,
    },
};

const useServiceStore = create((set) => ({
    ...initialState,
    showModal: (select) => {
        set((state) => ({
            modal: {
                ...state.modal,
                add: select === 'add',
                preview: select === 'preview',
                edit: select === 'edit',
            },
        }));
    },
    setService: (key, value) => {
        if (key === 'normal_price') {
            set((state) => ({
                service: {
                    ...state.service,
                    [key]: value,
                    price: value,
                },
            }));
        } else {
            set((state) => ({
                service: {
                    ...state.service,
                    [key]: value,
                },
            }));
        }
    },
    setDetailData: (obj) => {
        set((state) => {
            const updatedState = {
                edit: {
                    ...obj,
                    category_id: obj.category,
                    price: String(obj.price),
                    normal_price: String(obj.normal_price),
                },
            };

            return updatedState;
        });
    },
    editService: (key, value) => {
        if (key === 'normal_price') {
            set((state) => ({
                edit: {
                    ...state.edit,
                    [key]: value,
                    price: value,
                },
            }));
        } else {
            set((state) => ({
                edit: {
                    ...state.edit,
                    [key]: value,
                },
            }));
        }
    },
    setPreview: (select) => {
        set((state) => ({
            preview: {
                cut: select === 'cut',
                perm: select === 'perm',
                clinic: select === 'clinic',
                dry: select === 'dry',
                set: select === 'set',
                makeup: select === 'makeup',
                event: select === 'event',
            },
        }));
    },
    setCategory: (id) => {
        set((state) => {
            // 선택된 카테고리를 찾기 위해 find 함수를 사용합니다.
            const updatedCategory = state.category.map((category) => {
                // 주어진 id와 카테고리의 id가 일치하면 해당 카테고리의 selected를 true로 설정합니다.
                if (category.id === id) {
                    return { ...category, selected: true };
                } else {
                    return { ...category, selected: false };
                }
            });

            return {
                ...state,
                category: updatedCategory,
                selectedCategory: id, // 선택된 카테고리의 id를 저장합니다.
            };
        });
    },
    setCategoryFromServer: (arr) => {
        const updatedCategory = arr.map((item) => ({ ...item, selected: false }));

        const categoriesWithAll = [{ name: '전체', id: '', selected: true }, ...updatedCategory];

        set((state) => ({
            category: categoriesWithAll,
        }));
    },

    terminate: () => {
        set((state) => ({
            service: initialState.service,
        }));
    },
    terminateAll: () => {
        set(initialState);
    },
}));

export default useServiceStore;
