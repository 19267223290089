import React from 'react';
import {
    MyHeader,
    Container,
    ContainerPadding,
    Fz20Bold,
    ItemInfoCol2,
    InfoLabel,
    InfoValue,
    LiStyled,
    PostImg,
} from '@/css/style';
import { useShopDiscountReadQuery } from '@/services/shopService';
import { useParams } from 'react-router-dom';
import { format, parse } from 'date-fns';
import config from '@/config';

function BannerDetail() {
    const params = useParams();
    const { data: shopDiscountReadData } = useShopDiscountReadQuery(params.id);

    if (shopDiscountReadData) {
        return (
            <Container>
                <MyHeader>
                    <div style={{ width: '100%' }}>
                        <Fz20Bold $mb="2.3077rem">{shopDiscountReadData?.data.title}</Fz20Bold>
                        <ItemInfoCol2 $mb="1.154rem">
                            <InfoLabel>이벤트 기간</InfoLabel>
                            <InfoValue>
                                {format(
                                    parse(
                                        shopDiscountReadData?.data.from_date,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                        new Date()
                                    ),
                                    'yyyy. MM. dd'
                                )}
                                ~{' '}
                                {format(
                                    parse(
                                        shopDiscountReadData?.data.to_date,
                                        "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                        new Date()
                                    ),
                                    'yyyy. MM. dd'
                                )}
                            </InfoValue>
                        </ItemInfoCol2>
                        <ItemInfoCol2>
                            <InfoLabel>헤어샵</InfoLabel>
                            <InfoValue>{shopDiscountReadData?.data.shop.name}</InfoValue>
                        </ItemInfoCol2>
                    </div>
                </MyHeader>
                <ContainerPadding>
                    <PostImg>
                        <img
                            src={`${config.ADVERTISEMENT_IMG_URL}${shopDiscountReadData?.data.main_image?.filename}`}
                            onError={(e) =>
                                (e.currentTarget.src = require('@assets/img/altimg.png'))
                            }
                            alt="main_image"
                        />
                    </PostImg>
                    <LiStyled>
                        {shopDiscountReadData?.data.description.split('\n').map((line, index) => (
                            <React.Fragment key={index}>
                                {line}
                                <br />
                            </React.Fragment>
                        ))}
                    </LiStyled>
                </ContainerPadding>
            </Container>
        );
    }
}

export default BannerDetail;
