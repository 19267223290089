import { create } from 'zustand';

const initialState = {
    keyword: '',
    isSearch: false,
    byButton: false,
};

const useSearchStore = create((set) => ({
    ...initialState,
    toggleIsSearch: () => {
        set((state) => ({
            isSearch: !state.isSearch,
        }));
    },
    toggleByButton: () => {
        set((state) => ({
            byButton: !state.byButton,
        }));
    },
    setIsSearch: (bool) => {
        set((state) => ({
            isSearch: bool,
        }));
    },
    setKeyword: (text) => {
        set((state) => ({
            keyword: text,
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useSearchStore;
