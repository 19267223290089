import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { Rating } from 'react-simple-star-rating';
import {
    Mb30,
    BtnDelete,
    PhotoItem,
    GridImg,
    Spacing20,
    PhotoGrid,
    FileFullLabel,
    StickyBtm,
    TextBoxCount,
    TextBoxWrapper,
    Textbox,
    BtnFullModal,
    Fz15Bold,
    PadVert30,
    Container,
    ContainerPadding,
    Mb20,
    NavHeight,
    InputHidden,
    TxCent,
} from '@/css/style';
import config from '@/config';
import { useReviewReadQuery, useReviewUpdateMutation } from '@/services/reviewService';
import useReviewStore from '@/stores/user/review';
import altimg from '@/assets/img/altimg.png';
import icClear from '@/assets/img/ic_clear.svg';
import icPhoto from '@/assets/img/ic_photo.svg';
import { useIsMutating } from '@tanstack/react-query';

function ReviewEdit() {
    const rs = useReviewStore();
    const { navigate } = useOutletContext();
    const params = useParams();
    const isMutating = useIsMutating();
    const { data: reviewData } = useReviewReadQuery(params.id);

    useEffect(() => {
        if (reviewData) {
            rs.setFieldFromServer({
                reservId: reviewData.data.reservation.id,
                content: reviewData.data.content,
                rating: reviewData.data.rating,
                pic_server: reviewData.data.images,
            });
        }
    }, [reviewData]);

    const ReviewUpdateMutation = useReviewUpdateMutation(() =>
        navigate('/my/review', { replace: true, state: { from: 'my' } })
    );

    const requestReview = () => {
        if (Object.values(rs.validations).every((val) => val === true)) {
            ReviewUpdateMutation.mutate(rs.fields);
        } else {
            return;
        }
    };

    useEffect(() => {
        return () => {
            rs.terminate();
        };
    }, []);
    return (
        <>
            <Container>
                <NavHeight />
                <ContainerPadding>
                    <PadVert30 as={TxCent}>
                        <Fz15Bold $col="#000">시술에 만족하셨나요?</Fz15Bold>
                        <Spacing20 />
                        <Rating
                            onClick={(rating) => {
                                rs.setField('rating', rating);
                            }}
                            allowFraction={true}
                            fillColor="#FFBB00"
                            emptyColor="#E8EAED"
                            size="2rem"
                            initialValue={rs.fields.rating}
                        />
                    </PadVert30>
                    <Mb20>
                        <TextBoxWrapper>
                            <Textbox
                                id="content"
                                name="content"
                                rows="8"
                                maxLength="1000"
                                placeholder="시술평을 최소 10자 이상 입력해 주세요"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    rs.setField('content', value);
                                }}
                                value={rs.fields.content}
                            ></Textbox>
                            <TextBoxCount>
                                {rs.fields.content.length
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                /1,000
                            </TextBoxCount>
                        </TextBoxWrapper>
                    </Mb20>
                    <Mb30>
                        <FileFullLabel>
                            <InputHidden
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    rs.addImage(e);
                                }}
                            />
                            <img src={icPhoto} /> <Fz15Bold>사진 첨부하기</Fz15Bold>
                        </FileFullLabel>
                    </Mb30>
                    <PhotoGrid>
                        {rs.fields.pic_server &&
                            rs.fields.pic_server.map((image) => (
                                <PhotoItem key={image.id}>
                                    <GridImg
                                        src={`${config.REVIEW_IMG_URL}${image.filename}`}
                                        alt="이미지"
                                        onError={(e) => {
                                            e.currentTarget.src = altimg;
                                        }}
                                    />

                                    <BtnDelete onClick={() => rs.deleteImageServer(image.id)}>
                                        <img src={icClear} alt="삭제" />
                                    </BtnDelete>
                                </PhotoItem>
                            ))}
                        {rs.fields.pic.map((image, index) => (
                            <PhotoItem key={index}>
                                <GridImg src={image.fileURL} />
                                <BtnDelete onClick={() => rs.deleteImage(index)}>
                                    <img src={icClear} alt="삭제" />
                                </BtnDelete>
                            </PhotoItem>
                        ))}
                    </PhotoGrid>
                </ContainerPadding>
            </Container>
            <StickyBtm>
                <BtnFullModal
                    $active={Object.values(rs.validations).every((val) => val === true)}
                    onClick={requestReview}
                    disabled={!!isMutating}
                >
                    수정
                </BtnFullModal>
            </StickyBtm>
        </>
    );
}
export default ReviewEdit;
