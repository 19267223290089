import React from 'react';
import { Link } from 'react-router-dom';
import {
    FlxMiddleMb20,
    PadVert20,
    MapNav,
    MapStep1,
    TabMap,
    MapStep2ul,
    NavHeight,
    Container,
    TabAccent,
    Mb20,
    BtnFullLine,
    ToggleShow,
    TabAccent2,
    Gap10,
    ContainerPadding,
    TabWrapper,
} from '@/css/style';
import icFind from '@/assets/img/ic_find.svg';

function Neighbour() {
    return (
        <>
            <Container>
                <NavHeight />
                {/* 내 주변 상단 부분 */}
                <ContainerPadding>
                    <FlxMiddleMb20>
                        <TabAccent $active>지역별</TabAccent>
                        <Gap10 />
                        <TabAccent>역주변</TabAccent>
                    </FlxMiddleMb20>
                    <Mb20>
                        <BtnFullLine>
                            <img src={icFind} />
                            &nbsp;내 주변 모든 미용실 보기
                        </BtnFullLine>
                    </Mb20>
                    {/* 메모: 아래 필터는 역주변 탭에서만 노출됩니다 */}
                    <PadVert20 as={ToggleShow}>
                        <TabWrapper>
                            <TabAccent2 $active>수도권</TabAccent2>・<TabAccent2>부산</TabAccent2>・
                            <TabAccent2>대구</TabAccent2>・<TabAccent2>광주</TabAccent2>・
                            <TabAccent2>대전</TabAccent2>
                        </TabWrapper>
                    </PadVert20>
                </ContainerPadding>
                {/* //내 주변 상단 부분 */}
                <MapNav>
                    <ul>
                        <MapStep1>
                            <TabMap>서울</TabMap>
                            <MapStep2ul>
                                <li>서울1</li>
                                <li>서울2</li>
                                <li>서울3</li>
                                <li>서울4</li>
                                <li>서울5</li>
                                <li>서울6</li>
                                <li>서울7</li>
                                <li>서울8</li>
                                <li>서울9</li>
                                <li>서울10</li>
                                <li>서울11</li>
                                <li>서울12</li>
                                <li>서울13</li>
                                <li>서울14</li>
                                <li>서울15</li>
                            </MapStep2ul>
                        </MapStep1>
                        <MapStep1>
                            {/* 메모: tabmap이랑 mapstep2ul에 같이 active가 토글되면 됩니다 */}
                            <TabMap $active>부산</TabMap>
                            <MapStep2ul $active>
                                <li>부산1</li>
                                <li>부산2</li>
                                <li>부산3</li>
                                <li>부산4</li>
                                <li>부산5</li>
                                <li>부산6</li>
                                <li>부산7</li>
                                <li>부산8</li>
                                <li>부산9</li>
                                <li>부산10</li>
                                <li>부산11</li>
                                <li>부산12</li>
                                <li>부산13</li>
                                <li>부산14</li>
                                <li>부산15</li>
                            </MapStep2ul>
                        </MapStep1>
                    </ul>
                </MapNav>
            </Container>
        </>
    );
}

export default Neighbour;
