import { instance } from '@services/api';
import { useQuery, useMutation, useInfiniteQuery } from '@tanstack/react-query';
const reservationService = {
    reservTime: (id, date) => {
        if (date === undefined) {
            return;
        } else {
            return instance.get(`/designers/${id}/timetable/${date}`);
        }
    },
    reservTimeV2: (id, date) => {
        if (date === undefined) {
            return;
        } else {
            return instance.get(`/designers/${id}/timetable/${date}/v2`);
        }
    },
    reservaionAdd: (id, data) => {
        return instance.post(`/designers/${id}/reservations`, data);
    },
    reservation: (page, filter) => {
        return instance.get('/reservations', {
            params: {
                page: page,
                status: filter,
            },
        });
    },
    reservationRead: (id) => {
        return instance.get(`/reservations/${id}`);
    },
    reservationUpdate: (id, data) => {
        return instance.put(`/reservations/${id}`, data);
    },
    reservationSummary: (id) => {
        return instance.get(`/users/${id}/reservation-summary`);
    },
};

export const useReservTimeQuery = (params) => {
    const { id, date } = params;

    return useQuery({
        queryKey: ['reservTime', date ? date : 'date'],
        queryFn: () => reservationService.reservTime(id, date),
        enabled: false,
    });
};

export const useReservTimeV2Query = (params) => {
    const { id, date } = params;

    return useQuery({
        queryKey: ['reservTime', id, date], 
        queryFn: () => reservationService.reservTimeV2(id, date),
        gcTime: 1000 * 60 * 1,// 1분
        enabled: !!id && !!date,
    });
};


export const useReservaionAddMutation = (onSuccess, onError) => {
    return useMutation({
        mutationFn: async (obj) => {
            let { id, data } = obj;
            const idArray = data.menu.map((obj) => obj.id);
            data = { ...data, menu: idArray };
            const response = await reservationService.reservaionAdd(id, data);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            onError(error.response.data.message);
            console.log('msg', error.response.data.message);
        },
    });
};

export const useReservationQuery = (filter) => {
    return useInfiniteQuery({
        queryKey: ['reservation', filter],
        queryFn: ({ pageParam }) => reservationService.reservation(pageParam, filter),
        initialPageParam: 1,
        getNextPageParam: (lastPage, allPages) => {
            return lastPage.data.nextPage;
        },
    });
};

export const useReservationReadQuery = (id) => {
    return useQuery({
        queryKey: ['reservationRead', id],
        queryFn: () => reservationService.reservationRead(id),
    });
};

export const useReservationUpdateMutation = (onSuccess) => {
    return useMutation({
        mutationFn: async (obj) => {
            const { reservId, data } = obj;
            console.log('obj', obj);
            const response = await reservationService.reservationUpdate(reservId, data);
            return response;
        },
        onSuccess: async (res) => {
            console.log('성공:', res);
            onSuccess(res);
            return res;
        },
        onError: async (error) => {
            console.log(error);
        },
    });
};

export const useReservationSummaryQuery = (id) => {
    return useQuery({
        queryKey: ['reservationSummary'],
        queryFn: () => reservationService.reservationSummary(id),
    });
};
