import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
    FlxbtFullMb10,
    Flx,
    Spacing70,
    FieldSet,
    Pad20,
    PopShortMsg,
    Block,
    PadVert10,
    InputWrapper,
    JoinInput,
    BorderBox,
    Mb10,
    TimeItem,
    ListCol4,
    Spacing30,
    StickyBtm,
    LinkFull,
    RsvTab,
    TabItem,
    Gap20,
    InfoLabel,
    InfoValue,
    PriceItemBorder,
    ItemInfoCol2,
    Paragraph,
    Fz15,
    BtnFullModal,
    Fz18Medium,
    Container,
    Mb20,
    Fz15Bold,
    Fz16Medium,
    Mb30,
    Mb1rem,
    FlxMiddle,
    Gap10,
    SectionDivider50,
    ContainerPadding,
    DesignerPrices,
    TxRight,
    OrgPrice,
    ConsumerPrice,
    FlxMiddleMb20,
    Mb15,
    RsvTermBox,
    ChkAndTxt,
    Spacing20,
    Mb40,
} from '@/css/style';
import { compareAsc, format, getDay, getHours, getMinutes, getWeekOfMonth, parse } from 'date-fns';
import { useDesignerDetailQuery, useDesignerMenuQuery } from '@/services/designerService';
import { useProductCategoryQuery } from '@/services/productService';
import { useReservTimeQuery, useReservaionAddMutation } from '@/services/reservationService';
import { useMyQuery } from '@/services/authService';
import useDayoffsStore from '@/stores/designer/dayoffs';
import useReservationStore from '@/stores/user/reservation';
import useUserStore from '@/stores/user/user';
import Chkbox from '@/components/Chkbox';
import NewModal from '@/components/NewModal';
import RsvDayPicker from '@/components/DayPicker';
import TopStepBar from '@/components/TopStepBar';
import arrowDownGray from '@/assets/img/arrow_down_thin_gray.svg';
import arrowUpGray from '@/assets/img/arrow_up_thin_gray.svg';
import icRsvInfo from '@/assets/img/ic_rsv_info.svg';
import icRsvUser from '@/assets/img/ic_rsv_user.svg';
import { dayMappings } from '@/utils/helper';
import useShowStore from '@/stores/common/show';
import NoContent from '@/components/NoContent';
import { useIsMutating } from '@tanstack/react-query';
import useWindowSize from '@/hooks/useWindowSize';

function Reservation() {
    const { navigate } = useOutletContext();
    const windowSize = useWindowSize();
    const [timeTable, setTimeTable] = useState({
        am: [],
        pm: [],
    });
    const location = useLocation();
    const ds = useDayoffsStore();
    const rs = useReservationStore();
    const us = useUserStore();
    const isMutating = useIsMutating();
    const ss = useShowStore();
    // const navigate = useNavigate();
    const params = useParams();

    const { data: reservTimeData, refetch: refetchReservTimeData } = useReservTimeQuery({
        id: params.id,
        date: rs.reserv.date ? rs.reserv.date : undefined,
    });
    const { data: designerDetailData, isLoading } = useDesignerDetailQuery(params.id);
    const { data: categoryData } = useProductCategoryQuery();
    // const { data: designerMenuData } = useDesignerMenuQuery(params.id, {
    //     category: rs.selectedCategory,
    // });
    const { data: designerMenuData } = useDesignerMenuQuery(params.id, {
        sort: 'averageRating',
    });

    const reservationAdd = useReservaionAddMutation(
        () => rs.showModal('complete'),
        (err) => {
            rs.setErr(err);
            rs.showModal('fail');
        }
    );

    const { data } = useMyQuery((data) => {
        rs.setReservFromServer({
            name: data.data.name,
            phone: data.data.phone,
            email: data.data.user_id,
        });
    });

    useEffect(() => {
        let firstDayOfMonth;
        if (!ds.daypicker.currentMonth) {
            firstDayOfMonth = new Date();
            firstDayOfMonth.setDate(1);
        } else {
            firstDayOfMonth = ds.daypicker.currentMonth;

            firstDayOfMonth.setDate(1);
        }

        getDisabledDates(firstDayOfMonth);
        if (designerDetailData) {
            ds.setWeekTable(designerDetailData.data.holidayByDay);
            ds.setSelected(designerDetailData.data.holidayByDay);
        }
    }, [designerDetailData]);

    useEffect(() => {
        if (reservTimeData?.data && typeof reservTimeData.data.filter === 'function') {
            const am = reservTimeData.data.filter((item) => {
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const itemTime = parse(item, 'HH:mm', new Date());
                const currentTime = parse(
                    getHours(new Date()) + ':' + getMinutes(new Date()),
                    'HH:mm',
                    new Date()
                );
                if (rs.reserv.date === currentDate) {
                    return compareAsc(itemTime, currentTime) && item < '12:00';
                } else {
                    return item < '12:00';
                }
            });
            const pm = reservTimeData.data.filter((item) => {
                const currentDate = format(new Date(), 'yyyy-MM-dd');
                const itemTime = parse(item, 'HH:mm', new Date());
                const currentTime = parse(
                    getHours(new Date()) + ':' + getMinutes(new Date()),
                    'HH:mm',
                    new Date()
                );
                if (rs.reserv.date === currentDate) {
                    return compareAsc(itemTime, currentTime) && item >= '12:00';
                } else {
                    return item >= '12:00';
                }
            });

            setTimeTable({ am, pm });
        }
    }, [reservTimeData]);

    useEffect(() => {
        refetchReservTimeData();
    }, [rs.reserv.date, refetchReservTimeData]);

    useEffect(() => {
        if (categoryData) {
            rs.setCategoryFromServer(categoryData.data);
        }
    }, [categoryData]);

    useEffect(() => {
        return () => {
            rs.terminate();
        };
    }, []);

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [isLoading]);

    const getDisabledDates = (currentMonth) => {
        const disabledDates = [];

        for (
            let day = 1;
            day <= new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0).getDate();
            day++
        ) {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), day);
            const dayNumber = getDay(date);
            const weekNumber = getWeekOfMonth(date);

            designerDetailData?.data.holidayByDay.forEach(({ day, week }) => {
                if (day === dayNumber && week.includes(weekNumber)) {
                    disabledDates.push(format(date, 'yyyy-MM-dd'));
                }
            });
        }

        ds.setHolidayByDay(disabledDates);
    };

    const toggleField = (fieldName, direction) => {
        const currentIndex = rs.screenOrder.indexOf(fieldName);
        const canGoForward =
            direction === 'forward' &&
            currentIndex !== -1 &&
            currentIndex !== rs.screenOrder.length - 1;
        const canGoBackward = direction === 'backward' && currentIndex !== -1 && currentIndex !== 0;

        if (
            canGoForward &&
            Object.values(rs.validations[rs.currentScreen]).every((value) => value === true)
        ) {
            rs.setScreen(fieldName, false);
            const newIndex = currentIndex + 1;
            rs.setScreen(rs.screenOrder[newIndex], true);
            rs.setCurrentScreen(rs.screenOrder[newIndex]);
            rs.setCurrentValidate(rs.validatesOrder[newIndex]);
        } else if (canGoBackward) {
            rs.setScreen(fieldName, false);
            const newIndex = currentIndex - 1;
            rs.setScreen(rs.screenOrder[newIndex], true);
            rs.setCurrentScreen(rs.screenOrder[newIndex]);
            rs.setCurrentValidate(rs.validatesOrder[newIndex]);
        } else if (
            currentIndex === rs.screenOrder.length - 1 &&
            Object.values(rs.validations[rs.currentScreen]).every((value) => value === true)
        ) {
            reservationAdd.mutate({ id: params.id, data: rs.reserv });
        }
        if (currentIndex === 0 && direction === 'backward') {
            navigate(-1);
        }
    };

    if (!isLoading) {
        return (
            <>
                <Container $pb="6.46rem">
                    <TopStepBar
                        isCancelBtn={() => {
                            if (!location.state?.previousUrl) {
                                navigate('/', { state: { previousUrl: location.pathname } });
                            } else {
                                navigate(-1, { state: { previousUrl: location.pathname } });
                            }
                        }}
                        title="예약하기"
                    />
                    <FieldSet $visible={rs.screen.step1}>
                        {/* 달력 */}
                        <style>{rsvPickerStyle}</style>
                        <Spacing20 />
                        <ContainerPadding>
                            <RsvDayPicker
                                mode="single"
                                selected={new Date(rs.reserv.date)}
                                onSelect={(day) => {
                                    rs.setReserv('date', day ? format(day, 'yyyy-MM-dd') : null);
                                }}
                                onMonthChange={(date) => {
                                    const firstDayOfMonth = new Date(
                                        date.getFullYear(),
                                        date.getMonth(),
                                        1
                                    );
                                    ds.setCurrentMonth(firstDayOfMonth);
                                    getDisabledDates(firstDayOfMonth);
                                }}
                                disabled={[
                                    {
                                        dayOfWeek: (
                                            designerDetailData?.data.shop.opening_hour || []
                                        )
                                            .filter((item) => item.type === 'dayoff')
                                            .map((item) => dayMappings[item.day].dayNumber),
                                    },
                                    { before: new Date() },
                                    ...[
                                        ...ds.daypicker.holidayByDay,
                                        ...(designerDetailData?.data.holidayByDate || []).map(
                                            (items) => {
                                                return items.date;
                                            }
                                        ),
                                    ]
                                        .filter((date) => {
                                            const workDates =
                                                designerDetailData?.data.workDate || [];
                                            return !workDates
                                                .map((items) => items.date)
                                                .includes(date);
                                        })
                                        .map((date) => new Date(date + 'T00:00:00')),
                                ]}
                                modifiers={{
                                    holiday: [
                                        ...ds.daypicker.holidayByDay,
                                        ...(designerDetailData?.data.holidayByDate || []).map(
                                            (items) => {
                                                return items.date;
                                            }
                                        ),
                                    ].map((date) => new Date(date + 'T00:00:00')),
                                    work: [
                                        ...(designerDetailData?.data.workDate || []).map(
                                            (items) => {
                                                return items.date;
                                            }
                                        ),
                                    ].map((date) => new Date(date + 'T00:00:00')),
                                    shop_holiday: [
                                        {
                                            dayOfWeek: (
                                                designerDetailData?.data.shop.opening_hour || []
                                            )
                                                .filter((item) => item.type === 'dayoff')
                                                .map((item) => dayMappings[item.day].dayNumber),
                                        },
                                    ],
                                }}
                                modifiersClassNames={{
                                    holiday: 'offday',
                                    work: 'workday',
                                    shop_holiday: 'offday',
                                }}
                            />
                        </ContainerPadding>
                        {/* 달력 */}
                        {reservTimeData && !reservTimeData?.data.length > 0 && (
                            <NoContent message="현재 예약 가능한 시간이 없습니다."></NoContent>
                        )}
                        {reservTimeData &&
                            rs.reserv.date &&
                            (timeTable.am.length > 0 || timeTable.pm.length > 0) && (
                                <>
                                    <SectionDivider50 />
                                    <ContainerPadding>
                                        {/* 시간 선택 */}
                                        <Fz18Medium $mb="1.538rem">예약시간</Fz18Medium>
                                        {timeTable.am.length > 0 ? (
                                            <>
                                                <Mb10>
                                                    <Fz15>오전</Fz15>
                                                </Mb10>
                                                <Mb20>
                                                    <ListCol4>
                                                        {/* 메모: classname="disabled" 적용안됨 */}
                                                        {timeTable.am.map((item) => (
                                                            <TimeItem
                                                                key={item}
                                                                id={item}
                                                                $active={rs.reserv.time === item}
                                                                className={
                                                                    item.isReserved
                                                                        ? 'disabled'
                                                                        : ''
                                                                }
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="time"
                                                                    value={item}
                                                                    onChange={() =>
                                                                        rs.setReserv('time', item)
                                                                    }
                                                                />
                                                                {item}
                                                            </TimeItem>
                                                        ))}
                                                    </ListCol4>
                                                </Mb20>
                                            </>
                                        ) : null}
                                        {timeTable.pm.length > 0 ? (
                                            <>
                                                <Mb10>
                                                    <Fz15>오후</Fz15>
                                                </Mb10>
                                                <ListCol4>
                                                    {timeTable.pm.map((item) => (
                                                        <TimeItem
                                                            key={item}
                                                            id={item}
                                                            $active={rs.reserv.time === item}
                                                            className={
                                                                item.isReserved ? 'disabled' : ''
                                                            }
                                                        >
                                                            <input
                                                                type="radio"
                                                                name="time"
                                                                value={item}
                                                                onChange={() =>
                                                                    rs.setReserv('time', item)
                                                                }
                                                            />
                                                            {item}
                                                        </TimeItem>
                                                    ))}
                                                </ListCol4>
                                            </>
                                        ) : null}
                                    </ContainerPadding>
                                    {rs.reserv.time ? (
                                        <>
                                            <SectionDivider50 />
                                            <ContainerPadding>
                                                {/* 안내사항 */}
                                                {designerDetailData?.data.shop
                                                    .reservation_notice ? (
                                                    <Block>
                                                        <Fz18Medium $mb="1.538rem">
                                                            안내사항
                                                        </Fz18Medium>
                                                        <Paragraph $col="#000" $fz="1.154rem">
                                                            {
                                                                designerDetailData?.data.shop
                                                                    .reservation_notice
                                                            }
                                                        </Paragraph>
                                                    </Block>
                                                ) : null}
                                                {/* //안내사항 */}
                                                {/* 시술 메뉴 선택 */}
                                                <Block>
                                                    <Fz18Medium $mb="1.538rem">
                                                        시술 메뉴 선택
                                                    </Fz18Medium>
                                                    <RsvTab
                                                        $maxwidth={
                                                            windowSize.width < 440
                                                                ? windowSize.width
                                                                : 440
                                                        }
                                                    >
                                                        <TabItem
                                                            $active={
                                                                rs.category.find(
                                                                    (item) => item.id === ''
                                                                )?.selected
                                                            }
                                                            onClick={() => rs.setCategory('')}
                                                        >
                                                            전체
                                                        </TabItem>
                                                        {categoryData?.data.map((category) => {
                                                            const selectedCategory =
                                                                rs.category.find(
                                                                    (item) =>
                                                                        item.id === category.id
                                                                );
                                                            return (
                                                                designerMenuData?.data.find(
                                                                    (d) =>
                                                                        d.category === category.id
                                                                ) && (
                                                                    <TabItem
                                                                        key={category.id}
                                                                        $active={
                                                                            selectedCategory?.selected
                                                                        }
                                                                        onClick={() =>
                                                                            rs.setCategory(
                                                                                category.id
                                                                            )
                                                                        }
                                                                    >
                                                                        {category.name}
                                                                    </TabItem>
                                                                )
                                                            );
                                                        })}
                                                    </RsvTab>
                                                    <Mb30>
                                                        <DesignerPrices
                                                            style={{
                                                                borderTop: '1px solid #e8eaed',
                                                                borderBottom: '1px solid #e8eaed',
                                                            }}
                                                        >
                                                            {designerMenuData?.data.map(
                                                                (items) =>
                                                                    (rs.selectedCategory === '' ||
                                                                        items.category ===
                                                                            rs.selectedCategory) && (
                                                                        <PriceItemBorder
                                                                            key={items.id}
                                                                        >
                                                                            <FlxbtFullMb10
                                                                                as="label"
                                                                                htmlFor={items.id}
                                                                            >
                                                                                <Fz16Medium>
                                                                                    {items.name}
                                                                                </Fz16Medium>
                                                                                <FlxMiddle>
                                                                                    {items.normal_price ===
                                                                                    items.price ? (
                                                                                        <TxRight>
                                                                                            <ConsumerPrice>
                                                                                                {items.normal_price.toLocaleString()}
                                                                                                원
                                                                                            </ConsumerPrice>
                                                                                        </TxRight>
                                                                                    ) : (
                                                                                        <TxRight>
                                                                                            {items.price !=
                                                                                                0 && (
                                                                                                <OrgPrice>
                                                                                                    {items.normal_price.toLocaleString()}
                                                                                                </OrgPrice>
                                                                                            )}
                                                                                            <ConsumerPrice>
                                                                                                {items.price ==
                                                                                                0
                                                                                                    ? '무료'
                                                                                                    : `${items.price.toLocaleString()}원`}
                                                                                            </ConsumerPrice>
                                                                                        </TxRight>
                                                                                    )}
                                                                                    <Gap20 />
                                                                                    <Chkbox
                                                                                        checked={rs.reserv.menu.some(
                                                                                            (
                                                                                                item
                                                                                            ) =>
                                                                                                item.id ===
                                                                                                items.id
                                                                                        )}
                                                                                        id={
                                                                                            items.id
                                                                                        }
                                                                                        onChange={() =>
                                                                                            rs.setMenu(
                                                                                                items
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </FlxMiddle>
                                                                            </FlxbtFullMb10>
                                                                            <p>{items.option}</p>
                                                                        </PriceItemBorder>
                                                                    )
                                                            )}
                                                        </DesignerPrices>
                                                    </Mb30>
                                                    <Flx>
                                                        <Fz15>*&nbsp;</Fz15>
                                                        <Fz15>
                                                            매장에서 디자이너와 상담 후 확정된
                                                            시술메뉴와 금액으로 결제됩니다.
                                                        </Fz15>
                                                    </Flx>
                                                </Block>
                                                {/* //시술 메뉴 선택 */}
                                            </ContainerPadding>
                                        </>
                                    ) : null}
                                </>
                            )}

                        {/* //시간 선택 */}
                    </FieldSet>
                    <FieldSet $visible={rs.screen.step2}>
                        {/* <Spacing70 /> */}
                        <ContainerPadding>
                            <Mb40>
                                <BorderBox>
                                    <FlxMiddleMb20>
                                        <img src={icRsvInfo} />
                                        <Gap10 />
                                        <Fz18Medium>예약현황</Fz18Medium>
                                    </FlxMiddleMb20>
                                    <ul>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>디자이너</InfoLabel>
                                                <InfoValue>
                                                    {designerDetailData?.data.title}
                                                </InfoValue>
                                            </ItemInfoCol2>
                                        </Mb15>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>예약 일자</InfoLabel>
                                                <InfoValue>
                                                    {new Date(rs.reserv.date).toLocaleDateString(
                                                        'ko-KR',
                                                        {
                                                            weekday: 'long',
                                                            year: 'numeric',
                                                            month: 'long',
                                                            day: 'numeric',
                                                        }
                                                    )}
                                                </InfoValue>
                                            </ItemInfoCol2>
                                        </Mb15>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>예약 시간</InfoLabel>
                                                <InfoValue>
                                                    {rs.reserv.time.replace(':', '시 ') + '분'}
                                                </InfoValue>
                                            </ItemInfoCol2>
                                        </Mb15>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>시술 메뉴</InfoLabel>

                                                <InfoValue>
                                                    {rs.reserv.menu.map((item, index) => (
                                                        <React.Fragment key={item.id}>
                                                            {index > 0 && ', '}
                                                            {item.name}
                                                        </React.Fragment>
                                                    ))}
                                                </InfoValue>
                                            </ItemInfoCol2>
                                        </Mb15>
                                        <li>
                                            <ItemInfoCol2>
                                                <InfoLabel>예약 상품</InfoLabel>
                                                <InfoValue>
                                                    <OrgPrice>
                                                        {rs.reserv.menu
                                                            .reduce(
                                                                (total, item) =>
                                                                    total + item.normal_price,
                                                                0
                                                            )
                                                            .toLocaleString()}
                                                    </OrgPrice>
                                                    <ConsumerPrice>
                                                        {rs.reserv.menu
                                                            .reduce(
                                                                (total, item) => total + item.price,
                                                                0
                                                            )
                                                            .toLocaleString()}
                                                        원
                                                    </ConsumerPrice>
                                                </InfoValue>
                                            </ItemInfoCol2>
                                        </li>
                                    </ul>
                                </BorderBox>
                                <Spacing20 />
                                <BorderBox>
                                    <FlxMiddleMb20>
                                        <img src={icRsvUser} />
                                        <Gap10 />
                                        <Fz18Medium>방문자 정보</Fz18Medium>
                                    </FlxMiddleMb20>
                                    <ul>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>방문자</InfoLabel>
                                                <JoinInput
                                                    type="text"
                                                    id="rsvName"
                                                    name="rsvName"
                                                    value={rs.reserv.name}
                                                    onChange={(e) =>
                                                        rs.setReserv('name', e.target.value)
                                                    }
                                                    placeholder="이름을 입력해 주세요"
                                                />
                                            </ItemInfoCol2>
                                        </Mb15>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>연락처</InfoLabel>
                                                <JoinInput
                                                    type="text"
                                                    id="rsvPhone"
                                                    name="rsvPhone"
                                                    value={rs.reserv.phone}
                                                    onChange={(e) =>
                                                        rs.setReserv('phone', e.target.value)
                                                    }
                                                    placeholder="연락처를 입력해 주세요"
                                                />
                                            </ItemInfoCol2>
                                        </Mb15>
                                        <Mb15 as="li">
                                            <ItemInfoCol2>
                                                <InfoLabel>이메일</InfoLabel>
                                                <JoinInput
                                                    type="text"
                                                    id="rsvEmail"
                                                    name="rsvEmail"
                                                    value={rs.reserv.email}
                                                    onChange={(e) =>
                                                        rs.setReserv('email', e.target.value)
                                                    }
                                                    placeholder="이메일을 입력해 주세요"
                                                />
                                            </ItemInfoCol2>
                                        </Mb15>
                                    </ul>
                                </BorderBox>
                                <Spacing30 />
                                <PadVert10>
                                    <Fz15Bold>요청사항</Fz15Bold>
                                </PadVert10>
                                <Mb20>
                                    <InputWrapper>
                                        <JoinInput
                                            type="text"
                                            id="rsvRequest"
                                            name="rsvRequest"
                                            value={rs.reserv.memo}
                                            onChange={(e) => rs.setReserv('memo', e.target.value)}
                                            placeholder="디자이너에게 요청하실 내용"
                                        />
                                    </InputWrapper>
                                </Mb20>
                            </Mb40>
                            <Mb40>
                                <Fz18Medium $mb="1.538rem">결제 방식</Fz18Medium>
                                <Mb1rem>
                                    <Fz15 $col="#000">매장 직접 결제</Fz15>
                                </Mb1rem>
                                <Fz15 $col="#FF3062">
                                    · 디자이너님과 상담 후 비용이 변경될 수 있습니다.
                                </Fz15>
                            </Mb40>
                            <Block>
                                <Fz18Medium $mb="1.538rem">개인정보 수집 및 제공</Fz18Medium>
                                <Mb20>
                                    <BorderBox>
                                        <RsvTermBox onClick={() => rs.toggleState('term1')}>
                                            개인정보 수집 동의
                                            <img
                                                src={rs.toggle.term1 ? arrowUpGray : arrowDownGray}
                                            />
                                        </RsvTermBox>
                                        {rs.toggle.term1 && (
                                            <>
                                                <Spacing20 />
                                                <Paragraph>
                                                    개인정보 수집 동의 1. 기본수집항목: [필수] 이름,
                                                    (휴대)전화번호, [선택] 이메일 주소※ 추가
                                                    수집하는 필수항목- 예약자와 실제 방문자가 다른
                                                    경우 : 방문자의 이름 및 휴대전화번호- 배송, 방문
                                                    등이 필요한 상품 구매 시 : 주소- 해외 여행 관련
                                                    상품 구매 시 : 여권상 영문명, 여권번호 끝 4자리,
                                                    성별, 생년월일, 이메일주소, 카카오톡ID, 동행
                                                    아동정보(여권상 영문명, 생년월일, 신장)- 병원을
                                                    이용하는 경우: 생년월일 (병원 재진 시 이전
                                                    진료기록 조회를 위해 예약자명, 생년월일,
                                                    전화번호가 수집될 수 있습니다.)- 자동차 검사소
                                                    예약 시 : 차량번호, [선택](사회적약자 수수료
                                                    감면 대상인 경우)기초생활대상자, 국가유공자,
                                                    한부모가족, 다자녀, 교통사고지원가족,
                                                    교통안전의인 중 해당 여부2. 수집 및 이용목적 :
                                                    사업자회원과 예약이용자의 원활한 거래 진행,
                                                    고객상담, 불만처리 등 민원 처리, 분쟁조정 해결을
                                                    위한 기록보존3. 보관기간- 회원탈퇴 시 지체없이
                                                    파기- 단, 자동차 검사소 예약시 선택항목은 별도
                                                    저장하지 않음- 단, 관련 법령에 의하여 일정 기간
                                                    보관이 필요한 경우에는 해당 기간 동안 보관함4.
                                                    동의 거부권 등에 대한 고지: 정보주체는
                                                    개인정보의 수집 및 이용 동의를 거부할 권리가
                                                    있으나, 이 경우 상품 및 서비스 예약이 제한될 수
                                                    있습니다. 그 밖의 내용은 네이버 개인정보
                                                    처리방침을 따릅니다.
                                                </Paragraph>
                                            </>
                                        )}
                                    </BorderBox>
                                </Mb20>
                                <BorderBox>
                                    <RsvTermBox onClick={() => rs.toggleState('term2')}>
                                        개인정보 제공 동의
                                        <img src={rs.toggle.term2 ? arrowUpGray : arrowDownGray} />
                                    </RsvTermBox>
                                    {rs.toggle.term2 && (
                                        <>
                                            <Spacing20 />
                                            <Paragraph>
                                                개인정보 수집 동의 1. 기본수집항목: [필수] 이름,
                                                (휴대)전화번호, [선택] 이메일 주소※ 추가 수집하는
                                                필수항목- 예약자와 실제 방문자가 다른 경우 :
                                                방문자의 이름 및 휴대전화번호- 배송, 방문 등이
                                                필요한 상품 구매 시 : 주소- 해외 여행 관련 상품 구매
                                                시 : 여권상 영문명, 여권번호 끝 4자리, 성별,
                                                생년월일, 이메일주소, 카카오톡ID, 동행
                                                아동정보(여권상 영문명, 생년월일, 신장)- 병원을
                                                이용하는 경우: 생년월일 (병원 재진 시 이전 진료기록
                                                조회를 위해 예약자명, 생년월일, 전화번호가 수집될 수
                                                있습니다.)- 자동차 검사소 예약 시 : 차량번호,
                                                [선택](사회적약자 수수료 감면 대상인
                                                경우)기초생활대상자, 국가유공자, 한부모가족, 다자녀,
                                                교통사고지원가족, 교통안전의인 중 해당 여부2. 수집
                                                및 이용목적 : 사업자회원과 예약이용자의 원활한 거래
                                                진행, 고객상담, 불만처리 등 민원 처리, 분쟁조정
                                                해결을 위한 기록보존3. 보관기간- 회원탈퇴 시
                                                지체없이 파기- 단, 자동차 검사소 예약시 선택항목은
                                                별도 저장하지 않음- 단, 관련 법령에 의하여 일정 기간
                                                보관이 필요한 경우에는 해당 기간 동안 보관함4. 동의
                                                거부권 등에 대한 고지: 정보주체는 개인정보의 수집 및
                                                이용 동의를 거부할 권리가 있으나, 이 경우 상품 및
                                                서비스 예약이 제한될 수 있습니다. 그 밖의 내용은
                                                네이버 개인정보 처리방침을 따릅니다.
                                            </Paragraph>
                                        </>
                                    )}
                                </BorderBox>
                            </Block>
                            <Block>
                                <ChkAndTxt>
                                    <Chkbox
                                        checked={rs.toggle.isAgree}
                                        onChange={() => rs.toggleState('isAgree')}
                                    />
                                    <Fz15 $col="#000" as="p">
                                        예약 서비스 이용을 위한 개인정보 수집 및 제3자
                                        제공,취소/환불 규정을 확인하였으며 이에 동의합니다.
                                    </Fz15>
                                </ChkAndTxt>
                            </Block>
                        </ContainerPadding>
                    </FieldSet>
                    {rs.reserv.time ? (
                        <StickyBtm
                            onClick={() => {
                                toggleField(
                                    rs.screenOrder[rs.screenOrder.indexOf(rs.currentScreen)],
                                    'forward'
                                );
                            }}
                            disabled={!!isMutating}
                        >
                            <LinkFull
                                $active={Object.values(rs.validations[rs.currentScreen]).every(
                                    (value) => value === true
                                )}
                            >
                                {rs.screenOrder[rs.screenOrder.indexOf(rs.currentScreen)] ===
                                'step2'
                                    ? '동의하고 예약완료'
                                    : '다음'}
                            </LinkFull>
                        </StickyBtm>
                    ) : null}
                </Container>
                {/* 예약 완료 모달 */}
                <NewModal
                    isOpen={rs.modal.complete}
                    onRequestClose={() => rs.showModal(false)}
                    contentLabel="탈퇴 완료"
                    $visible
                >
                    <Pad20>
                        <PopShortMsg>
                            <Mb20>
                                <Fz15Bold $col="#000">예약이 완료 되었습니다</Fz15Bold>
                            </Mb20>
                            <Paragraph $fz="1.154rem">
                                {new Date(rs.reserv.date).toLocaleDateString('ko-KR', {
                                    weekday: 'long',
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                })}
                                {' ' + rs.reserv.time.replace(':', '시 ') + '분'}
                                <br />
                                {designerDetailData?.data.title}
                                <br />
                                예약해 주셔서 감사합니다.
                            </Paragraph>
                        </PopShortMsg>
                        <BtnFullModal $active onClick={() => navigate('/', { replace: true })}>
                            확인
                        </BtnFullModal>
                    </Pad20>
                </NewModal>
                <NewModal
                    isOpen={rs.modal.fail}
                    onRequestClose={() => rs.showModal(false)}
                    contentLabel="예약 실패"
                    $visible
                >
                    <Pad20>
                        <PopShortMsg>
                            <Mb20>
                                <Fz15Bold $col="#000">예약을 실패하였습니다.</Fz15Bold>
                            </Mb20>
                            <Paragraph $fz="1.154rem">{rs.modal.msg}</Paragraph>
                        </PopShortMsg>
                        <BtnFullModal
                            $active
                            onClick={() => {
                                window.location.reload();
                            }}
                        >
                            확인
                        </BtnFullModal>
                    </Pad20>
                </NewModal>
                {/* //예약 완료 모달 */}
            </>
        );
    }
}
const rsvPickerStyle = `
.offday.workday.rdp-day_selected, 
.offday.workday.rdp-day_selected:focus-visible, 
.offday.workday.rdp-day_selected:hover {
    padding-top:0;
    align-items:center;
    background-color:#00BF9D !important;
    color:#fff;
}
`;
export default Reservation;
