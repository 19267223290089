import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { instance } from '@services/api';
import { useCallNiceCertScreenMutation } from './externalService';

const authService = {
    my: () => {
        return instance.get('/auth/me');
    },
    refreshToken: () => {
        return instance.post('/auth/refresh-token');
    },
    login: (data) => {
        return instance.post('/auth/authenticate', data);
    },
    regist: (data) => {
        return instance.post('/auth/user/register', data);
    },
    registDesigner: (data) => {
        return instance.post('/auth/designer/register', data);
    },
    checkPhone: (phoneNumber) => {
        return instance.get(`/auth/phone/${phoneNumber}/check`);
    },
    verify: (phoneNumber) => {
        return instance.get(`/auth/phone/${phoneNumber}`);
    },
    verifyConfirm: (data) => {
        return instance.post(`/auth/phone/${data.phoneNumber}/${data.code}/verify`, data);
    },
    chkId: (id) => {
        return instance.get(`/auth/${id}/check`);
    },
    chkNick: (nickname) => {
        return instance.get(`/auth/nickname/${nickname}/check`);
    },
    delete: (params) => {
        return instance.post(`/auth/leave`, params);
    },
    findIdVerify: (phoneNumber) => {
        return instance.get(`/auth/find-id/${phoneNumber}`);
    },
    findIdVerifyConfirm: (data) => {
        return instance.post(`/auth/find-id/${data.phoneNumber}/${data.code}/verify`, data);
    },
    findPasswordVerify: (phoneNumber) => {
        return instance.get(`/auth/find-pw/${phoneNumber}`);
    },
    findPasswordVerifyConfirm: (data) => {
        return instance.post(`/auth/find-pw/${data.phoneNumber}/${data.code}/verify`, data);
    },
    getCertificateData: (receivedata) => {
        return instance.get(`/nice?receivedata=${receivedata}`);
    },
    getDecryptData: (data) => {
        return instance.get(
            `/nice/callback?token_version_id=${data.token_version_id}&enc_data=${data.enc_data}`
        );
    },
};

export const useMyQuery = (onSuccess) => {
    return useQuery({
        queryKey: ['my'],
        queryFn: () =>
            authService.my().then((res) => {
                if (onSuccess) {
                    onSuccess(res);
                }
                return res;
            }),
    });
};

export const useRefreshTokenMutation = (us) => {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async () => {
            const response = await authService.refreshToken();
            return response;
        },
        onSuccess: (res) => {
            us.registUser(res.data);
            return res;
        },
        onError: (error) => {
            queryClient.clear();
            localStorage.clear();
            us.logout();
        },
    });
};

export const useLoginMutation = (us, func) => {
    const navigate = useNavigate();
    const redirect = localStorage.getItem('redirect');
    return useMutation({
        mutationFn: async (data) => {
            const response = await authService.login(data);
            return response;
        },
        onSuccess: (res) => {
            us.registUser(res.data);
            redirect ? navigate(redirect, { replace: true }) : navigate('/', { replace: true });
        },
        onError: (error) => {
            func(error);
        },
    });
};

export const useCheckPhoneQuery = (js, onSuccess, onError) => {
    return useQuery({
        queryKey: ['checkPhone', js.fields.phone],
        queryFn: () =>
            authService.checkPhone(js.fields.phone).then(
                (res) => {
                    onSuccess(res);
                    return res;
                },
                (error) => {
                    onError(error);
                    throw error;
                }
            ),
        enabled: false,
    });
};

export const useVerifyQuery = (js, onSuccess, onError) => {
    return useQuery({
        queryKey: ['verify', js.fields.phone],
        queryFn: () =>
            authService.verify(js.fields.phone).then(
                (res) => {
                    onSuccess(res);
                    return res;
                },
                (error) => {
                    onError(error);
                    throw error;
                }
            ),
        enabled: false,
    });
};

export const useChkIdQuery = (js, onSuccess, onError) => {
    return useQuery({
        queryKey: ['chkId', js.fields.user_id],
        queryFn: () =>
            authService.chkId(js.fields.user_id).then(
                (res) => {
                    onSuccess(res);
                    return res;
                },
                (error) => {
                    onError(error);
                    throw error;
                }
            ),
        enabled: false,
    });
};

export const useChkNickQuery = (js, onSuccess, onError) => {
    return useQuery({
        queryKey: ['chkNick', js.fields.nickname],
        queryFn: () =>
            authService.chkNick(js.fields.nickname).then(
                (res) => {
                    onSuccess(res);
                    return res;
                },
                (error) => {
                    onError(error);
                    throw error;
                }
            ),
        enabled: false,
    });
};

export const useVerifyConfirmMutation = (js) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await authService.verifyConfirm(data);
            return response;
        },
        onSuccess: (res) => {
            js.setVerify('isVerified', true);
            js.verifyValidate();
        },
        onError: (error) => {
            js.setVerify('isVerified', false);
            js.verifyValidate();
            js.togglePopUp('mismatchCode');
        },
    });
};

export const useRegistMutation = (js, us) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await authService.regist(data);
            return response;
        },
        onSuccess: (data) => {
            js.togglePopUp('joinComplete');
            us.registUser(data.data);
        },
        onError: (error) => {
            console.log(error.response.data.message);
            js.setRegistError(error.response.data.message);
            js.togglePopUp('joinFail');
        },
    });
};

export const useRegistDesignerMutation = (js, us) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await authService.registDesigner(data);
            return response;
        },
        onSuccess: (data) => {
            js.togglePopUp('joinComplete');
            us.registUser(data.data);
        },
        onError: (error) => {
            console.error('실패:', error);
            // 에러 발생 시 수행할 작업 추가
        },
    });
};

export const useDeleteMutation = (func) => {
    return useMutation({
        mutationFn: async (obj) => {
            const response = await authService.delete(obj);
            return response;
        },
        onSuccess: (res) => {
            func(res);
            return res;
        },
        onError: (error) => {
            console.log(error);
        },
    });
};

export const useFindIdVerifyQuery = (js, screen, onSuccess, onError) => {
    return useQuery({
        queryKey: ['findIdVerify', js.fields[screen].phone],
        queryFn: () =>
            authService.findIdVerify(js.fields[screen].phone).then(
                (res) => {
                    onSuccess(res);
                    return res;
                },
                (error) => {
                    onError(error);
                    throw error;
                }
            ),
        enabled: false,
    });
};

export const useFindIdVerifyConfirmMutation = (phone, onSuccess, onError) => {
    return useMutation({
        mutationKey: ['findIdVerifyConfirm', phone],
        mutationFn: async (data) => {
            const response = await authService.findIdVerifyConfirm(data);
            return response;
        },
        onSuccess: (res) => {
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            onError(error);
            throw error;
        },
    });
};

export const useFindPasswordVerifyQuery = (js, screen, onSuccess, onError) => {
    return useQuery({
        queryKey: ['findPasswordVerify', js.fields[screen].phone],
        queryFn: () =>
            authService.findPasswordVerify(js.fields[screen].phone).then(
                (res) => {
                    onSuccess(res);
                    return res;
                },
                (error) => {
                    onError(error);
                    throw error;
                }
            ),
        enabled: false,
    });
};

export const useFindPasswordVerifyConfirmMutation = (phone, onSuccess, onError) => {
    return useMutation({
        mutationKey: ['findPasswordVerifyConfirm', phone],
        mutationFn: async (data) => {
            const response = await authService.findPasswordVerifyConfirm(data);
            return response;
        },
        onSuccess: (res) => {
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            onError(error);
            throw error;
        },
    });
};

export const useGetCertificateDataQuery = (callScreen, sub) => {
    const CallNiceCertScreenMutation = useCallNiceCertScreenMutation();
    let query =
        callScreen === 'find-pw' || callScreen === 'edit_phone'
            ? callScreen + ',' + sub
            : callScreen;
    return useQuery({
        queryKey: ['getCertificateData', callScreen],
        queryFn: () =>
            authService.getCertificateData(query).then(
                (res) => {
                    CallNiceCertScreenMutation.mutate({
                        token_version_id: res.data.tokenVersionId,
                        enc_data: res.data.encData,
                        integrity_value: res.data.integrityValue,
                        receivedata: callScreen,
                    });

                    return res;
                },
                (error) => {
                    throw error;
                }
            ),
        enabled: false,
        gcTime: 4000,
    });
};

export const useRequestDecryptDataMutation = (data, onSuccess, onError) => {
    return useMutation({
        mutationFn: async (data) => {
            const response = await authService.requestDecryptData(data);
            return response;
        },
        onSuccess: (res) => {
            onSuccess(res);
            return res;
        },
        onError: (error) => {
            onError(error);
            throw error;
        },
    });
};

export const useGetDecryptDataQuery = (data) => {
    return useQuery({
        queryKey: ['GetDecryptData'],
        queryFn: () => authService.getDecryptData(data),
    });
};

export default authService;
