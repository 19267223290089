import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import ChkOnOff from '@/components/ChkOnOff';
import {
    RsvTab,
    TabItem,
    ConsumerPrice,
    OrgPrice,
    FlxbtFullMb10,
    Paragraph,
    Mb30,
    FlxMiddle,
    PadVert30,
    ContainerPadding,
    Fz20Bold,
    Fz16Medium,
} from '@/css/style';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import useServiceStore from '@/stores/designer/service';

export default function ServicePreview({ isOpen, closeModal }) {
    const ss = useServiceStore();
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="시술 메뉴 등록"
        >
            <ContainerPadding>
                <PadVert30>
                    <button onClick={closeModal}>
                        <img src={icCloseModal} alt="닫기" />
                    </button>
                </PadVert30>
                <Fz20Bold $mb="2.3077rem">미리보기</Fz20Bold>
                {/* 시술 메뉴 선택 */}
                <RsvTab>
                    <TabItem $active={ss.preview.cut} onClick={() => ss.setPreview('cut')}>
                        컷
                    </TabItem>
                    <TabItem $active={ss.preview.perm} onClick={() => ss.setPreview('perm')}>
                        펌
                    </TabItem>
                    <TabItem $active={ss.preview.clinic} onClick={() => ss.setPreview('clinic')}>
                        클리닉
                    </TabItem>
                    <TabItem $active={ss.preview.dry} onClick={() => ss.setPreview('dry')}>
                        드라이
                    </TabItem>
                    <TabItem $active={ss.preview.set} onClick={() => ss.setPreview('set')}>
                        셋트
                    </TabItem>
                    <TabItem $active={ss.preview.makeup} onClick={() => ss.setPreview('makeup')}>
                        메이크업
                    </TabItem>
                    <TabItem $active={ss.preview.event} onClick={() => ss.setPreview('event')}>
                        이벤트
                    </TabItem>
                </RsvTab>
                {/* //시술 메뉴 선택 */}
                {/* 시술 목록 */}
                <ul>
                    <Mb30 as="li">
                        <FlxbtFullMb10>
                            <Fz16Medium>시술명</Fz16Medium>
                            <FlxMiddle>
                                <OrgPrice>원래 가격</OrgPrice>
                                <ConsumerPrice>가격 원</ConsumerPrice>
                            </FlxMiddle>
                        </FlxbtFullMb10>
                        <Paragraph $fz="1.154rem">시술 설명</Paragraph>
                    </Mb30>
                    <Mb30 as="li">
                        <FlxbtFullMb10>
                            <Fz16Medium>시술명</Fz16Medium>
                            <FlxMiddle>
                                <OrgPrice>원래 가격</OrgPrice>
                                <ConsumerPrice>가격 원</ConsumerPrice>
                            </FlxMiddle>
                        </FlxbtFullMb10>
                        <Paragraph $fz="1.154rem">시술 설명</Paragraph>
                    </Mb30>
                </ul>
                {/* //시술 목록 */}
            </ContainerPadding>
        </Modal>
    );
}
const customStyles = {
    content: {
        top: 0,
        left: 'calc(50% + 3.846rem)',
        width: '87.2%',
        maxWidth: '26.154rem',
        height: '100%',
        transform: 'translateX(-50%)',
        fontSize: '1.154rem',
    },
};
