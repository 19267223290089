import { create } from 'zustand';

const initialState = {
    modal: {
        phone: false,
        password: false,
        nickname: false,
        shop: false,
        addShop: false,
        editPhoneConfirm: false,
        editPhoneErr: false,
    },
    user: {},
    fields: {},
    verify: {
        isVerified: false,
        isInputNumber: false,
        verifyCode: '',
        isCheckedId: '',
        isCheckedPhone: '',
        isCheckedNick: '',
    },
    popUpStates: {
        mismatchCode: false,
        timeout: false,
        cancelSignUp: false,
        searchSalon: false,
        joinComplete: false,
        verifyError: false,
        requestShop: false,
        addressError: false,
    },
    makeShop: {
        name: null,
        address: null,
        address_detail: '',
        isSearched: false,
        isMarker: false,
    },
    certError: {},
    isCert: false,
};

const useMyPageStore = create((set) => ({
    ...initialState,
    setUser: (obj) => {
        set((state) => ({
            user: obj,
        }));
    },
    setIsCert: (boolean) => {
        set({ isCert: boolean });
    },
    setCertError: (obj) => {
        set({ certError: obj });
    },
    setField: (fieldName, value) => {
        set((state) => ({
            fields: {
                ...state.fields,
                [fieldName]: value,
            },
        }));
    },
    setVerify: (key, value) => {
        set((state) => ({
            verify: {
                ...state.verify,
                [key]: value,
            },
        }));
    },
    verifyValidate: () => {
        set((state) => {
            let updatedValidations = { ...state.validations };

            // updatedValidations.phone = validateField('phone', '', state);

            return {
                validations: updatedValidations,
            };
        });
    },
    setMakeShop: (field, value) => {
        set((state) => ({
            makeShop: {
                ...state.makeShop,
                [field]: value,
            },
        }));
    },
    togglePopUp: (popUpName) => {
        set((state) => ({
            popUpStates: {
                ...state.popUpStates,
                [popUpName]: !state.popUpStates[popUpName],
            },
        }));
    },
    closeAllPopUps: () => {
        set({
            popUpStates: {
                mismatchCode: false,
                timeout: false,
                cancelSignUp: false,
                searchSalon: false,
                joinComplete: false,
                verifyed: false,
            },
        });
    },
    closeAllModals: () => {
        set({
            modal: {
                phone: false,
                password: false,
                nickname: false,
                shop: false,
                addShop: false,
            },
        });
    },
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useMyPageStore;
