import { FaStar, FaStarHalfAlt } from 'react-icons/fa';

const RatingStars = ({ rating }) => {
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 === 0.5;

    const stars = Array.from({ length: fullStars }, (_, index) => <FaStar key={index} />);
    if (hasHalfStar) stars.push(<FaStarHalfAlt key={fullStars} />);

    return <>{stars}</>;
};

export default RatingStars;
