import { create } from 'zustand';

const initialState = {
    modal: {
        socialError: false,
    },
};

const useSocialStore = create((set) => ({
    ...initialState,
    toggleModal: (select) => {
        set((state) => ({
            modal: {
                [select]: !state.modal[select],
            },
        }));
    },
    terminate: () => {
        set(initialState);
    },
}));

export default useSocialStore;
