import React, { useEffect } from 'react';
import useLoginStore from '@/stores/common/login';
import { useConnectAppleSocialMutation } from '@/services/userService';
import useUserStore from '@/stores/user/user';
import { useNavigate } from 'react-router-dom';
import { useRefreshTokenMutation } from '@/services/authService';

export default function AppleLoginRedirect() {
    const ls = useLoginStore();
    const us = useUserStore();
    const navigate = useNavigate();
    const redirect = localStorage.getItem('redirect');
    const ConnectAppleLoginMutation = useConnectAppleSocialMutation();
    const refreshTokenMutation = useRefreshTokenMutation(us);

    useEffect(() => {
        let id_token = new URL(window.location.href).searchParams.get('id_token');
        let error = new URL(window.location.href).searchParams.get('error');
        let state = new URL(window.location.href).searchParams.get('state');

        switch (state) {
            case 'appleLogin':
                if (window.opener) {
                    if (error) {
                        window.opener.postMessage({
                            success: false,
                        });
                        window.close();
                    } else {
                        window.opener.postMessage({
                            success: true,
                            redirect: localStorage.getItem('redirect'),
                        });
                        window.close();
                    }
                } else {
                    if (error) {
                        ls.toggleModal('socialError');
                        navigate('/login', { replace: true });
                    } else {
                        const refreshToken = async () => {
                            const response = await refreshTokenMutation.mutateAsync();
                            return response;
                        };
                        refreshToken()
                            .then((response) => {
                                if (response.status === 200) {
                                    redirect
                                        ? navigate(redirect, { replace: true })
                                        : navigate('/', { replace: true });
                                }
                            })
                            .catch((error) => {
                                console.error('Error refreshing token:', error);
                                // 에러 처리 로직 추가
                            });
                    }
                }
                break;
            case 'connectAppleSocial':
                ConnectAppleLoginMutation.mutate({ user_id: us.user.id, id_token: id_token });
                break;
            default:
                return;
        }
    }, []);

    return;
}
