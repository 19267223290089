import { instance } from '@/services/api';
import { useNavigate } from 'react-router-dom';
import { useRefreshTokenMutation } from './authService';

const Setup = (store) => {
    const refreshTokenMutation = useRefreshTokenMutation(store);

    instance.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem('accessToken');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalRequest = err.config;
            if (err.response?.status === 401) {
                const response = await refreshTokenMutation.mutateAsync();
                originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
                return instance(originalRequest);
            }
            return Promise.reject(err);
        }
    );
};

export default Setup;
