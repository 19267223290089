import React from 'react';
import { BtnSnsLogin } from '@/css/style';
import icKakao from '@/assets/img/ic_kakao.svg';
import icNaver from '@/assets/img/ic_naver.svg';
import icApple from '@/assets/img/ic_apple.svg';
import config from '../config';

const createOAuthComponent = (img, provider, authUrl, bgcolor, api) => {
    return () => {
        const handleLogin = () => {
            localStorage.setItem('api', api);
            if (window.ReactNativeWebView) {
                window.location.href = authUrl;
            } else {
                const parentLeft = window.screenLeft || window.screenX || 0;
                const parentTop = window.screenTop || window.screenY || 0;

                const popupWidth = 500;
                const popupHeight = 600;

                const left = parentLeft + (window.innerWidth - popupWidth) / 2;
                const top = parentTop + (window.innerHeight - popupHeight) / 2;

                const option = `status=no, menubar=no, toolbar=no, resizable=no, width=500, height=600, left=${left}, top=${top}`;

                window.open(authUrl, '', option);
            }
        };

        return (
            <BtnSnsLogin onClick={handleLogin} $bg={bgcolor}>
                <img src={img} alt={provider} />
            </BtnSnsLogin>
        );
    };
};

function Apple() {
    return createOAuthComponent(
        icApple,
        '애플',
        `https://appleid.apple.com/auth/authorize?client_id=${config.APPLE_CLIENT_ID}&redirect_uri=https://api.hairhale.com/auth/authenticate/apple&response_type=code id_token&state=appleLogin&scope=name email&response_mode=form_post&m=11&v=1.5.4`,
        '#000',
        'appleLogin'
    )();
}

function Kakao() {
    return createOAuthComponent(
        icKakao,
        '카카오',
        `https://kauth.kakao.com/oauth/authorize?client_id=${config.KAKAO_CLIENT_ID}&redirect_uri=${config.CLIENT_HOST}/oauth-kakao&response_type=code`,
        '#F9E001',
        'kakaoLogin'
    )();
}

function Naver() {
    return createOAuthComponent(
        icNaver,
        '네이버',
        `https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=${config.NAVER_CLIENT_ID}&state=false&redirect_uri=${config.CLIENT_HOST}/oauth-naver`,
        '#2DB400',
        'naverLogin'
    )();
}

export { Apple, Kakao, Naver };
