import React, { useState } from 'react';
import {
    TermOver,
    TermHeader,
    Fz18Bold,
    TermModal,
    MyHeader,
    Paragraph,
    Container,
    ContainerPadding,
    Fz20Bold,
    NavHeight,
    TermItem2,
} from '@/css/style';
import { popOvers } from '@/utils/termData';
import arrowLeft from '@/assets/img/arrow_left_thin_bl.svg';
import arrowRightThinGray from '@/assets/img/arrow_right_thin_gray.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';

function TermList() {
    const [popOverData, setPopOverData] = useState(popOvers);

    const togglePopOver = (index) => {
        const updatedPopOvers = [...popOverData];
        updatedPopOvers[index].isVisible = !updatedPopOvers[index].isVisible;
        setPopOverData(updatedPopOvers);
    };

    const { navigate } = useOutletContext();

    return (
        <>
            <Container>
                {/* <MyHeader>
                    <Fz20Bold>약관 내용 확인</Fz20Bold>
                </MyHeader> */}
                <ContainerPadding>
                    {/* 약관 리스트 */}
                    <ul>
                        <TermItem2 onClick={() => navigate('/term-list/privacy')}>
                            개인정보 처리방침
                            <img src={arrowRightThinGray} alt="약관 보기" />
                        </TermItem2>
                    </ul>
                    <ul>
                        <TermItem2 onClick={() => navigate('/term-list/term')}>
                            머리할래 이용약관
                            <img src={arrowRightThinGray} alt="약관 보기" />
                        </TermItem2>
                    </ul>
                    <ul>
                        <TermItem2 onClick={() => navigate('/term-list/agreement')}>
                            개인정보 수집 및 이용 동의서
                            <img src={arrowRightThinGray} alt="약관 보기" />
                        </TermItem2>
                    </ul>
                    <ul>
                        <TermItem2 onClick={() => navigate('/term-list/location-term')}>
                            위치기반 서비스 이용약관
                            <img src={arrowRightThinGray} alt="약관 보기" />
                        </TermItem2>
                    </ul>
                    {/* //약관 리스트 */}
                </ContainerPadding>
            </Container>
        </>
    );
}
export default TermList;
