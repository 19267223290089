import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import arrowDownGray from '@/assets/img/arrow_down_thin_gray.svg';
import icPlus from '@/assets/img/ic_plus.svg';
import bgPc from '@/assets/img/bg_pc.svg';
import TextareaAutosize from 'react-textarea-autosize';

//mixin CSS 정의
const flxCent = css`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const inFlxCent = css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
`;
const grid = css`
    display: -ms-grid;
    display: grid;
`;
const toggleShow = css`
    display: ${({ $visible }) => ($visible ? 'block' : 'none')};
`;
const whfull = css`
    width: 100%;
    height: 100%;
`;
const rounded = css`
    border-radius: 10px;
    overflow: hidden;
`;
const fz14 = css`
    font-size: 1rem;
    letter-spacing: -0.07rem;
`;
const fz14Bold = css`
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: -0.07rem;
`;
const fz15 = css`
    font-size: 1.154rem;
    letter-spacing: -0.07rem;
`;
const fz15bold = css`
    font-size: 1.154rem;
    font-weight: 700;
    letter-spacing: -0.07rem;
`;
const fz16medium = css`
    font-size: 1.23rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
`;
const fz18medium = css`
    font-size: 1.385rem;
    font-weight: 500;
    letter-spacing: -0.07rem;
    @media screen and (max-width: 414px) {
        font-size: 1.285rem;
    }
`;
const fz18bold = css`
    font-size: 1.385rem;
    font-weight: 700;
    letter-spacing: -0.07rem;
`;
const fz20bold = css`
    font-size: 1.538rem;
    font-weight: 700;
    letter-spacing: -0.07rem;
    @media screen and (max-width: 414px) {
        font-size: 1.438rem;
    }
`;
const txtcolor = css`
    color: ${(props) => props.$col || '#757575'};
`;
const ellipsis = css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;
const horizonCent = css`
    position: absolute;
    left: 50%;
    -moz-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`;
const VertCent = css`
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
`;
const sticky = css`
    position: -webkit-sticky;
    position: sticky;
`;
const bordered = css`
    border-width: 1px;
    border-style: solid;
    white-space: nowrap;
    background-color: ${({ $active }) => ($active ? '#00BF9D' : '#FFF')};
    border-color: ${({ $active }) => ($active ? '#00BF9D' : '#e8eaed')};
    color: ${({ $active }) => ($active ? '#FFF' : '#000')};
    }};
`;
const status = css`
    ${inFlxCent};
    border: 1px solid #a1a1a1;
    border-radius: 4rem;
    white-space: nowrap;
    font-size: 1rem;
    font-weigth: 700;
`;
const hide = css`
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    line-height: 0;
    font-size: 0;
    text-indent: -9999px;
    text-shadow: none;
    color: transparent;
`;

//여백
const NavHeight = styled.div`
    width: 0;
    height: 4.65385rem;
    // height: 4.15385rem;
`;
const Mb1rem = styled.div`
    margin-bottom: 1rem;
`;
const Mb5 = styled.div`
    margin-bottom: 0.385rem;
`;
const Mb10 = styled.div`
    margin-bottom: 0.77rem;
`;
const Mb15 = styled.div`
    margin-bottom: 1.154rem;
`;
const Mb20 = styled.div`
    margin-bottom: 1.538rem;
`;
const Mb30 = styled.div`
    margin-bottom: 2.3077rem;
`;
const Mb40 = styled.div`
    margin-bottom: 3.077rem;
`;
const Mb50 = styled.div`
    margin-bottom: 3.846rem;
`;
const Spacing10 = styled.div`
    width: 100%;
    height: 0.77rem;
`;
const Spacing20 = styled.div`
    width: 100%;
    height: 1.538rem;
`;
const Spacing30 = styled.div`
    width: 100%;
    height: 2.3077rem;
`;
const Spacing40 = styled.div`
    width: 0;
    height: 3.077rem;
`;
const Spacing36 = styled.div`
    width: 0;
    height: 2.769rem;
`;
const Spacing50 = styled.div`
    width: 0;
    height: 3.846rem;
`;
const Spacing70 = styled.div`
    width: 0;
    height: 5.385rem;
    @media screen and (max-height: 700px) {
        height: 2.769rem;
    }
`;
const Spacing100 = styled.div`
    width: 0;
    height: 7.692rem;
`;
const Gap10 = styled.div`
    width: 0.77rem;
    height: 0;
`;
const Gap15 = styled.div`
    width: 1.154rem;
    height: 0;
`;
const Gap20 = styled.div`
    width: 1.538rem;
    height: 0;
`;
const Gap30 = styled.div`
    width: 2.3077rem;
    height: 0;
`;
const PadVert10 = styled.div`
    padding: 0.77rem 0;
`;
const PadVert20 = styled.div`
    padding: 1.538rem 0;
`;
const PadVert30 = styled.div`
    padding: 2.3077rem 0;
`;
const PadVert30PopOver = styled.div`
    padding: 2.3077rem 0 8rem;
`;
const Pad20 = styled.div`
    padding: 1.538rem;
`;
const Block = styled.div`
    padding-bottom: 1.846rem;
    ${fz15}
`;

//공통 컴포넌트
const BtnFullLine = styled.button.attrs({ type: 'button' })`
    width: 100%;
    height: 3.0769rem;
    // padding: 1.154rem;
    border-radius: 10px;
    column-gap: 0.4rem;
    ${flxCent};
    ${fz15bold};
    color: #757575 !important;
    ${bordered};
`;
const BtnFullLineGreen = styled(BtnFullLine)`
    padding: 1.193rem;
    border: 1px solid #00bf9d;
    color: #00bf9d !important;
`;
const BtnLineGreenShorter = styled(BtnFullLineGreen)`
    padding: 0.9rem;
`;
const BtnRelocate = styled.button.attrs({ type: 'button' })`
    ${horizonCent};
    bottom: 10.5rem;
    ${flxCent};
    padding: 1rem;
    border-radius: 8px;
    border: 1px solid #e8eaed;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    color: #000;
    img {
        margin-right: 0.3846rem;
    }
`;
const BtnDelete = styled.button.attrs({ type: 'button' })`
    position: absolute;
    top: 0.385rem;
    right: 0.385rem;
    z-index: 2;
`;
const BtnSnsLogin = styled.button`
    ${inFlxCent};
    width: 3.692rem;
    min-width: 3.692rem;
    height: 3.692rem;
    border-radius: 50%;
    background-color: ${(props) => props.$bg || '#E8EAED'};
    @media screen and (max-height: 700px) {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
    }
`;
const BtnLogin = styled.button.attrs({ type: 'button' })`
    ${flxCent}
    width: 100%;
    height: 3.692rem;
    border-radius: 10px;
    background-color: ${(props) => props.$bg || '#E8EAED'};
    color: ${(props) => props.$col || '#fff'};
    ${fz18medium};
`;
const BtnLogin2 = styled(Link)`
    ${flxCent}
    width: 100%;
    height: 3.692rem;
    border-radius: 10px;
    background-color: ${({ $active }) => ($active ? '#00BF9D' : '#F8F8F8')};
    color: ${({ $active }) => ($active ? '#FFF' : '#757575')};
    ${fz18medium}
`;
const LineGrayBox = styled.div`
    ${flxCent};
    padding: 0 0.92rem;
    border: 1px solid #a1a1a1;
    border-radius: 8px;
    background-color: #fff;
    color: inherit;
    line-height: 2.3077rem;
    font-weight: bold;
`;
const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #e8eaed;
    margin-top: ${(props) => props.$mt || '0'};
    margin-bottom: ${(props) => props.$mb || '0'};
`;
const SectionDivider = styled.div`
    width: 100%;
    height: 0.77rem;
    margin: 2.3077rem 0;
    background-color: #f8f8f8;
`;
const SectionDivider50 = styled(SectionDivider)`
    margin: 3.846rem 0;
`;
const TxtDivider = styled.div`
    min-width: 1px;
    width: 1px;
    height: 1.538rem;
    margin: 0 1.538rem;
    background-color: #e8eaed;
`;
const LiStyled = styled.li`
    display: flex;
    // margin-bottom: 1.154rem;
    column-gap: 0.6rem;
    white-space: pre-wrap;
    & > a {
        display: flex;
        width: 100%;
        margin-bottom: 2.3077rem;
        column-gap: 0.6rem;
    }
    & > span:first-of-type {
        color: #000;
    }
`;
const TimeItem = styled.label`
    padding: 1rem 1.538rem;
    border-radius: 3rem;
    & > input[type='radio'] {
        ${hide};
    }
    &.disabled {
        background-color: #f8f8f8;
        border-color: #e8eaed;
        color: #a1a1a1;
        text-decoration: line-through;
        pointer-events: none;
    }
    ${inFlxCent};
    ${bordered};
    ${fz16medium};
`;
const TimeItem2 = styled(TimeItem)`
    background-color: ${({ $active }) => ($active ? '#FF3062' : '#FFF')};
    border-color: ${({ $active }) => ($active ? '#FF3062' : '#e8eaed')};
`;
const Textbox = styled.textarea`
    width: 100%;
    padding: 1.538rem;
    border-width: 1px;
    border-style: solid;
    border-color: ${({ $active }) => ($active ? '#00BF9D' : '#e8eaed')};
    border-radius: 10px;
    ${fz15};
    color: #000;
    resize: none;
`;
const RadioLabel = styled.label`
    display: flex;
    width: 100%;
    align-items: center;
    ${fz15};
    color: #000;
    input[type='radio'] {
        margin-right: 0.77rem;
    }
`;
const TextLine = styled.textarea`
    width: 100%;
    resize: none;
    &::placeholder {
        font-size: 1.154rem;
    }
`;
const TextLineAutosize = styled(TextareaAutosize)`
    width: 100%;
    resize: none;
    &::placeholder {
        font-size: 1.154rem;
    }
`;
const LinkFull = styled.button.attrs(() => ({ type: 'button' }))`
    ${flxCent};
    ${fz18medium};
    width: 100%;
    padding: 1.154rem;
    border-radius: 10px;
    background-color: ${({ $active }) => ($active ? '#00BF9D' : '#E8EAED')};
    color: ${({ $active }) => ($active ? '#FFF' : '#A1A1A1')};
`;
const Paragraph = styled.p`
    line-height: 1.4666;
    letter-spacing: -0.07rem;
    font-size: ${(props) => props.$fz || '1rem'};
    text-align: ${(props) => props.$ta || 'unset'};
    color: ${(props) => props.$col || '#757575'};
`;
const BorderBox = styled.div`
    width: 100%;
    padding: ${(props) => props.$pd || '1.538rem'};
    border: 1px solid #e8eaed;
    background-color: #fff;
    ${rounded};
`;
const BorderBoxGray = styled(BorderBox)`
    background-color: #f8f8f8;
    p {
        max-height: 135px;
        overflow-y: auto;
    }
`;
const BorderBoxMb = styled(BorderBox)`
    margin-bottom: 1.538rem;
`;
const TextBoxWrapper = styled.div`
    position: relative;
    textarea {
        padding-bottom: 2.3077rem;
    }
`;
const TextBoxCount = styled.span`
    position: absolute;
    right: 1.538rem;
    bottom: 1.538rem;
    background-color: #fff;
`;
const LinkFlxMiddle = styled(Link)`
    display: flex;
    margin-bottom: ${(props) => props.$mb || ''};
    align-items: center;
`;
const LinkFlxbtFull = styled(LinkFlxMiddle)`
    justify-content: space-between;
`;

//레이아웃 정의
const Flx = styled.div`
    display: flex;
`;
const Flxbt = styled.div`
    position: ${(props) => props.$pos || ''};
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const FlxbtFull = styled(Flxbt)`
    width: 100%;
    margin-bottom: ${(props) => props.$mb || '0'};
`;
const FlxbtFullMb10 = styled(FlxbtFull)`
    margin-bottom: 0.77rem;
`;
const FlxbtFullMb15 = styled(FlxbtFull)`
    margin-bottom: 1.154rem;
`;
const FlxbtFullMb20 = styled(FlxbtFull)`
    margin-bottom: 1.538rem;
`;
const FlxbtFullMb30 = styled(FlxbtFull)`
    margin-bottom: 2.3077rem;
`;
const FlxbtFullMb40 = styled(FlxbtFull)`
    margin-bottom: 3.077rem;
`;
const FlxbtFullMb50 = styled(FlxbtFull)`
    margin-bottom: 3.846rem;
`;
const FlxMiddle = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${(props) => props.$jc || ''};
`;
const FlxMiddleMb10 = styled(FlxMiddle)`
    margin-bottom: 0.77rem;
`;
const FlxMiddleMb15 = styled(FlxMiddle)`
    margin-bottom: 1.154rem;
`;
const FlxMiddleMb20 = styled(FlxMiddle)`
    margin-bottom: 1.538rem;
`;
const FlxMiddleMb30 = styled(FlxMiddle)`
    margin-bottom: 2.3077rem;
`;
const FlxMiddleMb50 = styled(FlxMiddle)`
    margin-bottom: 3.846rem;
`;
const FlxGap10 = styled(FlxMiddle)`
    & > * {
        margin-right: 0.77rem;
        &:last-child {
            margin-right: 0;
        }
    }
`;
const FlxRight = styled.div`
    display: flex;
    width: 100%;
    justify-content: right;
`;
const StickyBtm = styled.div`
    position: fixed;
    // bottom: 3.077rem;
    bottom: ${(props) => props.$bt || '2.077rem'};
    width: 100%;
    max-width: 440px;
    & > div,
    & > a,
    & > button {
        width: 90%;
        margin: 0 auto;
    }
`;
const AbsolBtm = styled.div`
    position: absolute;
    bottom: ${(props) => props.$bt || '3.077rem'};
    width: ${(props) => props.$wd || '90%'};
    margin: auto;
`;
const ToggleShow = styled.div`
    ${toggleShow}
`;
const LineCenter = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
`;
const FullLine = styled.div`
    width: 100%;
    margin-bottom: ${(props) => props.$mb || '0'};
`;
const Frame = styled.div`
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    min-height: -webkit-fill-available;
`;
let Main;
if (window.ReactNativeWebView) {
    Main = styled.main`
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 440px;
        height: 100%;
        min-height: 100vh;
        background-color: #fff;
        @media only screen and (min-width: 992px) {
            min-height: 100vh;
            margin-left: 44rem;
        }
    `;
} else {
    Main = styled.main`
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 440px;
        height: 100%;
        min-height: calc(var(--vh, 1vh) * 100);
        background-color: #fff;
        @media only screen and (min-width: 992px) {
            min-height: 100vh;
            margin-left: 44rem;
        }
    `;
}

const Container = styled.div`
    position: ${(props) => props.$pos || ''};
    width: 100%;
    height: 100%;
    min-height: calc(calc(var(--vh, 1vh) * 100) - 134px);
    padding-top: 55px;
    padding-bottom: ${(props) => props.$pb || '4.23077rem'};
    @media only screen and (min-width: 992px) {
        min-height: calc(100vh - 127px);
    }
`;
const ContainerFit = styled(Container)`
    width: 100%;
    // height: calc(100vh - 0.924rem);
    height: calc(var(--vh, 1vh) * 100);
    min-height: unset;
    overflow: hidden;
    @media only screen and (min-width: 992px) {
        height: 100vh;
    }
`;
const ContainerMap = styled.div`
    position: relative;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;
    @media only screen and (min-width: 992px) {
        height: 100vh;
    }
    // height: calc(100vh - 130px);
`;
const AddContainer = styled.div`
    padding: 2.3077rem;
`;
const ContainerPadding = styled.div`
    width: 90%;
    margin: 0 auto;
`;
// const SwipeUpContainer = styled.div`
//     position: absolute;
//     // top: 87%;
//     top: calc(100% - 9.4615rem);
//     width: 100%;
//     // height: 85vh;
//     height: 90vh;
//     border-radius: 20px 20px 0px 0px;
//     background: #fff;
//     box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.2);
//     z-index: 101;
// `;
const SwipeUpContainer = styled.div`
    position: absolute;
    top: ${({ $isDraggedToBounds }) =>
        $isDraggedToBounds ? 'calc(100% - 9.4615rem - 80vh)' : 'calc(100% - 9.4615rem)'};
    width: 100%;
    height: 90vh;
    border-radius: 20px 20px 0px 0px;
    background: #fff;
    box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.2);
    z-index: 101;
    transition: top 0.5s ease;
`;
const List = styled.div`
    display: flex;
    flex-flow: wrap row;
`;
const ListColumn = styled(List)`
    flex-flow: wrap column;
`;
const ListCol2 = styled.div`
    display: flex;
    flex-flow: wrap row;
    justify-content: space-between;
`;
const ListCol3 = styled(List)`
    width: 100%;
    row-gap: 1.538rem;
`;
const ListCol4 = styled(List)`
    flex-flow: wrap row;
    & > div,
    & > label {
        width: calc(25% - 0.77rem);
        margin: 0.77rem;
        margin-left: 0;
        &:last-of-type {
            margin-right: 0;
        }
    }
`;
const Col2Grid = styled.div`
    ${grid}
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 1.154rem;
    & > button {
        width: calc(100% - 0.512rem);
    }
`;
const ItemInfoCol2 = styled.div`
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-top: ${(props) => props.$mt || 'unset'};
    margin-bottom: ${(props) => props.$mb || 'unset'};
    padding-bottom: ${(props) => props.$pb || 'unset'};
`;
const ItemHalf = styled.div`
    width: 48%;
    flex-flow: wrap row;
    flex: 0 0 auto;
    justify-content: ${(props) => props.$jc || 'space-between'};
    margin-bottom: 2.3rem;
`;
const ItemHalf2 = styled(ItemHalf)`
    display: flex;
    width: 43%;
    margin-bottom: 0;
`;
const DetailContents = styled.div`
    ${toggleShow};
    padding: 1.3077rem 0;
    ${fz15}
`;
//이미지 레이아웃 및 이미지
const PhotoGrid = styled.div`
    ${grid};
    grid-template-columns: repeat(3, 1fr);
    gap: 0.385rem;
`;
const PhotoGridCol2 = styled.div`
    ${grid};
    grid-template-columns: repeat(2, 1fr);
    gap: 1.385rem;
`;
const PhotoItem = styled.div`
    position: relative;
    ${flxCent};
    display: grid;
    aspect-ratio: 1/1;
    overflow: hidden;
`;
const StyleItem = styled.div`
    position: relative;
    width: calc(33.33333333% - 0.77rem);
    margin-right: 0.77rem;
    &:nth-of-type(3n) {
        margin-right: 0;
    }
`;
const FullImg = styled.img`
    ${whfull};
`;
const GridImg = styled.img`
    width: 100%;
    min-height: 100%;
`;
const CircleImg = styled.div`
    width: 28.58%;
    aspect-ratio: 1/1;
    border-radius: 50%;
    overflow: hidden;
`;

//텍스트
const Fz15 = styled.span`
    font-size: 1.154rem;
    letter-spacing: -0.07rem;
    ${txtcolor}
`;
const Fz15Block = styled.pre`
    font-size: 1.154rem;
    letter-spacing: -0.07rem;
    display: block;
    ${txtcolor}
`;
const Fz15Bold = styled(Fz15)`
    font-weight: 700;
`;
const Fz16Medium = styled.h4`
    margin-bottom: ${(props) => props.$mb || '0'};
    ${fz16medium};
    color: ${(props) => props.$col || '#000'};
`;
const Fz18Medium = styled.h3`
    margin-bottom: ${(props) => props.$mb || '0'};
    ${fz18medium};
    color: ${(props) => props.$col || '#000'};
`;
const Fz18Bold = styled(Fz18Medium)`
    font-weight: 700;
`;
const Fz20Bold = styled.h3`
    margin-bottom: ${(props) => props.$mb || '0'};
    ${fz20bold};
    color: ${(props) => props.$col || '#000'};
`;
const Fz30Bold = styled.h3`
    margin-bottom: ${(props) => props.$mb || '0'};
    font-size: 2.3077rem;
    font-weight: 700;
    color: ${(props) => props.$col || '#000'};
    @media screen and (max-width: 414px) {
        font-size: 2.2077rem;
    }
`;
const ColAccent = styled.span`
    color: #00bf9d;
`;
const ColAccent2 = styled.span`
    color: #ff3062;
`;
const ColRed = styled.span`
    color: #f33535;
`;
const ColPrimary = styled.span`
    color: rgb(0, 191, 157);
`;
const ColGray1 = styled.span`
    color: #a1a1a1;
`;
const ColGray2 = styled.span`
    color: #555;
`;
const ColBody = styled.span`
    color: #757575;
`;
const ColBlack = styled.span`
    color: #000;
`;
const TxRight = styled.div`
    text-align: right;
`;
const TxCent = styled.div`
    text-align: center;
`;
const Underline = styled(ColAccent2)`
    text-decoration: underline;
`;
//GNB
const NavBar = styled.header`
    // ${sticky};
    position: fixed;
    top: 0;
    // left: 50%;
    display: flex;
    width: 100%;
    max-width: 440px;
    height: 54px;
    padding: 0.924rem 1.54rem;
    // transform: translateX(-50%);
    justify-content: right;
    align-items: center;
    background-color: #fff;
    z-index: 102;
`;
const NavLogo = styled.h1`
    position: absolute;
    top: 1.346rem;
    // top: 0.846rem;
    left: 1.538rem;
    cursor: pointer;
`;
const NavLink = styled.a`
    text-decoration: none;
    color: #fff;
    &:hover {
        text-decoration: underline;
    }
`;
const NavTitle = styled.h2`
    ${horizonCent};
    ${fz18medium};
    color: #000;
`;
const NavStepWrapper = styled(NavBar)`
    justify-content: flex-end;
    button[data-btntype='cancel'] {
        padding-top: 0.77rem;
    }
`;

//Aside (내비게이션)
const AsideBigMenu = styled(Link)`
    ${flxCent};
    flex-direction: column;
    color: #000;
    img {
        margin-bottom: 1.154rem;
    }
    opacity: ${({ $active }) => ($active ? '1' : '0.3')};
`;
const AsideMenu = styled(LinkFlxMiddle)`
    margin-bottom: 2.3077rem;
    ${fz18medium};
    color: #000;
    img {
        margin-right: 1.154rem;
    }
    &:last-of-type {
        margin-bottom: 0;
    }
    @media screen and (max-height: 700px) {
        margin-bottom: 1.5384rem;
    }
`;
const VertDivider = styled(TxtDivider)`
    width: 1px;
    height: 4.6153rem;
    // margin: 0 2.3077rem;
`;

//PC 사이드
const FixMainPC = styled.div`
    position: fixed;
    left: calc(-44rem + 50vw);
    width: 44rem;
    height: 100%;
    @media screen and (max-width: 1200px) {
        left: 5rem;
    }
    @media screen and (max-width: 992px) {
        display: none;
    }
`;
const MainPc = styled.aside`
    display: flex;
    align-items: center;
    max-width: 36rem;
    height: 100%;
`;
const PcBg = styled.div`
    position: fixed;
    inset: 0;
    background-image: linear-gradient(
        to bottom,
        #f8f8f8 42%,
        #f8f8f8 42%,
        #46d3ba 42%,
        #46d3ba 100%
    );
`;
const PcContents = styled.section`
    position: relative;
    &::before {
        content: '';
        position: absolute;
        top: -100px;
        left: -150px;
        display: block;
        width: 229px;
        height: 229px;
        background: url(${bgPc}) no-repeat center/contain;
        z-index: -1;
    }
`;
const PcLogo = styled.h1`
    position: fixed;
    top: 20px;
    left: 20px;
    max-width: 139px;
    z-index: 99;
    @media screen and (max-width: 1536px) {
        display: none;
    }
`;
const Fz45Medium = styled.h2`
    margin-bottom: 6.1538rem;
    color: #000;
    line-height: 1.25em;
    font-size: 3.4615rem;
    font-weight: 400;
    letter-spacing: -2px;
    @media screen and (max-width: 414px) {
        // font-size: 2.2077rem;
    }
`;
const PcSchWrapper = styled(FlxbtFull)`
    width: 100%;
    max-width: 35.077rem;
    height: 6.1538rem;
    padding: 0 2.3077rem;
    border-radius: 4rem;
    border: 3px solid #00bf9d;
    background: #fff;
    input[type='text'] {
        width: 100%;
        font-size: 1.538rem;
    }
    button > img {
        width: 30px;
    }
`;
const KeywordWrapper = styled.div`
    margin-bottom: 10.77rem;
    & > button {
        margin-right: 0.77rem;
        margin-bottom: 0.77rem;
    }
    & > button:last-of-type {
        margin-right: 0;
    }
`;
const SchKeyword = styled.button.attrs({ type: 'button' })`
    ${inFlxCent};
    ${fz15bold};
    width: fit-content;
    height: 2.6923rem;
    padding: 0 1.538em;
    border: 2px solid #fff;
    border-radius: 4rem;
    color: #fff;
`;

//하단 메뉴바
const FooterBar = styled.footer`
    position: fixed;
    bottom: 0;
    display: flex;
    width: 100%;
    max-width: 440px;
    height: 55px;
    background: #fff;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.1);
    align-items: center;
    z-index: 101;
`;
const FooterBg = styled.div`
    padding: 2.3rem 0 3rem;
    background-color: #f8f8f8;
`;
const FooterList = styled.ul`
    display: flex;
    width: 100%;
    padding: 0;
    justify-content: space-around;
    list-style-type: none;
`;
const FooterInner = styled.div`
    display: -ms-grid;
    display: grid;
    height: 2.84615rem;
    text-align: center;
`;
const FooterItem = styled.li`
    width: 20%;
`;
const FooterLink = styled.a`
    color: white;
    text-decoration: none;
`;
const FooterImg = styled.div`
    position: relative;
    margin: 0 auto;
    max-width: 1.538rem;
    img {
        max-width: 100%;
    }
`;
const FooterFlxGap10 = styled(FlxGap10)`
    & > span {
        width: 1px;
        min-width: 1px;
        height: 0.77rem;
        background-color: #a1a1a1;
    }
    @media screen and (max-width: 414px) {
        & > * {
            margin-right: 0.384rem;
        }
    }
`;

//폼 공통
const FieldSet = styled.fieldset`
    ${toggleShow};
`;
const JoinField = styled.div`
    margin-bottom: ${(props) => props.$mb || '2.3077rem'};
`;
const InputWrapper = styled(Flxbt)`
    position: relative;
    padding: 0.77rem 0;
    border-bottom: 2px solid #e8eaed;
    &:focus,
    &:focus-visible,
    &:focus-within,
    &:active,
    &:hover {
        border-color: #00bf9d;
    }
`;
const JoinInput = styled.input`
    ${fz18medium}
    width: 70%;
    background-color: #fff;
    color: #000;
    &::placehoder {
        color: #a1a1a1;
    }
`;
const JoinInputPlc15 = styled(JoinInput)`
    font-size: 1.154rem;
`;
const JoinFileLabel = styled.label`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > input[type='file'] {
        ${hide}
    }
    ${fz15}
    width: 100%;
    background-color: #fff;
    color: #000;
    & > span {
        display: inline-block;
        max-width: 80%;
        line-height: 1.4;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    & > button {
        pointer-events: none;
    }
`;
const InputHidden = styled.input`
    ${hide}
`;
const Select = styled.select`
    appearance: none;
    -webkit-appearance: none;
    width: 100%;
    background: url(${arrowDownGray}) no-repeat calc(100% - 1.154rem) center;
`;
const ChkAndTxt = styled(FlxMiddle).attrs({ as: 'label' })`
    ${FlxMiddle};
    ${fz15};
    color: #000;
    margin-right: ${(props) => props.$mr || '1.538rem'};
    input[type='radio'],
    input[type='checkbox'] {
        margin-right: 0.77rem;
    }
    line-height: 0;
    word-break: keep-all;
`;
const RoundImg = styled.div`
    position: relative;
    ${flxCent};
    height: 8.46rem;
    margin-bottom: 1.154rem;
    ${rounded}
`;
const BtnInInputRound = styled.button.attrs({ type: 'button' })`
    min-width: 6.92rem;
    padding: 0.62rem;
    border-radius: 3rem;
    letter-spacing: -0.07rem;
    font-weight: 700;
    color: #757575 !important;
    ${flxCent};
    ${bordered};
`;
const DescInput = styled.p`
    padding-top: ${(props) => props.$pt || '1.538rem'};
    line-height: 1;
    letter-spacing: -0.054rem;
    color: #a1a1a1;
`;
const WarnInput = styled(DescInput)`
    color: #f33535;
`;
const PossibleInput = styled(DescInput)`
    color: #46d3ba;
`;
const TimeLeft = styled.span`
    ${VertCent}
    ${fz15}
    right: 0;
    padding: 0.62rem 0;
    color: #ff3062;
`;
const InfoLabel = styled.div`
    flex: 0 0 auto;
    width: 24%;
    ${fz15bold};
    white-space: nowrap;
`;
const InfoLabelM = styled(InfoLabel)`
    font-weight: 500;
`;
const InfoLabelR = styled(InfoLabel)`
    font-weight: 400;
`;
const InfoValue = styled.div`
    flex: 0 0 auto;
    ${fz15};
    color: #000;
    width: 75%;
`;

//배너
const SpotBannerWrapper = styled.div`
    width: 100%;
    margin-bottom: 1.923rem;
    border-radius: 20px;
    overflow: hidden;
`;
const DcSlideWrapper = styled.div`
    position: relative;
    height: 11rem;
    margin: 0.77rem 0;
    ${rounded};
    background-color: #fff;
    cursor: pointer;
`;
const DcBanner = styled(DcSlideWrapper)`
    height: 13.077rem;
    margin-bottom: 1.538rem;
    cursor: pointer;
`;
const Slide = styled.div`
    display: flex !important;
    ${flxCent};
    ${whfull};
`;
const Slide2 = styled.div`
    position: relative;
    display: flex !important;
    row-gap: 1.538rem;
    flex-direction: column;
`;
const ImgGradient = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    ${whfull};
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
`;
const SlideTxt = styled.h5`
    ${horizonCent};
    bottom: 1.15rem;
    max-width: 90%;
    font-size: 1.23rem;
    font-weight: 500;
    color: #fff;
    ${ellipsis};
`;

//탭
const TabWrapper = styled(FlxMiddle)`
    margin-bottom: ${(props) => props.$mb || '0'};
    & > * {
        margin-right: 3px;
        &:last-of-type {
            margin-right: 0;
        }
    }
`;
const TabBlack = styled.button.attrs({ type: 'button' })`
    color: ${({ $active }) => ($active ? '#000' : '#A1A1A1')};
`;
const TabAccent2 = styled.button.attrs({ type: 'button' })`
    color: ${({ $active }) => ($active ? '#FF3062' : '#757575')};
    font-size: 1.154rem;
`;
const TabAccent = styled.button.attrs({ type: 'button' })`
    background-color: ${({ $active }) => ($active ? '#00BF9D' : '#E8EAED')};
    color: ${({ $active }) => ($active ? '#FFF' : '#A1A1A1')};
    font-weight: ${({ $active }) => ($active ? '700' : '400')};
    ${flxCent};
    ${fz15};
    padding: 0.47rem 0.77rem;
    border-radius: 5px;
`;
const TabMap = styled.button`
    width: 28.2%;
    height: 3.846rem;
    padding: 1rem;
    background-color: ${({ $active }) => ($active ? '#FFF' : '#F8F8F8')};
    color: ${({ $active }) => ($active ? '#FF3062' : '#757575')};
    font-weight: ${({ $active }) => ($active ? '700' : '400')};
    ${flxCent};
    font-size: 1.23rem;
`;
const TabService = styled.button.attrs({ type: 'button' })`
    ${flxCent};
    ${rounded};
    ${fz15bold};
    // width: calc(50% - 0.385rem);
    width: 100%;
    padding: 0.9rem 1.1538rem;
    background-color: #fff;
    border-style: solid;
    border-width: 1px;
    border-color: ${({ $active }) => ($active ? '#00BF9D' : '#E8EAED')};
    color: ${({ $active }) => ($active ? '#00BF9D' : '#757575')};
`;
const IndexTab = styled.div`
    ${flxCent};
    margin-bottom: 3rem;
    font-size: 1.385rem;
    letter-spacing: -0.07rem;
`;
const RsvTab = styled.div`
    ${FlxMiddle};
    white-space: nowrap;
    max-width: ${(props) => props.$maxwidth || 440}px;
    margin-bottom: 2.3077rem;
    overflow-x: auto;
    & > div {
        margin-right: 0.77rem;
        &:last-of-type {
            margin-right: 0;
        }
    }
`;
const TabItem = styled.div`
    ${inFlxCent};
    padding: 1rem 1.538rem;
    border-radius: 3rem;
    ${fz16medium};
    ${bordered};
`;

//검색
const SearchTop = styled.div`
    position: relative;
    height: 20.77rem;
    padding: 3.077rem 1.538rem 3.846rem;
    background-color: #00bf9d;
`;
const SearchBottom = styled.div`
    // height: calc(100vh - 20.77rem);
    background-color: #fff;
`;
const SchInputWrapper = styled(FlxbtFull)`
  position: relative;
  border:1px solid #E8EAED;
  border-radius:3rem;
  padding: 0.65rem 1.538rem;
  background-color:#fff;
  ${fz15};
  input[type="text]{width:90%;}
`;

//모달
const BtnCloseModal = styled.button.attrs({ type: 'button' })`
    position: absolute;
    top: ${(props) => props.$top || '1.346rem'};
    // top: ${(props) => props.$top || '0.846rem'};
    left: 1.538rem;
    z-index: 102;
`;
const BtnCloseModal2 = styled(BtnCloseModal)`
    position: absolute;
    ${VertCent};
    left: 1.538rem;
    z-index: 102;
`;
const BtnCloseModal3 = styled(BtnCloseModal)`
    padding: 0.5rem;
    left: unset;
    right: 1.538rem;
`;
const BtnResetFilter = styled.button.attrs({ type: 'button' })`
    // position: absolute;
    // top:1.538rem;
    // left:1.538rem;
    ${fz15bold};
    color: #757575;
    img {
        margin-right: 0.615rem;
    }
`;
const BtnFullModal = styled.button`
    ${flxCent};
    ${fz18medium};
    width: 100%;
    padding: 1.154rem;
    border-radius: 10px;
    background-color: ${({ $active }) => ($active ? '#00BF9D' : '#E8EAED')};
    border-color: ${({ $active }) => ($active ? '#fff' : '#A1A1A1')};
    color: ${({ $active }) => ($active ? '#fff' : '#A1A1A1')};
`;
const BtnHalfModal = styled(BtnFullModal)`
    width: calc(50% - 0.385rem);
    background-color: #00bf9d;
    color: #fff;
`;
const BtnHalfModalBlack = styled(BtnHalfModal)`
    background-color: #000;
`;
const TermModal = styled.div`
    position: relative;
    padding: 0 1.538rem 2.3077rem;
`;
const ModalHeader = styled.div`
    position: absolute;
    top: 0;
    ${flxCent}
    width: 100%;
    height: 4.15rem;
    background-color: #fff;
    z-index: 101;
`;
const ModalPhoto = styled.div`
    position: relative;
    width: 100%;
    max-height: 68vh;
`;
const BtnModalPhoto = styled.button.attrs({ type: 'button' })`
    ${VertCent};
    ${flxCent}
    width:3.077rem;
    height: 3.077rem;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 0.4s;
`;
const BtnModalPhotoLeft = styled(BtnModalPhoto)`
    left: 0.77rem;
`;
const BtnModalPhotoRight = styled(BtnModalPhoto)`
    right: 0.77rem;
`;
const CusModalTit = styled.div`
    margin-bottom: 2.3077rem;
    text-align: left;
    ${fz18bold};
    color: #000;
`;
const OnPayment = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    inset: 0;
    background-color: #ebebeb;
    z-index: 103;
`;

let PopOver;
//모달이긴한데 리액트모달이 아닌 검은 배경의 팝업
if (window.ReactNativeWebView) {
    PopOver = styled.div`
        ${toggleShow};
        position: fixed;
        top: 0;
        // left: 0;
        // left: 50%;
        width: 100%;
        height: 100vh;
        // transform: translateX(-50%);
        background-color: #fff;
        z-index: 102;
        @media only screen and (min-width: 992px) {
            width: 440px;
            height: 100vh;
        }
    `;
} else {
    PopOver = styled.div`
        ${toggleShow};
        position: fixed;
        top: 0;
        // left: 0;
        // left: 50%;
        width: 100%;
        height: calc(var(--vh, 1vh) * 100);
        // transform: translateX(-50%);
        background-color: #fff;
        z-index: 102;
        @media only screen and (min-width: 992px) {
            width: 440px;
            height: 100vh;
        }
    `;
}

const TermOver = styled(PopOver)`
    max-width: 440px;
    overflow-y: auto;
    font-size: 1rem;
    letter-spacing: -0.07rem;
    overscroll-behavior-y: contain;
`;
const TermHeader = styled(FlxMiddle)`
    ${sticky};
    top: 0;
    width: 100%;
    height: 54px;
    background-color: #fff;
`;
const PopOverAbsol = styled(PopOver)`
    position: absolute;
    top: 0;
    left: 0;
`;
const PopShortMsg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 9.4rem;
    padding: 0.77rem 0;
    margin-bottom: 1.538rem;
    text-align: center;
    span {
        line-height: 1.466;
    }
`;

//로그인
const LoginLogo = styled.div`
    width: 46%;
    min-width: 5.4rem;
    // text-align: center;
    // align-self: center;
    margin: 3.84rem auto;
    img {
        width: 100%;
    }
`;
const LoginList = styled.div`
    margin-bottom: 1.538rem;
`;
const LinkLogin = styled(Link)`
    display: block;
    text-align: center;
    text-decoration: underline;
    ${fz15};
    color: #000;
`;
const SnsLoginTit = styled(FlxbtFullMb30)`
    position: relative;
    ${fz15};
    &::before,
    &::after {
        content: '';
        display: block;
        width: 35%;
        height: 1px;
        background-color: #e8eaed;
    }
    @media screen and (max-height: 700px) {
        margin-bottom: 1.1538rem;
    }
`;
const BtmJoinDesigner = styled(FlxGap10)`
    padding: 1.538rem;
    justify-content: center;
    background-color: #00bf9d;
    cursor: pointer;
    @media screen and (max-height: 700px) {
        padding: 1rem;
    }
`;

//회원가입 및 정보수정
const TermItem = styled.li`
    ${flxCent};
    width: 100%;
    padding: 0.269rem 0;
    margin-bottom: 1.538rem;
    justify-content:space-between;
    ${fz15};
    color: #000;
    cursor: pointer;
    input[type="checkbox"] {
        position:relative;
        top:0.1rem;
        margin-right:0.77rem;
    }
  };
`;
const AgreeAllBox = styled(BtnFullLine)`
    height: 3.846rem;
    padding: 1.154rem 0.77rem;
    margin-bottom: 2.3077rem;
    justify-content: flex-start;
`;
const BtnClearInput = styled.button.attrs({ type: 'button' })`
    ${VertCent}
    right:0;
`;
const SelectSns = styled.div`
    position: relative;
    width: 17%;
    height: 3.2307rem;
    border-bottom: 2px solid #e8eaed;
    text-align: center;
`;
const SelectSnsCurrent = styled(FlxMiddle)`
    height: 100%;
    padding: 0 0.4615rem;
    column-gap: 0.3846rem;
    color: #a1a1a1;
    cursor: pointer;
    background: url(${arrowDownGray}) no-repeat calc(100% - 0.4615rem) 55%;
`;
const SelectSnsOptions = styled.div`
    position: absolute;
    top: 100%;
    left: 0;
    display: none;
    width: 100%;
    flex-direction: column;
    z-index: 102;
    border: 1px solid #e8eaed;
    background-color: #fff;
    label {
        ${flxCent};
        height: 2.8rem;
        border-bottom: 1px solid #e8eaed;
        &:last-of-type {
            border-bottom: 0;
        }
    }
    &.open {
        display: flex;
    }
`;
const SelectSnsImg = styled.img`
    width: 1.923rem;
    aspect-ratio: 1/1;
`;
const ConfirmInfoItem = styled(ItemInfoCol2)`
    padding-top: 1.154rem;
    padding-bottom: 1.154rem;
    margin-bottom: 1.154rem;
    border-bottom: 2px solid #f8f8f8;
    button {
        ${VertCent};
        right: 0;
    }
`;
//할인
const ShopAddress = styled.h4`
    ${ellipsis};
`;
const DesignerInfo = styled.div`
    width: 65.8%;
    h4 {
        cursor: pointer;
    }
`;
const DesignerName = styled(Fz16Medium)`
    max-width: ${(props) => props.$maw || '70%'};
    color: #000;
`;
const DesignerSns = styled.a`
    width: 1.923rem;
    min-width: 1.923rem;
    height: 1.923rem;
    aspect-ratio: 1/1;
    margin-left: 0.6154rem;
    img {
        width: 100%;
    }
`;
const DesignerRates = styled(Flx)`
    img,
    svg {
        margin-right: 0.3846rem;
    }
    img:first-of-type {
        position: relative;
        top: 1px;
    }
    strong:first-of-type {
        margin-right: 0.77rem;
    }
`;
const ProfileTag = styled.span`
    ${inFlxCent};
    padding: 0 0.3rem;
    margin-right: 0.25em;
    border: 1px solid #a1a1a1;
    border-radius: 1em;
    background-color: #fff;
    line-height: 1.3077rem;
    font-size: 0.923rem;
    font-weight: 500;
    letter-spacing: -1px;
    white-space: nowrap;
    color: #a1a1a1;
`;
const ProfileTagGreen = styled(ProfileTag)`
    padding: 0.62rem 0.77rem;
    margin: 0.3rem 0.25rem 0.3rem 0;
    border-color: #e8eaed;
    font-size: 1.154rem;
    color: #00bf9d;
`;

//홈
const OptionBox = styled(BtnFullLine)`
    height: 3.846rem;
    padding: 1.154rem 0.77rem;
    margin-bottom: 0.77rem;
    &:hover {
        border: 1px solid #00bf9d;
        background-color: #e5f6f3;
    }
`;
const Stars = styled.div`
    display: inline-flex;
    column-gap: 0.23rem;
    color: #ffbb00;
`;
const StarsBl = styled(Stars)`
    padding: 1.3077rem 0;
    font-size: 1rem;
    color: #000;
`;
const RateScore = styled(ColGray1)`
    ${fz18medium}
`;

//할인
const BtnLikeInImg = styled.button`
    position: absolute;
    max-width: 2rem;
    right: 0.3846rem;
    bottom: 0.3846rem;
    z-index: 2;
`;
const DesignerRates2 = styled(DesignerRates)`
    img:first-of-type {
        margin-left: 0;
    }
`;
const DateInfo = styled(Flxbt)`
    padding: 0.923rem 0;
    ${fz15};
`;
const BtnToggleAccent = styled.button.attrs({ type: 'button' })`
    ${flxCent};
    background-color: ${({ $active }) => ($active ? '#00BF9D' : '#F8F8F8')};
    color: ${({ $active }) => ($active ? '#FFF' : '#757575')};
    padding: 0.62rem 0.77rem;
    border: 1px solid #e8eaed;
    border-radius: 3rem;
    letter-spacing: -0.07rem;
    img {
        margin-left: 0.615rem;
    }
`;
const DashDivider = styled.div`
    width: 100%;
    height: 0;
    margin-bottom: 2.3rem;
    border-top: 1px dashed #a1a1a1;
    box-sizing: content-box;
    overflow: visible;
`;
const DesignerPrices = styled(ListColumn)`
    width: 100%;
    margin-top: ${(props) => props.$mt || '0'};
    ${fz15};
`;
const PriceItem = styled(Flxbt)`
    width: 100%;
    padding-top: 1.1538rem;
    padding-bottom: 1.153rem;
    border-bottom: 1px solid #e8eaed;
    flex-wrap: wrap;
    &:first-of-type {
        padding-top: 0;
    }
    &:last-of-type {
        border-bottom: 0;
    }
`;
const OrgPrice = styled.span`
    margin-right: 0.77rem;
    text-decoration: line-through;
    ${txtcolor}
`;
const ConsumerPrice = styled.strong`
    color: #000;
`;
const RangeWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;
const RangeTrackWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 2.77rem;
`;
const RangeTrack = styled.div`
    width: 100%;
    height: 2px;
    align-self: center;
`;
const RangeThumb = styled.div`
    display: flex;
    width: 1.154rem;
    min-width: 1.154rem;
    height: 1.154rem;
    border: 2px solid #ff3062;
    border-radius: 50%;
    background-color: #fff;
`;

//지역
const MapLayout = styled.div`
    /* height: calc(100% - 110px ); */
    height: calc(100% - 55px);
    // min-height: 90vh;
`;
const ShortMap = styled.div`
    width: 100%;
    height: 18.5rem;
`;
// const SwipeUpHandle = styled.button.attrs({ type: 'button' })`
//     width: 100%;
//     text-align: center;
//     padding: 0.77rem 0 1.538rem;
//     img {
//         pointer-events: none;
//     }
// `;
const SwipeUpHandle = styled.div`
    width: 100%;
    text-align: center;
    padding: 0.77rem 0 1.538rem;
    img {
        pointer-events: none;
    }
    ${(props) =>
        props.isDragged &&
        css`
            background-color: lightgray;
        `}
`;
const MapNav = styled.div`
    position: relative;
    width: 100%;
    border-top: 1px solid #e8eaed;
`;
const MapStep1 = styled.li`
    display: flex;
    flex-wrap: wrap;
`;
const MapStep2ul = styled.ul`
    position: absolute;
    display: ${({ $active }) => ($active ? 'flex' : 'none')};
    width: 100%;
    top: 0;
    left: 0;
    padding-left: 28.2%;
    min-height: 100%;
    max-height: 45rem;
    // 원래는 41rem
    overflow-y: auto;
    flex-direction: column;
    li {
        display: flex;
        width: 100%;
        height: 3.846rem;
        min-height: 3.846rem;
        padding: 1rem 1.538rem;
        align-items: center;
        ${fz15}
    }
`;
const BtnAroundFilter = styled.button.attrs({ type: 'button' })`
    position: absolute;
    top: 1.538rem;
    right: 1.538rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: #ff3062;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
    color: #fff;
    z-index: 101;
`;
const HalfField = styled.div`
    width: 40%;
`;
const AroundList = styled.div`
    // .slick-slider {
        padding-bottom: 4rem;
        // margin-bottom: 2.3077rem;
        // border-bottom: 1px solid #e8eaed;
    // }
`;
const AroundScroll = styled(ContainerPadding)`
    // max-height: 48rem;
    max-height: 77vh;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    &::-webkit-scrollbar {
        display: none;
    }
    &::-webkit-scrollbar-width {
        width: 0;
    }
`;

//디자이너 정보 관련
const ItemDesigner = styled(Flxbt)`
    display: flex !important;
    width: 100%;
    margin-top: 1.538rem;
    margin-bottom: ${(props) => props.$mb || '1rem'};
    flex-wrap: wrap;
`;
const DesignerProfile = styled.div`
    ${fz15}
    margin-bottom: 4rem;
`;
const DetailDcBanner = styled.div`
    height: 15.385rem;
    overflow: hidden;
`;
const DesignerTabs = styled.button.attrs({ type: 'button' })`
    ${flxCent}
    ${fz16medium}
    width:33.33333333%;
    flex: 0 0 auto;
    padding: 1.154rem 0.4rem;
    border-top: ${({ $active }) => ($active ? '1px solid #00BF9D' : '1px solid #E8EAED')};
    border-bottom: ${({ $active }) => ($active ? '1px solid #fff' : '1px solid #E8EAED')};
    background: ${({ $active }) =>
        $active
            ? 'linear-gradient(to bottom, #00BF9D 0%, #00BF9D 3.85%, #fff 3.85%, #fff 100%)'
            : '#F8F8F8'};
    color: #757575;
`;
const RoundProfile = styled(RoundImg)`
    position: relative;
    width: 28.58%;
    aspect-ratio: 1/1;
    margin-bottom: 0;
    border: 1px solid #e8eaed;
    ${rounded};
    img {
        object-fit: cover;
    }
`;
const OpenHoursValue = styled(Flxbt)`
    width: 85%;
    flex: 0 0 auto;
`;
const PhotoItemBorder = styled(PhotoItem)`
    border: 1px solid #e8eaed;
`;
const PriceItemBorder = styled(Flxbt)`
    cursor: pointer;
    width: 100%;
    padding: ${(props) => props.$pd || '1.154rem 0.77rem'};
    border-top: 1px solid #e8eaed;
    flex-wrap: wrap;
    &:last-of-type {
        border-bottom: 1px solid #e8eaed;
    }
    &:first-child {
        border-top: 0;
    }
    &:last-child {
        border-bottom: 0;
    }
`;
const ItemReview = styled.div`
    padding: 2.3077rem 0;
    border-bottom: 1px solid #e8eaed;
`;
const ReviewDate = styled.span`
    ${fz15}
    color: #A1A1A1;
`;
const ReviewStars = styled(Stars)`
    padding: 1.154rem 0;
    color: #000;
`;

//마이페이지
const MyHeader = styled(FlxbtFull)`
    padding: 0 1.538rem 2.3077rem;
    margin-bottom: ${(props) => props.$mb || '2.3077rem'};
    border-bottom: 1px solid #e8eaed;
`;
const MyLink = styled(LinkFlxMiddle)`
    display: flex;
    width: 100%;
    margin-bottom: 2.3077rem;
    & > img {
        margin-right: 1.1538rem;
    }
    @media screen and (max-width: 414px) {
        margin-bottom: 1.1538rem;
    }
`;
const FloatSetting = styled(Flx)`
    position: absolute;
    top: calc(100% + 0.5rem);
    right: 0;
    width: 6rem;
    background-color: #fff;
    border: 1px solid #e8eaed;
    border-bottom: 0;
    flex-direction: column;
    z-index: 2;
    ${fz15};
    ${toggleShow};
    button {
        width: 100%;
        padding: 1rem;
        ${flxCent};
        border-bottom: 1px solid #e8eaed;
    }
`;
const NoticeItem = styled(Link)`
    padding-bottom: 1.07692rem;
    margin-bottom: 2.154rem;
    border-bottom: 1px solid #e8eaed;
`;
const NoticeTitle = styled.h5`
    width: 100%;
    max-width: 24rem;
    ${fz15};
    color: #000;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;
const NoticeLabel = styled.strong`
    ${inFlxCent};
    width: 2.3077rem;
    height: 1.3077rem;
    // padding: 0.25rem 0.3rem;
    margin-right: 1.154rem;
    border-radius: 3rem;
    background-color: #00bf9d;
    color: #fff;
    // line-height: 0.723rem;
    font-size: 0.923rem;
    white-space: nowrap;
`;
const ArchiveThumb = styled.div`
    ${flxCent}
    ${rounded}
    height: 15.385rem;
`;
const PostImg = styled.div`
    width: 100%;
    max-width: 100%;
    margin: 1rem 0;
    img {
        width: 100%;
    }
`;
const ReviewThumb = styled.div`
    ${flxCent};
    display: flex !important;
    height: 15.385rem;
    margin: 0.77rem auto;
    overflow: hidden;
`;
const TermItem2 = styled(Flxbt).attrs({ as: 'li' })`
    padding-left: 0.615rem;
    margin-bottom: 2.3077rem;
    color: #000;
    ${fz15};
    cursor: pointer;
`;

//내 예약
const HistoryList = styled.div`
    width: 100%;
    // min-height:100%;
    min-height: 48vh;
    padding: 2.3077rem 0;
    background-color: #f8f8f8;
    border-top: 1px solid #e8eaed;
`;
const FileFullLabel = styled.label`
    width: 100%;
    padding: 0.95rem;
    border-radius: 10px;
    column-gap: 0.4rem;
    ${flxCent};
    ${fz15bold};
    color: #757575 !important;
    ${bordered};
`;
const FileSquareLabel = styled.label`
    display: block;
    width: 100%;
    aspect-ratio: 1/1;
    border: 1px solid #e8eaed;
    background: url(${icPlus}) no-repeat center/auto #fff;
`;
const StyleInput = styled.input.attrs({ type: 'text' })`
    width: 100%;
    padding: 1.154rem 0;
    ${fz15};
    color: #000;
    border-bottom: 2px solid #e8eaed !important;
    &::placeholder {
        color: #a1a1a1;
    }
`;
const RsvTermBox = styled(FullLine).attrs({ as: 'button', type: 'button' })`
    position: relative;
    color: #000;
    ${fz15};
    text-align: left;
    & > img {
        ${VertCent}
        right: 0;
    }
`;
const HistoryServices = styled.div`
    margin-bottom: 5px;
`;

//시술 메뉴 등록
const SettingOptions = styled.div`
    position: absolute;
    top: 100%;
    right: 0;
    display: none;
    width: 100%;
    max-width: 80px;
    flex-direction: column;
    z-index: 102;
    border: 1px solid #e8eaed;
    background-color: #fff;
    z-index: 2;
    ${fz15};
    button {
        ${flxCent};
        height: 2.8rem;
        border-bottom: 1px solid #e8eaed;
        &:last-of-type {
            border-bottom: 0;
        }
    }
    &.open {
        display: flex;
    }
`;

//휴무일 관리
const BtnToggleAccent2 = styled(BtnToggleAccent)`
    min-width: 6.154rem;
    padding: 1rem;
    ${fz16medium};
    &.selected {
        background-color: #00bf9d;
        border-color: #00bf9d;
        color: #fff;
    }
    &.on {
        background-color: #fff;
        border-color: #e8eaed;
        color: #000;
    }
    &.off {
        background-color: #ffebeb;
        border-color: #ff3062;
        color: #ff3062;
    }
`;
const OffList = styled(ListColumn).attrs({ as: 'ul' })`
    margin-bottom: 2.3077rem;
    li {
        display: flex;
        margin-bottom: 1.538rem;
        justify-content: space-between;
        &:last-of-type {
            margin-bottom: 0;
        }
        & > div {
            max-width: 68%;
            column-gap: 1.538rem;
            ${fz15};
            p {
                word-break: keep-all;
                color: #000;
            }
        }
    }
`;
//디자이너 예약 관리
const RsvStatus = styled.span`
    ${status};
    padding: 0 0.77rem;
    line-height: 1.846rem;
    border-color: #a1a1a1;
    border-radius: 3rem;
    background-color: #f8f8f8;
    color: #a1a1a1;
`;
const RsvStatusPink = styled(RsvStatus)`
    border-color: #ff3062;
    background-color: #ffebeb;
    color: #ff3062;
`;
const RsvStatusGreen = styled(RsvStatus)`
    border-color: #00bf9d;
    background-color: #e5f6f3;
    color: #00bf9d;
`;
const TableWrapper = styled.div`
    ${fz15};
    background-color: #fff;
    min-width: 26.923rem;
    padding-bottom: 2rem;
    overflow-x: auto;
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th,
    td {
        border-right: 1px solid #e8eaed;
        border-bottom: 1px solid #e8eaed;
    }
    th {
        min-width: 5rem;
        font-weight: 400;
        color: #000;
    }
    td {
        padding: 0.385rem;
        &:has(button:disabled) {
            background-color: #f8f8f8;
        }
    }
    thead th {
        padding: 1rem;
        border-bottom: 2px solid #00bf9d;
        &:last-of-type {
            border-right: 0;
        }
    }
`;
const OffItem = styled(TimeItem).attrs({ as: 'button', type: 'button' })`
    width: 100%;
    ${fz16medium}
`;
const RsvServiceList = styled(ListColumn).attrs({ as: 'ul' })`
    margin-bottom: 2.3077rem;
    h5 {
        padding-bottom: 1.154rem;
    }
    li {
        padding-bottom: ${(props) => props.$pb || '1.154rem'};
        &:last-of-type {
            padding-bottom: 0;
        }
    }
`;
const BtnLineGray = styled.button.attrs({ type: 'button' })`
    ${flxCent};
    padding: 0 0.92rem;
    border-width: 1px;
    border-style: solid;
    border-radius: 8px;
    background-color: #fff;
    border-color: ${({ $active }) => ($active ? '#00BF9D' : '#757575')};
    color: ${({ $active }) => ($active ? '#00BF9D' : '#757575')};
    line-height: 2.3077rem;
    white-space: nowrap;
    font-weight: 700;
`;
const BtnLineGray2 = styled(BtnLineGray)`
    border-color: #a1a1a1;
`;

// FAQ
const FaqItem = styled.ul`
    ${fz15};
    button,
    a {
        width: 90%;
        padding: 2.3077rem 0 1.538rem;
        margin: 0 auto;
        border-bottom: 1px solid #e8eaed;
        &.on {
            border: 0;
        }
    }
    a {
        flex-wrap: wrap;
        h4 {
            width: 100%;
            margin-bottom: 1.154rem;
        }
    }
`;
const FaqContent = styled(Pad20)`
    ${toggleShow};
    background-color: #f8f8f8;
`;
const QnaAnswerHeader = styled.div`
    padding: 0 1.538rem 2.3077rem;
    margin-bottom: 2.3077rem;
    border-bottom: 1px solid #e8eaed;
`;

const DaumWrapper = styled.div`
    position: absolute;
    top: 110%;
    left: 0;
    width: 100%;
    max-width: 396px;
    border: 1px solid #e8eaed;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    z-index: 2;
`;

export {
    AbsolBtm,
    AddContainer,
    AgreeAllBox,
    ArchiveThumb,
    AroundScroll,
    AroundList,
    AsideBigMenu,
    AsideMenu,
    PhotoItemBorder,
    Block,
    BorderBox,
    BorderBoxGray,
    BorderBoxMb,
    BtmJoinDesigner,
    BtnAroundFilter,
    BtnClearInput,
    BtnCloseModal,
    BtnCloseModal2,
    BtnCloseModal3,
    BtnDelete,
    BtnFullLine,
    BtnFullLineGreen,
    BtnFullModal,
    BtnHalfModal,
    BtnHalfModalBlack,
    BtnInInputRound,
    BtnLikeInImg,
    BtnLineGray,
    BtnLineGray2,
    BtnLineGreenShorter,
    BtnSnsLogin,
    BtnLogin,
    BtnLogin2,
    BtnRelocate,
    BtnResetFilter,
    BtnModalPhoto,
    BtnModalPhotoLeft,
    BtnModalPhotoRight,
    BtnToggleAccent,
    BtnToggleAccent2,
    CircleImg,
    ChkAndTxt,
    Col2Grid,
    ColAccent,
    ColAccent2,
    ColBlack,
    ColBody,
    ColGray1,
    ColGray2,
    ColRed,
    ColPrimary,
    ConfirmInfoItem,
    ConsumerPrice,
    Container,
    ContainerFit,
    ContainerPadding,
    ContainerMap,
    CusModalTit,
    DashDivider,
    DateInfo,
    DaumWrapper,
    DcBanner,
    DcSlideWrapper,
    DescInput,
    DesignerInfo,
    DesignerName,
    DesignerPrices,
    DesignerProfile,
    DesignerRates,
    DesignerRates2,
    DesignerSns,
    DetailDcBanner,
    DetailContents,
    Divider,
    FaqItem,
    FaqContent,
    FieldSet,
    FileFullLabel,
    FileSquareLabel,
    FixMainPC,
    FloatSetting,
    Flx,
    Flxbt,
    FlxbtFull,
    FlxbtFullMb10,
    FlxbtFullMb15,
    FlxbtFullMb20,
    FlxbtFullMb30,
    FlxbtFullMb40,
    FlxbtFullMb50,
    FlxGap10,
    FlxMiddle,
    FlxMiddleMb10,
    FlxMiddleMb15,
    FlxMiddleMb20,
    FlxMiddleMb30,
    FlxMiddleMb50,
    FlxRight,
    FooterBar,
    FooterBg,
    FooterFlxGap10,
    FooterImg,
    FooterInner,
    FooterItem,
    FooterLink,
    FooterList,
    Frame,
    FullImg,
    FullLine,
    fz14,
    fz14Bold,
    Fz15,
    Fz15Bold,
    Fz16Medium,
    Fz18Bold,
    Fz18Medium,
    Fz20Bold,
    Fz30Bold,
    Fz45Medium,
    Gap10,
    Gap15,
    Gap20,
    Gap30,
    GridImg,
    HalfField,
    HistoryList,
    HistoryServices,
    ImgGradient,
    IndexTab,
    InfoLabel,
    InfoLabelR,
    InfoLabelM,
    InfoValue,
    InputHidden,
    InputWrapper,
    ItemDesigner,
    ItemHalf,
    ItemHalf2,
    ItemInfoCol2,
    ItemReview,
    JoinField,
    JoinFileLabel,
    JoinInput,
    JoinInputPlc15,
    KeywordWrapper,
    LineCenter,
    LineGrayBox,
    LinkFlxbtFull,
    LinkFlxMiddle,
    LinkFull,
    LinkLogin,
    List,
    ListCol2,
    ListCol3,
    ListCol4,
    ListColumn,
    LiStyled,
    LoginList,
    LoginLogo,
    Main,
    MainPc,
    MapLayout,
    MapNav,
    MapStep1,
    MapStep2ul,
    Mb1rem,
    Mb5,
    Mb10,
    Mb15,
    Mb20,
    Mb30,
    Mb40,
    Mb50,
    ModalHeader,
    ModalPhoto,
    MyHeader,
    MyLink,
    NavBar,
    NavHeight,
    NavLink,
    NavLogo,
    NavStepWrapper,
    NavTitle,
    NoticeItem,
    NoticeLabel,
    NoticeTitle,
    OffItem,
    OffList,
    OnPayment,
    OpenHoursValue,
    OptionBox,
    OrgPrice,
    Pad20,
    PadVert10,
    PadVert20,
    PadVert30,
    PadVert30PopOver,
    Paragraph,
    PcBg,
    PcContents,
    PcLogo,
    PcSchWrapper,
    PhotoGrid,
    PhotoGridCol2,
    PhotoItem,
    PopOver,
    PopOverAbsol,
    PopShortMsg,
    PossibleInput,
    PostImg,
    PriceItem,
    PriceItemBorder,
    ProfileTag,
    ProfileTagGreen,
    QnaAnswerHeader,
    RadioLabel,
    RangeTrack,
    RangeTrackWrapper,
    RangeThumb,
    RangeWrapper,
    RateScore,
    ReviewDate,
    ReviewStars,
    ReviewThumb,
    RoundImg,
    RoundProfile,
    RsvServiceList,
    RsvStatus,
    RsvStatusGreen,
    RsvStatusPink,
    RsvTab,
    RsvTermBox,
    SchInputWrapper,
    SchKeyword,
    SearchTop,
    SearchBottom,
    SectionDivider,
    SectionDivider50,
    Select,
    SelectSns,
    SelectSnsCurrent,
    SelectSnsImg,
    SelectSnsOptions,
    SettingOptions,
    ShopAddress,
    ShortMap,
    Slide,
    Slide2,
    SlideTxt,
    Spacing10,
    Spacing20,
    Spacing30,
    Spacing40,
    Spacing36,
    Spacing50,
    Spacing70,
    Spacing100,
    SpotBannerWrapper,
    SnsLoginTit,
    Stars,
    StarsBl,
    StickyBtm,
    StyleInput,
    StyleItem,
    SwipeUpContainer,
    SwipeUpHandle,
    TabAccent,
    TabAccent2,
    TabBlack,
    TabItem,
    TableWrapper,
    TabMap,
    TabService,
    TermOver,
    DesignerTabs,
    TabWrapper,
    TermHeader,
    TermItem,
    TermItem2,
    TermModal,
    Textbox,
    TextBoxCount,
    TextBoxWrapper,
    TextLine,
    TextLineAutosize,
    TimeItem,
    TimeItem2,
    TimeLeft,
    ToggleShow,
    TxCent,
    TxRight,
    TxtDivider,
    Underline,
    VertDivider,
    WarnInput,
    Fz15Block,
};
