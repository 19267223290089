import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    FixMainPC,
    MainPc,
    PcBg,
    PcContents,
    PcLogo,
    Fz45Medium,
    ColAccent,
    KeywordWrapper,
    FullImg,
    PcSchWrapper,
    SchKeyword,
    JoinInputPlc15,
    Gap20,
    FlxMiddle,
    Fz20Bold,
    Pad20,
    Mb20,
    Fz15Bold,
    Gap15,
    BtnFullModal,
} from '@/css/style';
import { useDesignerQuery, useDesignerSearchQuery } from '../services/designerService';
import { useShopDiscountQuery } from '../services/shopService';
import useDisCountStore from '@/stores/common/discount';
import useSearchStore from '../stores/common/search';
import useShowStore from '@/stores/common/show';
import NewModal from '@/components/NewModal';
import SearchResult from '@/screens/common/SearchResult';
import arrowDownWh from '@/assets/img/arrow_down_thick_wh.svg';
import icSearch from '@/assets/img/ic_search_green.svg';
import imgAppApple from '@/assets/img/img_app_apple.svg';
import imgAppGoogle from '@/assets/img/img_app_google.svg';
import logo from '@/assets/img/logo.svg';
import qrcode_google from '@/assets/img/qrcode_google.png';
import qrcode_apple from '@/assets/img/qrcode_apple.png';

export default function PcOnly() {
    const ss = useShowStore();
    const search = useSearchStore();
    const [googleModal, setGoogleModal] = useState(false);
    const [appleModal, setAppleModal] = useState(false);
    const { refetch } = useDesignerSearchQuery({
        search_text: search.keyword,
    });

    useEffect(() => {
        requestSearchDesigner();
    }, [search.byButton]);

    const requestSearchDesigner = () => {
        if (!search.keyword) {
            if (search.isSearch) {
             search.setIsSearch(false);
            }
            return;
        } else {
            refetch();
            if (!search.isSearch) {
                search.toggleIsSearch();
            }
        }
    };
    return (
        <>
            <FixMainPC>
                <PcBg />
                <MainPc>
                    {
                        //                    <PcLogo><img src={logo} alt="머리할래" /> </PcLogo>
                    }
                    <PcContents style={{position:'absolute', top: '42%', transform: 'translateY(-42%)'}}>
                        <Fz45Medium>
                            내가 찾는{' '}
                            <ColAccent as="strong">
                                헤어 디자이너
                                <br />
                                머리할래
                            </ColAccent>{' '}
                            에서 찾아보세요!
                        </Fz45Medium>

                        <PcSchWrapper $mb="2.3077rem" style={{position: 'relative', top: '20px'}}>
                        {/* <PcSchWrapper $mb="3.3077rem" style={{position: 'relative', top: '0px'}}> */}
                            <JoinInputPlc15
                                type="text"
                                value={search.keyword}
                                maxLength="10"
                                onChange={(e) => {
                                    const { value } = e.target;
                                    search.setKeyword(value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        requestSearchDesigner();
                                    }
                                }}
                                placeholder="디자이너 또는 헤어샵을 검색해 주세요!"
                            />
                            <button type="button" onClick={requestSearchDesigner}>
                                <img src={icSearch} alt="찾기" />
                            </button>
                        </PcSchWrapper>
                        <KeywordWrapper style={{position: 'relative', top: '40px'}}>
                            <SchKeyword
                                onClick={() => {
                                    search.setKeyword('펌');
                                    search.toggleByButton();
                                }}
                            >
                                펌
                            </SchKeyword>
                            <SchKeyword
                                onClick={() => {
                                    search.setKeyword('컷');
                                    search.toggleByButton();
                                }}
                            >
                                컷
                            </SchKeyword>
                            <SchKeyword
                                onClick={() => {
                                    search.setKeyword('염색');
                                    search.toggleByButton();
                                }}
                            >
                                염색
                            </SchKeyword>
                            <SchKeyword
                                onClick={() => {
                                    search.setKeyword('드라이');
                                    search.toggleByButton();
                                }}
                            >
                                드라이
                            </SchKeyword>
                            {/* <SchKeyword
                                onClick={() => {
                                    search.setKeyword('샛별헤어');
                                    search.toggleByButton();
                                }}
                            >
                                샛별헤어
                            </SchKeyword>
                            <SchKeyword
                                onClick={() => {
                                    search.setKeyword('플로움헤어디자인');
                                    search.toggleByButton();
                                }}
                            >
                                플로움헤어디자인
                            </SchKeyword>
                            <SchKeyword
                                onClick={() => {
                                    search.setKeyword('첨단');
                                    search.toggleByButton();
                                }}
                            >
                                첨단
                            </SchKeyword> */}
                        </KeywordWrapper>
                        <Fz20Bold $col="#fff" $mb="2.3077rem">
                            <FlxMiddle>
                                머리할래 앱 다운로드
                                <Gap15 />
                                <img src={arrowDownWh} alt="" />
                            </FlxMiddle>
                        </Fz20Bold>
                        <FlxMiddle>
                            <Link onClick={() => setGoogleModal(true)}>
                                <img
                                    src={imgAppGoogle}
                                    alt="머리할래 앱 구글플레이 다운로드"
                                    title="머리할래 앱 구글플레이 다운로드"
                                />
                            </Link>
                            <Gap20 />
                            <Link onClick={() => setAppleModal(true)}>
                                <img
                                    src={imgAppApple}
                                    alt="머리할래 앱 앱스토어 다운로드"
                                    title="머리할래 앱 앱스토어 다운로드"
                                />
                            </Link>
                        </FlxMiddle>
                    </PcContents>
                </MainPc>
            </FixMainPC>
            <NewModal contentLabel="구글스토어" ariaHideApp={false} isOpen={googleModal}>
                {/* design center and font */}
                <h1 style={{color:'000', textAlign:'center', fontSize:"1.3rem", fontWeight:700, marginTop: '30px'}}>휴대전화에 머리할래 설치하기</h1>
                <h2 style={{textAlign:'center', fontSize:"1rem", margin: '15px 15px 0'}}>앱을 설치하려면 휴대전화로 QR 코드를 스캔하거나 휴대전화에서 Google Play로 이동하세요.</h2>
                <FullImg src={qrcode_google} alt="머리할래 앱 구글플레이 QR코드" />
                <Pad20>
                    <BtnFullModal
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        type="button"
                        onClick={() => setGoogleModal(false)}
                    >
                        닫기
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal contentLabel="앱스토어" ariaHideApp={false} isOpen={appleModal}>
                <h1 style={{color:'000', textAlign:'center', fontSize:"1.3rem", fontWeight:700, marginTop: '30px'}}>휴대전화에 머리할래 설치하기</h1>
                <h2 style={{textAlign:'center', fontSize:"1rem", margin: '15px 15px 0'}}>앱을 설치하려면 휴대전화로 QR 코드를 스캔하거나 휴대전화에서 Apple AppStore로 이동하세요.</h2>
                <FullImg src={qrcode_apple} alt="머리할래 앱 애플스토어 QR코드" />
                <Pad20>
                    <BtnFullModal
                        style={{ backgroundColor: '#000', color: '#fff' }}
                        type="button"
                        onClick={() => setAppleModal(false)}
                    >
                        닫기
                    </BtnFullModal>
                </Pad20>
            </NewModal>
        </>
    );
}
