import React, { useEffect } from 'react';
import {
    BorderBox,
    Mb10,
    TimeItem2,
    ListCol4,
    StickyBtm,
    LinkFull,
    RsvTab,
    TabItem,
    Paragraph,
    Fz15,
    Fz18Medium,
    Container,
    Mb20,
    ContainerPadding,
    ColRed,
    Mb30,
    FieldSet,
    PadVert10,
    SectionDivider,
} from '@/css/style';
import { format } from 'date-fns';
import { getDisabledDates } from '@/utils/helper';
import {
    useDesignerDetailQuery,
    useDesignerReservationTimeUpdateMutation,
} from '@/services/designerService';
import { useReservTimeQuery } from '@/services/reservationService';
import useDayoffsStore from '@/stores/designer/dayoffs';
import useScheduleStore from '@/stores/designer/schedule';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import RsvDayPicker from '@/components/DayPicker';
import { dayMappings } from '@/utils/helper';
import { useIsMutating } from '@tanstack/react-query';

function Schedule() {
    const ds = useDayoffsStore();
    const ss = useScheduleStore();
    const show = useShowStore();
    const us = useUserStore();
    const isMutating = useIsMutating();
    let am,
        pm = [];

    const { data: designerDetailData } = useDesignerDetailQuery(us.user.id);
    const {
        data: reservTimeData,
        refetch: refetchReservTimeData,
        isFetching,
    } = useReservTimeQuery({
        id: us.user.id,
        date: ss.date.currentDate ? ss.date.currentDate : undefined,
    });
    const DesignerReservationTimeUpdateMutation = useDesignerReservationTimeUpdateMutation(() => {
        refetchReservTimeData();
        ss.resetRequest();
    });

    useEffect(() => {
        refetchReservTimeData();
    }, [ss.date.currentDate, refetchReservTimeData]);

    useEffect(() => {
        if (designerDetailData) {
            let firstDayOfMonth = ds.daypicker.currentMonth || new Date();
            firstDayOfMonth.setDate(1);

            getDisabledDates(firstDayOfMonth, designerDetailData, ds);
            ds.setWeekTable(designerDetailData.data.holidayByDay);
            ds.setSelected(designerDetailData.data.holidayByDay);
        }
    }, [designerDetailData, ds.daypicker.currentMonth]);

    useEffect(() => {
        isFetching ? show.setLottie(true) : show.setLottie(false);
    }, [isFetching]);

    useEffect(() => {
        isFetching ? show.setLottie(true) : show.setLottie(false);
    }, [reservTimeData]);

    const requestBreakTime = () => {
        DesignerReservationTimeUpdateMutation.mutate({
            designerId: us.user.id,
            date: ss.date.currentDate,
            data: ss.date.request,
        });
    };

    if (ss.date.currentDay) {
        am = ss.timeTable.filter(
            (time) =>
                time < '12:00' &&
                time >=
                    designerDetailData?.data.shop.opening_hour.find(
                        (item) => item.day === ss.date.currentDay
                    ).from_time &&
                time <=
                    designerDetailData?.data.shop.opening_hour.find(
                        (item) => item.day === ss.date.currentDay
                    ).to_time
        );

        pm = ss.timeTable.filter(
            (time) =>
                time >= '12:00' &&
                time >=
                    designerDetailData?.data.shop.opening_hour.find(
                        (item) => item.day === ss.date.currentDay
                    ).from_time &&
                time <=
                    designerDetailData?.data.shop.opening_hour.find(
                        (item) => item.day === ss.date.currentDay
                    ).to_time
        );
    }

    return (
        <>
            <Container $pb="5rem">
                <ContainerPadding>
                    {/* <FlxMiddle>
                        <TabAccent>근무시간 설정</TabAccent>
                        <Gap10 />
                        <TabAccent>브레이크타임 설정</TabAccent>
                    </FlxMiddle> */}
                    <Mb30>
                        <BorderBox $pd="1.154rem">
                            <Paragraph $col="#000" $fz="1.154rem">
                                <ColRed>30분</ColRed> 시술 기준이며, <ColRed>당일은 30분 이전까지만 예약가능</ColRed>로 설정되어
                                있습니다.{' '}
                            </Paragraph>
                        </BorderBox>
                    </Mb30>
                </ContainerPadding>
                <form>
                    <FieldSet>
                        <ContainerPadding>
                            <Fz18Medium $mb="1.538rem">근무시간 설정</Fz18Medium>
                            <Mb30>
                                <Paragraph $col="#000" $fz="1.154rem">
                                    매장의 기본 오픈 시간이 노출됩니다. 아래 시간 중 예약불가시간을
                                    체크해 주시면 됩니다.
                                </Paragraph>
                            </Mb30>
                            <Mb20>
                                <Fz15 $col="#F33535">* 아래 설정 시간은 반복 적용됩니다.</Fz15>
                            </Mb20>
                            <RsvTab as={Mb10}>
                                <TabItem $active>일</TabItem>
                                <TabItem>월</TabItem>
                                <TabItem>화</TabItem>
                                <TabItem>수</TabItem>
                                <TabItem>목</TabItem>
                                <TabItem>금</TabItem>
                                <TabItem>토</TabItem>
                            </RsvTab>
                            <PadVert10>
                                <Fz15>오전</Fz15>
                            </PadVert10>
                            <ListCol4>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    11:00
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    11:30
                                </TimeItem2>
                            </ListCol4>
                            <PadVert10>
                                <Fz15>오후</Fz15>
                            </PadVert10>
                            <ListCol4>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    12:00
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    12:30
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    13:00
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    13:30
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    14:00
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    14:30
                                </TimeItem2>
                                <TimeItem2>
                                    <input type="checkbox" />
                                    15:00
                                </TimeItem2>
                            </ListCol4>
                            {/* <PadVert30>
                  <BorderBox $pd="1.154rem">
                    <ul>
                      <Mb15 as="li">
                        <Fz15Bold $col="#000">브레이크 타임</Fz15Bold>
                      </Mb15>
                      <Mb15 as="li">
                        <ItemInfoCol2>
                          <InfoLabel>운영자</InfoLabel>
                          <InfoValue>오전 12:00, 오전 12:30</InfoValue>
                        </ItemInfoCol2>
                      </Mb15>
                      <li>
                        <ItemInfoCol2>
                          <InfoLabel>디자이너</InfoLabel>
                          <InfoValue>오후 02:00</InfoValue>
                        </ItemInfoCol2>
                      </li>
                    </ul>
                  </BorderBox>
                </PadVert30>
                <BtnFullLineGreen onClick={openModal}>
                  근무시간 보기
                </BtnFullLineGreen> */}
                            <StickyBtm>
                                <LinkFull>저장</LinkFull>
                            </StickyBtm>
                        </ContainerPadding>
                    </FieldSet>

                    <FieldSet $visible>
                        <ContainerPadding>
                            <Fz18Medium $mb="1.538rem">브레이크타임 설정</Fz18Medium>
                            <Mb30>
                                <Paragraph $col="#000" $fz="1.154rem">
                                    {designerDetailData?.data?.title ? `${designerDetailData?.data?.title}님 `:''}휴식 시간을 선택하시면 예약 불가 시간으로 체크됩니다.
                                </Paragraph>
                            </Mb30>
                            <RsvDayPicker
                                mode="single"
                                selected={new Date(ss.date.currentDate)}
                                onSelect={(day) => {
                                    ss.setCurrentDate(day ? format(day, 'yyyy-MM-dd') : null);
                                }}
                                onMonthChange={(date) => {
                                    const firstDayOfMonth = new Date(
                                        date.getFullYear(),
                                        date.getMonth(),
                                        1
                                    );
                                    ds.setCurrentMonth(firstDayOfMonth);
                                    getDisabledDates(firstDayOfMonth, designerDetailData, ds);
                                }}
                                disabled={[
                                    {
                                        dayOfWeek: (
                                            designerDetailData?.data.shop.opening_hour || []
                                        )
                                            .filter((item) => item.type === 'dayoff')
                                            .map((item) => dayMappings[item.day].dayNumber),
                                    },
                                    { before: new Date() },
                                    ...[
                                        ...ds.daypicker.holidayByDay,
                                        ...(designerDetailData?.data.holidayByDate || []).map(
                                            (items) => {
                                                return items.date;
                                            }
                                        ),
                                    ]
                                        .filter((date) => {
                                            const workDates =
                                                designerDetailData?.data.workDate || [];
                                            return !workDates
                                                .map((items) => items.date)
                                                .includes(date);
                                        })
                                        .map((date) => new Date(date + 'T00:00:00')),
                                ]}
                                modifiers={{
                                    holiday: [
                                        ...ds.daypicker.holidayByDay,
                                        ...(designerDetailData?.data.holidayByDate || []).map(
                                            (items) => {
                                                return items.date;
                                            }
                                        ),
                                    ].map((date) => new Date(date + 'T00:00:00')),
                                    work: [
                                        ...(designerDetailData?.data.workDate || []).map(
                                            (items) => {
                                                return items.date;
                                            }
                                        ),
                                    ].map((date) => new Date(date + 'T00:00:00')),
                                    shop_holiday: [
                                        {
                                            dayOfWeek: (
                                                designerDetailData?.data.shop.opening_hour || []
                                            )
                                                .filter((item) => item.type === 'dayoff')
                                                .map((item) => dayMappings[item.day].dayNumber),
                                        },
                                    ],
                                }}
                                modifiersClassNames={{
                                    holiday: 'offday',
                                    work: 'workday',
                                    shop_holiday: 'offday',
                                }}
                            />
                        </ContainerPadding>
                        <SectionDivider />
                        {reservTimeData && (
                            <>
                                <ContainerPadding>
                                    {am.length > 0 && (
                                        <>
                                            <Mb10>
                                                <Fz15>오전</Fz15>
                                            </Mb10>
                                            <ListCol4>
                                                {/* ss.timeTable
                                            .slice(
                                                ss.timeTable.indexOf(
                                                    designerDetailData?.data.shop.opening_hour.find(
                                                        (item) => item.day === ss.date.currentDay
                                                    ).from_time
                                                ),
                                                ss.timeTable.indexOf(
                                                    designerDetailData?.data.shop.opening_hour.find(
                                                        (item) => item.day === ss.date.currentDay
                                                    ).to_time
                                                ) + 1
                                            ) */}
                                                {am.map((items) => {
                                                    const isActive =
                                                        reservTimeData.data.availableTime.includes(
                                                            items
                                                        );
                                                    const isExist = ss.date.request.some(
                                                        (item) => item.time === items
                                                    );

                                                    const isReserved =
                                                        reservTimeData.data.reservation.hasOwnProperty(
                                                            items
                                                        );

                                                    return (
                                                        <TimeItem2
                                                            key={items}
                                                            id={items}
                                                            $active={!isActive ? !isExist : isExist}
                                                            className={isReserved && 'disabled'}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id={items}
                                                                value={items}
                                                                onChange={() => {
                                                                    ss.toggleTime(
                                                                        items,
                                                                        isActive ? false : true
                                                                    );
                                                                }}
                                                                checked={
                                                                    !isActive ? !isExist : isExist
                                                                }
                                                            />
                                                            {items}
                                                        </TimeItem2>
                                                    );
                                                })}
                                            </ListCol4>
                                        </>
                                    )}
                                    {pm.length > 0 && (
                                        <>
                                            <PadVert10>
                                                <Fz15>오후</Fz15>
                                            </PadVert10>
                                            <ListCol4 as={Mb30}>
                                                {pm.map((items) => {
                                                    const isActive =
                                                        reservTimeData.data.availableTime.includes(
                                                            items
                                                        );
                                                    const isExist = ss.date.request.some(
                                                        (item) => item.time === items
                                                    );

                                                    const isReserved =
                                                        reservTimeData.data.reservation.hasOwnProperty(
                                                            items
                                                        );

                                                    return (
                                                        <TimeItem2
                                                            key={items}
                                                            id={items}
                                                            $active={!isActive ? !isExist : isExist}
                                                            className={isReserved && 'disabled'}
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id={items}
                                                                value={items}
                                                                onChange={() => {
                                                                    ss.toggleTime(
                                                                        items,
                                                                        isActive ? false : true
                                                                    );
                                                                }}
                                                                checked={
                                                                    !isActive ? !isExist : isExist
                                                                }
                                                            />
                                                            {items}
                                                        </TimeItem2>
                                                    );
                                                })}
                                            </ListCol4>
                                        </>
                                    )}
                                </ContainerPadding>
                                <StickyBtm
                                    onClick={() => requestBreakTime()}
                                    disabled={!!isMutating}
                                >
                                    <LinkFull $active>저장</LinkFull>
                                </StickyBtm>
                            </>
                        )}
                    </FieldSet>
                </form>
            </Container>
            {/* 근무시간 확인 모달 */}
            {/* <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="근무시간 확인"
                $visible
            >
                <Pad20>
                    <FlxbtFull>
                        <Fz18Bold>근무시간 확인</Fz18Bold>
                        <button type="button">
                            <img src={icCloseModal} alt="닫기" />
                        </button>
                    </FlxbtFull>
                    <PadVert20>
                        <Mb10>
                            <BorderBox $pd="1.154rem">
                                <ul>
                                    <Mb15 as="li">
                                        <Fz15Bold $col="#000">월요일</Fz15Bold>
                                    </Mb15>
                                    <Mb15 as="li">
                                        <ItemInfoCol2>
                                            <InfoLabel>근무시간</InfoLabel>
                                            <InfoValue>
                                                <ColAccent2>휴무</ColAccent2>
                                            </InfoValue>
                                        </ItemInfoCol2>
                                    </Mb15>
                                    <li>
                                        <ItemInfoCol2>
                                            <InfoLabel>휴무시간</InfoLabel>
                                            <InfoValue>-</InfoValue>
                                        </ItemInfoCol2>
                                    </li>
                                </ul>
                            </BorderBox>
                        </Mb10>
                        <Mb10>
                            <BorderBox $pd="1.154rem">
                                <ul>
                                    <Mb15 as="li">
                                        <Fz15Bold $col="#000">화요일</Fz15Bold>
                                    </Mb15>
                                    <Mb15 as="li">
                                        <ItemInfoCol2>
                                            <InfoLabel>근무시간</InfoLabel>
                                            <InfoValue>오전 10:00 ~ 오후 04:30</InfoValue>
                                        </ItemInfoCol2>
                                    </Mb15>
                                    <li>
                                        <ItemInfoCol2>
                                            <InfoLabel>휴무시간</InfoLabel>
                                            <InfoValue>오전 11:30, 오후 01:00</InfoValue>
                                        </ItemInfoCol2>
                                    </li>
                                </ul>
                            </BorderBox>
                        </Mb10>
                        <Mb10>
                            <BorderBox $pd="1.154rem">
                                <ul>
                                    <Mb15 as="li">
                                        <Fz15Bold $col="#000">수요일</Fz15Bold>
                                    </Mb15>
                                    <Mb15 as="li">
                                        <ItemInfoCol2>
                                            <InfoLabel>근무시간</InfoLabel>
                                            <InfoValue>오전 10:00 ~ 오후 04:30</InfoValue>
                                        </ItemInfoCol2>
                                    </Mb15>
                                    <li>
                                        <ItemInfoCol2>
                                            <InfoLabel>휴무시간</InfoLabel>
                                            <InfoValue>오전 11:30, 오후 01:00</InfoValue>
                                        </ItemInfoCol2>
                                    </li>
                                </ul>
                            </BorderBox>
                        </Mb10>
                        <Mb10>
                            <BorderBox $pd="1.154rem">
                                <ul>
                                    <Mb15 as="li">
                                        <Fz15Bold $col="#000">목요일</Fz15Bold>
                                    </Mb15>
                                    <Mb15 as="li">
                                        <ItemInfoCol2>
                                            <InfoLabel>근무시간</InfoLabel>
                                            <InfoValue>
                                                <ColAccent2>휴무</ColAccent2>
                                            </InfoValue>
                                        </ItemInfoCol2>
                                    </Mb15>
                                    <li>
                                        <ItemInfoCol2>
                                            <InfoLabel>휴무시간</InfoLabel>
                                            <InfoValue>-</InfoValue>
                                        </ItemInfoCol2>
                                    </li>
                                </ul>
                            </BorderBox>
                        </Mb10>
                        <Mb10>
                            <BorderBox $pd="1.154rem">
                                <ul>
                                    <Mb15 as="li">
                                        <Fz15Bold $col="#000">금요일</Fz15Bold>
                                    </Mb15>
                                    <Mb15 as="li">
                                        <ItemInfoCol2>
                                            <InfoLabel>근무시간</InfoLabel>
                                            <InfoValue>오전 10:00 ~ 오후 04:30</InfoValue>
                                        </ItemInfoCol2>
                                    </Mb15>
                                    <li>
                                        <ItemInfoCol2>
                                            <InfoLabel>휴무시간</InfoLabel>
                                            <InfoValue>오전 11:30, 오후 01:00</InfoValue>
                                        </ItemInfoCol2>
                                    </li>
                                </ul>
                            </BorderBox>
                        </Mb10>
                        <Mb10>
                            <BorderBox $pd="1.154rem">
                                <ul>
                                    <Mb15 as="li">
                                        <Fz15Bold $col="#000">토요일</Fz15Bold>
                                    </Mb15>
                                    <Mb15 as="li">
                                        <ItemInfoCol2>
                                            <InfoLabel>근무시간</InfoLabel>
                                            <InfoValue>오전 10:00 ~ 오후 04:30</InfoValue>
                                        </ItemInfoCol2>
                                    </Mb15>
                                    <li>
                                        <ItemInfoCol2>
                                            <InfoLabel>휴무시간</InfoLabel>
                                            <InfoValue>오전 11:30, 오후 01:00</InfoValue>
                                        </ItemInfoCol2>
                                    </li>
                                </ul>
                            </BorderBox>
                        </Mb10>
                        <BorderBox $pd="1.154rem">
                            <ul>
                                <Mb15 as="li">
                                    <Fz15Bold $col="#000">일요일</Fz15Bold>
                                </Mb15>
                                <Mb15 as="li">
                                    <ItemInfoCol2>
                                        <InfoLabel>근무시간</InfoLabel>
                                        <InfoValue>오전 10:00 ~ 오후 04:30</InfoValue>
                                    </ItemInfoCol2>
                                </Mb15>
                                <li>
                                    <ItemInfoCol2>
                                        <InfoLabel>휴무시간</InfoLabel>
                                        <InfoValue>오전 11:30, 오후 01:00</InfoValue>
                                    </ItemInfoCol2>
                                </li>
                            </ul>
                        </BorderBox>
                    </PadVert20>
                    <ListCol2>
                        <BtnHalfModalBlack>수정</BtnHalfModalBlack>
                        <BtnHalfModal>확인</BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </Modal> */}
            {/* //근무시간 확인 모달 */}
        </>
    );
}
export default Schedule;
