import React, { useEffect, useRef } from 'react';
import NewModal from '@/components/NewModal';
import { Link, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import {
    ListCol2,
    BtnHalfModal,
    PopShortMsg,
    DesignerName,
    BtnLineGray,
    Fz15Bold,
    HistoryList,
    ListColumn,
    TabAccent2,
    Mb1rem,
    PadVert30,
    ColAccent2,
    Paragraph,
    TxCent,
    Flxbt,
    TxtDivider,
    ItemHalf2,
    Fz16Medium,
    BorderBox,
    Container,
    ContainerPadding,
    SectionDivider,
    FlxMiddle,
    FlxbtFull,
    Fz15,
    Gap10,
    Mb20,
    ColAccent,
    Pad20,
    NavHeight,
    BorderBoxMb,
    FlxMiddleMb20,
    FlxbtFullMb10,
    FlxbtFullMb20,
    BtnHalfModalBlack,
    LinkFlxbtFull,
    ColBlack,
    LineCenter,
    TabWrapper,
    Divider,
    HistoryServices,
    Mb30,
} from '@/css/style';
import { differenceInDays, format, parse } from 'date-fns';
import { ko } from 'date-fns/locale';
import {
    useReservationQuery,
    useReservationSummaryQuery,
    useReservationUpdateMutation,
} from '@/services/reservationService';
import { useIntersectionObserver } from '@/utils/helper';
import useHistoryStore from '@/stores/user/history';
import useReviewStore from '@/stores/user/review';
import useShowStore from '@/stores/common/show';
import useUserStore from '@/stores/user/user';
import NoContent from '@/components/NoContent';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import arrowRightThinGray from '@/assets/img/arrow_right_thin_gray.svg';
import icReviewRed from '@/assets/img/ic_review_red.svg';
import icRsvInfo from '@/assets/img/ic_rsv_info.svg';
import { useIsMutating } from '@tanstack/react-query';

function History() {
    const { navigate } = useOutletContext();
    const hs = useHistoryStore();
    const rs = useReviewStore();
    const us = useUserStore();
    const ss = useShowStore();
    const containerRef = useRef(null);
    const {
        data: reservationData,
        refetch: refetchReservationData,
        fetchNextPage,
        hasNextPage,
        isFetchingNextPage,
    } = useReservationQuery(hs.selectedStatus);

    const {
        data: summaryData,
        refetch: refetchSummaryData,
        isLoading,
    } = useReservationSummaryQuery(us.user.id);

    const observer = useIntersectionObserver(
        fetchNextPage,
        containerRef,
        reservationData,
        isFetchingNextPage
    );

    useEffect(() => {
        return () => {
            hs.terminate();
        };
    }, []);

    useEffect(() => {
        isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [isLoading]);

    const ReservationUpdateMutation = useReservationUpdateMutation(() => {
        refetchReservationData();
        refetchSummaryData();
    });
    // const navigate = useNavigate();
    const location = useLocation();

    const isMutating = useIsMutating();

    const requestCancel = () => {
        ReservationUpdateMutation.mutate({
            reservId: hs.selectedReservId,
            data: { status: 'Cancel' },
        });
        hs.showModal(false);
    };
    return (
        <>
            <Container>
                {/* 예약 상단 블록 */}
                <ContainerPadding>
                    {/* 예약내역 요약 박스 */}
                    <Mb30>
                        <Mb1rem>
                            <BorderBox>
                                <FlxMiddleMb20 $jc="center">
                                    <img src={icRsvInfo} />
                                    <Gap10 />
                                    <Fz16Medium>
                                        <ColAccent>{us.user.name}</ColAccent>
                                        님의 총 예약{' '}
                                        <strong>{summaryData?.data.total.count}</strong> 건
                                    </Fz16Medium>
                                </FlxMiddleMb20>
                                <Paragraph $col="#000" $fz="1.154rem" $ta="center">
                                    금일까지 총 예약 금액은{' '}
                                    <ColAccent2>
                                        {summaryData?.data.total.price.toLocaleString()}
                                    </ColAccent2>
                                    원이며
                                    <br />
                                    할인받으신 금액은 총{' '}
                                    <ColAccent2>
                                        {summaryData?.data.total.discount_price.toLocaleString()}
                                    </ColAccent2>
                                    원입니다.
                                </Paragraph>
                            </BorderBox>
                        </Mb1rem>
                        <BorderBox>
                            <Fz16Medium as={FlxbtFull}>
                                <ItemHalf2>
                                    <Fz16Medium $col="#757575">예약방문</Fz16Medium>
                                    <ColBlack>
                                        {summaryData?.data.status.find(
                                            (item) => item.value === 'Complete'
                                        )?.total_count || 0}{' '}
                                        건
                                    </ColBlack>
                                </ItemHalf2>
                                <TxtDivider />
                                <ItemHalf2>
                                    <Fz16Medium $col="#757575">예약취소</Fz16Medium>
                                    <Fz16Medium>
                                        {summaryData?.data.status.find(
                                            (item) => item.value === 'Cancel'
                                        )?.total_count || 0}{' '}
                                        건
                                    </Fz16Medium>
                                </ItemHalf2>
                            </Fz16Medium>
                        </BorderBox>
                    </Mb30>
                    {/* //예약내역 요약 박스 */}
                    <TxCent>
                        <img src={icReviewRed} />
                        &nbsp;&nbsp;
                        <Fz15 $col="#000">
                            작성 가능한 후기가 {summaryData?.data.remain_review_count}건 있습니다.
                        </Fz15>
                    </TxCent>
                </ContainerPadding>
                {/* //예약 상단 블록 */}
                <SectionDivider />
                <ContainerPadding>
                    {/* 목록 필터 */}
                    <Flxbt as={Mb20}>
                        <TabWrapper>
                            <TabAccent2
                                $active={hs.category.all}
                                onClick={() => {
                                    hs.toggleCategory('');
                                }}
                            >
                                전체
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.category.New}
                                onClick={() => {
                                    hs.toggleCategory('New');
                                }}
                            >
                                예약
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.category.Confirm}
                                onClick={() => {
                                    hs.toggleCategory('Confirm');
                                }}
                            >
                                확정
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.category.Complete}
                                onClick={() => {
                                    hs.toggleCategory('Complete');
                                }}
                            >
                                완료
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.category.NoShow}
                                onClick={() => {
                                    hs.toggleCategory('NoShow');
                                }}
                            >
                                노쇼
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.category.Cancel}
                                onClick={() => {
                                    hs.toggleCategory('Cancel');
                                }}
                            >
                                취소
                            </TabAccent2>
                            ・
                            <TabAccent2
                                $active={hs.category.Reject}
                                onClick={() => {
                                    hs.toggleCategory('Reject');
                                }}
                            >
                                거절
                            </TabAccent2>
                        </TabWrapper>
                    </Flxbt>
                    {/* 목록 필터 */}
                </ContainerPadding>
                <HistoryList>
                    <ContainerPadding>
                        <ListColumn>
                            {!(
                                reservationData?.pages &&
                                reservationData?.pages[0].data.docs.length > 0
                            ) && <NoContent message="검색된 예약이 없습니다." />}
                            {reservationData?.pages.map((page) =>
                                page.data.docs.map((items) => (
                                    <BorderBoxMb key={items.id}>
                                        <FlxbtFullMb20>
                                            {items.status === 'Confirm' ? (
                                                <ColAccent>예약완료</ColAccent>
                                            ) : items.status === 'Complete' ? (
                                                <span>방문완료</span>
                                            ) : items.status === 'Reject' ? (
                                                <span>예약거절</span>
                                            ) : items.status === 'NoShow' ? (
                                                <span>노쇼</span>
                                            ) : items.status === 'Cancel' ? (
                                                <ColAccent2>예약취소</ColAccent2>
                                            ) : null}
                                            <span>
                                                {format(
                                                    parse(
                                                        items.date + ' ' + items.time,
                                                        'yyyy-MM-dd HH:mm',
                                                        new Date()
                                                    ),
                                                    'yyyy. MM. dd (E)',
                                                    { locale: ko }
                                                )}{' '}
                                                {items.time}
                                            </span>
                                        </FlxbtFullMb20>
                                        <LinkFlxbtFull
                                            to={`/history/${items.id}`}
                                            $mb="0.77rem"
                                            state={{ previousUrl: location.pathname }}
                                        >
                                            <Fz15Bold $col="#00bf9d">
                                                {items.designer.shop.name}
                                            </Fz15Bold>
                                            <FlxMiddle>
                                                상세보기 <img src={arrowRightThinGray} />
                                            </FlxMiddle>
                                        </LinkFlxbtFull>
                                        <DesignerName $maw="100%">
                                            {items.designer.title}
                                        </DesignerName>
                                        <Divider $mt="1.154rem" $mb="1.154rem" />
                                        <HistoryServices>
                                            {items.menus.map((menus) => (
                                                <FlxbtFullMb10 key={menus._id}>
                                                    <Fz15 $col="#000">{menus.name}</Fz15>
                                                    <Fz15 $col="#000">
                                                        {menus.price.toLocaleString()}원
                                                    </Fz15>
                                                </FlxbtFullMb10>
                                            ))}
                                        </HistoryServices>
                                        <FlxbtFull>
                                            <ColAccent2>
                                                {differenceInDays(
                                                    new Date(),
                                                    parse(items.date, 'yyyy-MM-dd', new Date())
                                                ) > 0 &&
                                                    items.status === 'Complete' && (
                                                        <>
                                                            {differenceInDays(
                                                                new Date(),
                                                                parse(
                                                                    items.date,
                                                                    'yyyy-MM-dd',
                                                                    new Date()
                                                                )
                                                            )}
                                                            일 전 시술 받았습니다.
                                                        </>
                                                    )}
                                            </ColAccent2>
                                            {items.status === 'New' ? (
                                                <BtnLineGray
                                                    onClick={() => hs.showModal('cancel', items.id)}
                                                >
                                                    예약취소
                                                </BtnLineGray>
                                            ) : items.status === 'Complete' && !items.review ? (
                                                <BtnLineGray
                                                    $active
                                                    onClick={() => {
                                                        rs.setField('reservId', items.id);
                                                        navigate('/review', { replace: true });
                                                    }}
                                                >
                                                    리뷰작성
                                                </BtnLineGray>
                                            ) : null}
                                        </FlxbtFull>
                                    </BorderBoxMb>
                                ))
                            )}
                        </ListColumn>
                        {hasNextPage && (
                            <LineCenter ref={containerRef}>
                                <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                                <img src={arrowDownThinGray} alt="more" />
                            </LineCenter>
                        )}
                    </ContainerPadding>
                </HistoryList>
            </Container>
            {/* 리뷰 권장 모달 */}
            {/* <Modal isOpen={hs.modal.review} style={customStyles} contentLabel="리뷰 권장" $visible>
                <Pad20>
                    <PopShortMsg>
                        <Fz15Bold $col="#000" as={Mb20}>
                            윤아랑 디자이너님과
                            <br />
                            함께 하신 시간은 어떠셨나요?
                        </Fz15Bold>
                        <Fz15>
                            시간이 되신다면 후기를 남겨주세요!
                            <br />
                            고객님의 후기는 디자이너에게 큰 힘이 됩니다.
                        </Fz15>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack onClick={() => hs.showModal(false)}>
                            다음에
                        </BtnHalfModalBlack>
                        <BtnHalfModal onClick={() => navigate('/review')}>리뷰작성</BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </Modal> */}
            {/* //리뷰 권장 모달 */}
            {/* 예약취소 모달 */}
            <NewModal isOpen={hs.modal.cancel} contentLabel="예약취소" $visible>
                <Pad20>
                    <PopShortMsg>
                        <Fz15Bold $col="#000">예약을 취소하시겠습니까?</Fz15Bold>
                    </PopShortMsg>
                    <ListCol2>
                        <BtnHalfModalBlack onClick={() => hs.showModal(false)}>
                            취소
                        </BtnHalfModalBlack>
                        <BtnHalfModal onClick={() => requestCancel()} disabled={!!isMutating}>
                            확인
                        </BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
            {/* //예약취소 모달 */}
        </>
    );
}

export default History;
