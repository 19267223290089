import React, { useState, useEffect } from 'react';
import { Container as MapDiv, NaverMap, useNavermaps, InfoWindow, Marker } from 'react-naver-maps';
import { BtnRelocate } from '@/css/style';
import useLocationStore from '@/stores/common/location';
import useShowStore from '@/stores/common/show';
import icResetBl from '@/assets/img/ic_reset_bl.svg';
import { useNavigate, useOutletContext } from 'react-router-dom';

function LocationMap() {
    const ls = useLocationStore();
    const ss = useShowStore();
    const { navigate, navermaps } = useOutletContext();

    const [map, setMap] = useState(null); // 지도 상태
    const [marker, setMarker] = useState(null); // 마커 상태

    useEffect(() => {
        const setLocationAndMarker = (location) => {
            map.setCenter(location);
            if (marker) {
                marker.setPosition(location);
            } else {
                const newMarker = new navermaps.Marker({
                    position: location,
                    map: map,
                });
                setMarker(newMarker);
            }
        };

        const handleDragEnd = () => {
            const center = map.getCenter();
            ls.setField('lat', center.lat());
            ls.setField('lng', center.lng());

            if (marker) {
                marker.setPosition(center);
            } else {
                const newMarker = new navermaps.Marker({
                    position: center,
                    map: map,
                });
                setMarker(newMarker);
            }
        };

        if (map) {
            if (ls.lat !== '' && ls.lng !== '') {
                const location = new navermaps.LatLng(ls.lat, ls.lng);
                setLocationAndMarker(location);
            }

            const centerChangedListener = navermaps.Event.addListener(
                map,
                'dragend',
                handleDragEnd
            );

            return () => {
                navermaps.Event.removeListener(centerChangedListener);
            };
        }
    }, [map, marker, ls.lat, ls.lng]);

    useEffect(() => {
        ls.isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [ls.isLoading]);

    const handleRelocateClick = () => {
        ls.relocate(ls.lat, ls.lng); // 위치 다시 가져오기
        navigate(ls.prev); // 홈 페이지로 이동
    };

    return (
        <>
            <MapDiv
                style={{
                    width: '100%',
                    height: 'calc(100vh - 54px)',
                }}
            >
                <NaverMap
                    defaultZoom={16}
                    defaultMapTypeId={navermaps.MapTypeId.NORMAL}
                    ref={(ref) => setMap(ref)} // 지도 설정
                />
            </MapDiv>
            <BtnRelocate onClick={handleRelocateClick}>
                <img src={icResetBl} alt="" />이 위치로 재검색
            </BtnRelocate>
        </>
    );
}

export default LocationMap;
