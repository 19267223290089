import React, { useState } from 'react';
import NewModal from '@/components/NewModal';
import {
    Pad20,
    BtnFullModal,
    PopShortMsg,
    Fz15Bold,
    Mb1rem,
    PadVert10,
    Textbox,
    RadioLabel,
    AbsolBtm,
    LinkFull,
    Block,
    ListColumn,
    Container,
    ContainerPadding,
    Fz15,
    Mb20,
} from '@/css/style';
import { useDeleteMutation } from '@/services/authService';
import { useQueryClient } from '@tanstack/react-query';
import useUserStore from '@/stores/user/user';
import RadioBox from '@/components/RadioBox';

function MyDelete() {
    const [isOpen, setIsOpen] = useState(false);
    const [reason, setReason] = useState();
    const [etc, setEtc] = useState();
    const us = useUserStore();
    const queryClient = useQueryClient();

    const Delete = useDeleteMutation(() => {
        queryClient.clear();
        us.logout();
    });

    const handleReasonChange = (value) => {
        setReason(value);
    };

    const requestUserDelete = () => {
        Delete.mutate({ user_id: us.user_id, reason: reason, etc: etc });
    };

    return (
        <>
            <Container>
                <ContainerPadding>
                    <Block>
                        <Mb20>
                            <Fz15 $col="#000">정말 탈퇴하시겠어요?</Fz15>
                        </Mb20>
                        <Fz15>탈퇴 시 계정이 삭제되며 복구되지 않습니다.</Fz15>
                    </Block>
                    <ListColumn>
                        <Mb1rem>
                            <RadioLabel htmlFor="not_use">
                                <RadioBox
                                    id="not_use"
                                    name="reason"
                                    checked={reason === '자주 사용하지 않습니다.'}
                                    value="자주 사용하지 않습니다."
                                    onChange={() => handleReasonChange('자주 사용하지 않습니다.')}
                                />
                                자주 사용하지 않습니다.
                            </RadioLabel>
                        </Mb1rem>
                        <Mb1rem>
                            <RadioLabel htmlFor="uncompotable">
                                <RadioBox
                                    id="uncompotable"
                                    name="reason"
                                    checked={reason === '앱이 불편합니다.'}
                                    value="앱이 불편합니다."
                                    onChange={() => handleReasonChange('앱이 불편합니다.')}
                                />
                                앱이 불편합니다.
                            </RadioLabel>
                        </Mb1rem>
                        <Mb1rem>
                            <RadioLabel htmlFor="not_match">
                                <RadioBox
                                    id="not_match"
                                    name="reason"
                                    checked={reason === '찾는 매장과 디자이너가 없습니다.'}
                                    value="찾는 매장과 디자이너가 없습니다."
                                    onChange={() =>
                                        handleReasonChange('찾는 매장과 디자이너가 없습니다.')
                                    }
                                />
                                찾는 매장과 디자이너가 없습니다.
                            </RadioLabel>
                        </Mb1rem>
                        <RadioLabel htmlFor="etc">
                            <RadioBox
                                id="etc"
                                name="reason"
                                checked={reason === '기타'}
                                value="기타"
                                onChange={() => handleReasonChange('기타')}
                            />
                            기타
                        </RadioLabel>
                        {reason === '기타' && (
                            <PadVert10>
                                <Textbox
                                    rows="8"
                                    placeholder="탈퇴하시는 사유를 적어주세요"
                                    value={etc}
                                    onChange={(e) => setEtc(e.target.value)}
                                ></Textbox>
                            </PadVert10>
                        )}
                    </ListColumn>
                    <AbsolBtm>
                        <LinkFull
                            $active={reason}
                            onClick={() => {
                                reason && setIsOpen(true); // reason 값이 존재할 때만 setIsOpen(true) 호출
                            }}
                        >
                            탈퇴하기
                        </LinkFull>
                    </AbsolBtm>
                </ContainerPadding>
            </Container>
            <NewModal isOpen={isOpen} ariaHideApp={false} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">탈퇴가 완료되었습니다</Fz15Bold>
                        </Mb20>
                        <Fz15>머리할래를 이용해 주셔서 감사합니다.</Fz15>
                    </PopShortMsg>
                    <BtnFullModal $active onClick={() => requestUserDelete()}>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
        </>
    );
}
export default MyDelete;
