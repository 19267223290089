import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import {
    NavHeight,
    PopOver,
    Fz18Bold,
    Mb40,
    StickyBtm,
    Spacing20,
    BtnFullModal,
    Fz18Medium,
    DcBanner,
    BtnResetFilter,
    ImgGradient,
    SlideTxt,
    Container,
    RoundProfile,
    ItemDesigner,
    TabAccent,
    FlxbtFull,
    DateInfo,
    DesignerInfo,
    DesignerName,
    ListColumn,
    Fz16Medium,
    BtnFullLine,
    FullImg,
    Mb30,
    Mb1rem,
    BtnToggleAccent,
    ShopAddress,
    FlxMiddle,
    TabAccent2,
    Gap10,
    ContainerPadding,
    Spacing10,
    DesignerPrices,
    PriceItem,
    TxRight,
    OrgPrice,
    ConsumerPrice,
    Flxbt,
    ColAccent,
    ColAccent2,
    DashDivider,
    FlxMiddleMb30,
    Mb10,
    FlxbtFullMb40,
    ColBlack,
    FlxMiddleMb20,
    FlxbtFullMb20,
    TabWrapper,
    DesignerSns,
    DesignerRates,
    FlxbtFullMb10,
    BtnLikeInImg,
    LineCenter,
    Fz15Bold,
    RsvTab,
    TabItem,
    LineGrayBox,
    Mb20,
    Pad20,
    PopShortMsg,
    Paragraph,
    InputWrapper,
    JoinInput,
    BtnClearInput,
    ListCol2,
    BtnHalfModalBlack,
    BtnHalfModal,
    Block,
    OptionBox,
    Select,
    JoinField,
    PadVert10,
} from '@/css/style';
import useDisCountStore from '@/stores/common/discount';
import useFilterStore from '@/stores/common/filter';
import useLocationStore from '@/stores/common/location';
import Chkbox from '@/components/Chkbox';
import arrowDownGray from '@/assets/img/arrow_down_gray.svg';
import arrowUpWhite from '@/assets/img/arrow_up_wh.svg';
import icCloseModal from '@/assets/img/ic_close_modal_gray.svg';
import icLikeOffLine from '@/assets/img/ic_like_off.svg';
import icLikeOnLine from '@/assets/img/ic_like_on.svg';
import icFilter from '@/assets/img/ic_filter.svg';
import icFind from '@/assets/img/ic_find.svg';
import icReset from '@/assets/img/ic_reset.svg';
import icReview from '@/assets/img/ic_review.svg';
import { FaStar } from 'react-icons/fa';
import { useShopDiscountQuery } from '@/services/shopService';
import {
    getAddressLatLng,
    getShopDistance,
    snsTable,
    useIntersectionObserver,
} from '@/utils/helper';
import arrowDownThinGray from '@/assets/img/arrow_down_thin_gray.svg';
import NoContent from '@/components/NoContent';
import { useDesignerDiscountQuery } from '@/services/designerService';
import config from '@/config/index';
import altimg from '@/assets/img/altimg.png';
import { useBookMarkCreateMutation, useBookMarkDeleteMutation } from '@/services/userService';
import useUserStore from '@/stores/user/user';
import { differenceInDays, format, parse } from 'date-fns';
import useShowStore from '@/stores/common/show';
import { useProductCategoryQuery } from '@/services/productService';
import NewModal from '@/components/NewModal';
import icClear from '@/assets/img/ic_clear.svg';
import { useIsMutating } from '@tanstack/react-query';
import ngcImg from '@/assets/img/ngc.png';

function Discount() {
    const { navigate, navermaps } = useOutletContext();
    const ds = useDisCountStore();
    const ls = useLocationStore();
    const us = useUserStore();
    const ss = useShowStore();
    const designerRef = useRef(null);
    const shopRef = useRef(null);
    const [addresses, setAddresses] = useState([]);
    const isMutating = useIsMutating();
    const {
        data: shopDiscountData,
        shopDiscountfetchNextPage,
        shopDiscounthasNextPage,
        shopDiscountisFetchingNextPage,
    } = useShopDiscountQuery({
        lat: ls.lat,
        lng: ls.lng,
    });
    const {
        data: designerDiscountData,
        refetch: designerDiscountRefetch,
        designerDiscountfetchNextPage,
        designerDiscounthasNextPage,
        designerDiscountisFetchingNextPage,
    } = useDesignerDiscountQuery({
        lat: ls.lat,
        lng: ls.lng,
        maxdistance: ds.filter.distance,
        category: ds.filter.category
            .filter((item) => item.isActive)
            .map((item) => item.name)
            .join(', '),
        sort: Object.keys(ds.order).find((key) => ds.order[key]),
    });
    const { data: productCategoryData } = useProductCategoryQuery();
    useEffect(() => {
        if (productCategoryData) {
            ds.setFilterCategory(productCategoryData.data);
        }
    }, [productCategoryData]);

    const BookMarkCreate = useBookMarkCreateMutation(() => designerDiscountRefetch());
    const BookMarkDelete = useBookMarkDeleteMutation(() => designerDiscountRefetch());
    const observerShop = useIntersectionObserver(
        shopDiscountfetchNextPage,
        shopRef,
        shopDiscountData,
        shopDiscountisFetchingNextPage
    );
    const observerDesigner = useIntersectionObserver(
        designerDiscountfetchNextPage,
        designerRef,
        designerDiscountData,
        designerDiscountisFetchingNextPage
    );

    useEffect(() => {
        ls.isLoading ? ss.setLottie(true) : ss.setLottie(false);
    }, [ls.isLoading]);

    useEffect(() => {
        return () => {
            ds.terminate();
            ls.resetModal();
        };
    }, []);

    const applyFilter = () => {
        designerDiscountRefetch({
            lat: ls.lat,
            lng: ls.lng,
            maxdistance: ds.filter.distance,
            category: ds.filter.category
                .filter((item) => item.isActive)
                .map((item) => item.name)
                .join(', '),
            sort: Object.keys(ds.order).find((key) => ds.order[key]),
        });

        ds.setShowFilter(false);
    };

    const handleSearchAddress = () => {
        getAddressLatLng(ls.searchAddress, navermaps)
            .then((result) => {
                setAddresses(result.items);
            })
            .catch((error) => {
                // 오류가 발생한 경우에 대한 처리
                console.error('에러 발생:', error);
                // 에러 처리 로직을 구현합니다.
            });
    };

    return (
        <Container $pb="6.46rem">
            <ContainerPadding>
                {/* 할인 탭 */}
                <FlxMiddle>
                    <TabAccent
                        $active={ds.category.designer}
                        onClick={() => ds.toggleCategory('designer')}
                    >
                        디자이너
                    </TabAccent>
                    <Gap10 />
                    <TabAccent
                        $active={ds.category.banner}
                        onClick={() => ds.toggleCategory('banner')}
                    >
                        헤어샵
                    </TabAccent>
                    <Gap10 />
                    {/* <TabAccent
                        $active={ds.category.alliance}
                        onClick={() => ds.toggleCategory('alliance')}
                    >
                        제휴
                    </TabAccent> */}
                </FlxMiddle>
                {/* //할인 탭 */}
                {/* 할인 디자이너 컨테이너 */}
                <div
                    data-tab="designer"
                    style={{ display: ds.category.designer ? 'block' : 'none', paddingTop: '1rem' }}
                >
                    <Flxbt as={Mb20}>
                        {ls.lat === '' &&
                        ls.lng === '' &&
                        ls.lat_map === '' &&
                        ls.lng_map === '' ? (
                            <LineGrayBox as="button" type="button" onClick={() => ls.getLocation()}>
                                <img src={icFind} />
                                &nbsp;현위치
                            </LineGrayBox>
                        ) : (
                            <FlxMiddle>
                                <LineGrayBox
                                    as="button"
                                    type="button"
                                    onClick={() => ls.toggleModal('option')}
                                >
                                    <img src={icFind} />
                                    &nbsp;재설정
                                </LineGrayBox>
                                <Gap10 />
                                <address>{ls.addr}</address>
                            </FlxMiddle>
                        )}
                    </Flxbt>
                    {/* 목록 필터 */}
                    <FlxbtFullMb20>
                        <TabWrapper>
                            <TabAccent2
                                $active={ds.order.price}
                                onClick={() => ds.toggleOrder('price')}
                            >
                                가격낮은순
                            </TabAccent2>
                            ・
                            {ls.addr && (
                                <>
                                    <TabAccent2
                                        $active={ds.order.distance}
                                        onClick={() => ds.toggleOrder('distance')}
                                    >
                                        거리
                                    </TabAccent2>
                                    ・
                                </>
                            )}
                            <TabAccent2
                                $active={ds.order.grade}
                                onClick={() => ds.toggleOrder('grade')}
                            >
                                인기
                            </TabAccent2>
                        </TabWrapper>
                        <button onClick={() => ds.setShowFilter(true)}>
                            <img src={icFilter} alt="목록 필터창 열기" />
                        </button>
                    </FlxbtFullMb20>
                    {/* //목록 필터 */}
                    <ListColumn>
                        {!(
                            designerDiscountData?.pages &&
                            designerDiscountData?.pages[0].data.docs.length > 0
                        ) && <NoContent message="할인 중인 디자이너가 없습니다." />}
                        {designerDiscountData?.pages.map((page) =>
                            page.data.docs.map((items) => (
                                <React.Fragment key={items._id}>
                                    <ItemDesigner>
                                        <RoundProfile>
                                            <FullImg
                                                src={`${config.DESIGNER_IMG_URL}${items.designer.profileImage?.filename}?size=150`}
                                                onError={(e) => {
                                                    e.currentTarget.src = altimg;
                                                }}
                                                onClick={() =>
                                                    navigate(`/designer/${items.designer._id}`)
                                                }
                                                alt="프로필 이미지"
                                            />
                                            {us.user.user_id &&
                                                (items.isBookmarked ? (
                                                    <BtnLikeInImg
                                                        type="button"
                                                        disabled={!!isMutating}
                                                        onClick={() => {
                                                            BookMarkDelete.mutate(
                                                                items.designer._id
                                                            );
                                                        }}
                                                    >
                                                        <img src={icLikeOnLine} alt="좋아요" />
                                                    </BtnLikeInImg>
                                                ) : (
                                                    <BtnLikeInImg
                                                        type="button"
                                                        onClick={() => {
                                                            BookMarkCreate.mutate(
                                                                items.designer._id
                                                            );
                                                        }}
                                                        disabled={!!isMutating}
                                                    >
                                                        <img src={icLikeOffLine} alt="좋아요" />
                                                    </BtnLikeInImg>
                                                ))}
                                        </RoundProfile>
                                        <DesignerInfo>
                                            <Mb10>
                                                <strong>{items.designer.shop.name}</strong>
                                                &nbsp;&nbsp;&nbsp;
                                                {ls.lat !== '' && ls.lng !== '' && (
                                                    <ColAccent>
                                                        {getShopDistance(items.distance.distance)}
                                                    </ColAccent>
                                                )}
                                            </Mb10>
                                            <Flxbt as={Mb1rem}>
                                                {/* 메모: 디자이너 네임에 onclick이벤트 */}
                                                <DesignerName>{items.designer.title}</DesignerName>
                                                <FlxMiddle>
                                                    {items.designer.sns.map((sns) => (
                                                        <DesignerSns href={sns.link} key={sns._id}>
                                                            <img
                                                                src={
                                                                    snsTable.find(
                                                                        (item) =>
                                                                            item.provider ===
                                                                            sns.provider
                                                                    ).src
                                                                }
                                                                alt={sns.provider}
                                                            />
                                                        </DesignerSns>
                                                    ))}
                                                </FlxMiddle>
                                            </Flxbt>
                                            <DateInfo>
                                                <span>
                                                    {format(
                                                        parse(
                                                            items.from_date,
                                                            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                            new Date()
                                                        ),
                                                        'yyyy. MM. dd'
                                                    )}{' '}
                                                    ~{' '}
                                                    {format(
                                                        parse(
                                                            items.to_date,
                                                            "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                            new Date()
                                                        ),
                                                        'yyyy. MM. dd'
                                                    )}
                                                </span>
                                                {differenceInDays(
                                                    parse(
                                                        items.to_date,
                                                        "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                        new Date()
                                                    ),
                                                    new Date()
                                                ) > 0 && (
                                                    <ColAccent2>
                                                        {differenceInDays(
                                                            parse(
                                                                items.to_date,
                                                                "yyyy-MM-dd'T'HH:mm:ss.SSSX",
                                                                new Date()
                                                            ),
                                                            new Date()
                                                        )}
                                                        일 남음
                                                    </ColAccent2>
                                                )}
                                            </DateInfo>
                                            <ShopAddress>
                                                {`${items.designer.shop.address} ${items.designer.shop.address_detail}`}
                                            </ShopAddress>
                                        </DesignerInfo>
                                        {/* <Spacing10 /> */}
                                        <FlxbtFull>
                                            <DesignerRates>
                                                <img src={icReview} alt="후기" />
                                                <strong>{items.designer.ratingCount}</strong>
                                                <FaStar color="#FFBB00" />
                                                <strong>
                                                    {' '}
                                                    {items.designer.rating
                                                        ? items.designer.averageRating.toFixed(1)
                                                        : 0}
                                                </strong>
                                            </DesignerRates>
                                            <BtnToggleAccent
                                                $active={ds.toggleStates[items.designer._id]}
                                                onClick={() => ds.togglePrices(items.designer._id)}
                                            >
                                                시술메뉴{' '}
                                                {ds.toggleStates[items.designer._id]
                                                    ? '닫기'
                                                    : '보기'}
                                                <img
                                                    src={
                                                        ds.toggleStates[items.designer._id]
                                                            ? arrowUpWhite
                                                            : arrowDownGray
                                                    }
                                                    alt="시술메뉴 토글"
                                                />
                                            </BtnToggleAccent>
                                        </FlxbtFull>
                                        {ds.toggleStates[items.designer._id] && (
                                            <DesignerPrices $mt="1.154rem">
                                                {!(
                                                    items.designer.menus &&
                                                    items.designer.menus.length > 0
                                                ) && (
                                                    <NoContent message="등록된 시술이 없습니다." />
                                                )}
                                                {items.designer.menus.map((menus) => (
                                                    <PriceItem key={menus._id}>
                                                        <FlxbtFullMb10>
                                                            <Fz16Medium>{menus.name}</Fz16Medium>

                                                            {menus.normal_price === menus.price ? (
                                                                <TxRight>
                                                                    <ConsumerPrice>
                                                                        {menus.normal_price.toLocaleString()}
                                                                        원
                                                                    </ConsumerPrice>
                                                                </TxRight>
                                                            ) : (
                                                                <TxRight>
                                                                    <OrgPrice>
                                                                        {menus.normal_price.toLocaleString()}
                                                                    </OrgPrice>
                                                                    <ConsumerPrice>
                                                                        {menus.price.toLocaleString()}
                                                                        원
                                                                    </ConsumerPrice>
                                                                </TxRight>
                                                            )}
                                                        </FlxbtFullMb10>
                                                        <p>기장 추가</p>
                                                    </PriceItem>
                                                ))}
                                            </DesignerPrices>
                                        )}
                                    </ItemDesigner>
                                    <DashDivider style={{ marginBottom: '1.3rem' }} />
                                </React.Fragment>
                            ))
                        )}
                    </ListColumn>
                    {designerDiscounthasNextPage && (
                        <LineCenter ref={designerRef}>
                            <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                            <img src={arrowDownThinGray} alt="more" />
                        </LineCenter>
                    )}
                </div>
                {/* //할인 디자이너 컨테이너 */}
                {/* 할인 배너 컨테이너 */}
                <div
                    data-tab="banner"
                    style={{ display: ds.category.banner ? 'block' : 'none', paddingTop: '1rem' }}
                >
                    <Flxbt>
                        {ls.lat === '' &&
                        ls.lng === '' &&
                        ls.lat_map === '' &&
                        ls.lng_map === '' ? (
                            <LineGrayBox as="button" type="button" onClick={() => ls.getLocation()}>
                                <img src={icFind} />
                                &nbsp;현위치
                            </LineGrayBox>
                        ) : (
                            <FlxMiddle>
                                <LineGrayBox
                                    as="button"
                                    type="button"
                                    onClick={() => ls.toggleModal('option')}
                                >
                                    <img src={icFind} />
                                    &nbsp;재설정
                                </LineGrayBox>
                                <Gap10 />
                                <address>{ls.addr}</address>
                            </FlxMiddle>
                        )}
                    </Flxbt>

                    <ListColumn>
                        {!(
                            shopDiscountData?.pages &&
                            shopDiscountData?.pages[0].data.docs.length > 0
                        ) && <NoContent message="할인 중인 헤어샵이 없습니다." />}
                        {shopDiscountData?.pages.map((page) =>
                            page.data.docs.map((items) => (
                                <DcBanner
                                    key={items._id}
                                    onClick={() => navigate(`/banner/${items._id}`)}
                                >
                                    <ImgGradient />
                                    <FullImg
                                        src={`${config.ADVERTISEMENT_IMG_URL}${items.main_image?.filename}?size=350`}
                                        onError={(e) => (e.currentTarget.src = altimg)}
                                    />
                                    <SlideTxt>{items.shop.name}</SlideTxt>
                                </DcBanner>
                            ))
                        )}
                    </ListColumn>
                    {shopDiscounthasNextPage && (
                        <LineCenter ref={shopRef}>
                            <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                            <img src={arrowDownThinGray} alt="more" />
                        </LineCenter>
                    )}
                </div>
                {/* //할인 배너 컨테이너 */}
                {/* 할인 배너 컨테이너 */}
                <div
                    data-tab="alliance"
                    style={{ display: ds.category.alliance ? 'block' : 'none', paddingTop: '1rem' }}
                >
                    {/* <Flxbt as={Mb20}>
                        {ls.lat === '' && ls.lng === '' ? (
                            <LineGrayBox as="button" type="button" onClick={() => ls.getLocation()}>
                                <img src={icFind} />
                                &nbsp;현위치
                            </LineGrayBox>
                        ) : (
                            <FlxMiddle>
                                <LineGrayBox
                                    as="button"
                                    type="button"
                                    onClick={() => ls.toggleModal('option')}
                                >
                                    <img src={icFind} />
                                    &nbsp;재설정
                                </LineGrayBox>
                                <Gap10 />
                                <address>{ls.addr}</address>
                            </FlxMiddle>
                        )}
                    </Flxbt> */}

                    <ListColumn>
                        <DcBanner
                            key={'items._id'}
                            // onClick={() => navigate(`/banner/${items._id}`)}
                        >
                            {/* <ImgGradient /> */}
                            <a href="https://www.newgencosmetic.com" target="_blank">
                                <FullImg
                                    src={ngcImg}
                                    onError={(e) => (e.currentTarget.src = altimg)}
                                />
                            </a>
                            {/* <SlideTxt>{items.shop.name}</SlideTxt> */}
                        </DcBanner>
                        {/* {!(
                            shopDiscountData?.pages &&
                            shopDiscountData?.pages[0].data.docs.length > 0
                        ) && <NoContent message="할인 중인 헤어샵이 없습니다." />}
                        {shopDiscountData?.pages.map((page) =>
                            page.data.docs.map((items) => (
                                <DcBanner
                                    key={items._id}
                                    onClick={() => navigate(`/banner/${items._id}`)}
                                >
                                    <ImgGradient />
                                    <FullImg
                                        src={`${config.ADVERTISEMENT_IMG_URL}${items.main_image?.filename}?size=350`}
                                        onError={(e) => (e.currentTarget.src = altimg)}
                                    />
                                    <SlideTxt>{items.shop.name}</SlideTxt>
                                </DcBanner>
                            ))
                        )} */}
                    </ListColumn>
                    {/* {shopDiscounthasNextPage && (
                        <LineCenter ref={shopRef}>
                            <Fz15Bold $col="#A1A1A1">더보기</Fz15Bold>
                            <img src={arrowDownThinGray} alt="more" />
                        </LineCenter>
                    )} */}
                </div>
                {/* //할인 배너 컨테이너 */}
            </ContainerPadding>
            {/* 할인필터 모달 */}
            <PopOver $visible={ds.showFilter}>
                <Spacing20 />
                <form>
                    <ContainerPadding>
                        <FlxbtFullMb40>
                            <BtnResetFilter onClick={() => ds.resetFilter()}>
                                <img src={icReset} /> 초기화
                            </BtnResetFilter>
                            <button type="button" onClick={() => ds.setShowFilter(false)}>
                                <img src={icCloseModal} alt="닫기" />
                            </button>
                        </FlxbtFullMb40>
                        {/* 거리 범위 */}
                        <Fz18Bold $mb="2.3077rem">거리 기준</Fz18Bold>
                        <JoinField $mb="3.077rem">
                            <PadVert10>
                                <Fz15Bold>검색 노출거리</Fz15Bold>
                            </PadVert10>
                            <InputWrapper>
                                <Select
                                    onChange={(e) => ds.setFilter('distance', e.target.value)}
                                    value={ds.filter.distance}
                                >
                                    <option value="0.5">0.5km</option>
                                    <option value="1">1km</option>
                                    <option value="1.5">1.5km</option>
                                    <option value="2">2km</option>
                                    <option value="2.5">2.5km</option>
                                    <option value="3">3km</option>
                                    <option value="3.5">3.5km</option>
                                    <option value="4">4km</option>
                                    <option value="4.5">4.5km</option>
                                    <option value="5">5km</option>
                                </Select>
                            </InputWrapper>
                        </JoinField>
                        {/* //거리 범위 */}
                        {/* 카테고리 */}
                        <Fz18Bold $mb="2.3077rem">카테고리</Fz18Bold>
                        <RsvTab>
                            {productCategoryData?.data.map((item, index) => (
                                <TabItem
                                    $active={ds.filter.category[index]?.isActive}
                                    value={item.id}
                                    key={item.id}
                                    onClick={() => {
                                        ds.toggleFilterCategory(item.id);
                                    }}
                                >
                                    {item.name}
                                </TabItem>
                            ))}
                        </RsvTab>
                    </ContainerPadding>
                    <StickyBtm>
                        <BtnFullModal type="button" onClick={applyFilter} $active>
                            적용하기
                        </BtnFullModal>
                    </StickyBtm>
                </form>
            </PopOver>
            {/* //할인_필터 모달 */}
            <NewModal isOpen={ls.modal.reject} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">내 위치 설정</Fz15Bold>
                        </Mb20>
                        <Paragraph $fz="1.154rem">
                            사용기기의 설정에서 '위치정보' 사용을 허용해 주시기 바랍니다.
                        </Paragraph>
                    </PopShortMsg>
                    <BtnFullModal $active onClick={() => ls.toggleModal('reject')}>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal isOpen={ls.modal.option} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb20>
                            <Fz15Bold $col="#000">내 위치 설정</Fz15Bold>
                        </Mb20>
                        <Paragraph $fz="1.154rem">세가지 옵션 중 선택해 주세요.</Paragraph>
                    </PopShortMsg>
                    <Block>
                        <OptionBox
                            onClick={() => {
                                ls.toggleModal('option');
                                ls.getLocation();
                            }}
                        >
                            현재 위치로 재검색
                        </OptionBox>
                        <OptionBox
                            onClick={() => {
                                ls.toggleModal('option');
                                ls.toggleModal('address');
                            }}
                        >
                            주소로 위치 찾기
                        </OptionBox>
                        <OptionBox
                            onClick={() => {
                                ls.setField('prev', '/discount');
                                navigate('/map');
                            }}
                        >
                            지도로 위치 찾기
                        </OptionBox>
                    </Block>
                    <BtnFullModal onClick={() => ls.toggleModal('option')} $active>
                        확인
                    </BtnFullModal>
                </Pad20>
            </NewModal>
            <NewModal isOpen={ls.modal.address} $visible>
                <Pad20>
                    <PopShortMsg>
                        <Mb40>
                            <Fz15Bold $col="#000">위치 변경</Fz15Bold>
                        </Mb40>
                        <Mb20>
                            <InputWrapper>
                                <JoinInput
                                    type="text"
                                    placeholder="예) 판교역로 235, 한남동714"
                                    maxLength="20"
                                    onChange={(e) => ls.setField('searchAddress', e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            handleSearchAddress();
                                        }
                                    }}
                                    value={ls.searchAddress}
                                />
                                <BtnClearInput
                                    tabIndex="-1"
                                    onClick={() => {
                                        ls.setField('searchAddress', '');
                                    }}
                                >
                                    <img src={icClear} alt="지우기" />
                                </BtnClearInput>
                            </InputWrapper>
                        </Mb20>
                    </PopShortMsg>
                    <Fz18Medium
                        $mb="3.077rem"
                        $col="#00BF9D"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            ls.setField('lat', addresses[0]?.point.y);
                            ls.setField('lng', addresses[0]?.point.x);
                            ls.setField(
                                'addr',
                                addresses[0]?.address.length > 15
                                    ? `${addresses[0]?.address.substring(0, 15)}...`
                                    : addresses[0]?.address
                            );
                            ls.toggleModal('address');
                        }}
                    >
                        {addresses[0]?.address}
                    </Fz18Medium>
                    <ListCol2>
                        <BtnHalfModalBlack
                            onClick={() => {
                                ls.toggleModal('address');
                            }}
                        >
                            취소
                        </BtnHalfModalBlack>
                        <BtnHalfModal onClick={handleSearchAddress}>검색</BtnHalfModal>
                    </ListCol2>
                </Pad20>
            </NewModal>
        </Container>
    );
}
export default Discount;
